import * as React from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import { useState } from "react";

import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";
import { Box, Modal, Tooltip } from "@mui/material";
import {
  useDownloadPdfTestingMutation,
  useLazyGetRecordByIdQuery,
} from "../services/testingModule";
import { showError, showToast } from "../constants/toast";

import { LocalFireDepartmentTwoTone } from "@mui/icons-material";
import Loader from "../constants/Loader";
import { generateEncryptedKeyBody } from "../utils/crypto";
import { CommonBody } from "../types/General";
import { handleDownload } from "../utils/handleDownload";
import { useChangeLanguageMutation } from "../services/utils";
import useTranslation from "../hooks/Translation";
import { isArabicText } from "../utils/validations";

type SendRes = {
  data: any[];
  message: string;
  record_id: string;
};

function TestingResultModal(props: any) {
  const [result, setResult] = useState<any>();
  const [ques, setQues] = useState<any>([]);
  const [getById] = useLazyGetRecordByIdQuery();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [downloadFile] = useDownloadPdfTestingMutation();
  const [sendRes, setSendRes] = useState<SendRes>();
  const [path, setPath] = useState<string>("");
  const navigate = useNavigate();
  const [translatedData, setTranslatedData] = useState<any>();
  const [changeLanguage] = useChangeLanguageMutation();
  const translate = useTranslation();
  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "100%",
    maxWidth: 587,
    bgcolor: "#353e3d",
    border: "1px solid #FFFFFF8A",
    boxShadow: 24,
    borderRadius: "10px",
    p: 3,
    maxHeight: "95vh",
    overflow: "auto",
  };
  const [isRepeating, setIsRepeating] = useState(false);
  const fetchDataById = async () => {
    setIsLoading(true);
    try {
      const response = await getById(props.id).unwrap();
      setIsLoading(false);

      if (response?.status === 200) {
        setResult(response?.data);
        setIsRepeating(response?.data?.is_repeating);
        setQues(response?.data?.result);
        setSendRes({
          data: response?.data?.result,
          message: "Data from outside",
          record_id: response?.data?.id,
        });
        response?.data?.sub_category_type === 1
          ? setPath("/quiz/")
          : setPath("/flashcard-quiz/");
        console.log(ques, "ques in get by id");
      }
    } catch (error: any) {
      setIsLoading(false);
      showError(
        error?.message ||
        translate.errors.somethingWentWrong ||
        "Something went wrong"
      );
    }
  };

  const getChangeLanguage = async (download: boolean) => {
    setIsLoading(true);
    try {
      const body = {
        text: ques.map((data: any) => {
          return ({
            question: data.question,
            question_no: data.question_no,
            user_answer: data.user_answer,
            answer_option: data.answer_option,
            correct_answer: data.correct_answer
          })
        }),
      };
      console.log('change language body :', body);

      const encryptedBody = generateEncryptedKeyBody(body) as CommonBody;
      const response = await changeLanguage(encryptedBody).unwrap();

      if (response.status === 200) {
        const isArabic = isArabicText(response?.data?.[0]?.question);

        setTranslatedData(response?.data);
        if (download === true) {
          const body = {
            html_text: `<!DOCTYPE html>
                <html lang="en">
                <head>
                    <meta charset="UTF-8">
                    <meta name="viewport" content="width=device-width, initial-scale=1.0">
                    <title>Quiz</title>
                    <style>
                        body {
                            font-family: Arial, sans-serif;
                            background-color: #ffffff;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                        }
                        .quiz-container {
                            background-color: #ffffff;
                            padding: 20px;
                            width: 100%;
                        }
                        .quiz-header {
                            text-align: center;
                            margin-bottom: 20px;
                        }
                        .question {
                            font-size: 18px;
                            margin-bottom: 10px;
                            margin-top: 30px;
                        }
                        .answers {
                            list-style-type: none;
                            padding: 0;
                        }
                        .answers li {
                            background-color: #e7e7e7;
                            border-radius: 5px;
                            margin-bottom: 10px;
                            padding: 10px;
                            cursor: pointer;
                            transition: background-color 0.3s;
                        }
                        .answers li:hover {
                            background-color: #d3d3d3;
                        }
                        .quiz-footer p {
                            background-color: rgba(39, 174, 96, 0.3);
                            border-radius: 5px;
                            margin: 10px 0px;
                            padding: 10px;
                        }
                        .quiz-footer button {
                            background-color: #6200ee;
                            color: white;
                            border: none;
                            padding: 10px 20px;
                            border-radius: 5px;
                            cursor: pointer;
                            transition: background-color 0.3s;
                        }
                        .quiz-footer button:hover {
                            background-color: #3700b3;
                        }
                    </style>
                </head>
                <body dir="auto">
                
                <div class="quiz-container" dir="auto">
                    <div class="quiz-header" dir="auto">
                        <h2>${isArabic ? "اختبار" : "Testing"}</h2>
                    </div>
                    ${response?.data
                ?.map((item: any, index: number) => {
                  return `
                        <div class="question">${isArabic ? "سؤال" : "Question"} ${index + 1}: ${item?.question || ""}</div>
                        <ul class="answers">
                          ${item?.answer_option ? item?.answer_option
                      ?.map((option: any, optionIndex: number) => {
                        return `<li>${optionIndex + 1}. ${option}</li>`;
                      })
                      .join("") : ""}
                        </ul>
                        <div class="quiz-footer">
                          <p>${isArabic ? "الإجابة الصحيحة" : "Correct Answer"}: ${item?.correct_answer || ""}</p>
                        </div>`;
                })
                .join("")}
                </div>
                </body>
                </html>`,
          };

          setIsLoading(true);
          console.log('change language body :', body);

          try {
            const encryptedBody = generateEncryptedKeyBody(body) as CommonBody;
            const res = await downloadFile(encryptedBody).unwrap();
            if (res?.status === 200) {
              console.log(res, "download ka response");
              handleDownload(res?.data, "quiz");
              showToast(
                translate.toastMessages.pdfDownloadedSuccessfully ||
                "Pdf downloaded Successfully"
              );
              props.handleClose();
            }
            setIsLoading(false);
          } catch (error: any) {
            showError(
              error?.message ||
              translate.errors.somethingWentWrong ||
              "Something went wrong"
            );
            setIsLoading(false);
          }
        }
      }
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const FetchDownLoadFile = async (option: number) => {
    setIsLoading(true);
    const body1 = {
      html_text: `<!DOCTYPE html>
          <html lang="en">
          <head>
              <meta charset="UTF-8">
              <meta name="viewport" content="width=device-width, initial-scale=1.0">
              <title>Quiz</title>
              <style>
                  body {
                      font-family: Arial, sans-serif;
                      background-color: #ffffff;
                      display: flex;
                      justify-content: center;
                      align-items: center;
                  }
                  .quiz-container {
                      background-color: #ffffff;
                      padding: 20px;
                      width: 100%;
                  }
                  .quiz-header {
                      text-align: center;
                      margin-bottom: 20px;
                  }
                  .question {
                      font-size: 18px;
                      margin-bottom: 10px;
                      margin-top: 30px;
                  }
                  .answers {
                      list-style-type: none;
                      padding: 0;
                  }
                  .answers li {
                      background-color: #e7e7e7;
                      border-radius: 5px;
                      margin-bottom: 10px;
                      padding: 10px;
                      cursor: pointer;
                      transition: background-color 0.3s;
                  }
                  .answers li:hover {
                      background-color: #d3d3d3;
                  }
                  .quiz-footer p {
                      background-color: rgba(39, 174, 96, 0.3);
                      border-radius: 5px;
                      margin: 10px 0px;
                      padding: 10px;
                  }
                  .quiz-footer button {
                      background-color: #6200ee;
                      color: white;
                      border: none;
                      padding: 10px 20px;
                      border-radius: 5px;
                      cursor: pointer;
                      transition: background-color 0.3s;
                  }
                  .quiz-footer button:hover {
                      background-color: #3700b3;
                  }
              </style>
          </head>
          <body>
          
          <div class="quiz-container">
              <div class="quiz-header">
                  <h2>Testing</h2>
              </div>
              ${ques
          ?.map((item: any, index: number) => {
            return `
      <div class="question">Question ${index + 1}: ${item?.question || ""}</div>
      <ul class="answers">
        ${item?.answer_option
                ?.map((option: string, optionIndex: number) => {
                  return `<li>${optionIndex + 1}. ${option}</li>`;
                })
                .join("")}
      </ul>
      ${option !== 2
                ? `<div class="quiz-footer">
        <p>
          Correct Answer: ${item?.correct_answer || ""}
        </p>
      </div>`
                : ""
              }`;
          })
          .join("")}
          </div>
          
          </body>
          </html>`,
    };
    const body2 = {
      html_text: `<!DOCTYPE html>
          <html lang="en">
          <head>
              <meta charset="UTF-8">
              <meta name="viewport" content="width=device-width, initial-scale=1.0">
              <title>Quiz</title>
              <style>
                  body {
                      font-family: Arial, sans-serif;
                      background-color: #ffffff;
                      display: flex;
                      justify-content: center;
                      align-items: center;
                  }
                  .quiz-container {
                      background-color: #ffffff;
                      padding: 20px;
                      width: 100%;
                  }
                  .quiz-header {
                      text-align: center;
                      margin-bottom: 20px;
                  }
                  .question {
                      font-size: 18px;
                      margin-bottom: 10px;
                      margin-top: 30px;
                  }
                  .answers {
                      list-style-type: none;
                      padding: 0;
                  }
                  .answers li {
                      background-color: #e7e7e7;
                      border-radius: 5px;
                      margin-bottom: 10px;
                      padding: 10px;
                      cursor: pointer;
                      transition: background-color 0.3s;
                  }
                  .answers li:hover {
                      background-color: #d3d3d3;
                  }
                  .quiz-footer p {
                      background-color: rgba(39, 174, 96, 0.3);
                      border-radius: 5px;
                      margin: 10px 0px;
                      padding: 10px;
                  }
                  .quiz-footer button {
                      background-color: #6200ee;
                      color: white;
                      border: none;
                      padding: 10px 20px;
                      border-radius: 5px;
                      cursor: pointer;
                      transition: background-color 0.3s;
                  }
                  .quiz-footer button:hover {
                      background-color: #3700b3;
                  }
              </style>
          </head>
          <body>
          
          <div class="quiz-container">
              <div class="quiz-header">
                  <h2>Testing</h2>
              </div>
              ${ques
          ?.map((item: any, index: number) => {
            return `
          <div class="question">Question ${index + 1}: ${item?.question || ""}</div>
          ${option !== 2
                ? `<div class="quiz-footer">
          <p>
          Answer: ${item?.correct_answer || ""}
          </p>
          </div>`
                : ""
              }`;
          })
          .join("")}
          </div>
          
          </body>
          </html>`,
    };
    try {
      const encryptedBody = generateEncryptedKeyBody(
        result?.sub_category_type === 1 ? body1 : body2
      ) as CommonBody;
      const res = await downloadFile(encryptedBody).unwrap();
      setIsLoading(false);
      if (res?.status === 200) {
        console.log(res, "download ka response");
        // window.open(res?.data);
        handleDownload(res.data, "testing.pdf");
        props.handleClose();
      }
    } catch (error: any) {
      setIsLoading(false);
      showError(
        error?.message ||
        translate.errors.somethingWentWrong ||
        "Something went wrong"
      );
    }
  };

  console.log(result, "result");
  console.log(props.id, "props.id");

  React.useEffect(() => {
    if (props?.id) fetchDataById();
  }, [props.open]);

  return (
    <>
      <React.Fragment>
        <Loader isLoad={isLoading} />
        <Modal
          open={props.open}
          onClose={props.handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          className="modall"
        >
          <Box sx={style} className="text_center test_cmplt">
            <div className="modal_header">
              <h2>{translate.testing.testResult}</h2>
              <p className="close_icon" onClick={() => props.handleClose()}>
                <CloseIcon />
              </p>
            </div>
            <figure className="wron_ans">
              <img src={Number(result?.correct_answers_percentage)>50?"/static/images/smily_face.png":"/static/images/wrong_ans.png"} alt="icon"></img>
            </figure>
            <div className="over_score">
              <div className="over_scr_bx">
                <p>{translate.testing.overallScore}</p>
                <h2>{result?.correct_answers}</h2>
              </div>
              <ul className="prgrs_list ">
                <li>
                  <h3>{result?.correct_answers_percentage}%</h3>
                  <p>{translate.testing.correct}</p>
                </li>
                <li>
                  <h3>{result?.wrong_answers_percentage}%</h3>
                  <p>{translate.testing.incorrect}</p>
                </li>
                <li>
                  <h3>{result?.remaining_answers_percentage}%</h3>
                  <p>{translate.testing.remaining}</p>
                </li>
              </ul>
            </div>
            <div>
              <Tooltip title="Do this test again">
                <button
                  className="re_attempt"
                  onClick={() => {
                    navigate(path, {
                      state: {
                        ...sendRes,
                        data: sendRes?.data?.map((data: any, index: any) => ({
                          ...data,
                          isDone: false,
                          qCount: index + 1,
                        })),
                        repeatingQuestion: isRepeating,
                      },
                    });
                    window.scrollTo(0, 0);
                  }}
                >
                  {translate.testing.reAppear}
                </button>
              </Tooltip>
            </div>
            <div className="btn_group">
              <Button
                onClick={() => {
                  getChangeLanguage(true);
                }}
              >
                {translate.global.changeLanguageDownload}
              </Button>
              <Button onClick={() => FetchDownLoadFile(2)}>
                {translate.testing.downloadWithoutAnswers}
              </Button>
              <Button onClick={() => FetchDownLoadFile(3)}>
                {translate.testing.downloadWithAnswers}
              </Button>
            </div>
          </Box>
        </Modal>
      </React.Fragment>
    </>
  );
}
export default TestingResultModal;
