import React, { useState } from "react";
import Layout from "../../layout";
import { Button } from "@mui/material";

import "react-tabs/style/react-tabs.css";
import { useNavigate } from "react-router-dom";
import useTranslation from "../../hooks/Translation";

const Workpage = () => {
  const translate = useTranslation();
  const [workPageNumber, setWorkPageNumber] = useState(1);
  const navigate = useNavigate();
  return (
    <>
      {workPageNumber == 1 && (
        <section className="bnr_sec c_white ai_wrk bnr_main">
          <div className="side_container">
            <div className="bnr_mn">
              <div className="bnr_lt">
                <figure className="logo">
                  <img
                    src="/static/images/logo.svg"
                    alt=""
                    onClick={() => navigate("/")}
                  ></img>
                </figure>
                <h1>
                  {translate.home.let}
                  <span className="ai_bdr">
                    {translate.home.ai} <span></span>
                  </span>
                  <span className="d_block"> {translate.home.doYourWork}</span>
                </h1>
                <p>
                  {translate.home.homeMessageLine1}
                  <span className="d_block">
                    {translate.home.homeMessageLine2}
                  </span>{" "}
                  {translate.home.homeMessageLine3}
                </p>
              </div>
              <div className="bnr_rt text_center">
                <video
                  playsInline
                  autoPlay
                  muted
                  loop
                  src="/static/images/wrk_vdo.mp4"
                ></video>
                <Button
                  type="button"
                  className="btn btn_primary btnn lrg"
                  onClick={() => setWorkPageNumber(workPageNumber + 1)}
                >
                  {translate.global.next || "Next"}
                </Button>
              </div>
            </div>
          </div>
        </section>
      )}
      {workPageNumber == 2 && (
        <section className="bnr_sec c_white test_sec bnr_main">
          <div className="conta_iner">
            <div className="bnr_mn">
              <div className="bnr_lt">
                <figure className="logo">
                  <img
                    src="/static/images/logo.svg"
                    alt=""
                    onClick={() => navigate("/")}
                  ></img>
                </figure>
                <h1>
                  {translate.home.let}
                  <span className="ai_bdr">
                    {translate.home.ai}
                    <span></span>
                  </span>{" "}
                  <span className="d_block">
                    {translate.testing.checkTestForYou || "CHECK TEST FOR YOU"}
                  </span>
                </h1>
                <p>
                  {translate.home.homeMessageLine1}
                  <span className="d_block">
                    {" "}
                    {translate.home.homeMessageLine2}
                  </span>{" "}
                  {translate.home.homeMessageLine3}
                </p>
              </div>
              <div className="bnr_rt text_center">
                <video
                  playsInline
                  autoPlay
                  loop
                  muted
                  src="/static/images/test.mp4"
                ></video>
                <Button
                  type="button"
                  className="btn btn_primary btnn lrg"
                  onClick={() => setWorkPageNumber(workPageNumber + 1)}
                >
                  {translate.global.next || "Next"}
                </Button>
              </div>
            </div>
          </div>
        </section>
      )}
      {workPageNumber == 3 && (
        <section className="bnr_sec c_white solu_sec test_sec bnr_main">
          <div className="conta_iner">
            <div className="bnr_mn">
              <div className="bnr_lt">
                <figure className="logo">
                  <img
                    src="/static/images/logo.svg"
                    alt=""
                    onClick={() => navigate("/")}
                  ></img>
                </figure>
                <h1>
                  {translate.home.let}
                  <span className="ai_bdr">
                    {translate.home.ai} <span></span>{" "}
                  </span>{" "}
                  <span className="d_block">
                    {translate.assignmentSolution.GiveYouSolution ||
                      "GIVE YOU SOLUTIONS"}
                  </span>
                </h1>
                <p>
                  {translate.home.homeMessageLine1}
                  <span className="d_block">
                    {translate.home.homeMessageLine2}
                  </span>{" "}
                  {translate.home.homeMessageLine3}
                </p>
              </div>
              <div className="bnr_rt text_center">
                <video
                  playsInline
                  autoPlay
                  muted
                  loop
                  src="/static/images/solution.mp4"
                ></video>
                <Button
                  type="button"
                  className="btn btn_primary btnn lrg"
                  onClick={() => setWorkPageNumber(workPageNumber + 1)}
                >
                  {translate.global.next || "Next"}
                </Button>
              </div>
            </div>
          </div>
        </section>
      )}
      {workPageNumber == 4 && (
        <section className="bnr_sec c_white test_sec nt_sec bnr_main">
          <div className="conta_iner">
            <div className="bnr_mn">
              <div className="bnr_lt">
                <figure className="logo">
                  <img
                    src="/static/images/logo.svg"
                    alt=""
                    onClick={() => navigate("/")}
                  ></img>
                </figure>
                <h1>
                  {translate.home.let}
                  <span className="ai_bdr">
                    {translate.home.ai}
                    <span></span>{" "}
                  </span>{" "}
                  <span className="d_block">
                    {translate.noteTaking.takeNotesForYou ||
                      "TAKE NOTES FOR YOU"}
                  </span>
                </h1>
                <p>
                  {translate.home.homeMessageLine1}
                  <span className="d_block">
                    {translate.home.homeMessageLine2}
                  </span>{" "}
                  {translate.home.homeMessageLine3}
                </p>
              </div>
              <div className="bnr_rt text_center">
                <video
                  playsInline
                  autoPlay
                  muted
                  loop
                  src="/static/images/note.mp4"
                ></video>
                <Button
                  type="button"
                  className="btn btn_primary btnn lrg"
                  onClick={() => setWorkPageNumber(workPageNumber + 1)}
                >
                  {translate.global.next || "Next"}
                </Button>
              </div>
            </div>
          </div>
        </section>
      )}
      {workPageNumber == 5 && (
        <section className="bnr_sec c_white test_sec jn_sec bnr_main jn_vdo">
          <div className="conta_iner">
            <div className="bnr_mn">
              <div className="bnr_lt">
                <figure className="logo">
                  <img
                    src="/static/images/logo.svg"
                    alt=""
                    onClick={() => navigate("/")}
                  ></img>
                </figure>
                <h1>
                  <span className="d_block">{translate.auth.joinMillions}</span>{" "}
                  {translate.global.of}{" "}
                  <span className="ai_bdr usr">
                    {translate.global.users} <span></span> <span></span>{" "}
                  </span>{" "}
                </h1>
                <p>
                  {translate.home.homeMessageLine1}
                  <span className="d_block">
                    {translate.home.homeMessageLine2}
                  </span>{" "}
                  {translate.home.homeMessageLine3}
                </p>
              </div>
              <div className="bnr_rt text_center">
                <video
                  playsInline
                  autoPlay
                  muted
                  loop
                  src="/static/images/join.mp4"
                ></video>
                <Button
                  type="button"
                  className="btn btn_primary btnn lrg"
                  onClick={() => navigate("/login")}
                >
                  {translate.global.next || "Next"}
                </Button>
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
};
export default Workpage;
