import React, { useEffect, useRef, useState } from "react";

import "react-tabs/style/react-tabs.css";
import "../quiz/quiz.scss";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Menu,
  MenuItem,
  Modal,
  TextField,
  Tooltip,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { generateEncryptedKeyBody } from "../../utils/crypto";
import { CommonBody } from "../../types/General";
import { useChangeLanguageMutation } from "../../services/utils";
import Loader from "../../constants/Loader";
import {
  useDownloadPdfTestingMutation,
  useLazyGetRecordByIdQuery,
  useUpdateResultMutation,
} from "../../services/testingModule";
import { showError, showToast, showWarning } from "../../constants/toast";
import { handleDownload } from "../../utils/handleDownload";
import { getFromStorage } from "../../constants/storage";
import { STORAGE_KEYS } from "../../constants/storageKeys";
import { fileToBase64 } from "../../utils/fileToB64";
import TestAi from "../testAi";
import useTranslation from "../../hooks/Translation";
import { API_URL } from "../../constants/url";
import { isArabicText } from "../../utils/validations";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { MoreVert } from "@mui/icons-material";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "100%",
  maxWidth: 587,
  bgcolor: "#353e3d",
  border: "1px solid #FFFFFF8A",
  boxShadow: 24,
  borderRadius: "10px",
  p: 3,
  maxHeight: "95vh",
  overflow: "auto",
};

interface UserResponse {
  question: string;
  question_no: number;
  user_answer: string;
  correct_answer: string;
}
let isBack = false;
let allQuestionsDone = true;
let record_id = 0;
const FlashCardQuiz = () => {
  const quizRef: any = useRef(null);
  console.log("quizRef :", quizRef);
  const translate = useTranslation();
  const [open1, setOpen1] = React.useState(false);
  const handleOpen1 = () => setOpen1(true);
  const handleClose1 = () => setOpen1(false);
  const { state } = useLocation();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [quesData, setQuesData] = useState<any>([]);
  console.log("quesDatamohitttttttt :", quesData);
  const totalCount = state.data?.length;
  const [disableSkip, setDisableSkip] = useState(false);
  const [showAns, setShowAns] = useState<boolean>(false);
  const [currentQues, setCurrentQues] = useState<any>(null);
  const [currentNum, setCurrentNum] = useState<number>(1);
  const navigate = useNavigate();
  const [result, setResult] = useState<any>();
  const [changeLanguage] = useChangeLanguageMutation();
  const [eng, setEng] = useState<boolean>(true);
  const [translatedData, setTranslatedData] = useState<any>();
  const [sendResponce] = useUpdateResultMutation();
  let [userResBody, setUserResBody] = useState<UserResponse[]>([]);
  const [downloadFile] = useDownloadPdfTestingMutation();

  const gotohome = () => {
    navigate("/testing");
    const handleClose1 = () => setOpen1(false);
  };
  const gotohome1 = () => {
    navigate("/testing");
  };
  /* menu */
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const openMenu = Boolean(anchorEl);
  const openChat = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const closeChat = () => {
    setAnchorEl(null);
  };

  const truncateText = (text: string, maxChars: number) => {
    if (text?.length > maxChars) {
      return (
        <Tooltip title={text}>
          <span>{text.slice(0, maxChars) + " ..."}</span>
        </Tooltip>
      );
    }
    return text;
  };
  const [message, setMessage] = useState("");
  const [chatHistory, setChatHistory] = useState<any>([]);
  const [socket, setSocket] = useState<any>(null);
  const [loading, setLoading] = useState(false);
  const [connecting, setConnecting] = useState(true);
  const [messages, setMessages] = useState<{ query: string; answer: string }[]>(
    []
  );
  const [isWaiting, setIsWaiting] = useState(0);
  const yesGuess = () => {
    if (isLoading) setIsLoading(false);
    setTranslatedData(null);
    if (isWaiting) setIsWaiting(0);
    {
      window.scroll(0, 0);
      let body = [
        {
          question: currentQues?.question,
          question_no: currentNum,
          user_answer: "YES",
          correct_answer: currentQues?.correct_answer,
        },
      ];
      console.log(result, "result1012");
      console.log(currentNum, "current num1012");
      console.log(quesData?.length, "quesData?.length1012");
      console.log(userResBody?.length, "userResBody?.length1012");
      if (currentNum <= quesData.length)
        setUserResBody([...userResBody, ...body]);
      if (currentNum < quesData.length) {
        // setUserResBody([...userResBody, ...body]);
        setCurrentNum(
          currentNum !== quesData?.length ? currentNum + 1 : currentNum
        );
        setCurrentQues(quesData[currentNum]);
        setShowAns(false);
        setDisableSkip(false);
      } else {
        if (!allQuestionsDone) {
          setIsWaiting(1);
          setIsLoading(true);
          showWarning(
            translate.testing.pleaseWaitQuestionsBeingLoaded ||
              "Please wait while the flashcards are being loaded"
          );
        }
      }
      const temp = [...userResBody, ...body];
      if (allQuestionsDone) {
        if (
          quesData?.length === currentNum
          // &&temp?.length === quesData?.length
        ) {
          UserResponce(temp);
        }
      }
    }
  };
  const noGuess = () => {
    if (isLoading) setIsLoading(false);
    setTranslatedData(false);
    if (isWaiting) setIsWaiting(0);
    {
      window.scroll(0, 0);
      let body = [
        {
          question: currentQues?.question,
          question_no: currentNum,
          user_answer: "NO",
          correct_answer: currentQues?.correct_answer,
        },
      ];
      console.log(result, "result1012");
      console.log(currentNum, "current num1012");
      console.log(quesData?.length, "quesData?.length1012");
      console.log(userResBody?.length, "userResBody?.length1012");
      if (currentNum <= quesData.length)
        setUserResBody([...userResBody, ...body]);
      if (currentNum < quesData.length) {
        // setUserResBody([...userResBody, ...body]);
        setCurrentNum(
          currentNum !== quesData?.length ? currentNum + 1 : currentNum
        );
        setCurrentQues(quesData[currentNum]);
        setShowAns(false);
        setDisableSkip(false);
      } else {
        if (!allQuestionsDone) {
          setIsWaiting(2);
          setIsLoading(true);
          showWarning(
            translate.testing.pleaseWaitQuestionsBeingLoaded ||
              "Please wait while the flashcards are being loaded"
          );
        }
      }
      const temp = [...userResBody, ...body];
      if (allQuestionsDone) {
        if (
          quesData?.length === currentNum
          // &&temp?.length === quesData?.length
        ) {
          UserResponce(temp);
        }
      }
    }
  };
  const skipFlashcard = () => {
    if (isLoading) setIsLoading(false);
    setTranslatedData(null);
    if (isWaiting) setIsWaiting(0);
    {
      if (disableSkip) return;
      let body = [
        {
          question: currentQues?.question,
          question_no: currentNum,
          user_answer: "",
          correct_answer: currentQues?.correct_answer,
        },
      ];
      console.log(result, "result1012");
      console.log(currentNum, "current num1012");
      console.log(quesData?.length, "quesData?.length1012");
      console.log(userResBody?.length, "userResBody?.length1012");
      if (currentNum <= quesData.length)
        setUserResBody([...userResBody, ...body]);
      if (currentNum < quesData.length) {
        setCurrentQues(quesData[currentNum]);
        setCurrentNum(currentNum + 1);
        setShowAns(false);
        setDisableSkip(false);
      } else {
        if (!allQuestionsDone) {
          setIsWaiting(3);
          setIsLoading(true);
          showWarning(
            translate.testing.pleaseWaitQuestionsBeingLoaded ||
              "Please wait while the flashcards are being loaded"
          );
        }
      }
      const temp = [...userResBody, ...body];
      // if (
      //   quesData?.length === currentNum &&
      //   temp?.length === quesData?.length
      // ) {
      //   UserResponce(temp);
      // }
      if (allQuestionsDone) {
        if (
          quesData?.length === currentNum
          // &&temp?.length === quesData?.length
        ) {
          UserResponce(temp);
        }
      }
    }
  };
  useEffect(() => {
    if (isWaiting == 1) yesGuess();
    if (isWaiting == 2) noGuess();
    if (isWaiting == 3) skipFlashcard();
  }, [quesData]);
  const chatHistoryRef = useRef(null);

  useEffect(() => {
    if (openMenu) {
      setConnecting(true);
      connectToBot();
    } else {
      socket?.close();
    }
  }, [openMenu]);
  useEffect(() => {
    if (chatHistoryRef.current) {
      //@ts-ignore
      chatHistoryRef.current.scrollTop = chatHistoryRef.current.scrollHeight;
    }
  }, [chatHistory]);
  const connectToBot = () => {
    const ws = new WebSocket(
      `wss:${API_URL.replaceAll("https://", "")}chatbot/`
    );
    ws.onmessage = (event) => {
      const eventData = JSON.parse(event.data);
      console.log(eventData);
      setLoading(false);
      setMessages((prev) => [...prev, { ...eventData, question: "" }]);
      setChatHistory((prevHistory: any) => [
        ...prevHistory,
        { sender: "AI Bot", text: eventData.answer },
      ]);
    };

    ws.onopen = async () => {
      console.log("Connected to bot successfully");
      setConnecting(false);
    };
    ws.onerror = (error) => {
      ws.close();
      setLoading(false);
      console.log("There is some error", error);
    };
    ws.onclose = () => {
      setLoading(false);
      console.log("Socket is closed");
    };
    setSocket(ws);
  };

  const onSendMessage = (e: any) => {
    e.preventDefault();
    if (loading) return;
    try {
      if (!message?.trim()?.length) {
        return;
      }
      setLoading(true);
      const PAYLOAD = {
        query: message,
      };
      socket?.send(JSON.stringify(PAYLOAD));
      setChatHistory([...chatHistory, { sender: "You", text: message }]);
      // setMessages((prev) => [...prev, { ...PAYLOAD, answer: "" }]);
      setMessage("");
    } catch (error) {
      console.log(error || translate.errors.somethingWentWrong);
      setLoading(false);
    }
  };
  const FetchDownLoadFile = async (option: number) => {
    setIsLoading(true);
    const body = {
      html_text: `<!DOCTYPE html>
    <html lang="en">
    <head>
        <meta charset="UTF-8">
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
        <title>Quiz</title>
        <style>
            body {
                font-family: Arial, sans-serif;
                background-color: #ffffff;
                display: flex;
                justify-content: center;
                align-items: center;
            }
            .quiz-container {
                background-color: #ffffff;
                padding: 20px;
                width: 100%;
            }
            .quiz-header {
                text-align: center;
                margin-bottom: 20px;
            }
            .question {
                font-size: 18px;
                margin-bottom: 10px;
                margin-top: 30px;
            }
            .answers {
                list-style-type: none;
                padding: 0;
            }
            .answers li {
                background-color: #e7e7e7;
                border-radius: 5px;
                margin-bottom: 10px;
                padding: 10px;
                cursor: pointer;
                transition: background-color 0.3s;
            }
            .answers li:hover {
                background-color: #d3d3d3;
            }
	.quiz-footer{
background-color: rgba(39, 174, 96, 0.1);
       border-radius: 5px;

		}

            .quiz-footer p {
                border-radius: 5px;
                margin: 10px 0px;
                padding: 10px;
            }
            .quiz-footer button {
                background-color: #6200ee;
                color: white;
                border: none;
                padding: 10px 20px;
                border-radius: 5px;
                cursor: pointer;
                transition: background-color 0.3s;
            }
            .quiz-footer button:hover {
                background-color: #3700b3;
            }
        </style>
    </head>
    <body>
    
    <div class="quiz-container">
        <div class="quiz-header">
            <h2>Testing</h2>
        </div>
        ${quesData
          ?.map((item: any, index: number) => {
            return `
    <div class="question">Question ${index + 1}: ${item?.question || ""}</div>
    ${
      option !== 2
        ? `<div class="quiz-footer">
    <p>
    Answer: ${
      Array.isArray(item?.correct_answer)
        ? item?.correct_answer?.map((item: string) => {
            return `<ul style={{listStyle="disc"}}>
            <li>${item}</li>
            </ul>
            `;
          })
        : item?.correct_answer
    }
    </p>
    </div>`
        : ""
    }`;
          })
          .join("")}
    </div>
    
    </body>
    </html>`,
    };

    try {
      const encryptedBody = generateEncryptedKeyBody(body) as CommonBody;
      const res = await downloadFile(encryptedBody).unwrap();
      setIsLoading(false);
      if (res?.status === 200) {
        console.log(res, "download ka response");
        handleDownload(res.data, "testing_flashcard.pdf");
        // window.open(res?.data);
      }
    } catch (error: any) {
      setIsLoading(false);
      showError(
        error?.data?.message ||
          translate.errors.somethingWentWrong ||
          "Something went wrong"
      );
    }
  };
  useEffect(() => {
    setEng(true);
  }, [currentNum]);
  const getChangeLanguage = async (download: boolean) => {
    setIsLoading(true);
    try {
      const body = {
        text: download
          ? quesData.map((data: any) => {
              return {
                question: data.question,
                question_no: data.question_no,
                user_answer: data.user_answer,
                correct_answer: data.correct_answer,
              };
            })
          : [currentQues],
      };
      const encryptedBody = generateEncryptedKeyBody(body) as CommonBody;
      const response = await changeLanguage(encryptedBody).unwrap();
      setIsLoading(false);
      if (response.status === 200) {
        const isArabic = isArabicText(response?.data?.[0]?.question);

        setTranslatedData(response?.data);
        // setCurrentQues(response?.data?.[0]);
        if (download === true) {
          const body = {
            html_text: `<!DOCTYPE html>
                <html lang="en">
                <head>
                    <meta charset="UTF-8">
                    <meta name="viewport" content="width=device-width, initial-scale=1.0">
                    <title>Quiz</title>
                    <style>
                        body {
                            font-family: Arial, sans-serif;
                            background-color: #ffffff;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                        }
                        .quiz-container {
                            background-color: #ffffff;
                            padding: 20px;
                            width: 100%;
                        }
                        .quiz-header {
                            text-align: center;
                            margin-bottom: 20px;
                        }
                        .question {
                            font-size: 18px;
                            margin-bottom: 10px;
                            margin-top: 30px;
                        }
                        .answers {
                            list-style-type: none;
                            padding: 0;
                        }
                        .answers li {
                            background-color: #e7e7e7;
                            border-radius: 5px;
                            margin-bottom: 10px;
                            padding: 10px;
                            cursor: pointer;
                            transition: background-color 0.3s;
                        }
                        .answers li:hover {
                            background-color: #d3d3d3;
                        }
                        .quiz-footer p {
                            background-color: rgba(39, 174, 96, 0.3);
                            border-radius: 5px;
                            margin: 10px 0px;
                            padding: 10px;
                        }
                        .quiz-footer button {
                            background-color: #6200ee;
                            color: white;
                            border: none;
                            padding: 10px 20px;
                            border-radius: 5px;
                            cursor: pointer;
                            transition: background-color 0.3s;
                        }
                        .quiz-footer button:hover {
                            background-color: #3700b3;
                        }
                    </style>
                </head>
                <body dir="auto">
                
                <div class="quiz-container" dir="auto">
                    <div class="quiz-header" dir="auto">
                        <h2>${isArabic ? "اختبار" : "Testing"}</h2>
                    </div>
                    ${response?.data
                      ?.map((item: any, index: number) => {
                        return `
                        <div class="question">${isArabic ? "سؤال" : "Question"} ${index + 1}: ${item?.question || ""}</div>
                        <ul class="answers">
                          ${
                            item?.answer_option
                              ? item?.answer_option
                                  ?.map((option: any, optionIndex: number) => {
                                    return `<li>${optionIndex + 1}. ${option}</li>`;
                                  })
                                  .join("")
                              : ""
                          }
                        </ul>
                        <div class="quiz-footer">
                          <p>${isArabic ? "الإجابة الصحيحة" : "Correct Answer"}: ${item?.correct_answer || ""}</p>
                        </div>`;
                      })
                      .join("")}
                </div>
                </body>
                </html>`,
          };

          try {
            const encryptedBody = generateEncryptedKeyBody(body) as CommonBody;
            const res = await downloadFile(encryptedBody).unwrap();
            if (res?.status === 200) {
              handleDownload(res?.data, "quiz");
              showToast("Pdf downloaded Successfully");
            }
          } catch (error: any) {
            showError(
              error?.data?.message ||
                translate.errors.somethingWentWrong ||
                "Something went wrong"
            );
          }
        }
      }
    } catch (error: any) {
      console.log(error);
      showError(
        error?.data?.message ||
          translate.errors.somethingWentWrong ||
          "Something went wrong"
      );
    }
    setIsLoading(false);
  };

  console.log(userResBody, "userResBody");

  const UserResponce = async (temp: any) => {
    setIsLoading(true);
    const body = {
      user_response: temp,
    };
    console.log(body, "body for user marks");

    try {
      const encryptedBody = generateEncryptedKeyBody(body) as CommonBody;
      const response = await sendResponce({
        body: encryptedBody,
        id: state?.record_id || record_id,
      }).unwrap();
      setIsLoading(false);
      if (response.status === 200) {
        setResult(response?.data);
        handleOpen1();
      }
    } catch (error: any) {
      setIsLoading(false);
      showError(
        error?.data?.message ||
          translate.errors.somethingWentWrong ||
          "Something went wrong"
      );
    }
  };

  // useEffect(() => {
  //   if (eng) {
  //     setQuesData(state.data);
  //     setCurrentQues(state.data?.[currentNum - 1] || currentQues)
  //   } else {
  //     setQuesData(translatedData);
  //     setCurrentQues(translatedData?.[currentNum - 1] || currentQues)
  //   }
  //   console.log(translatedData, "translatedData------------->");

  //   setShowAns(false);
  // }, [eng, translatedData])
  const [streamedQuestions, setStreamedQuestions] = useState<any>([]);
  const [disableButton, setDisableButton] = useState(false);
  const [isBlink, setIsBlink] = useState(false);
  console.log("streamedQuestions :", streamedQuestions);
  // const [allQuestionsDone, setAllQuestionsDone] = useState(true);
  useEffect(() => {
    if (eng) {
      if (streamedQuestions?.length) {
        setQuesData(
          streamedQuestions?.map((data: any, index: number) => ({
            ...data,
            qCount: index + 1,
          }))
        );
        setCurrentQues(streamedQuestions?.[currentNum - 1] || currentQues);
      }
      if (state?.data) {
        setQuesData(state.data);
        allQuestionsDone = true;
        setCurrentQues(state.data?.[currentNum - 1] || currentQues);
      }
    } else {
      // setQuesData(translatedData);
      // setCurrentQues(translatedData?.[currentNum - 1] || currentQues);
    }
    console.log(translatedData);
  }, [eng, translatedData]);
  useEffect(() => {
    if (streamedQuestions?.length && !currentQues)
      setCurrentQues(streamedQuestions?.[0]);
    console.log("11currentQues :", currentQues);
    if (streamedQuestions?.length)
      setQuesData(
        streamedQuestions?.map((data: any, index: number) => ({
          ...data,
          qCount: index + 1,
        }))
      );
  }, [streamedQuestions]);
  const [ws, setWs] = useState<any>();
  const socketRef: any = useRef();
  const [getFirstDAta, setGetFirstDAta] = useState<any>(false);
  const createNewSummarization = (isChangeLanguage?: any) => {
    // const ws=new WebSocket('https://d164-122-160-196-233.ngrok-free.app/file-summarization/');
    // if (isChangeLanguage && originalSummary) {
    //   setSummary(originalSummary)
    // }
    if (isChangeLanguage) {
      setDisableButton(true);
      setIsLoading(true);
    }
    const ws = new WebSocket(
      `wss:${API_URL.replaceAll("https://", "")}testing/generate-response/`
    );
    socketRef.current = ws;
    ws.onmessage = (event) => {
      localStorage.setItem("isTestLoading", "1");
      console.log("event.data :", event.data);
      const data = JSON.parse(event.data);
      if (data.signal == -1) {
        ws.close();
        showError(
          data?.data ||
            data?.message ||
            translate.errors.pleaseTryAgain ||
            "Please try again"
        );
        navigate("/testing", { replace: true });
      }
      if (data.signal == 0) {
        setDisableButton(false);
        allQuestionsDone = true;
        record_id = data?.record_id;
        ws.close();
        showToast(
          translate.testing.flashcardsGeneratedSuccessfully ||
            "Flashcards Generated successfully"
        );
      }
      if (data.signal == 401) {
        ws.close();
        showError(
          data?.data ||
            data?.message ||
            translate.errors.pleaseLogin ||
            "Please login"
        );
        navigate("/");
        // return;
      }
      if (data.signal == 400) {
        ws.close();
        showError(
          data?.data ||
            data?.message ||
            translate.errors.somethingWentWrong ||
            ""
        );
        // if (isChangeLanguage) setIsLoading(false);
        navigate("/testing", { replace: true });
        // return;
      }
      if (data.signal == 1) {
        setGetFirstDAta(true);
        const data2: any = JSON.parse(event.data);
        setStreamedQuestions((prev: any) => [
          ...prev,
          ...data2?.data?.flashcards,
        ]);
        // setStreamedQuestions(data2?.data);
        console.log("data2.data mmmmmm :", data2);
      }
      const teset = document.getElementById("mohit");
      if (teset) {
        setTimeout(() => {
          teset.scrollIntoView({ behavior: "smooth", block: "center" });
        }, 0);
      }

      console.log(event);
    };
    ws.onopen = async () => {
      setIsBlink(true);

      setDisableButton(true);
      allQuestionsDone = false;
      let binaryData: any = [];
      if (state?.selectedFile) {
        if (Array.isArray(state?.selectedFile)) {
          for (let key = 0; key < state?.selectedFile?.length; key++) {
            binaryData.push(await fileToBase64(state?.selectedFile[key]));
          }
        } else {
          binaryData = await fileToBase64(state?.selectedFile);
        }
      }
      const sub_cat = state?.nextPage === "/quiz/" ? 1 : 2;
      const PAYLOAD: any = {
        token: JSON?.parse(getFromStorage(STORAGE_KEYS.token) as string),
        sub_category: sub_cat,
        type: state?.fileType,
        binary_data: binaryData,
        file_name: state?.fileName ? state?.fileName : Date.now(),
      };

      console.log("PAYLOAD :", PAYLOAD);
      ws?.send(JSON.stringify(PAYLOAD));
      console.log("Connected to websocket successfully");
    };
    ws.onerror = (error) => {
      setIsBlink(false);
      // setIsLoading(false);
      ws.close();
      console.log("There is some error", error);
    };
    ws.onclose = () => {
      setIsBlink(false);
      // setIsLoading(false);
      console.log("Socket is closed");
    };
    setWs(ws);
  };
  const handleBeforeUnload = (e: any) => {
    e.preventDefault();
    const message = "Are you sure you want to leave?";
    e.returnValue = message;
    return message;
  };
  useEffect(() => {
    if (state?.isGenerating)
      window.addEventListener("beforeunload", handleBeforeUnload);
    return () => window.removeEventListener("beforeunload", handleBeforeUnload);
  }, []);
  useEffect(() => {
    if (state?.isGenerating) createNewSummarization();
    if (localStorage.getItem("isTestLoading") == "1") {
      showError("Reloading not allowed during test");
      navigate("/testing");
    }
  }, []);
  useEffect(() => {
    isBack = false;
    return () => {
      isBack = true;
      if (socketRef?.current) {
        console.log("Cleaning up: Closing WebSocket");
        socketRef?.current.close(); // Close WebSocket on cleanup
      }
      if (ws) {
        ws.close();
      }
    };
  }, []);
  console.log("state in flashcard page", state);
  console.log("array of user response", userResBody);

  const [anchorEl1, setAnchorEl1] = React.useState<null | HTMLElement>(null);
  console.log("mohit", anchorEl1);

  const open = Boolean(anchorEl);
  const handleClick1 = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl1(event.currentTarget);
  };
  const handleClose11 = () => {
    setAnchorEl1(null);
  };
  const [anchorEl0, setAnchorEl0] = React.useState<null | HTMLElement>(null);
  const open0 = Boolean(anchorEl0);
  const handleClick0 = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl0(event.currentTarget);
  };
  const handleClose0 = (event: any) => {
    event.stopPropagation();

    setAnchorEl0(null);
  };

  const getClassName = (data: any) => {
    console.log("data123213 :", data);
    if (!data) return "";
    if (data?.user_answer == "YES") {
      return "correct";
    }

    if (!data?.user_answer) {
      return "skip";
    }

    if (data?.user_answer == "NO") {
      return "wrong";
    }
  };
  return state?.isGenerating && !getFirstDAta ? (
    <TestAi />
  ) : (
    <>
      <section className="quiz_wrap">
        <Loader isLoad={isLoading} />
        <div className="quiz_sec">
          <div className="quiz_lft">
            <button onClick={gotohome1} className="back_btn">
              <ArrowBackIosIcon />
            </button>
            <ul className="quiz_list">
              {quesData?.length
                ? quesData?.map((item: any, index: number) => {
                    return (
                      <li key={index}>
                        <span>
                          {" "}
                          {translate.testing.ques || "Ques"} {index + 1}{" "}
                        </span>{" "}
                        <span
                          style={
                            currentNum == index + 1
                              ? { color: "#8a61ff", cursor: "pointer" }
                              : { cursor: "pointer" }
                          }
                          // onClick={() => { setCurrentQues(item); setCurrentNum(index + 1); setShowAns(false) }}
                        >
                          {truncateText(item?.question, 15)}
                        </span>
                      </li>
                    );
                  })
                : ""}
              {disableButton && (
                <li>
                  <figure>
                    <img
                      src="/static/images/test-load.gif"
                      style={{ marginInline: "100px", maxWidth: "12%" }}
                    />
                  </figure>
                </li>
              )}
            </ul>
          </div>
          <div className="quiz_rht ">
            <div className="flash_quiz_rht">
              <div className="quiz_head">
                <p>
                  {currentNum <= quesData?.length
                    ? (currentNum || 1) + "/" + quesData?.length
                    : ""}
                  {disableButton && (
                    <figure>
                      <img src="/static/images/test-load.gif" />
                    </figure>
                  )}
                </p>
              </div>
              {/* <div className="quiz_progress">
                <span
                  className="progress_status"
                  style={{ width: `${(currentNum / quesData?.length) * 100}%` }}
                ></span>
              </div> */}
              <div className="quiz_progress2">
                {quesData
                  ?.filter((data: any) => (data?.isDone ? false : data))
                  ?.map((data: any, index: any) => {
                    const data2 = userResBody?.find(
                      (data2: any) => data2?.question_no == data?.qCount
                    );
                    return (
                      <span
                        className={
                          !isLoading && !open1 && currentNum == index + 1
                            ? "filled"
                            : getClassName(data2)
                        }
                      ></span>
                    );
                  })}
              </div>
              <div className="question_bx_outer prevent-select">
                <ul
                  className={
                    showAns
                      ? "question_bx flip-card active"
                      : "question_bx flip-card"
                  }
                  onClick={() => {
                    setShowAns(!showAns);
                    setDisableSkip(true);
                    window.scrollTo(0, window.outerHeight);
                    window.scroll(0, 0);
                  }}
                >
                  <li className="flip-card-inner">
                    <div className="flip-card-front">
                      <ul className="flp_inr_lst">
                        <li>
                          <Button
                            disabled={Boolean(translatedData)}
                            onClick={(e) => {
                              e.stopPropagation();
                              if (eng) {
                                getChangeLanguage(false);
                              }
                              setEng(!eng);
                            }}
                          >
                            <img
                              src="/static/images/chatbot_icon.svg"
                              alt="icon"
                            />
                            {translate.global.translate || "Translate"}
                          </Button>
                        </li>
                        {/* <li>
                          <Button>
                            <img
                              src="/static/images/chatbot_icon.svg"
                              alt="icon"
                            />{" "}
                            AI Explain
                          </Button>
                        </li> */}
                        <li className="more_float">
                          <Button
                            aria-label="more"
                            id="demo-positioned-button"
                            aria-controls={
                              open0 ? "demo-positioned-menu" : undefined
                            }
                            aria-haspopup="true"
                            aria-expanded={open0 ? "true" : undefined}
                            onClick={handleClick0}
                          >
                            <MoreVert htmlColor="white" />
                          </Button>
                          <Menu
                            className="more_drop"
                            id="demo-positioned-menu"
                            aria-labelledby="demo-positioned-button"
                            anchorEl={anchorEl0}
                            open={open0}
                            onClose={handleClose0}
                            anchorOrigin={{
                              vertical: "bottom",
                              horizontal: "right",
                            }}
                            transformOrigin={{
                              vertical: "top",
                              horizontal: "right",
                            }}
                          >
                            <MenuItem>
                              <Button
                                className="btnn sm"
                                onClick={() => {
                                  setAnchorEl0(null);
                                  FetchDownLoadFile(2);
                                }}
                              >
                                {translate.testing.downloadWithoutAnswers ||
                                  "Download File Without Answers"}
                              </Button>
                            </MenuItem>

                            <MenuItem>
                              <Button
                                className="btnn sm"
                                onClick={() => {
                                  setAnchorEl0(null);
                                  FetchDownLoadFile(3);
                                }}
                              >
                                {translate.testing.downloadWithAnswers ||
                                  "Download File With Answers"}
                              </Button>
                            </MenuItem>
                          </Menu>
                        </li>
                      </ul>

                      <p
                        className="ques"
                        dir={
                          isArabicText(currentQues?.question) ? "rtl" : "ltr"
                        }
                      >
                        <span>
                          {isArabicText(currentQues?.question)
                            ? "سؤال"
                            : "Ques"}{" "}
                          {currentNum}
                        </span>
                        <strong>{currentQues?.question || "-"}</strong>
                      </p>
                      {translatedData ? (
                        <>
                          <hr />
                          <p
                            className="ques"
                            dir={
                              isArabicText(translatedData?.[0]?.question)
                                ? "rtl"
                                : "ltr"
                            }
                          >
                            <span>
                              {isArabicText(translatedData?.[0]?.question)
                                ? "سؤال"
                                : "Ques"}{" "}
                              {currentNum}
                            </span>
                            <strong>
                              {translatedData?.[0]?.question || "-"}
                            </strong>
                          </p>
                        </>
                      ) : null}

                      <Button className="clck_crd" disabled={showAns}>
                        {translate.testing.guessAnswerClickCard ||
                          "Guess the answer and click on card"}
                      </Button>
                    </div>

                    <div className="flip-card-back">
                      <ul className="flp_inr_lst">
                        <li>
                          <Button
                            disabled={Boolean(translatedData)}
                            onClick={(e) => {
                              e.stopPropagation();
                              if (eng) {
                                getChangeLanguage(false);
                              }
                              setEng(!eng);
                            }}
                          >
                            <img
                              src="/static/images/translate_icon.svg"
                              alt="icon"
                            />
                            {translate.global.translate || "Translate"}
                          </Button>
                        </li>
                        {/* <li>
                          <Button>
                            <img
                              src="/static/images/chatbot_icon.svg"
                              alt="icon"
                            />{" "}
                            AI Explain
                          </Button>
                        </li> */}
                        <li className="more_float">
                          <Button
                            aria-label="more"
                            id="demo-positioned-button"
                            aria-controls={
                              open0 ? "demo-positioned-menu" : undefined
                            }
                            aria-haspopup="true"
                            aria-expanded={open0 ? "true" : undefined}
                            onClick={handleClick0}
                          >
                            <MoreVert htmlColor="white" />
                          </Button>
                          <Menu
                            className="more_drop"
                            id="demo-positioned-menu"
                            aria-labelledby="demo-positioned-button"
                            anchorEl={anchorEl0}
                            open={open0}
                            onClose={handleClose0}
                            anchorOrigin={{
                              vertical: "bottom",
                              horizontal: "right",
                            }}
                            transformOrigin={{
                              vertical: "top",
                              horizontal: "right",
                            }}
                          >
                            <MenuItem>
                              <Button
                                className="btnn sm"
                                onClick={() => {
                                  setAnchorEl0(null);
                                  FetchDownLoadFile(2);
                                }}
                              >
                                {translate.testing.downloadWithoutAnswers ||
                                  "Download File Without Answers"}
                                <img
                                  src="/static/images/download_icon.svg"
                                  alt="icon"
                                />
                              </Button>
                            </MenuItem>

                            <MenuItem>
                              <Button
                                className="btnn sm"
                                onClick={() => {
                                  setAnchorEl0(null);
                                  FetchDownLoadFile(3);
                                }}
                              >
                                {translate.testing.downloadWithAnswers ||
                                  "Download File With Answers"}
                                <img
                                  src="/static/images/download_icon.svg"
                                  alt="icon"
                                />
                              </Button>
                            </MenuItem>
                          </Menu>
                        </li>
                      </ul>
                      {showAns ? (
                        <div className="ans_card">
                          <div className="answer-container">
                            <p
                              dir={
                                isArabicText(currentQues?.question)
                                  ? "rtl"
                                  : "ltr"
                              }
                            >
                              <b>
                                {isArabicText(currentQues?.question)
                                  ? "الإجابة:"
                                  : "Ans :"}
                              </b>{" "}
                              {Array.isArray(currentQues?.correct_answer)
                                ? currentQues?.correct_answer?.map(
                                    (item: string) => {
                                      return (
                                        <ul
                                          key={item}
                                          style={{
                                            listStyle: "disc",
                                            padding: "10px 20px",
                                          }}
                                        >
                                          <li>{item}</li>
                                        </ul>
                                      );
                                    }
                                  )
                                : currentQues?.correct_answer}
                            </p>
                            {translatedData ? (
                              <>
                                <hr />
                                <p
                                  dir={
                                    isArabicText(translatedData?.[0]?.question)
                                      ? "rtl"
                                      : "ltr"
                                  }
                                >
                                  <b>
                                    {isArabicText(translatedData?.[0]?.question)
                                      ? "الإجابة:"
                                      : "Ans :"}
                                  </b>{" "}
                                  {Array.isArray(
                                    translatedData?.[0]?.correct_answer
                                  )
                                    ? translatedData?.[0]?.correct_answer?.map(
                                        (item: string) => {
                                          return (
                                            <ul
                                              key={item}
                                              style={{
                                                listStyle: "disc",
                                                padding: "10px 20px",
                                              }}
                                            >
                                              <li>{item}</li>
                                            </ul>
                                          );
                                        }
                                      )
                                    : translatedData?.[0]?.correct_answer}
                                </p>
                              </>
                            ) : null}
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </li>
                </ul>
              </div>
              <div className="skip">
                <div className="skipBtn_group">
                  <Button
                    onClick={() => skipFlashcard()}
                    style={{
                      color: !disableSkip ? "white" : "grey",
                      cursor: !disableSkip ? "pointer" : "default",
                    }}
                  >
                    {translate.testing.Skip || "Skip"}
                  </Button>
                  {/* <>
                    <Button><img src="/static/images/undo_icon.svg" alt="icon" /> Undo</Button>
                  </> */}
                </div>
                <div className="chat_box">
                  {showAns ? (
                    <div className="ans_status">
                      <div className="usr_dtl">
                        <figure>
                          <img
                            src="/static/images/smily_face.png"
                            alt="icon"
                          ></img>
                        </figure>
                        <div className="usr_cnt hd_5">
                          <h3>
                            {translate.testing.wasAnswerCorrect ||
                              "Was your answer Correct?"}
                          </h3>
                          <div className="btn_group">
                            <Button
                              size="small"
                              variant="outlined"
                              onClick={() => noGuess()}
                            >
                              {translate.global.no || "No"}
                            </Button>
                            <Button
                              size="small"
                              variant="outlined"
                              // onClick={handleOpen1}
                              onClick={() => yesGuess()}
                            >
                              {translate.global.yes || "Yes"}
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="floating_bot">
        <Button
          size="small"
          id="basic-button"
          className="ask_btn"
          aria-controls={openMenu ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={openMenu ? "true" : undefined}
          onClick={openChat}
        >
          {openMenu ? (
            <CloseIcon />
          ) : (
            <>
              <figure>
                <img src="/static/images/chatbot_icon.svg" alt="icon"></img>
              </figure>
            </>
          )}
        </Button>
        <Menu
          id="basic-menu"
          className="chatBot_box"
          anchorEl={anchorEl}
          open={openMenu}
          onClose={closeChat}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
          transformOrigin={{
            horizontal: "right",
            vertical: "bottom",
          }}
          anchorOrigin={{ horizontal: "right", vertical: "top" }}
        >
          <div className="chat_wrap">
            <p>{translate.global.whizzoAiChatbot || "Whizzo AI Chatbot"}</p>
            <div className="chat_body">
              <Box
                sx={{
                  height: "300px",
                  overflowY: "auto",
                  border: "1px solid #ccc",
                  borderRadius: "5px",
                  padding: "10px",
                  marginBottom: "10px",
                  // backgroundColor: "#f9f9f9",
                }}
                ref={chatHistoryRef}
              >
                {chatHistory.length ? (
                  chatHistory.map((msg: any, index: any) =>
                    msg.sender == "You" ? (
                      <div className="chat_right" key={index}>
                        <p dir="auto">
                          {/* <strong>{msg.sender}</strong>:  */}
                          {msg.text}
                        </p>
                      </div>
                    ) : (
                      <div className="chat_right chat_left">
                        <figure>
                          <img
                            style={{
                              maxWidth: "70%",
                              background: "#2d2b2b",
                              borderRadius: "50%",
                              padding: "7px",
                            }}
                            src="/static/images/chatBot.png"
                          />
                        </figure>
                        <p dir="auto">
                          {/* <strong>{msg.sender}</strong>:  */}
                          {msg.text}
                        </p>
                      </div>
                    )
                  )
                ) : (
                  <Box sx={{ textAlign: "center", color: "#888" }}>
                    {translate.global.noMessagesYet || "No messages yet."}
                  </Box>
                )}
                {chatHistory?.[chatHistory.length - 1]?.sender === "You" &&
                  loading && (
                    // <CircularProgress
                    //   size={30}
                    //   sx={{
                    //     marginLeft: "10px",
                    //     color: "#666",
                    //   }}
                    // />
                    <figure style={{ maxWidth: " 60px", minHeight: "25px" }}>
                      <img src="/static/images/chat_load.gif" />
                    </figure>
                  )}
              </Box>
              {/* <div className="chat_right">
                        <p>Please answer this ques</p>
                      </div>

                  
                      <div className="chat_right">
                        <p>Ok</p>
                      </div> */}
            </div>

            <div className="frmn_mn send_msg ">
              {/* <Button className="send_more">
                        {" "}
                        <AddIcon />{" "}
                      </Button> */}
              <form
                onSubmit={onSendMessage}
                style={{
                  display: "flex",
                  width: "100%",
                  alignItems: "center",
                }}
              >
                <TextField
                  className="control_group"
                  hiddenLabel
                  fullWidth
                  onKeyDown={(e) => {
                    e.stopPropagation();
                  }}
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  variant="outlined"
                  placeholder={
                    translate.testing.typeMessage || "Type a message"
                  }
                />
                <button
                  className="cursor_pointer"
                  onClick={onSendMessage}
                  style={{
                    background: "transparent",
                    border: "none",
                    padding: "8px",
                  }}
                >
                  <img src="/static/images/send_chat_icon.svg" alt="icon"></img>
                </button>
              </form>
            </div>
          </div>
        </Menu>
        <div style={{ fontSize: "14px", textAlign: "center" }}>
          {openMenu ? "Close" : "Ask me"}
        </div>
      </div>

      <Modal
        open={open1}
        onClose={handleClose1}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="modall"
        BackdropProps={{
          onClick: (event) => event.stopPropagation,
        }}
      >
        <Box sx={style} className="text_center test_cmplt">
          <div className="modal_header">
            <button className="back_btn" onClick={gotohome}>
              <figure>
                <img src="/static/images/back_arrow.svg" alt="icon"></img>
              </figure>
              Back to Home
            </button>

            <h2>Test Complete</h2>
            <p className="close_icon" onClick={() => navigate("/testing")}>
              <CloseIcon />
            </p>
          </div>
          <figure className="wron_ans">
            <img
              src={
                Number(result?.correct_answers_percentage) > 50
                  ? "/static/images/smily_face.png"
                  : "/static/images/wrong_ans.png"
              }
              alt="icon"
            ></img>
          </figure>
          <div className="over_score">
            <div className="over_scr_bx">
              <p>Overall Score</p>
              <h2>{result?.correct_answers}</h2>
            </div>
            <ul className="prgrs_list ">
              <li>
                <h3>{result?.correct_answers_percentage}%</h3>
                <p>correct</p>
              </li>
              <li>
                <h3>{result?.wrong_answers_percentage}%</h3>
                <p>Incorrect</p>
              </li>
              <li>
                <h3>{result?.remaining_answers_percentage}%</h3>
                <p>Remaining</p>
              </li>
            </ul>
          </div>
          <div>
            <Tooltip title="Do this test again">
              <button
                className="re_attempt"
                onClick={() => {
                  // setCurrentNum(1);
                  // setCurrentQues(state.data[0]);
                  // setUserResBody([]);
                  if (state?.isGenerating) {
                    setCurrentNum(1);
                    setCurrentQues(streamedQuestions?.[0]);
                    setUserResBody([]);
                  } else {
                    setCurrentNum(1);
                    setCurrentQues(state.data[0]);
                    setUserResBody([]);
                  }
                  setEng(true);
                  setResult(null);
                  handleClose1();
                  setShowAns(false);
                }}
              >
                Re-appear
              </button>
            </Tooltip>
          </div>
          <div className="btn_group">
            <Button onClick={() => getChangeLanguage(true)}>
              Change language and download
            </Button>
            <Button onClick={() => FetchDownLoadFile(2)}>
              Download File Without Answers
            </Button>
            <Button onClick={() => FetchDownLoadFile(3)}>
              Download File With Answers
            </Button>
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default FlashCardQuiz;
