/* eslint-disable @typescript-eslint/no-unused-vars */
import Layout from "../../layout/index";
import "react-tabs/style/react-tabs.css";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import TextField from "@mui/material/TextField";
import React, { useState } from "react";
import Header from "../../layout/header/Header";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import useAuth from "../../hooks/useAuth";
import { isString } from "formik";
import { showError } from "../../constants/toast";
import { isValidInputNative } from "../../utils/validations";
import useTranslation from "../../hooks/Translation";

function CreateArtical() {
  const navigate = useNavigate();
  const translate = useTranslation();
  const [articleTopic, setarticleTopic] = useState("");
  return (
    <>
      <div className="sde_br_mn">
        <div className="crt_psn hd_6 mt_40 crt_artcl">
          <h3>{translate.article.topicOfArticle}</h3>
          <div className="atcl_cntr">
            <TextField
              className="control_group"
              hiddenLabel
              placeholder={translate.article.enterArticleTopic}
              fullWidth
              value={articleTopic}
              onChange={(e) => {
                if (e.target.value == " ") return;
                if (!isValidInputNative(e.target.value)) {
                  return;
                }
                if (e.target.value.length <= 500)
                  setarticleTopic(e.target.value);
                else
                  showError(
                    translate.errors.topicNameNotExceed500Characters ||
                      "Topic name can't exceed 500 characters"
                  );
              }}
              // onChange={(e:React.ChangeEvent<HTMLInputElement>)=>setarticleTopic(e.target.value)}
              variant="outlined"
              multiline
              rows={10}
            />
            {/* <div className="artcl_btn">
                    <Button type="button" className="btnn btn_primary">
                      Effect of mass communication
                    </Button>
                    <Button type="button" className="btnn btn_primary">
                      Effect of mass communication
                    </Button>
                    <Button type="button" className="btnn btn_primary">
                      Effect of mass communication of social media
                    </Button>
                  </div> */}
            <div className="rght_btn">
              <Button
                type="button"
                className="btnn btn_primary"
                onClick={() => {
                  if (articleTopic?.trim() == "") {
                    showError(
                      translate.errors.enterArticleTopics ||
                        "Please enter topic of article"
                    );
                    return;
                  }
                  navigate("/language", {
                    state: { articleTopic: articleTopic },
                    replace: true,
                  });
                }}
              >
                {translate.global.next || "Next"}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CreateArtical;
