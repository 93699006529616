/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { matchPath, useLocation, useNavigate } from "react-router-dom";
import { useLazyGetProfileQuery } from "../../services/auth";
import { useAppDispatch, useAppSelector } from "../../hooks/store";
import { showError, showToast } from "../../constants/toast";
import { getFromStorage, setToStorage } from "../../constants/storage";
import { getToken, setCredentials } from "../../reducers/authSlice";
import { STORAGE_KEYS } from "../../constants/storageKeys";
import useAuth from "../../hooks/useAuth";
import useTranslation from "../../hooks/Translation";

const SidePanel = () => {
  const location = useLocation();
  console.log(location.pathname);
  const navigate = useNavigate();
  const userData = useAuth();
  const setToken = useAppSelector(getToken);
  const dispatch = useAppDispatch();
  const translate = useTranslation();

  const [getProfile] = useLazyGetProfileQuery();
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const handleMouseDownPassword = (event: any) => {
    event.preventDefault();
  };

  const token = getFromStorage(STORAGE_KEYS.token) as any;
  const params = new URLSearchParams(window.location.search);
  const paramToken = params.get("token");

  if (!token) {
    if (!paramToken) {
      // showError("Something went wrong")
      navigate("/");
    }
  }
  const fetchData = async () => {
    if (setToken) {
      try {
        const res = await getProfile({}).unwrap();
        if (res?.status === 200) {
          dispatch(
            setCredentials({
              user: res?.data || null,
              token: token ? JSON.parse(token) : null,
            })
          );
          setToStorage(STORAGE_KEYS.user, JSON.stringify(res?.data));
        }
      } catch (error: any) {
        showError(error?.data?.message || error?.message);
      }
    }
  };

  useEffect(() => {
    if (token) {
      const user = getFromStorage(STORAGE_KEYS.user) as any;
      dispatch(
        setCredentials({
          user: user ? JSON.parse(user) : null,
          token: token ? JSON.parse(token) : null,
        })
      );
    } else if (paramToken) {
      setToStorage(STORAGE_KEYS.token, JSON.stringify(paramToken));
      const user = getFromStorage(STORAGE_KEYS.user) as any;
      dispatch(
        setCredentials({
          user: user ? JSON.parse(user) : null,
          token: paramToken ? paramToken : null,
        })
      );
    }
  }, []);

  useEffect(() => {
    if (!userData?.first_name) {
      fetchData();
    }
  }, [userData]);

  return (
    <div className="sde_bar">
      <figure className="logo">
        <img
          src="/static/images/logo.svg"
          alt=""
          onClick={() => navigate("/testing")}
        ></img>
      </figure>
      <ul className="sdebr_lst ">
        <li
          onClick={() => navigate("/testing")}
          className={location.pathname == "/testing" ? "active" : ""}
        >
          <figure>
            <img src="/static/images/testing.png" alt=""></img>
          </figure>
          <p>{translate.testing.testing}</p>
        </li>
        <li
          onClick={() => navigate("/file-summarization")}
          className={
            location.pathname == "/file-summarization" ||
            location.pathname.includes("/edit-file")
              ? "active"
              : ""
          }
        >
          <figure>
            <img src="/static/images/summarization.png" alt=""></img>
          </figure>
          <p>{translate.fileSummarization.fileSummarization}</p>
        </li>
        <li
          onClick={() =>
            // showToast("Coming soon")
            navigate("/note-taking")
          }
          className={location.pathname == "/note-taking" ? "active" : ""}
        >
          <figure>
            <img src="/static/images/note-taking.png" alt=""></img>
          </figure>
          <p>{translate.noteTaking.noteTaking}</p>
        </li>
        <li
          onClick={() => navigate("/research")}
          className={
            location.pathname == "/research" ||
            location.pathname == "/refernce" ||
            location.pathname == "/reserch-tone" ||
            location.pathname == "/research-page" ||
            location.pathname == "/topic-research" ||
            location.pathname.includes("/reserch-edit")
              ? "active"
              : ""
          }
        >
          <figure>
            <img src="/static/images/research.png" alt=""></img>
          </figure>
          <p>{translate.research.research}</p>
        </li>
        <li
          onClick={() => navigate("/assignment-solution")}
          className={
            location.pathname === "/assignment-solution" ||
            location.pathname === "/assign-solution" ||
            matchPath("/edit-assign/:id", location.pathname)
              ? "active"
              : ""
          }
        >
          <figure>
            <img src="/static/images/assignment.png" alt=""></img>
          </figure>
          <p>{translate.assignmentSolution.assignmentSolution}</p>
        </li>
        <li
          onClick={() => navigate("/file-type")}
          className={
            location.pathname == "/file-type" ||
            location.pathname == "/file-convert"
              ? "active"
              : ""
          }
        >
          <figure>
            <img src="/static/images/file_type.png" alt=""></img>
          </figure>
          <p>{translate.fileTypeConversion.fileTypeConversion}</p>
        </li>

        <li
          onClick={() => navigate("/presentations")}
          className={location.pathname == "/presentations" ? "active" : ""}
        >
          <figure>
            <img src="/static/images/presentations.png" alt=""></img>
          </figure>
          <p>{translate.presentation.presentations}</p>
        </li>
        <li
          onClick={() => navigate("/artical")}
          className={
            location.pathname == "/artical" ||
            location.pathname == "/createartical" ||
            location.pathname == "/language" ||
            location.pathname == "/selecttons" ||
            location.pathname == "/pronouns" ||
            location.pathname.includes("/edit-article/")
              ? "active"
              : ""
          }
        >
          <figure>
            <img src="/static/images/articles.png" alt=""></img>
          </figure>
          <p>{translate.article.articles}</p>
        </li>
        <li
          onClick={() => navigate("/subscriptions")}
          className={location.pathname == "/subscriptions" ? "active" : ""}
        >
          <figure>
            <img src="/static/images/subscriptions.png" alt=""></img>
          </figure>
          <p>{translate.subscriptions.subscriptions}</p>
        </li>
        <li
          onClick={() => navigate("/setting")}
          className={location.pathname == "/setting" ? "active" : ""}
        >
          <figure>
            <img src="/static/images/settings.png" alt=""></img>
          </figure>
          <p>{translate.settings.settings}</p>
        </li>
      </ul>
    </div>
  );
};

export default SidePanel;
