/* eslint-disable @typescript-eslint/no-unused-vars */
import Layout from "../../layout/index";
import "react-tabs/style/react-tabs.css";
import { Box, Button, Modal, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import PaymentCards from "../../components/paymentCards";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import useAuth from "../../hooks/useAuth";
import React, { useState } from "react";
import Header from "../../layout/header/Header";
import useTranslation from "../../hooks/Translation";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 680,
  bgcolor: "#252F2E",
  borderRadius: "30px",
  p: 6,
};
function Subscriptions() {
  const translate = useTranslation();
  const navigate = useNavigate();
  const [value, setValue] = useState(1);
  const [open, setOpen] = useState(false);
  const [sidebar, setSidebar] = useState(true);
  return (
    <>
      {value == 1 && (
        <div className="sde_br_mn slct">
          {/* <div className="sde_hdr">
            <div className="sde_hdr_lt">
              <figure>
                <img src="/static/images/subscriptions.png" alt=""></img>
              </figure>
              <div className="">
                <h3>Subscriptions</h3>
                <p> Lorem Ipsum dolor et amet</p>
              </div>
            </div>
            <div className="sde_hdr_rt">
              <figure>
                <img src="/static/images/notification.png" alt=""></img>
              </figure>
              <h3>Hi, Ahmed</h3>
              <figure>
                <img src="/static/images/ahmed.png" alt=""></img>
              </figure>
            </div>
          </div> */}
          <h6>{translate.subscriptions.subscriptions}</h6>
          <div className="sub_flx">
            <div className="sb_lt">
              <figure>
                <img src="/static/images/sub.png" alt=""></img>
              </figure>
            </div>
            <div className="sb_rt">
              <div className="wz_subLst">
                <RadioGroup
                  className="checkbox_label"
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue="card1"
                  name="radio-buttons-group"
                >
                  <FormControlLabel
                    value="card1"
                    control={<Radio />}
                    label={
                      <>
                        <p>
                          <small>{translate.subscriptions.freeTrial}</small>
                        </p>
                      </>
                    }
                  />
                  <FormControlLabel
                    value="card2"
                    control={<Radio />}
                    label={
                      <>
                        <div className="hd_4">
                          <p>
                            <small>
                              {translate.subscriptions.monthlySubscription}
                            </small>
                          </p>
                          <h3>$29.99/yr</h3>
                        </div>
                      </>
                    }
                  />
                  <FormControlLabel
                    value="card3"
                    control={<Radio />}
                    label={
                      <>
                        <div className="hd_4">
                          <p>
                            <small>
                              {translate.subscriptions.quarterlySubscription}
                            </small>
                          </p>
                          <h3>$100.99/yr</h3>
                        </div>
                      </>
                    }
                  />
                  <FormControlLabel
                    value="card4"
                    control={<Radio />}
                    label={
                      <>
                        <div className="hd_4">
                          <p>
                            <small>
                              {translate.subscriptions.yearlySubscription}
                            </small>
                          </p>
                          <h3>$100.99/yr</h3>
                        </div>
                      </>
                    }
                  />
                </RadioGroup>
              </div>
              <Button
                type="button"
                className="btn btn_primay btnn lrg"
                onClick={() => setValue(value + 1)}
              >
                {translate.subscriptions.tryForFree}
              </Button>
            </div>
          </div>
        </div>
      )}

      {value == 2 && (
        <div className="">
          <div className="sde_br_mn slct">
            {/* <div className="sde_hdr">
              <div className="sde_hdr_lt">
                <button
                  className="back_btn"
                  onClick={() => navigate("/subscriptions")}
                >
                  <ArrowBackIosNewIcon />
                </button>
                <figure>
                  <img src="/static/images/subscriptions.png" alt=""></img>
                </figure>
                <div className="">
                  <h3>Subscriptions</h3>
                  <p> Lorem Ipsum dolor et amet</p>
                </div>
              </div>
              <div className="sde_hdr_rt">
                <figure>
                  <img src="/static/images/notification.png" alt=""></img>
                </figure>
                <h3>Hi, Ahmed</h3>
                <figure>
                  <img src="/static/images/ahmed.png" alt=""></img>
                </figure>
              </div>
            </div> */}
            <h6>{translate.subscriptions.paymentOptions}</h6>
            <div className="sub_flx pymt_optn">
              <div className="sb_lt">
                <figure>
                  <img src="/static/images/sub.png" alt=""></img>
                </figure>
              </div>
              <div className="pymt_optn_rt pymt_non">
                <div className="pymt_mn">
                  <PaymentCards />
                  <div className="ptm_dtl">
                    <div className="ptm_cnt hd_2_1">
                      <h2>{translate.subscriptions.paymentDetails}</h2>
                      <ul className="ptm_flx hd_5">
                        <li>
                          <h3>{translate.subscriptions.totalAmount}</h3>
                          <h4>$29.99</h4>
                        </li>
                        <li>
                          <h3>{translate.subscriptions.tax}</h3>
                          <h4>$10.00</h4>
                        </li>
                        <li>
                          <h3>{translate.subscriptions.grandTotal}</h3>
                          <h4 className="totl">$30.99</h4>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <Button
                  className="btnn btn_primary lrg"
                  onClick={() => setOpen(true)}
                >
                  {translate.subscriptions.payNow}
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}

      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="congrs_modl">
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            className="py_mdl"
          >
            <figure>
              <img src="/static/images/check2.png" alt=""></img>
            </figure>
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <div className="py_mdl_cnt hd_3 text_center">
              <h3>{translate.subscriptions.congratulations}</h3>
              <p>{translate.subscriptions.paymentCompleted}</p>
            </div>
          </Typography>
        </Box>
      </Modal>
    </>
  );
}

export default Subscriptions;
