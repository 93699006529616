import React, { useEffect, useRef, useState } from "react";
import PptEditor from "../../components/Fabric";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
// @ts-ignore
import { compress, decompress } from "@witzbould/utils-string-compression";

import {
  Button,
  Drawer,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";

import PresentationSlider4 from "../presentationedit/presentationSlider4";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import * as fabric from "fabric";
import { showError, showToast } from "../../constants/toast";
import {
  useEditPresentationByIdMutation,
  useLazyGetPresentationByIdQuery,
} from "../../services/presentation";
import { presentationTemplate } from "../../constants/presentationTemplate";
import {
  Add,
  AddPhotoAlternateOutlined,
  ArrowLeft,
  ArrowRight,
  Cancel,
  ChangeHistory,
  Check,
  CircleOutlined,
  ContentCopyOutlined,
  ContentPasteOutlined,
  Crop,
  Delete,
  FormatAlignCenterOutlined,
  FormatAlignLeftOutlined,
  FormatAlignRightOutlined,
  FormatBoldOutlined,
  FormatItalicOutlined,
  FormatUnderlinedOutlined,
  PostAddRounded,
  Redo,
  Square,
  SquareOutlined,
  StickyNote2Sharp,
  TextDecreaseOutlined,
  TextIncreaseOutlined,
  Undo,
} from "@mui/icons-material";
import { CommonBody } from "../../types/General";
import { generateEncryptedKeyBody } from "../../utils/crypto";
import Loader from "../../constants/Loader";
import { getFromStorage } from "../../constants/storage";
import { STORAGE_KEYS } from "../../constants/storageKeys";
import { useWindowSize } from "@uidotdev/usehooks";
import imageCompression from "browser-image-compression";
import {
  compressData,
  decompressData,
  GetTextData,
} from "../../utils/decompressData";
import { UploadTextData } from "../../utils/mediaUpload";
import StickerPicker from "../../components/stickerPicker";
import useTranslation from "../../hooks/Translation";
type Anchor = "right";
const CANVAS_WIDTH = 1152;
const CANVAS_HEIGHT = 648;
let lastSelectedButton = 1;
let isSomethingCopied = false;
// let selectedTemplateID = 1;
let selectedSlideIndex = 1;
let undoCount = 0;
let redoCount = 0;
let currentImage: any = null;
let isRedoing = false;
let h: any = [];
// let totalAddedObjectOnDrawingPage=0;
// let binaryDataArray:any=[]
const PresentationEditor = () => {
  const navigate = useNavigate();
  const translate = useTranslation();
  const [isSomethingChanged, setIsSomethingChanged] = useState(false);
  const [selectedTemplateID, setSelectedTemplateID] = useState(1);
  const [age, setAge] = React.useState("");
  const [undoData, setUndoData] = useState<any>([]);
  // console.log('undoData :', undoData);
  const [redoData, setRedoData] = useState<any>([]);
  // console.log('redoData :', redoData);
  const { id } = useParams();
  const canvasRef = useRef(null);
  const [drawingMode, setDrawingMode] = useState(false);
  const [selectedButton, setSelectedButton] = useState(1);
  const [drawerState, setDrawerState] = React.useState(false);
  const [themeDrawer, setThemeDrawer] = React.useState(false);
  const [binaryDataArray, setBinaryDataArray] = useState<any>([]);
  const [binaryDataUpdated, setBinaryDataUpdated] = useState(false);
  console.log("binaryDataArray :", binaryDataArray);
  const [loadIndex, setLoadIndex] = useState(-1);
  const [canvas, setCanvas] = useState<any>(null);
  const [slides, setSlides] = useState<any>([]);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [eraseMode, setEraseMode] = useState(false);
  const [selectedColor, setSelectedColor] = useState("#000000");
  const [selectedFontSize, setSelectedFontSize] = useState(36);
  const [selectedFontFace, setSelectedFontFace] = useState("Arial");
  const [slideList, setSlideList] = useState<any>([]);
  const [slideListData, setSlideListData] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);
  // console.log('slideListData :', slideListData);
  const [backgroundImage, setBackgroundImage] = useState("");
  const [copiedObject, setCopiedObject] = useState<any>(null);
  const { state } = useLocation();
  const [penSize, setPenSize] = useState(3);
  const [toastLocationBottom, setToastLocationBottom] = useState(false);
  // const[isRedoing,setIsRedoing]=useState(false);
  // const xyz=()=>()=>{

  // }

  // useEffect(() => {
  //   window.addEventListener("beforeunload", handleBeforeUnload);
  //   // window.addEventListener("popstate", handlePopstate);
  //   window.onpopstate = (e) => handlePopstate(e);
  //   return () => {
  //     window.removeEventListener("beforeunload", handleBeforeUnload);
  //     // window.removeEventListener("popstate", handlePopstate);
  //   };
  // }, []);

  const handleBeforeUnload = (e: any) => {
    e.preventDefault();
    const message =
      translate.main.editedDataWillLost ||
      "Are you sure you want to leave? Edited data will be lost.";
    e.returnValue = message;
    return message;
  };

  const handlePopstate = (e: any) => {
    e.preventDefault();

    const message =
      translate.main.editedDataWillLost ||
      "Are you sure you want to leave? Edited data will be lost.";
    // alert(message);
    if (window.confirm(message)) {
      // Allow the user to navigate back
    } else {
      // Prevent the user from navigating back
      window.history.forward();
    }
  };

  useEffect(() => {
    if (getFromStorage(STORAGE_KEYS.application)) {
      setToastLocationBottom(true);
    }
    return () => {
      // if (isSomethingCopied)
        window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);
  console.log("selectedSlideIndex :", selectedSlideIndex);
  const handleFontChange = (event: SelectChangeEvent) => {
    const obj = canvas.getActiveObject();
    if (obj) {
      if (obj?.type == "i-text") {
        const text = obj?.text;
        const selectionStart = obj.selectionStart;
        const selectionEnd = obj.selectionEnd;
        const selectedText = text.substring(selectionStart, selectionEnd);
        console.log("selectedText :", selectedText);
        if (selectedText?.length) {
          obj.setSelectionStyles({
            fontSize: Number(event.target.value),
          });
        } else {
          for (let i = 0; i < text.length; i++) {
            obj.setSelectionStyles(
              {
                fontSize: Number(event.target.value),
              },
              i,
              i + 1
            );
          }

          // obj.fontSize = Number(event.target.value); // set font
        }
      }
      canvas.renderAll();
    }
    setSelectedFontSize(Number(event.target.value));
  };
  const handleFontFaceChange = (event: SelectChangeEvent) => {
    const obj = canvas.getActiveObject();
    console.log("obj21 :", obj);
    console.log(" obj23:", event.target.value);
    if (obj) {
      if (obj.type === "i-text") {
        const text = obj.text;
        const selectionStart = obj.selectionStart;
        const selectionEnd = obj.selectionEnd;
        const selectedText = text.substring(selectionStart, selectionEnd);
        console.log("selectedText :", selectedText);
        if (selectedText.length) {
          obj.setSelectionStyles({
            fontFamily: event.target.value,
          });
        } else {
          for (let i = 0; i < text.length; i++) {
            obj.setSelectionStyles(
              {
                fontFamily: event.target.value,
              },
              i,
              i + 1
            );
          }
        }
        // obj.fontFace = event.target.value; // set font face
      }
      canvas.renderAll();
    }
    setSelectedFontFace(event.target.value);
  };
  const fontFaces = [
    "Arial",
    "Calibri",
    "Cambria",
    "Courier New",
    "Garamond",
    "Georgia",
    "Helvetica",
    "Impact",
    "Lucida Console",
    "Lucida Sans Unicode",
    "Microsoft Sans Serif",
    "Monaco",
    "Open Sans",
    "Segoe UI",
    "Tahoma",
    "Times New Roman",
    "Verdana",
  ];
  const handleStopDrawing = () => {
    setDrawingMode(false);
    canvas.isDrawingMode = false;
  };
  const handleDelete = () => {
    const activeObjects = canvas.getActiveObjects();

    if (activeObjects.length > 0) {
      activeObjects.forEach((obj: any) => {
        canvas.remove(obj);
      });
      canvas.discardActiveObject();
      canvas.renderAll();
    } else {
      showError(
        translate.presentation.selectSomethingToDelete ||
          "Select something to delete",
        toastLocationBottom
      );
    }
  };
  const singleLineLimitAdder = (stringData: any, wordLimitPerLine: number) => {
    stringData = stringData?.trim()?.replaceAll("\n", " ");
    let count = 0;
    let tempString = "";
    for (let key of stringData.split(" ")) {
      if (count < wordLimitPerLine) tempString += key + " ";
      else {
        tempString += key + "\n";
        count = 0;
      }
      count++;
    }
    return tempString;
  };

  const handleAddRichText = () => {
    let stringData = "Sample Text Data";
    const text = new fabric.IText(singleLineLimitAdder(stringData, 12), {
      left: 50,
      top: 50,
      fontSize: selectedFontSize || 18,
      fontFamily: "Helvetica",
      fill: selectedColor || "#000000",
      lockScalingX: true,
      width: CANVAS_WIDTH,
      lockScalingY: true,
      hasControls: false,
    });
    canvas.add(text);
    canvas.setActiveObject(text);
  };

  const handleStartDrawing = () => {
    setDrawingMode(true);
    canvas.isDrawingMode = true;
    canvas.freeDrawingBrush = new fabric.PencilBrush(canvas);
    canvas.freeDrawingBrush.width = 8;
    canvas.freeDrawingBrush.color = selectedColor || "#000000";
    // canvas.freeDrawingBrush.color = "#000000";
  };

  const handleColorChange = (color: any) => {
    if (canvas.freeDrawingBrush) canvas.freeDrawingBrush.color = color;
    // currentColor = color; // Update the current color
    setSelectedColor(color);
    const activeObject = canvas.getActiveObject();
    if (activeObject) {
      if (activeObject.type === "i-text") {
        const text = activeObject.text;
        const selectionStart = activeObject.selectionStart;
        const selectionEnd = activeObject.selectionEnd;
        const selectedText = text.substring(selectionStart, selectionEnd);
        console.log("selectedText :", selectedText);
        if (selectedText.length) {
          activeObject.setSelectionStyles({
            fill: color,
          });
        } else {
          for (let i = 0; i < text.length; i++) {
            activeObject.setSelectionStyles(
              {
                fill: color,
              },
              i,
              i + 1
            );
          }
        }
        // activeObject.fontFace = event.target.value; // set font face
      } else if (activeObject?.type == "path")
        activeObject.set("stroke", color);
      else activeObject.set("fill", color);
      canvas.requestRenderAll();
    }
  };
  const toggleDrawer = () => {
    setDrawerState(!drawerState);
  };
  const toggleTheme = () => {
    setThemeDrawer(!themeDrawer);
  };
  const handleAddShape = () => {
    const rect = new fabric.Rect({
      width: 100,
      height: 100,
      cornerColor: "#8A5FF7",
      cornerStrokeColor: "#8A5FF7",
      fill: selectedColor || "#d00661",
    });
    canvas.add(rect);
    canvas.setActiveObject(rect);
  };

  const handleAddPath = (canvas: any) => {
    const path = new fabric.Path("M 0 0 L 100 100", {
      stroke: selectedColor || "#d00661",
      strokeWidth: 2,
      fill: "rgba(0,0,0,0)",
    });
    canvas.add(path);
    canvas.setActiveObject(path);
  };

  const handleAddCircle = () => {
    const circle = new fabric.Circle({
      radius: 50,
      cornerColor: "#8A5FF7",
      cornerStrokeColor: "#8A5FF7",
      fill: selectedColor || "#d00661",
    });
    canvas.add(circle);
    canvas.setActiveObject(circle);
  };

  const handleAddTriangle = () => {
    const triangle = new fabric.Triangle({
      width: 100,
      cornerColor: "#8A5FF7",
      cornerStrokeColor: "#8A5FF7",
      height: 100,
      fill: selectedColor || "#d00661",
    });
    canvas.add(triangle);
    canvas.setActiveObject(triangle);
  };

  const handleAlignLeft = () => {
    const activeObject = canvas.getActiveObject();
    if (activeObject && activeObject.type === "i-text") {
      activeObject.set({ textAlign: "left" });
      canvas.renderAll();
    }
  };
  const handleAlignCenter = () => {
    const activeObject = canvas.getActiveObject();
    if (activeObject && activeObject.type === "i-text") {
      activeObject.set({ textAlign: "center" });
      canvas.renderAll();
    }
  };

  const handleAlignRight = () => {
    const activeObject = canvas.getActiveObject();
    if (activeObject && activeObject.type === "i-text") {
      activeObject.set({ textAlign: "right" });
      canvas.renderAll();
    }
  };
  const handleUnderline = () => {
    const activeObject = canvas.getActiveObject();
    if (activeObject && activeObject.type === "i-text") {
      console.log(activeObject.fontStyle);
      const text = activeObject.text;
      const selectionStart = activeObject.selectionStart;
      const selectionEnd = activeObject.selectionEnd;
      const selectedText = text.substring(selectionStart, selectionEnd);
      console.log("selectedText :", selectedText);
      // set the font weight of the selected activeObject to bold
      const underlineData = activeObject.getSelectionStyles(
        selectionStart,
        selectionEnd,
        true
      );
      console.log("underlineData :", underlineData);
      let isUnderline = false;
      if (selectedText?.length) {
        for (let i = 0; i < underlineData.length; i++) {
          if (underlineData[i].underline) {
            isUnderline = true;
            break;
          }
        }

        if (isUnderline) {
          activeObject.setSelectionStyles(
            {
              underline: false,
            },
            selectionStart,
            selectionEnd
          );
        } else {
          activeObject.setSelectionStyles(
            {
              underline: true,
            },
            selectionStart,
            selectionEnd
          );
        }
      }

      // activeObject.set({ underline: !activeObject.underline });
      canvas.renderAll();
    }
  };
  // const handleBold = () => {
  //   const activeObject = canvas.getActiveObject();
  //   if (activeObject && activeObject.type === 'i-text') {
  //     console.log(activeObject.fontStyle);
  //     let newStyle="";
  //     if(activeObject.fontStyle=="bold")
  //     {
  //       newStyle="normal"
  //     }
  //     else if(activeObject.fontStyle=="bold italic")
  //     {
  //       newStyle="italic"
  //     }
  //     else if(activeObject.fontStyle=="italic")
  //     {
  //          newStyle="bold italic"
  //     }
  //     else
  //     {
  //       newStyle="bold"
  //     }
  //     activeObject.set({
  //       fontStyle: newStyle,
  //     });
  //     canvas.renderAll();
  //   }
  // };

  const windowSize = useWindowSize();
  const [togglable, setTogglable] = useState(false);
  useEffect(() => {
    console.log("MM", windowSize);
    if (!windowSize?.width) return;
    if (windowSize.width < 768) {
      setSelectedButton(0);
      setTogglable(true);
    }
  }, [windowSize]);
  const [isCropping, setIsCropping] = useState(false);
  const [selectionRect, setSelectionRect] = useState<any>(null);
  const [stickerOpen, setStickerOpen] = useState(false);

  const loadImageAndAdd = async (link: string) => {
    const res = await fetch(link?.slice(0, link.lastIndexOf("?")), {
      method: "GET",
      headers: { Accept: "*" },
    });
    console.log("res :", res);

    if (!res.ok) {
      throw new Error(`Failed to retrieve text data: ${res.statusText}`);
    }
    const ImageFile = await res.blob();
    console.log("ImageFile :", ImageFile);
    const reader = new FileReader();
    reader.onload = () => {
      const imageData = reader.result;
      const img: any = new Image();
      img.src = imageData;
      img.onload = () => {
        const image = new fabric.Image(img, {
          scaleX: 200 / img.width,
          cornerColor: "#8A5FF7",
          cornerStrokeColor: "#8A5FF7",
          scaleY: 200 / img.width,
          backgroundColor: "rgb(0,0,0,0)",
        });
        canvas.add(image);
        canvas.setActiveObject(image);
      };
    };
    reader.readAsDataURL(ImageFile);
  };
  const createMaskForCrop = () => {
    const active = canvas.getActiveObject();
    active.set({
      angle: 0,
    });
    canvas.remove(active);
    canvas.add(active);
    currentImage = active;
    // setCurrentImage(active);
    // isCropping=true;
    setIsCropping(true);
    const rect = new fabric.Rect({
      fill: "rgba(0,0,0,0.3)",
      originX: "left",
      originY: "top",
      stroke: "black",
      opacity: 1,
      width: active.getScaledWidth(),
      height: active.getScaledHeight(),
      left: active.left,
      top: active.top,
      flipX: false,
      flipY: false,
      lockRotation: true,
      lockScalingFlip: true,
      hasRotatingPoint: false,
      transparentCorners: false,
      cornerColor: "white",
      cornerStrokeColor: "black",
      borderColor: "black",
      cornerSize: 12,
      padding: 0,
      cornerStyle: "circle",
      // lockMovementX:true,
      // lockMovementY:true,
      borderDashArray: [5, 5],
      borderScaleFactor: 1.3,
      selectable: false,
      minX: active.left,
      minY: active.top,
      maxX: active.left + active.getScaledWidth(),
      maxY: active.top + active.getScaledHeight(),
    });

    // canvas.containSelection = true;
    // rect.scaleToWidth(300);
    // canvas.centerObject(rect);
    canvas.add(rect);
    setSelectionRect(rect);
    canvas.setActiveObject(rect);
    rect?.on("deselected", () => {
      console.log("REACT IS BEAST");
      canvas.remove(rect);
      // isCropping=false;
      setIsCropping(false);
    });
    canvas.requestRenderAll();
  };
  const crop = () => {
    const rect = new fabric.Rect({
      left: selectionRect.left,
      top: selectionRect.top,
      width: selectionRect.getScaledWidth(),
      height: selectionRect.getScaledHeight(),
      absolutePositioned: true,
    });

    currentImage.clipPath = rect;

    canvas.remove(selectionRect);

    const cropped = new Image();
    cropped.src = canvas.toDataURL({
      left: rect.left,
      top: rect.top,
      width: rect.width,
      height: rect.height,
      multiplier: 1,
    });

    cropped.onload = function () {
      // canvas.clear();
      const image = new fabric.Image(cropped, {
        left: rect.left,
        top: rect.top,
      });
      canvas.remove(currentImage);
      canvas.add(image);
      canvas.requestRenderAll();
      // setCurrentImage(null)
      currentImage = null;
      setIsCropping(false);
      // isCropping=false;
    };
  };
  const handleCropMohit = () => {
    const active = canvas.getActiveObject();
    if (active && active.type === "image") {
      // initCrop(active,canvas);
      createMaskForCrop();
    } else {
      showError(
        translate.presentation.selectImageToCrop || "Select image to crop"
      );
      return;
    }
  };
  const cancelCrop = () => {
    if (currentImage) {
      currentImage = null;
      setIsCropping(false);
      canvas.remove(canvas.getActiveObject());
    }
  };
  const handleBold = () => {
    const activeObject = canvas.getActiveObject();
    if (activeObject && activeObject.type === "i-text") {
      console.log(activeObject.fontStyle);
      const text = activeObject.text;
      const selectionStart = activeObject.selectionStart;
      const selectionEnd = activeObject.selectionEnd;
      const selectedText = text.substring(selectionStart, selectionEnd);
      console.log("selectedText :", selectedText);
      // set the font weight of the selected activeObject to bold
      const boldData = activeObject.getSelectionStyles(
        selectionStart,
        selectionEnd,
        true
      );
      console.log("bolsdData :", boldData);
      let isBold = false;
      if (selectedText?.length) {
        for (let i = 0; i < boldData.length; i++) {
          if (boldData[i].fontWeight === "bold") {
            isBold = true;
            break;
          }
        }
        console.log("activeObject :", activeObject);

        if (isBold) {
          activeObject.setSelectionStyles(
            {
              fontWeight: "normal",
            },
            selectionStart,
            selectionEnd
          );
        } else {
          activeObject.setSelectionStyles(
            {
              fontWeight: "bold",
            },
            selectionStart,
            selectionEnd
          );
        }
      }
      // else
      // {
      //   activeObject.setSelectionStyles({
      //     fontWeight: activeObject.fontWeight?.includes('bold')?'normal':'bold'
      //   }, 0, text.length);
      //   // let newStyle="";
      //   //     if(activeObject.fontStyle=="bold")
      //   //     {
      //   //       newStyle="normal"
      //   //     }
      //   //     else if(activeObject.fontStyle=="bold italic")
      //   //     {
      //   //       newStyle="italic"
      //   //     }
      //   //     else if(activeObject.fontStyle=="italic")
      //   //     {
      //   //          newStyle="bold italic"
      //   //     }
      //   //     else
      //   //     {
      //   //       newStyle="bold"
      //   //     }
      //   //     activeObject.set({
      //   //       fontStyle: newStyle,
      //   //     });

      // }
      // render the canvas to apply the changes
      canvas.requestRenderAll();
    }
  };

  const handleItalic = () => {
    const activeObject = canvas.getActiveObject();
    if (activeObject && activeObject.type === "i-text") {
      console.log(activeObject.fontStyle);
      const text = activeObject.text;
      const selectionStart = activeObject.selectionStart;
      const selectionEnd = activeObject.selectionEnd;
      const selectedText = text.substring(selectionStart, selectionEnd);
      console.log("selectedText :", selectedText);
      // set the font weight of the selected activeObject to bold
      const italicData = activeObject.getSelectionStyles(
        selectionStart,
        selectionEnd,
        true
      );
      console.log("italicData :", italicData);
      let isItalic = false;
      if (selectedText?.length) {
        for (let i = 0; i < italicData.length; i++) {
          if (italicData[i].fontStyle === "italic") {
            isItalic = true;
            break;
          }
        }

        if (isItalic) {
          activeObject.setSelectionStyles(
            {
              fontStyle: "normal",
            },
            selectionStart,
            selectionEnd
          );
        } else {
          activeObject.setSelectionStyles(
            {
              fontStyle: "italic",
            },
            selectionStart,
            selectionEnd
          );
        }
      }
      // else
      // {
      //   activeObject.setSelectionStyles({
      //     fontStyle: 'normal'
      //   }, 0, text.length);
      //   let newStyle="";
      //   if(activeObject.fontStyle=="italic")
      //   {
      //     newStyle="normal"
      //   }
      //   else if(activeObject.fontStyle=="bold italic")
      //   {
      //     newStyle="bold"
      //   }
      //   else if(activeObject.fontStyle=="bold")
      //   {
      //        newStyle="bold italic"
      //   }
      //   else
      //   {
      //     newStyle="italic"
      //   }
      //   activeObject.set({
      //     fontStyle: newStyle,
      //   });
      // }
      canvas.renderAll();
    }
  };
  // const handleItalic = () => {
  //   const activeObject = canvas.getActiveObject();
  //   if (activeObject && activeObject.type === 'i-text') {
  //     let newStyle="";
  //     if(activeObject.fontStyle=="italic")
  //     {
  //       newStyle="normal"
  //     }
  //     else if(activeObject.fontStyle=="bold italic")
  //     {
  //       newStyle="bold"
  //     }
  //     else if(activeObject.fontStyle=="bold")
  //     {
  //          newStyle="bold italic"
  //     }
  //     else
  //     {
  //       newStyle="italic"
  //     }
  //     activeObject.set({
  //       fontStyle: newStyle,
  //     });
  //     canvas.renderAll();
  //   }
  // };

  // const handleBold = () => {
  //   const activeObject = canvas.getActiveObject();
  //   if (activeObject && activeObject.type === 'i-text') {
  //     const selectedText = activeObject.getSelectedText();
  //     const startIndex = activeObject.getSelectionStart();
  //     const endIndex = activeObject.getSelectionEnd();

  //     if (selectedText) {
  //       const currentStyle = activeObject.getSelectionStyles(
  //         startIndex,
  //         endIndex
  //       )[0];
  //       const newStyle = {
  //         ...currentStyle,
  //         fontWeight: currentStyle.fontWeight === "bold" ? "normal" : "bold",
  //       };

  //       activeObject.setText(selectedText, newStyle, startIndex, endIndex);
  //       canvas.renderAll();
  //     }
  //   }
  // };

  const handleIncreaseFontSize = () => {
    const activeObject = canvas.getActiveObject();
    if (activeObject && activeObject.type === "i-text") {
      activeObject.set({ fontSize: activeObject.fontSize + 1 });
      canvas.renderAll();
    }
  };

  const handleDecreaseFontSize = () => {
    const activeObject = canvas.getActiveObject();
    if (activeObject && activeObject.type === "i-text") {
      activeObject.set({ fontSize: activeObject.fontSize - 1 });
      canvas.renderAll();
    }
  };
  useEffect(() => {
    setTimeout(() => {
      const anchor: any = document.querySelector("#selectedTheme");
      if (anchor && themeDrawer)
        anchor.scrollIntoView({ behavior: "smooth", block: "center" });
    }, 100);
  }, [themeDrawer]);
  useEffect(() => {
    setTimeout(() => {
      const anchor: any = document.querySelector("#selectedPage");
      if (anchor && drawerState)
        anchor.scrollIntoView({ behavior: "smooth", block: "center" });
    }, 100);
  }, [drawerState]);

  const handleThemeChange = async (url: string, id: number) => {
    setIsLoading(true);
    // selectedTemplateID = id;
    setSelectedTemplateID(id);
    const canvasElement: any = document.createElement("canvas");
    const canvasInstance = new fabric.Canvas(canvasElement, {
      width: CANVAS_WIDTH,
      height: CANVAS_HEIGHT,
      backgroundColor: "#FFFFFF",
    });
    const slideList: string[] = [];
    const binaryData: string[] = [];
    // console.log('pptData :', pptData);
    console.log("pptData12ee :", binaryDataArray);
    for (const data in binaryDataArray) {
      await canvasInstance
        .loadFromJSON(
          data == selectedSlideIndex.toString()
            ? canvas.toJSON()
            : JSON.parse(binaryDataArray[data])
        )
        .then(() => {
          canvas.forEachObject(function (obj: any) {
            obj.cornerColor = "#8A5FF7";
            obj.cornerStrokeColor = "#8A5FF7";
            if (obj instanceof fabric.Text) {
              // obj.lockMovementX = true;
              // obj.lockMovementY = true;
              obj.lockScalingX = true;
              obj.lockScalingY = true;
              obj.hasControls = false;
            }
          });
          console.log("12ee", data);

          const img = new Image();
          img.src = url;
          img.onload = () => {
            const image = new fabric.Image(img, {
              width: CANVAS_WIDTH,
              height: CANVAS_HEIGHT,
              selectable: false, // make sure the background image is not selectable
            });
            canvasInstance.backgroundImage = image; // set the background image
            canvasInstance.renderAll(); // render the canvas
            slideList.push(canvasInstance.toDataURL());
            binaryData.push(JSON.stringify(canvasInstance.toJSON()));
            canvasInstance.clear();
          };

          // canvasInstance.renderAll();
          // console.log('canvasInstance.toDataURL() :', canvasInstance.toDataURL());
          // setSlideList((prevData:any)=>[...prevData,canvasInstance?.toDataURL()])
          // console.log('slideList00000 :', slideList);
        });
    }
    console.log("slideList12ee :", slideList);
    console.log("binaryData12ee :", binaryData);
    setSlideList(slideList);
    setBinaryDataArray(binaryData);
    await canvas
      .loadFromJSON(JSON.parse(binaryData[selectedSlideIndex]))
      .then(() => {
        canvas.forEachObject(function (obj: any) {
          obj.cornerColor = "#8A5FF7";
          obj.cornerStrokeColor = "#8A5FF7";
          if (obj instanceof fabric.Text) {
            // obj.lockMovementX = true;
            // obj.lockMovementY = true;
            obj.lockScalingX = true;
            obj.lockScalingY = true;
            obj.hasControls = false;
          }
        });
        canvas.renderAll();
      });
    setIsLoading(false);
    showToast(
      translate.presentation.themeChangedSuccessfully ||
        "Theme changed successfully",
      Boolean(getFromStorage(STORAGE_KEYS.application))
    );
  };

  const [editById] = useEditPresentationByIdMutation();

  const handleUpdatePPT = async () => {
    setIsLoading(true);
    try {
      const finalDAta = [];
      // for (let key = 0; key < binaryDataArray.length; key++) {
      //   if (selectedSlideIndex == key) {
      //     finalDAta.push(JSON.stringify(canvas.toJSON()))
      //   } else {
      //     finalDAta.push(binaryDataArray[key])
      //   }
      // }
      for (let key = 0; key < binaryDataArray.length; key++) {
        if (selectedSlideIndex == key) {
          finalDAta.push(await compressData(JSON.stringify(canvas.toJSON())));
        } else {
          finalDAta.push(await compressData(binaryDataArray[key]));
        }
        console.log("muskan1 :", await compressData(binaryDataArray[key]));
        // console.log("muskan1", await compress(binaryDataArray[key], 'gzip'))
      }
      for (let key = 0; key < binaryDataArray.length; key++) {
        // finalDAta.push(await decompress(binaryDataArray[key], 'gzip'))
        console.log("muskan2", await decompressData(finalDAta[key]));
      }
      const binaryAWS = await UploadTextData(JSON.stringify(finalDAta));
      const body = {
        template_id: selectedTemplateID,
        binary_data: binaryAWS,
        // binary_data: binaryDataArray.map((data: any, index: number) =>
        //   index == selectedSlideIndex
        //     ? JSON.stringify(canvas.toJSON())
        //     : data
        // ),
      };
      console.log("body :", body);
      // console.log('body :', binaryDataArray);
      const encryptedBody = generateEncryptedKeyBody(body) as CommonBody;
      console.log("AFTER BODY");

      const response = await editById({
        id: id as string,
        body: encryptedBody,
      }).unwrap();
      if (response?.status == 200) {
        // generateImagesFromPptJson(data)
        showToast(
          translate.presentation.presentationUpdatedSuccessfully ||
            "Presentation updated successfully",
          toastLocationBottom
        );
        navigate("/presentation-edit/" + id, { replace: true });
        console.log(response);
      }
    } catch (error: any) {
      showError(
        error?.data?.message ||
          translate.errors.somethingWentWrong ||
          "Something went wrong",
        toastLocationBottom
      );
      console.log(error);
    }
    setIsLoading(false);
  };

  // let currentColor = '#000000';

  async function Copy() {
    // clone what are you copying since you
    // may want copy and paste on different moment.
    // and you do not want the changes happened
    // later to reflect on the copy.
    const temp = await canvas.getActiveObject()?.clone();
    console.log("MOHIT", temp.type);
    if (temp) {
      setCopiedObject(temp);
      showToast(
        translate.presentation.itemCopiedSuccessfully ||
          "Item Copied successfully",
        toastLocationBottom
      );
      isSomethingCopied = true;
      // _clipboard = cloned;
    }
  }

  async function Paste() {
    // clone again, so you can do multiple copies.
    // const clonedObj = await copiedObject?.clone();
    if (!isSomethingCopied) {
      showError(
        translate.presentation.pleaseSelectAnythingFirstToCopy ||
          "Please select anything first to copy",
        toastLocationBottom
      );
      return;
    }
    // canvas.discardActiveObject();
    copiedObject.set({
      left: copiedObject.left + 10,
      top: copiedObject.top + 10,
      evented: true,
    });
    if (copiedObject.type === "activeselection") {
      // active selection needs a reference to the canvas.
      copiedObject.canvas = canvas;
      copiedObject.forEachObject(function (obj: any) {
        canvas.add(obj);
      });
      copiedObject.setCoords();
      // this should solve the unselectability
    } else {
      canvas.add(copiedObject);
    }

    copiedObject.top = (canvas.getActiveObject().top || copiedObject.top) + 10;
    copiedObject.left =
      (canvas.getActiveObject().left || copiedObject.left) + 10;
    canvas.setActiveObject(copiedObject);
    canvas.requestRenderAll();
    isSomethingCopied = false;
    showToast(
      translate.presentation.itemPastedSuccessful || "Item Pasted Successful",
      toastLocationBottom
    );
    Copy();
  }

  const handleUploadImage = async (event: any) => {
    const file = event?.target?.files?.[0];
    if (!file) {
      return;
    }
    try {
      setIsLoading(true);
      const allowedExtensions = ["png", "jpg", "jpeg"];
      const fileExtension = file.name.split(".").pop()?.toLowerCase();
      console.log("sssssssssssssssssssss", fileExtension);
      if (!allowedExtensions.includes(fileExtension || "")) {
        showError(
          translate.errors.onlyJpgPngAllowed ||
            "Invalid file format: only png, jpg images are allowed",
          toastLocationBottom
        );
        return;
      }
      const reader = new FileReader();
      reader.onload = () => {
        const imageData = reader.result;
        const img: any = new Image();
        img.src = imageData;
        img.onload = () => {
          const image = new fabric.Image(img, {
            scaleX: 200 / img.width,
            cornerColor: "#8A5FF7",
            cornerStrokeColor: "#8A5FF7",
            scaleY: 200 / img.width,
          });
          canvas.add(image);
          canvas.setActiveObject(image);
          setIsLoading(false);
        };
      };
      const options = {
        maxSizeMB: 2,
        maxWidthOrHeight: 1920,
        useWebWorker: true,
      };
      const compressedFile = await imageCompression(file, options);
      reader.readAsDataURL(compressedFile);
    } catch (error: any) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const generateImagesFromPptJson = async (pptData: any[]) => {
    const canvasElement: any = document.createElement("canvas");

    try {
      const canvasInstance = new fabric.Canvas(canvasElement, {
        width: CANVAS_WIDTH,
        height: CANVAS_HEIGHT,
        backgroundColor: "#FFFFFF",
      });
      const slideList: string[] = [];
      // console.log('pptData :', pptData);
      console.log("pptData :", pptData);
      for (const data of pptData) {
        await canvasInstance
          .loadFromJSON(JSON.parse(await decompressData(data)))
          .then(() => {
            //   canvas.forEachObject(function (obj: any) {
            //     obj.cornerColor="#8A5FF7"
            //    obj.cornerStrokeColor="#8A5FF7"
            //    if (obj instanceof fabric.Text) {
            //      // obj.lockMovementX = true;
            //      // obj.lockMovementY = true;
            //      obj.lockScalingX = true;
            //      obj.lockScalingY = true;
            //      obj.hasControls = false;
            //    }
            //  });
            canvasInstance.renderAll();
            // console.log('canvasInstance.toDataURL() :', canvasInstance.toDataURL());
            slideList.push(canvasInstance.toDataURL());
            // setSlideList((prevData:any)=>[...prevData,canvasInstance?.toDataURL()])
            // console.log('slideList00000 :', slideList);

            canvasInstance.clear();
          });
      }
      setSlideList(slideList);
      // setSelectedSlide(slideList[0]);
      // console.log('slideList232323 :', slideList);
    } catch (error: any) {
      console.log(error);
      setIsLoading(false);
    }
    setIsLoading(false);
  };
  useEffect(() => {
    if (binaryDataArray?.length) {
      if (!binaryDataUpdated) {
        canvas
          .loadFromJSON(JSON.parse(binaryDataArray[state || 0]))
          .then(() => {
            canvas.forEachObject(function (obj: any) {
              obj.cornerColor = "#8A5FF7";
              obj.cornerStrokeColor = "#8A5FF7";
              if (obj instanceof fabric.Text) {
                // obj.lockMovementX = true;
                // obj.lockMovementY = true;
                obj.lockScalingX = true;
                obj.lockScalingY = true;
                obj.hasControls = false;
              }
            });
            console.log(
              "canvas?.getObjects('text') :",
              canvas?.getObjects("i-text")
            );
            canvas.renderAll();
          });
        setBinaryDataUpdated(true);
      }
    }
  }, [binaryDataArray]);

  const [getDataById] = useLazyGetPresentationByIdQuery();
  const getDataByIdList = async () => {
    setIsLoading(true);
    try {
      const response = await getDataById({ id }).unwrap();
      if (response.status == 200) {
        // setCurrentPresentationData(response?.data);
        const data = JSON.parse(
          await GetTextData(response?.data?.binary_data?.data?.[0]?.media_url)
        );
        
        let finalData = [];
        for (let key = 0; key < data?.length; key++) {
          finalData.push(await decompressData(data[key]));
          // await decompress(finalDAta[key], 'gzip')
        }
        generateImagesFromPptJson(data);
        // topicName=response?.data?.text;
        // selectedTemplateID = response?.data?.template_id;
        setSelectedTemplateID(response?.data?.template_id);
        setBinaryDataArray(finalData);

        // template_id=response?.data?.template_id
        // setCurrentSlideInfo({currentPage:1,totalPages:response?.data?.binary_data?.length})
        console.log(response);
      }
    } catch (error: any) {
      showError(
        error?.data?.message ||
          translate.errors.somethingWentWrong ||
          "Something went wrong",
        toastLocationBottom
      );
      console.log(error);
      setIsLoading(false);
    }
  };

  // const saveStateFabric = async(canvasInstance:any) => {
  //   if (canvasInstance) {
  //     const currentState =await canvasInstance.toJSON();
  //     // setUndoData([...undoData, currentState]);
  //     setUndoData((prev:any)=>[...prev,currentState])
  //     setRedoData([]);
  //   }
  // };

  // const handleUndo = async () => {
  //   if (undoData.length > 0) {
  //     console.log('undoData :', undoData);
  //     const previousState = undoData[undoData.length - 1];
  //     await canvas.loadFromJSON(previousState);
  //     await canvas.renderAll();
  //     const newUndoData = undoData.slice(0, -1);
  //     const newRedoData = [...redoData, undoData[undoData.length - 1]];
  //     setUndoData(newUndoData);
  //     setRedoData(newRedoData);
  //   }
  // };

  // const handleRedo = async () => {
  //   if (redoData.length > 0) {
  //     const nextState = redoData[redoData.length - 1];
  //     await canvas.loadFromJSON(nextState);
  //     canvas.renderAll();
  //     const newUndoData = [...undoData, redoData[redoData.length - 1]];
  //     const newRedoData = redoData.slice(0, -1);
  //     setUndoData(newUndoData);
  //     setRedoData(newRedoData);
  //   }
  // };

  async function undo() {
    console.log(`UNDO :
      undoCount=${undoCount}
      redoCount=${redoCount}`);

    if (undoCount > 0) {
      --undoCount;
      ++redoCount;
      h.push(await canvas._objects.pop());
      await canvas.renderAll();
    }
    // if(canvas._objects.length>0){
    //  h.push(canvas._objects.pop());
    //  await canvas.renderAll();
    // }
  }
  async function redo() {
    console.log(`REDO START :
      undoCount=${undoCount}
      redoCount=${redoCount}`);
    console.log("REDO ARRAY :", h);

    if (redoCount > 0) {
      --redoCount;
      // ++undoCount;
      isRedoing = true;
      await canvas.add(h.pop());
      await canvas.renderAll();
    }
    console.log(`REDO EXIT :
    undoCount=${undoCount}
    redoCount=${redoCount}`);
    // if(h.length>0){
    //   setIsRedoing(true)
    //  await canvas.add(h.pop());
    //  await canvas.renderAll();

    // }
  }
  useEffect(() => {
    // const canvasElement: any = document.getElementById("canvas");
    if (!canvasRef.current) {
      return;
    }
    const canvasInstance = new fabric.Canvas(canvasRef.current, {
      width: CANVAS_WIDTH,
      height: CANVAS_HEIGHT,
      backgroundColor: "#FFFFFF",
    });
    canvasInstance.on("object:moving", () => {
      if (currentImage) {
        const selectedObject = canvasInstance.getActiveObject();
        if (!selectedObject) return;
        if (selectedObject?.left <= currentImage.left) {
          selectedObject?.set({
            left: currentImage.left,
          });
        }
        if (selectedObject?.top <= currentImage.top) {
          selectedObject?.set({
            top: currentImage.top,
          });
        }
        console.log(
          "(selectedObject.left+selectedObject.getBoundingRect().width)<=(currentImage.left+currentImage.getBoundingRect().width) :",
          selectedObject.left + selectedObject.getBoundingRect().width <=
            currentImage.left + currentImage.getBoundingRect().width
        );
        console.log(
          "(selectedObject.left+selectedObject.getBoundingRect().width) :",
          selectedObject.left + selectedObject.getBoundingRect().width
        );
        console.log(
          "(currentImage.left+currentImage.getBoundingRect().width) :",
          currentImage.left + currentImage.getBoundingRect().width
        );
        if (
          selectedObject.left + selectedObject.getBoundingRect().width >=
          currentImage.left + currentImage.getBoundingRect().width
        ) {
          selectedObject?.set({
            left:
              currentImage.left +
              currentImage.getBoundingRect().width -
              selectedObject.getBoundingRect().width,
          });
        }
        if (
          selectedObject.top + selectedObject.getBoundingRect().height >=
          currentImage.top + currentImage.getBoundingRect().height
        ) {
          selectedObject?.set({
            top:
              currentImage.top +
              currentImage.getBoundingRect().height -
              selectedObject.getBoundingRect().height,
          });
        }
        canvasInstance.requestRenderAll();
      }
    });
    canvasInstance.on("object:scaling", (e) => {
      if (currentImage) {
        const selectedObject = e.target;
        const scaleX = selectedObject.scaleX;
        const scaleY = selectedObject.scaleY;
        const width = selectedObject.width * scaleX;
        const height = selectedObject.height * scaleY;
        const left = selectedObject.left;
        const top = selectedObject.top;

        // Calculate the maximum scale values to keep the object within the image boundaries
        const maxScaleX =
          (currentImage.left + currentImage.getBoundingRect().width - left) /
          selectedObject.width;
        const maxScaleY =
          (currentImage.top + currentImage.getBoundingRect().height - top) /
          selectedObject.height;

        // Limit the scale values to the maximum allowed
        selectedObject.set({
          scaleX: Math.min(scaleX, maxScaleX),
          scaleY: Math.min(scaleY, maxScaleY),
        });

        // Adjust the object's position to maintain edge alignment
        if (selectedObject?.left <= currentImage.left) {
          selectedObject?.set({
            left: currentImage.left,
            scaleX: Math.min(
              scaleX,
              (currentImage.left +
                currentImage.getBoundingRect().width -
                currentImage.left) /
                selectedObject.width
            ),
          });
        }
        if (selectedObject?.top <= currentImage.top) {
          selectedObject?.set({
            top: currentImage.top,
            scaleY: Math.min(
              scaleY,
              (currentImage.top +
                currentImage.getBoundingRect().height -
                currentImage.top) /
                selectedObject.height
            ),
          });
        }

        canvasInstance.requestRenderAll();
      }
    });
    selectedSlideIndex = state || 0;
    //     canvasInstance.on('object:added',()=>{
    // saveStateFabric(canvasInstance);
    // })
    canvasInstance.on("object:modified", () => {
      // saveStateFabric(canvasInstance);
      if (!isSomethingChanged) {
        setIsSomethingChanged(true);
        window.addEventListener("beforeunload", handleBeforeUnload);
      }
    });

    canvasInstance.on("object:added", function () {
      if (!isSomethingChanged) {
        setIsSomethingChanged(true);
        window.addEventListener("beforeunload", handleBeforeUnload);
      }
      if (!isRedoing) {
        h = [];
        redoCount = 0;
      }
      ++undoCount;
      // handleAddPath(canvasInstance);

      // canvasInstance._objects.pop()
      // setIsRedoing(false);
      isRedoing = false;
    });

    // const img = new Image();
    // img.src = 'https://raw.githubusercontent.com/gitbrent/PptxGenJS/gh-pages/img/readme_banner.png';
    // img.onload = () => {
    //   const image = new fabric.Image(img, {
    //     width: CANVAS_WIDTH,
    //     height: CANVAS_HEIGHT,
    //     selectable: false, // make sure the background image is not selectable
    //   });
    //   canvasInstance.backgroundImage = image; // set the background image
    //   canvasInstance.renderAll(); // render the canvas
    // }
    // canvasInstance.getActiveObject() // set the background image

    canvasInstance.renderAll();
    // canvasInstance.on("mouse:wheel", function(opt:any) {
    //   opt.e.preventDefault()
    //   opt.e.stopPropagation()
    //   if (opt.e.ctrlKey) {
    //     console.log("pinch")
    //     var delta = opt.e.deltaY;
    //     var zoom = canvasInstance.getZoom();
    //     zoom *= 0.999 ** delta;
    //     canvasInstance.setZoom(zoom);
    //   } else {
    //     var e = opt.e;
    //     var vpt = canvasInstance.viewportTransform;
    //     vpt[4] += e.deltaX;
    //     vpt[5] += e.deltaY;
    //     canvasInstance.requestRenderAll();
    //   }
    // })

    setCanvas(canvasInstance);
    getDataByIdList();
    return () => {
      canvasInstance.dispose();
    };
  }, []);
  return (
    <>
      <main>
        <Loader isLoad={isLoading} />
        <section className="prev_ed pres_bk editPPT_sc">
          <div className="prev_ed_lt">
            <div className="sde_br_mn">
              <div className="sde_hdr">
                <div
                  onClick={() => {
                    const message =
                      translate.main.editedDataWillLost ||
                      "Are you sure you want to leave? Edited data will be lost.";
                    // alert(message);
                    if (window.confirm(message)) {
                      navigate("/presentation-edit/" + id, { replace: true });
                      // Allow the user to navigate back
                    }
                    console.log("kkkkkkkkkkkkk");
                  }}
                  style={{ cursor: "pointer" }}
                >
                  <ArrowBackIosNewIcon />
                </div>
              </div>
              <div className="sde_hdr_bt">
                {selectedButton == 1 && (
                  <>
                    <div className="frmn_mn">
                      <Select
                        className="control_group"
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={selectedFontFace}
                        onChange={handleFontFaceChange}
                      >
                        <MenuItem value={""} disabled>
                          {translate.global.select || "Select"}
                        </MenuItem>
                        {fontFaces?.map((data: string) => {
                          return <MenuItem value={data}>{data}</MenuItem>;
                        })}
                      </Select>
                    </div>
                    <div className="frmn_mn">
                      <Select
                        className="control_group"
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={selectedFontSize.toString()}
                        onChange={handleFontChange}
                      >
                        {/* <MenuItem value={8}>1</MenuItem> */}
                        <MenuItem value={12}>5</MenuItem>
                        <MenuItem value={18}>11</MenuItem>
                        <MenuItem value={24}>17</MenuItem>
                        <MenuItem value={28}>21</MenuItem>
                        <MenuItem value={32}>25</MenuItem>
                        <MenuItem value={36}>29</MenuItem>
                        <MenuItem value={40}>33</MenuItem>
                        <MenuItem value={42}>35</MenuItem>
                      </Select>
                    </div>
                    {/* <button>
                    <figure>
                      <img src="/static/images/t2.svg" alt="image" />
                    </figure>
                  </button>
  
                  <button>
                    <figure>
                      <img src="/static/images/t5.svg" alt="image" />
                    </figure>
                  </button>
                  <button>
                    <figure>
                      <img src="/static/images/t4.svg" alt="image" />
                    </figure>
                  </button>
                  <button>
                    <figure>
                      <img src="/static/images/t6.svg" alt="image" />
                    </figure>
                  </button>
                  <button>
                    <figure>
                      <img src="/static/images/B.svg" alt="image" />
                    </figure>
                  </button> */}
                    <button
                      style={{ cursor: "pointer" }}
                      onClick={() => handleAddRichText()}
                    >
                      <figure style={{ cursor: "pointer" }}>
                        <PostAddRounded htmlColor="white" />
                      </figure>
                    </button>
                    <input
                      type="color"
                      className="colorPickMohit"
                      value={selectedColor}
                      onChange={(e) => {
                        handleColorChange(e.target.value);
                      }}
                    />
                    {/* <button
                      style={{ cursor: "pointer" }}
                      onClick={handleIncreaseFontSize}
                    >
                      <figure style={{ cursor: "pointer" }}>
                        <TextIncreaseOutlined htmlColor="white" />
                      </figure>
                    </button>
                    <button
                      style={{ cursor: "pointer" }}
                      onClick={handleDecreaseFontSize}
                    >
                      <figure style={{ cursor: "pointer" }}>
                        <TextDecreaseOutlined htmlColor="white" />
                      </figure>
                    </button> */}
                    <button
                      style={{ cursor: "pointer" }}
                      onClick={handleAddShape}
                    >
                      <figure style={{ cursor: "pointer" }}>
                        <SquareOutlined htmlColor="white" />
                      </figure>
                    </button>
                    <button
                      style={{ cursor: "pointer" }}
                      onClick={handleAddCircle}
                    >
                      <figure style={{ cursor: "pointer" }}>
                        <CircleOutlined htmlColor="white" />
                      </figure>
                    </button>
                    <button
                      style={{ cursor: "pointer" }}
                      onClick={handleAddTriangle}
                    >
                      <figure style={{ cursor: "pointer" }}>
                        <ChangeHistory htmlColor="white" />
                      </figure>
                    </button>
                    <button
                      style={{ cursor: "pointer" }}
                      onClick={handleAlignLeft}
                    >
                      <figure style={{ cursor: "pointer" }}>
                        <FormatAlignLeftOutlined htmlColor="white" />
                      </figure>
                    </button>
                    <button
                      style={{ cursor: "pointer" }}
                      onClick={handleAlignCenter}
                    >
                      <figure style={{ cursor: "pointer" }}>
                        <FormatAlignCenterOutlined htmlColor="white" />
                      </figure>
                    </button>
                    <button
                      style={{ cursor: "pointer" }}
                      onClick={handleAlignRight}
                    >
                      <figure style={{ cursor: "pointer" }}>
                        <FormatAlignRightOutlined htmlColor="white" />
                      </figure>
                    </button>
                    <button style={{ cursor: "pointer" }} onClick={handleBold}>
                      <figure style={{ cursor: "pointer" }}>
                        <FormatBoldOutlined htmlColor="white" />
                      </figure>
                    </button>
                    <button
                      style={{ cursor: "pointer" }}
                      onClick={handleItalic}
                    >
                      <figure style={{ cursor: "pointer" }}>
                        <FormatItalicOutlined htmlColor="white" />
                      </figure>
                    </button>
                    <button
                      style={{ cursor: "pointer" }}
                      onClick={handleUnderline}
                    >
                      <figure style={{ cursor: "pointer" }}>
                        <FormatUnderlinedOutlined htmlColor="white" />
                      </figure>
                    </button>
                    <button style={{ cursor: "pointer" }} onClick={Copy}>
                      <figure style={{ cursor: "pointer" }}>
                        <ContentCopyOutlined htmlColor="white" />
                      </figure>
                    </button>
                    <button style={{ cursor: "pointer" }} onClick={Paste}>
                      <figure style={{ cursor: "pointer" }}>
                        <ContentPasteOutlined htmlColor="white" />
                      </figure>
                    </button>
                    <button
                      style={{ cursor: "pointer" }}
                      onClick={handleDelete}
                    >
                      <figure style={{ cursor: "pointer" }}>
                        <Delete htmlColor="white" />
                      </figure>
                    </button>
                  </>
                )}
                {selectedButton == 2 && (
                  <div className="top_dv">
                    {/* <input accept='image/*' id="ppt" type="file" onChange={handleUploadImage} style={{display:"none"}}/>
                  <label htmlFor="ppt" style={{cursor:"pointer"}}> <button style={{cursor:"pointer"}}>
                    <figure style={{cursor:"pointer"}}>
                    <AddPhotoAlternateOutlined htmlColor="white"/>
                    </figure>
                  </button></label> */}
                    {isCropping ? (
                      <>
                        <button style={{ cursor: "pointer" }} onClick={crop}>
                          <figure style={{ cursor: "pointer" }}>
                            <Check htmlColor="white" />
                          </figure>
                        </button>
                        <button
                          style={{ cursor: "pointer" }}
                          onClick={cancelCrop}
                        >
                          <figure style={{ cursor: "pointer" }}>
                            <Cancel htmlColor="white" />
                          </figure>
                        </button>
                      </>
                    ) : (
                      <>
                        <button
                          style={{ cursor: "pointer" }}
                          onClick={handleCropMohit}
                        >
                          <figure style={{ cursor: "pointer" }}>
                            <Crop htmlColor="white" />
                          </figure>
                        </button>
                        <button
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            setStickerOpen(true);
                          }}
                        >
                          <StickyNote2Sharp htmlColor="white" />
                        </button>
                        <label htmlFor="icon-button-files">
                          <div className="uploader">
                            <input
                              style={{ display: "none" }}
                              id="icon-button-files"
                              type="file"
                              multiple={false}
                              onChange={(e) => {
                                handleUploadImage(e);
                                e.target.value = "";
                              }}
                              accept=".png, .jpg, .jpeg"
                              // multiple
                            />
                            <figure
                              style={{
                                // height: "20px",
                                // width: "20px",
                                cursor: "pointer",
                              }}
                            >
                              <AddPhotoAlternateOutlined
                                sx={{ color: "white" }}
                              />
                            </figure>
                          </div>
                        </label>
                        <button
                          style={{ cursor: "pointer" }}
                          onClick={() => handleDelete()}
                        >
                          <figure style={{ cursor: "pointer" }}>
                            <Delete htmlColor="white" />
                          </figure>
                        </button>
                      </>
                    )}

                    {/* <button
                      style={{ cursor: "pointer" }}
                      onClick={() => handleDelete()}
                    >
                      <figure style={{ cursor: "pointer" }}>
                        <Delete htmlColor="white" />
                      </figure>
                    </button> */}
                  </div>
                )}
                {selectedButton == 3 && (
                  <>
                    <input
                      type="color"
                      className="colorPickMohit"
                      value={selectedColor}
                      onChange={(e) => {
                        handleColorChange(e.target.value);
                      }}
                    />
                    <button
                      //  disabled={!undoData?.length}
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        undo();
                      }}
                    >
                      <figure style={{ cursor: "pointer" }}>
                        <Undo htmlColor="white" />
                      </figure>
                    </button>
                    <button
                      //  disabled={!undoData?.length}
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        redo();
                      }}
                    >
                      <figure style={{ cursor: "pointer" }}>
                        <Redo htmlColor="white" />
                      </figure>
                    </button>
                    {/* <input
                  type="range"
                  min="1"
                  max="20"
                    value={`${penSize}`}
                    onChange={(value) => {
                      console.log(value.target.value);
                      if (value.target.value) {
                        setPenSize(Number(value.target.value));
                        if (canvas && canvas.freeDrawingBrush) {
                          canvas.freeDrawingBrush.width = value.target.value;
                        }
                      }
                    }}
                  /> */}
                    {/* <button>
                    <figure>
                    <img src="/static/images/t6.svg" alt="image" />
                    </figure>
                  </button> */}
                  </>
                )}
              </div>
            </div>

            <div className="but_gp">
              <div></div>
              <Button onClick={handleUpdatePPT}>
                {translate.global.update || "Update"}
              </Button>
            </div>
            <div className="intro_preview">
              {/* /* Here canvas will come */}
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginBlock: "16px",
                  alignItems: "center",
                }}
              >
                <canvas
                  ref={canvasRef}
                  id="canvas"
                  style={{ border: "1px solid #ccc" }}
                />
                {/* <input type="color" className="colorPickMohit" onChange={(e)=>{
                    handleColorChange(e.target.value);
              }}/> */}
              </div>
              {/* <div style={{    
              display: "flex",
              justifyContent: "space-between",
              marginInline: "250px"
              }}>
              <Button 
              // onClick={() => navigate("/presentation-down")}
              >
                Delete Slide
              </Button>
              <Button 
              // onClick={() => navigate("/presentation-down")}
              >
                Save Slide
              </Button>
            </div> */}
            </div>

            <span className="btnFlex">
              <Button
                className="arrowBtn"
                onClick={async () => {
                  if (selectedSlideIndex > 0) {
                    let idx = -1;
                    idx = binaryDataArray?.findIndex(
                      (ele: any, index: number) => index === selectedSlideIndex
                    );
                    if (idx > -1) {
                      let copySlides = [...binaryDataArray];
                      copySlides[idx] = JSON.stringify(canvas.toJSON());
                      console.log("copySlides[idx] :", copySlides[idx]);
                      setBinaryDataArray([...copySlides]);
                    }
                    setSlideList((prev: any) =>
                      prev.map((data: any, index: number) =>
                        index == selectedSlideIndex ? canvas?.toDataURL() : data
                      )
                    );
                    await canvas
                      .loadFromJSON(binaryDataArray[selectedSlideIndex - 1])
                      .then(() => {
                        canvas.forEachObject(function (obj: any) {
                          obj.cornerColor = "#8A5FF7";
                          obj.cornerStrokeColor = "#8A5FF7";
                          if (obj instanceof fabric.Text) {
                            // obj.lockMovementX = true;
                            // obj.lockMovementY = true;
                            obj.lockScalingX = true;
                            obj.lockScalingY = true;
                            obj.hasControls = false;
                          }
                        });
                        canvas.renderAll();
                      });

                    --selectedSlideIndex;
                  }
                }}
                size="small"
              >
                <ArrowBackIosNewIcon />
              </Button>
              <Button
                className="arrowBtn"
                onClick={async () => {
                  if (selectedSlideIndex < slideList?.length - 1) {
                    setSlideList((prev: any) =>
                      prev.map((data: any, index: number) =>
                        index == selectedSlideIndex ? canvas?.toDataURL() : data
                      )
                    );

                    let idx = -1;
                    idx = binaryDataArray?.findIndex(
                      (ele: any, index: number) => index === selectedSlideIndex
                    );
                    if (idx > -1) {
                      let copySlides = [...binaryDataArray];
                      copySlides[idx] = JSON.stringify(canvas.toJSON());
                      console.log("copySlides[idx] :", copySlides[idx]);
                      setBinaryDataArray([...copySlides]);
                    }

                    await canvas
                      .loadFromJSON(binaryDataArray[selectedSlideIndex + 1])
                      .then(() => {
                        canvas.forEachObject(function (obj: any) {
                          obj.cornerColor = "#8A5FF7";
                          obj.cornerStrokeColor = "#8A5FF7";
                          if (obj instanceof fabric.Text) {
                            // obj.lockMovementX = true;
                            // obj.lockMovementY = true;
                            obj.lockScalingX = true;
                            obj.lockScalingY = true;
                            obj.hasControls = false;
                          }
                        });
                        canvas.renderAll();
                      });

                    ++selectedSlideIndex;
                  }
                }}
                size="small"
              >
                <ArrowForwardIosIcon />
              </Button>
            </span>
            <div className="but_bottom">
              <div>
                <button
                  className={selectedButton == 1 ? "active" : ""}
                  onClick={() => {
                    if (togglable) {
                      if (selectedButton == 1) {
                        setSelectedButton(0);
                      } else {
                        setSelectedButton(1);
                      }
                    } else {
                      setSelectedButton(1);
                    }
                    if (drawingMode) {
                      handleStopDrawing();
                    }
                  }}
                >
                  <figure>
                    <img src="/static/images/t1.svg" alt="" />
                  </figure>
                </button>
                <button
                  className={selectedButton == 2 ? "active" : ""}
                  onClick={() => {
                    if (togglable) {
                      if (selectedButton == 2) {
                        setSelectedButton(0);
                      } else {
                        setSelectedButton(2);
                      }
                    } else {
                      setSelectedButton(2);
                    }
                    if (drawingMode) {
                      handleStopDrawing();
                    }
                  }}
                >
                  <figure>
                    <img src="/static/images/media.svg" alt="" />
                  </figure>
                </button>
                <button
                  className={selectedButton == 3 ? "active" : ""}
                  onClick={() => {
                    undoCount = 0;
                    redoCount = 0;
                    h = [];
                    if (togglable) {
                      if (selectedButton == 3) {
                        setSelectedButton(0);
                      } else {
                        setSelectedButton(3);
                      }
                    } else {
                      setSelectedButton(3);
                    }
                    handleStartDrawing();
                  }}
                >
                  <figure>
                    <img src="/static/images/t7.svg" alt="" />
                  </figure>
                </button>
                <button
                  className={selectedButton == 4 ? "active" : ""}
                  onClick={() => {
                    lastSelectedButton = selectedButton;
                    if (togglable) {
                      if (selectedButton == 4) {
                        setSelectedButton(0);
                      } else {
                        setSelectedButton(4);
                      }
                    } else {
                      setSelectedButton(4);
                    }
                    if (lastSelectedButton == 3) handleStartDrawing();
                    else if (drawingMode) handleStopDrawing();
                    setThemeDrawer(true);
                    // if(drawingMode){
                    //   handleStopDrawing();
                    // }
                  }}
                >
                  <figure>
                    <img src="/static/images/t3.svg" alt="" />
                  </figure>
                </button>
              </div>
            </div>
          </div>
          <Drawer
            anchor="right"
            open={drawerState}
            onClose={() => {
              setSelectedButton(1);
              handleStopDrawing();
              setDrawerState(false);
            }}
          >
            <div className="prev_ed_rt">
              <ul className="drawerUl dc">
                <figure
                  style={{
                    width: "100%",
                    textAlign: "center",
                    marginBottom: "10px",
                    cursor: "pointer",
                  }}
                >
                  {" "}
                  <Add
                    htmlColor="white"
                    onClick={() => {
                      selectedSlideIndex += 1;
                      const img = new Image();
                      img.src =
                        presentationTemplate[selectedTemplateID - 1 || 0].path;
                      img.onload = () => {
                        const image = new fabric.Image(img, {
                          width: CANVAS_WIDTH,
                          height: CANVAS_HEIGHT,
                          selectable: false, // make sure the background image is not selectable
                        });
                        const canvas = document.createElement("canvas");
                        const canvasInstance = new fabric.Canvas(canvas, {
                          width: CANVAS_WIDTH,
                          height: CANVAS_HEIGHT,
                          // backgroundColor: "#fff",
                          backgroundImage: image,
                        });

                        // canvasInstance.backgroundImage = image; // set the background image
                        canvasInstance.renderAll(); // render the canvas
                        // binaryData.push(
                        //   JSON.stringify(canvasInstance.toJSON())
                        // );
                        // };
                        setBinaryDataArray((prev: any) => [
                          JSON.stringify(canvasInstance.toJSON()),
                          ...prev,
                        ]);
                        setSlideList((prev: any) => [
                          canvasInstance.toDataURL(),
                          ...prev,
                        ]);
                      };
                    }}
                    style={{ background: "grey", borderRadius: "50%" }}
                  />
                </figure>
                {slideList?.map((data: any, index: number) => {
                  return (
                    <li
                      key={index}
                      id={selectedSlideIndex == index ? "selectedPage" : ""}
                      style={{ cursor: "pointer" }}
                    >
                      <figure
                        style={{ position: "relative" }}
                        onClick={() => {
                          // setCurrentSlideInfo(prev=>({...prev,currentPage:index+1}))
                          // setSelectedSlide(data)
                          if (selectedSlideIndex == index) {
                            setDrawerState(false);
                            console.log("SAEM INDEX");
                            setSlideList((prev: any) =>
                              prev.map((data: any, index: number) =>
                                index == selectedSlideIndex
                                  ? canvas?.toDataURL()
                                  : data
                              )
                            );

                            return;
                          }
                          canvas
                            .loadFromJSON(binaryDataArray[index])
                            .then(() => {
                              canvas.forEachObject(function (obj: any) {
                                obj.cornerColor = "#8A5FF7";
                                obj.cornerStrokeColor = "#8A5FF7";
                                if (obj instanceof fabric.Text) {
                                  // obj.lockMovementX = true;
                                  // obj.lockMovementY = true;
                                  obj.lockScalingX = true;
                                  obj.lockScalingY = true;
                                  obj.hasControls = false;
                                }
                              });
                              canvas.renderAll();
                              setDrawerState(false);
                            });

                          console.log(
                            "selectedSlideIndex :",
                            selectedSlideIndex
                          );
                          setSlideList((prev: any) =>
                            prev.map((data: any, index: number) =>
                              index == selectedSlideIndex
                                ? canvas?.toDataURL()
                                : data
                            )
                          );
                          // setBinaryDataArray((prev:any)=>prev.map((data:any,index:number)=>(index==selectedSlideIndex) ? JSON.stringify(canvas.toJSON()):data))
                          let idx = -1;
                          idx = binaryDataArray?.findIndex(
                            (ele: any, index: number) =>
                              index === selectedSlideIndex
                          );
                          if (idx > -1) {
                            let copySlides = [...binaryDataArray];
                            copySlides[idx] = JSON.stringify(canvas.toJSON());
                            console.log("copySlides[idx] :", copySlides[idx]);
                            setBinaryDataArray([...copySlides]);
                          }

                          setSelectedButton(1);
                          handleStopDrawing();
                          selectedSlideIndex = index;
                        }}
                      >
                        {/* <div style={{ position: "relative" }}> */}{" "}
                        {selectedSlideIndex == index ? null : (
                          <Delete
                            onClick={(e) => {
                              if (index <= selectedSlideIndex) {
                                selectedSlideIndex = selectedSlideIndex - 1;
                              }
                              setSlideList((prev: any) => [
                                ...prev.slice(0, index),
                                ...prev.slice(index + 1, slideList.length),
                              ]);
                              setBinaryDataArray((prev: any) => [
                                ...prev.slice(0, index),
                                ...prev.slice(index + 1, slideList.length),
                              ]);
                              e.stopPropagation();
                            }}
                            color="error"
                            style={{
                              background: "#b0a7a7",
                              position: "absolute",
                              borderRadius: "50%",
                              top: 5,
                              right: 5,
                              padding: "3px",
                            }}
                          />
                        )}
                        {/* </div> */}
                        <img
                          src={data ? data : "/static/images/AI1.jpg"}
                          style={{
                            border:
                              selectedSlideIndex == index
                                ? "5px solid #7548f1"
                                : "",
                          }}
                          alt="image"
                        />
                      </figure>
                      <figure
                        style={{
                          width: "100%",
                          textAlign: "center",
                          marginTop: "10px",
                        }}
                      >
                        {" "}
                        <Add
                          onClick={() => {
                            if (index < selectedSlideIndex) {
                              selectedSlideIndex = selectedSlideIndex + 1;
                            }
                            const img = new Image();
                            img.src =
                              presentationTemplate[
                                selectedTemplateID - 1 || 0
                              ].path;
                            img.onload = () => {
                              const image = new fabric.Image(img, {
                                width: CANVAS_WIDTH,
                                height: CANVAS_HEIGHT,
                                selectable: false, // make sure the background image is not selectable
                              });
                              const canvas = document.createElement("canvas");
                              const canvasInstance = new fabric.Canvas(canvas, {
                                width: CANVAS_WIDTH,
                                height: CANVAS_HEIGHT,
                                // backgroundColor: "#fff",
                                backgroundImage: image,
                              });

                              // canvasInstance.backgroundImage = image; // set the background image
                              canvasInstance.renderAll(); // render the canvas
                              // binaryData.push(
                              //   JSON.stringify(canvasInstance.toJSON())
                              // );
                              // };
                              setBinaryDataArray((prev: any) => [
                                ...prev.slice(0, index + 1),
                                JSON.stringify(canvasInstance.toJSON()),
                                ...prev.slice(
                                  index + 1,
                                  binaryDataArray.length
                                ),
                              ]);
                              setSlideList((prev: any) => [
                                ...prev.slice(0, index + 1),
                                canvasInstance.toDataURL(),
                                ...prev.slice(index + 1, slideList.length),
                              ]);
                            };
                          }}
                          style={{ background: "grey", borderRadius: "50%" }}
                        />
                      </figure>
                    </li>
                  );
                })}
              </ul>
            </div>
          </Drawer>
          <Drawer
            anchor="right"
            open={themeDrawer}
            onClose={() => {
              setThemeDrawer(false);
              setSelectedButton(lastSelectedButton);
            }}
          >
            <div className="prev_ed_rt">
              <ul className="drawerUl dd">
                {presentationTemplate?.map((data: any, index: number) => {
                  return (
                    <li
                      key={index}
                      id={selectedTemplateID == data.id ? "selectedTheme" : ""}
                      style={{ cursor: "pointer", color: "black" }}
                    >
                      {`${index + 1}) ${data?.name}`}
                      <figure
                        onClick={() => {
                          setThemeDrawer(false);
                          setSelectedButton(lastSelectedButton);
                          handleThemeChange(data?.path, data?.id);
                        }}
                      >
                        <img
                          style={{
                            border:
                              selectedTemplateID == data.id
                                ? "5px solid #7548f1"
                                : "",
                          }}
                          src={
                            data?.path ? data?.path : "/static/images/AI1.jpg"
                          }
                          alt="image"
                        />
                      </figure>
                    </li>
                  );
                })}
              </ul>
            </div>
          </Drawer>

          <Button
            className="side_btn"
            onClick={() => setDrawerState(true)}
            size="small"
          >
            <ArrowBackIosNewIcon />
          </Button>
        </section>
      </main>
      <StickerPicker
        open={stickerOpen}
        setOpen={setStickerOpen}
        loadImageAndAdd={loadImageAndAdd}
      />
    </>
    // <PptEditor/>
  );
};

export default PresentationEditor;
