import React, { useEffect, useState } from "react";
import Layout from "../../layout";
import {
  Button,
  Tooltip,
  TooltipProps,
  styled,
  tooltipClasses,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import "react-tabs/style/react-tabs.css";
import useTranslation from "../../hooks/Translation";

const HomeSection1 = () => {
  const [activeState, setActiveState] = useState(1);
  //1
  //2
  //3
  const translate = useTranslation();
  const navigate = useNavigate();
  return (
    <>
      <section className="bnr_sec">
        <div className="conta_iner">
          <div className="bnr_mn">
            <div className="bnr_lt">
              <h1>
                {translate.home.let}{" "}
                <span className="ai_bdr">
                  {translate.home.ai} <span></span>
                </span>
                <span className="d_block"> {translate.home.doYourWork}</span>
              </h1>
              <p>
                {translate.home.homeMessageLine1}
                <span className="d_block">
                  {translate.home.homeMessageLine2}
                </span>
                {translate.home.homeMessageLine3}
              </p>
              <Button
                type="button"
                className="btn btn_primary"
                onClick={() => navigate("/work")}
              >
                {translate.home.getStarted}
              </Button>
            </div>
            <div className="bnr_rt">
              <video
                autoPlay
                loop
                playsInline
                muted
                src="/static/images/banner_vdo.mp4"
              ></video>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default HomeSection1;
