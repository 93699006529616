import React from "react";
import Layout from "../../layout";
import Features from "../../features";

const Aboutpage = () => {
  return (
    <>
      <Features.AboutSection1 />
      <Features.AboutSection2 />
      <Features.AboutSection3 />
      <Features.AboutSection4 />
      <Features.AboutSection5 />
    </>
  );
};
export default Aboutpage;
