/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { covertFile } from "../../services/conversion";
import { showToast } from "../../constants/toast";
import { Close } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import useTranslation from "../../hooks/Translation";

let isCrossed = false;
function Convert() {
  const navigate = useNavigate();
  const translate = useTranslation();
  const { state } = useLocation();
  let isUploaded = false;
  const uploadDoc = async () => {
    isUploaded = true;
    const uploadDocument = await covertFile(
      state.selectedFile,
      state?.key,
      state?.url
    );
    if (!isCrossed) {
      if (uploadDocument.status == 200) {
        showToast(
          uploadDocument?.message ||
            translate.toastMessages.conversionSuccessful ||
            "Conversion successful"
        );
        navigate("/file-convert", {
          state: uploadDocument,
          replace: true,
        });
      } else {
        navigate("/file-type", {
          state: {
            selectedFile: state?.selectedFile,
            category: state?.category,
          },
          replace: true,
        });
      }
    } else {
      isCrossed = false;
    }
    console.log(uploadDocument);
  };
  useEffect(() => {
    if (state) {
      if (!isUploaded) uploadDoc();
    } else {
      navigate("/file-type", { replace: true });
    }
  }, []);
  return (
    <section className="bnr_sec cnvrt">
      <div className="conta_iner">
        <IconButton
          style={{ position: "absolute", right: "100px", color: "white" }}
          onClick={() => {
            isCrossed = true;
            navigate("/file-type");
          }}
        >
          <Close />
        </IconButton>
        <div className="bnr_mn">
          <div className="bnr_lt text_center">
            <video
              autoPlay
              loop
              playsInline
              muted
              src="/static/images/convert.mp4"
            ></video>
            <h1>
              {translate.home.let}
              <span className="ai_bdr">
                {translate.home.ai} <span></span>
              </span>
              <span className="d_block">{translate.home.doItMagic}</span>
            </h1>
          </div>
        </div>
      </div>
    </section>
  );
}
export default Convert;
