//@ts-ignore
import { compress, decompress } from "@witzbould/utils-string-compression";

export const decompressData = async (stringData: any) => {
  return await decompress(stringData, "deflate");
  return await decompress(atob(decodeURIComponent(stringData)), "gzip");
};
export const compressData = async (stringData: any) => {
  return await compress(stringData, "deflate");
  return encodeURIComponent(btoa(await compress(stringData, "gzip")));
};
export const GetTextData = async (link: string): Promise<string> => {
  console.log("link :", link);
  try {
    const res = await fetch(link, {
      method: "GET",
      headers: {
        Accept: "text/plain",
      },
    });

    if (!res.ok) {
      throw new Error(`Failed to retrieve text data: ${res.statusText}`);
    }

    const textData = await res.text();
    return textData;
  } catch (error) {
    console.error("Error retrieving text data:", error);
    throw error; // Rethrow the error to be caught by the caller
  }
};
export const insertAt=(index:number,value:any,arrayName:any[])=>
  {
    return [...arrayName?.slice(0,index),value,...arrayName?.slice(index,arrayName?.length)]
  }
  
 export const removeFromIndex=(index:number,arrayName:any)=>
  {
    return [...arrayName?.slice(0,index),...arrayName?.slice(index+1,arrayName?.length)]
  }