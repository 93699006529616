/* eslint-disable react/jsx-pascal-case */
import React, { useEffect } from "react";
import {
  Routes,
  Route,
  RouterProvider,
  Navigate,
  createBrowserRouter,
} from "react-router-dom";
import Pages from "./pages";
import { getFromStorage, setToStorage } from "./constants/storage";
import { STORAGE_KEYS } from "./constants/storageKeys";
import { setCredentials } from "./reducers/authSlice";
import { useAppDispatch } from "./hooks/store";
import MainContainer from "./layout/maincontainer";
import MainContainer2 from "./layout/maincontainer2";
import PageNotFound from "./features/pageNotFound";

const Routing = () => {
  const dispatch = useAppDispatch();

  const params = new URLSearchParams(window.location.search);
  const paramToken = params.get("token");

  useEffect(() => {
    if (paramToken) {
      setToStorage(STORAGE_KEYS.token, JSON.stringify(paramToken));
      const user = getFromStorage(STORAGE_KEYS.user) as any;
      dispatch(
        setCredentials({
          user: user ? JSON.parse(user) : null,
          token: paramToken ? paramToken : null,
        })
      );
    }
  }, []);
  const mordernRoutes = createBrowserRouter([
    {
      path: "/",
      children: [
        //header and footer only
        {
          element: <MainContainer2 />,
          children: [
            {
              index: true,
              element: <Pages.Homepage />,
            },

            {
              path: "about",
              element: <Pages.Aboutpage />,
            },
            {
              path: "privacy-policy",
              element: <Pages.PrivacyPolicy />,
            },
            {
              path: "terms-conditions",
              element: <Pages.TermsConditions />,
            },
            {
              path: "subscription",
              element: <Pages.Subscriptionpage />,
            },
            {
              path: "contact",
              element: <Pages.Contactpage />,
            },
            {
              path: "/subscription-plan",
              element: <Pages.SubscriptionPlanPage />,
            },
          ],
        },
        //end

        //with header and sidebars
        {
          element: <MainContainer />,
          children: [
            {
              path: "testing",
              element: <Pages.TestingPage />,
            },
            {
              path: "refernce",
              element: <Pages.SpecifyReference />,
            },
            {
              path: "reserch-tone",
              element: <Pages.ReserchTons />,
            },
            {
              path: "research-page",
              element: <Pages.ResearchPages />,
            },
            {
              path: "topic-research",
              element: <Pages.TopicResearch />,
            },
            {
              path: "/file-summarization",
              element: <Pages.FileSummarizationPage />,
            },
            {
              path: "/note-taking",
              element: <Pages.NoteTakingPage />,
            },
            {
              path: "/research",
              element: <Pages.Research />,
            },
            {
              path: "/assignment-solution",
              element: <Pages.AssignmentSolutions />,
            },
            {
              path: "assign-solution",
              element: <Pages.Assignsolution />,
            },
            {
              path: "/file-type",
              element: <Pages.FileType />,
            },
            {
              path: "file-convert",
              element: <Pages.FileCovert />,
            },
            {
              path: "creatpresentations",
              element: <Pages.Creatpresentations />,
            },
            {
              path: "presentations",
              element: <Pages.Presentations />,
            },
            {
              path: "presentation-preview",
              element: <Pages.PresentationPreview />,
            },
            {
              path: "artical",
              element: <Pages.Artical />,
            },
            {
              path: "createartical",
              element: <Pages.CreateArtical />,
            },

            {
              path: "language",
              element: <Pages.SelectLanguage />,
            },
            {
              path: "selecttons",
              element: <Pages.SelectTons />,
            },
            {
              path: "pronouns",
              element: <Pages.Pronouns />,
            },
            {
              path: "subscriptions",
              element: <Pages.Subscriptions />,
            },

            //might cause issue
            {
              path: "edit-note",
              children: [
                {
                  index: true,
                  element: <Pages.EditNote />,
                },
                {
                  path: ":id",
                  element: <Pages.EditNote />,
                },
              ],
            },

            {
              path: "setting",
              element: <Pages.Setting />,
            },
          ],
        },
        //end
        //below one are not using maincontainer
        {
          path: "edit-file",
          children: [
            {
              index: true,
              element: <Pages.EditFile />,
            },
            {
              path: ":id",
              element: <Pages.EditFile />,
            },
          ],
        },
        {
          path: "reserch-edit",
          children: [
            {
              index: true,
              element: <Pages.ResearchEdit />,
            },
            {
              path: ":id",
              element: <Pages.ResearchEdit />,
            },
          ],
        },
        {
          path: "reserch-edit2",
          children: [
            {
              index: true,
              element: <Pages.EditResearch2 />,
            },
            {
              path: ":id",
              element: <Pages.EditResearch2 />,
            },
          ],
        },

        {
          path: "edit-assign",
          children: [
            {
              index: true,
              element: <Pages.EditAssignment />,
            },
            {
              path: ":id",
              element: <Pages.EditAssignment />,
            },
          ],
        },
        {
          path: "edit-article",
          children: [
            {
              index: true,
              element: <Pages.EditArticle />,
            },
            {
              path: ":id",
              element: <Pages.EditArticle />,
            },
          ],
        },
        //end2

        {
          path: "faq",
          element: <Pages.Faqpage />,
        },
        {
          path: "work",
          element: <Pages.Workpage />,
        },
        {
          path: "login",
          element: <Pages.Login />,
        },
        {
          path: "signin",
          element: <Pages.SignIn />,
        },
        {
          path: "signup",
          element: <Pages.SignUp />,
        },
        {
          path: "profile",
          element: <Pages.Profile />,
        },
        {
          path: "forgot",
          element: <Pages.Forgot />,
        },
        {
          path: "verify",
          element: <Pages.Verify />,
        },
        {
          path: "reset",
          element: <Pages.Reset />,
        },
        {
          path: "paymentoption",
          element: <Pages.PaymentOption />,
        },
        {
          path: "congratulation",
          element: <Pages.Congratulation />,
        },
        {
          path: "/whizzosubscription",
          element: <Pages.WhizzoSubscription />,
        },

        {
          path: "/note-create",
          element: <Pages.NoteCreate />,
        },

        {
          path: "/quiz/",
          children: [
            {
              index: true,
              element: <Pages.Quiz />,
            },
            {
              path: ":id",
              element: <Pages.Quiz />,
            },
          ],
        },
        {
          path: "flashcard-quiz",
          children: [
            {
              index: true,
              element: <Pages.FlashCardQuiz />,
            },
            {
              path: ":id",
              element: <Pages.FlashCardQuiz />,
            },
          ],
        },

        {
          path: "editartical",
          element: <Pages.EditArtical />,
        },

        {
          path: "presentation-edit",
          children: [
            {
              //"MOHIT"
              index: true,
              element: <Navigate replace to={"/presentations"} />,
            },
            {
              path: ":id",
              element: <Pages.PresentationEdit />,
            },
          ],
        },
        {
          path: "presentation-edit2",
          children: [
            {
              index: true,
              element: <Navigate replace to={"/presentations"} />,
            },
            {
              path: ":id",
              element: <Pages.PresentationEditor />,
            },
          ],
        },
        {
          path: "presentation-down",
          element: <Pages.PresentationDown />,
        },

        {
          path: "convert",
          element: <Pages.Convert />,
        },
        {
          path: "test-ai",
          element: <Pages.TestAi />,
        },

        {
          path: "assign-video",
          element: <Pages.AssignmentVideo />,
        },
        {
          path: "artical-edit",
          element: <Pages.ArticalEdit />,
        },

        {
          path: "note-edit",
          element: <Pages.NoteEdit />,
        },
        {
          path: "note-create",
          element: <Pages.NoteCreate />,
        },
        {
          path: "note-taking-edit",
          children: [
            {
              index: true,
              element: <Pages.NoteTakingEditFirst />,
            },
            {
              path: ":id",
              element: <Pages.NoteTakingEditFirst />,
            },
          ],
        },
        {
          path: "pdfPreview",
          element: <Pages.PdfPreview />,
        },
        {
          path: "*",
          element: <PageNotFound />,
        },
      ],
    },
  ]);
  return <RouterProvider router={mordernRoutes} />;
  // return (
  //   <Routes>
  //     <Route path="/" element={<Pages.Homepage />} />
  //     <Route path="/about" element={<Pages.Aboutpage />} />
  //     <Route path="/privacy-policy" element={<Pages.PrivacyPolicy />} />
  //     <Route path="/terms-conditions" element={<Pages.TermsConditions />} />
  //     <Route path="/subscription" element={<Pages.Subscriptionpage />} />
  //     <Route path="/contact" element={<Pages.Contactpage />} />
  //     <Route path="/testing" element={<Pages.TestingPage />} />
  //     <Route path="/faq" element={<Pages.Faqpage />} />
  //     <Route path="/work" element={<Pages.Workpage />} />
  //     <Route path="/login" element={<Pages.Login />} />
  //     <Route path="/signin" element={<Pages.SignIn />} />
  //     <Route path="/signup" element={<Pages.SignUp />} />
  //     <Route path="/profile" element={<Pages.Profile />} />
  //     <Route path="/forgot" element={<Pages.Forgot />} />
  //     <Route path="/verify" element={<Pages.Verify />} />
  //     <Route path="/reset" element={<Pages.Reset />} />
  //     <Route path="/paymentoption" element={<Pages.PaymentOption />} />
  //     <Route path="/congratulation" element={<Pages.Congratulation />} />
  //     <Route
  //       path="/whizzosubscription"
  //       element={<Pages.WhizzoSubscription />}
  //     />
  //     <Route
  //       path="/subscription-plan"
  //       element={<Pages.SubscriptionPlanPage />}
  //     />
  //     <Route
  //       path="/file-summarization"
  //       element={<Pages.FileSummarizationPage />}
  //     />
  //     <Route path="/note-taking" element={<Pages.NoteTakingPage />} />
  //     <Route path="/note-create" element={<Pages.NoteCreate />} />
  //     <Route path="/research" element={<Pages.Research />} />
  //     <Route
  //       path="/assignment-solution"
  //       element={<Pages.AssignmentSolutions />}
  //     />
  //     <Route path="/file-type" element={<Pages.FileType />} />
  //     <Route path="/quiz/" element={<Pages.Quiz />} />
  //     <Route path="/quiz/:id" element={<Pages.Quiz />} />
  //     <Route path="/flashcard-quiz/" element={<Pages.FlashCardQuiz />} />`
  //     <Route path="/flashcard-quiz/:id" element={<Pages.FlashCardQuiz />} />
  //     ``
  //     <Route path="/presentations" element={<Pages.Presentations />} />
  //     <Route
  //       path="/creatpresentations"
  //       element={<Pages.Creatpresentations />}
  //     />
  //     <Route path="/artical" element={<Pages.Artical />} />
  //     <Route path="/createartical" element={<Pages.CreateArtical />} />
  //     <Route path="/language" element={<Pages.SelectLanguage />} />
  //     <Route path="/editartical" element={<Pages.EditArtical />} />
  //     <Route path="/selecttons" element={<Pages.SelectTons />} />
  //     <Route path="/pronouns" element={<Pages.Pronouns />} />
  //     <Route path="/subscriptions" element={<Pages.Subscriptions />} />
  //     <Route
  //       path="/presentation-preview"
  //       element={<Pages.PresentationPreview />}
  //     />
  //     <Route
  //       path="/presentation-edit/:id"
  //       element={<Pages.PresentationEdit />}
  //     />
  //     <Route path="/presentation-down" element={<Pages.PresentationDown />} />
  //     <Route path="/setting" element={<Pages.Setting />} />
  //     <Route path="/assign-solution" element={<Pages.Assignsolution />} />
  //     <Route path="/topic-research" element={<Pages.TopicResearch />} />
  //     <Route path="/research-page" element={<Pages.ResearchPages />} />
  //     <Route path="/convert" element={<Pages.Convert />} />
  //     <Route path="/test-ai" element={<Pages.TestAi />} />
  //     <Route path="/edit-assign/" element={<Pages.EditAssignment />} />
  //     <Route path="/edit-assign/:id" element={<Pages.EditAssignment />} />
  //     <Route path="/reserch-tone" element={<Pages.ReserchTons />} />
  //     <Route path="/refernce" element={<Pages.SpecifyReference />} />
  //     <Route path="/edit-note" element={<Pages.EditNote />} />
  //     <Route path="/edit-note/:id" element={<Pages.EditNote />} />
  //     <Route path="/edit-file" element={<Pages.EditFile />} />
  //     <Route path="/edit-file/:id" element={<Pages.EditFile />} />
  //     <Route path="/edit-article/:id" element={<Pages.EditArticle />} />
  //     <Route path="/edit-article" element={<Pages.EditArticle />} />
  //     <Route path="/file-convert" element={<Pages.FileCovert />} />
  //     <Route path="/assign-video" element={<Pages.AssignmentVideo />} />
  //     <Route path="/artical-edit" element={<Pages.ArticalEdit />} />
  //     <Route path="/reserch-edit/" element={<Pages.ResearchEdit />} />
  //     <Route path="/reserch-edit/:id" element={<Pages.ResearchEdit />} />
  //     <Route path="/reserch-edit2" element={<Pages.EditResearch2 />} />
  //     <Route path="/reserch-edit2/:id" element={<Pages.EditResearch2 />} />
  //     <Route path="/note-edit" element={<Pages.NoteEdit />} />
  //     <Route path="/note-create" element={<Pages.NoteCreate />} />
  //     <Route path="/note-taking-edit" element={<Pages.NoteTakingEditFirst />} />
  //     <Route
  //       path="/note-taking-edit/:id"
  //       element={<Pages.NoteTakingEditFirst />}
  //     />
  //     <Route path="/pdfPreview" element={<Pages.PdfPreview />} />
  //     <Route
  //       path="/presentation-edit2/:id"
  //       element={<Pages.PresentationEditor />}
  //     />
  //   </Routes>
  // );
};

export default Routing;
