import Layout from "../../layout";
import Features from "../../features";

import "react-tabs/style/react-tabs.css";

const Contactpage = () => {
  return (
    <>
      <Features.ContactSection1 />
      <Features.ContactSection2 />
    </>
  );
};
export default Contactpage;
