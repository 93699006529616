import React from "react";
import Layout from "../../layout";

import Features from "../../features";

function Subscriptionpage() {
  return (
    <>
      <Features.SubscriptionSection1 />

      <Features.SubscriptionSection2 />
      <Features.SubscriptionSection3 />
    </>
  );
}
export default Subscriptionpage;
