import React, { useEffect, useState } from "react";
import Layout from "../../layout";
import {
  Button,
  Tooltip,
  TooltipProps,
  styled,
  tooltipClasses,
} from "@mui/material";
import Features from "../../features";

const BootstrapTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
  },
}));
function Homepage() {
  return (
    <>
      <Features.HomeSection1 />
      <Features.HomeSection2 />
      <Features.HomeSection3 />
      <Features.HomeSection4 />
      <Features.HomeSection5 />
    </>
  );
}

export default Homepage;
