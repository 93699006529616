export const ar = {
  global: {
    searchStickers: "ملصقات البحث",
    hi: "أهلاً",
    name: "اسم",
    email: "بريد إلكتروني",
    message: "رسالة",
    submit: "يُقدِّم",
    faqs: "الأسئلة الشائعة",
    privacyPolicy: "سياسة الخصوصية",
    termsConditions: "الشروط والأحكام",
    whizzoUpperCase: "ويزو",
    login: "تسجيل الدخول",
    signup: "اشتراك",
    update: "تحديث",
    yes: "نعم",
    no: "لا",
    of: "ل",
    users: "المستخدمين",
    backToHome: "العودة إلى المنزل",
    downloadPDF: "تحميل قوات الدفاع الشعبي",
    downloadDoc: "تحميل الوثيقة",
    shareViaEmail: "شارك عبر البريد الإلكتروني",
    back: "خلف",
    download: "تحميل",
    changeLanguageDownload: "تغيير اللغة والتنزيل",
    changeLanguage: "تغيير اللغة",
    translate: "يترجم",
    downloadFileWithSpecifyingImportantVocabulary:
      "قم بتنزيل الملف مع تحديد المفردات المهمة",
    dragAndDropPdf: "قم بسحب وإسقاط ملف pdf هنا",
    dragAndDropImage: "قم بسحب وإسقاط صورة هنا",
    or: "أو",
    browseFile: "تصفح ملف",
    browsePDF: "تصفح ملف pdf",
    edit: "يحرر",
    delete: "يمسح",
    file: "ملف",
    seeAll: "رؤية الكل",
    notifications: "إشعارات",
    notificationDescription: "جميع الإخطارات الخاصة بك هنا ",
    showing: "عرض",
    items: "أغراض",
    rename: "إعادة تسمية",
    cancel: "يلغي",
    nameIsRequired: "الاسم مطلوب",
    noStickersFound: "لم يتم العثور على ملصقات",
    noRecordsFound: "لم يتم العثور على سجلات",
    regenerate: "تجديد",
    identifyingImportantVocabulary: "تحديد المفردات الهامة",
    aIExplanation: "شرح الذكاء الاصطناعي",
    english: "إنجليزي",
    arabic: "عربي",
    next: "التالي",
    getStarted: "ابدأ",
    enterDescription: "أدخل الوصف",
    verified: "تم التحقق منه",
    verify: "يؤكد",
    save: "يحفظ",
    select: "يختار",
    generate: "يولد",
    whizzoAiChatbot: "Whizzo AI Chatbot",
    noMessagesYet: " لا توجد رسائل حتى الآن.",
    done: "منتهي",
    highlightVocab: "تسليط الضوء على المفردات",
  },
  main: {
    editedDataWillLost: "هل أنت متأكد أنك تريد المغادرة؟ ",
  },
  home: {
    home: "بيت",
    let: "دعونا",
    ai: "منظمة العفو الدولية",
    doYourWork: "قم بعملك",
    doItMagic: "افعل هذا السحر",
    homeMessageLine1:
      "إنها حقيقة مثبتة منذ زمن طويل أن القارئ سوف يصرف انتباهه",
    homeMessageLine2: "من خلال المحتوى المقروء للصفحة عند النظر إليها",
    homeMessageLine3: "تَخطِيط. ",
    getStarted: "ابدأ",
    getPersonalizedWithWhizzo: "كن شخصيًا مع WHIZZO اليوم",
    getPersonalizedSubtext: " تواصل شخصيا مع Whizzo! ",
  },
  about: {
    aboutUs: "معلومات عنا",
    noInformationProvided: "لم يتم تقديم أي معلومات",
    seeMore: "رؤية المزيد",
    howItStarted: "كيف بدأت",
    howItStartedLine1:
      "إنها حقيقة مثبتة منذ زمن طويل أن المحتوى المقروء للصفحة سوف يشتت انتباه القارئ عند النظر إلى تخطيطها. ",
    howItStartedLine2:
      "إنها حقيقة مثبتة منذ زمن طويل أن المحتوى المقروء للصفحة سوف يشتت انتباه القارئ عند النظر إلى تخطيطها. ",
    services: "خدمات",
    weProvide: "نحن نقدم",
    servicesWeProvideLine:
      "إنها حقيقة مثبتة منذ زمن طويل أن المحتوى المقروء للصفحة سوف يشتت انتباه القارئ عند النظر إلى تخطيطها. ",
    ourMembers: "أعضائنا",
    join: "ينضم",
    millions: "الملايين",
    ofUsers: "من المستخدمين",
    joinMillionsUserLine:
      "إنها حقيقة مثبتة منذ زمن طويل أن المحتوى المقروء للصفحة سوف يشتت انتباه القارئ عند النظر إلى تخطيطها. ",
    singleReviewLine1: "رائع، أفضل تطبيق للذكاء الاصطناعي. ",
    singleReviewLine2: "رائع، أفضل تطبيق للذكاء الاصطناعي. ",
    singleReviewName: "آدم سميث",
    singleReviewDetailLine1: "لوريم إيبسوم",
    singleReviewDetailLine2: "لوريم",
  },
  contact: {
    contactUs: "اتصل بنا",
    contactUsHomepageLine1: "إنها حقيقة مثبتة منذ زمن طويل أن أ",
    contactUsHomepageLine2: "سوف يصرف انتباه القارئ",
    contactUsHomepageLine3: "محتوى قابل للقراءة",
    contactUsPageLine1:
      "إنها حقيقة مثبتة منذ زمن طويل أن المحتوى المقروء سيلهي القارئ",
    contactUsPageLine2: " للصفحة عند النظر إلى تخطيطها. ",
    feelFreeToContactUs: "لا تتردد في الاتصال بنا في أي وقت. ",
    whatWillBeNextStep: "ماذا ستكون الخطوة التالية؟",
    whatWillBeNextStepHeading:
      "إنها حقيقة مثبتة منذ زمن طويل أن المحتوى المقروء للصفحة سوف يشتت انتباه القارئ عند النظر إلى تخطيطها.",
    step1: "لوريم إيبسوم",
    step1Description:
      "إنها حقيقة مثبتة منذ زمن طويل أن القارئ سوف يصرف انتباهه",
    step2: "لوريم إيبسوم",
    step2Description:
      "إنها حقيقة مثبتة منذ زمن طويل أن القارئ سوف يصرف انتباهه",
    step3: "لوريم إيبسوم",
    step3Description:
      "إنها حقيقة مثبتة منذ زمن طويل أن القارئ سوف يصرف انتباهه",
  },
  auth: {
    password: "كلمة المرور",
    emailId: "معرف البريد الإلكتروني",
    enterYourEmail: "أدخل بريدك الإلكتروني",
    forgotPassword: "هل نسيت كلمة السر",
    changePassword: "تغيير كلمة المرور",
    oldPassword: "كلمة المرور القديمة",
    newPassword: "كلمة المرور الجديدة",
    passwordChanged: "تم تغيير كلمة المرور بنجاح",
    currentPassword: "كلمة المرور الحالية",
    enterCurrentPassword: "أدخل كلمة المرور الحالية",
    enterNewPassword: "أدخل كلمة المرور الجديدة",
    enterPassword: "أدخل كلمة المرور",
    reEnterPassword: "إعادة إدخال كلمة المرور",
    confirmNewPassword: "تأكيد كلمة المرور الجديدة",
    confirmPassword: "تأكيد كلمة المرور",
    logoutConfirmation: "هل أنت متأكد أنك تريد تسجيل الخروج؟",
    deleteAccountConfirmation: "هل أنت متأكد أنك تريد حذف حسابك؟",
    enterValidOTP: "الرجاء إدخال كلمة مرور صالحة",
    emailVerified: "تم التحقق من البريد الإلكتروني",
    otpVerification: "التحقق من OTP",
    codeSentToEmail:
      "لقد أرسلنا لك رسالة نصية قصيرة تحتوي على رمز إلى البريد الإلكتروني",
    codeNotReceived: "لم تحصل على الرمز؟",
    resend: "إعادة الإرسال",
    forgetPasswordDetailList1:
      "إنها حقيقة مثبتة منذ زمن طويل أن القارئ سوف يصرف انتباهه",
    forgetPasswordDetailList2: "من خلال المحتوى المقروء للصفحة عند النظر إليها",
    forgetPasswordDetailList3: "تَخطِيط. ",
    joinMillions: "انضم إلى الملايين",
    loginDetailLine1:
      "إنها حقيقة مثبتة منذ زمن طويل أن القارئ سوف يصرف انتباهه",
    loginDetailLine2: "من خلال المحتوى المقروء للصفحة عند النظر إليها",
    loginDetailLine3: "    تَخطِيط. ",
    signIn: "تسجيل الدخول",
    signUp: "اشتراك",
    profileSetupDetailLine1:
      "إنها حقيقة مثبتة منذ زمن طويل أن القارئ سوف يصرف انتباهه",
    profileSetupDetailLine2: "من خلال المحتوى المقروء للصفحة عند النظر إليها",
    profileSetupDetailLine3: "تَخطِيط. ",
    profileSetup: "إعداد الملف الشخصي",
    firstName: "الاسم الأول",
    lastName: "اسم العائلة",
    enterFirstName: "أدخل الاسم الأول",
    enterLastName: "أدخل الاسم الأخير",
    phoneNumber: "رقم التليفون",
    purpose: "غاية",
    resetPassword: "إعادة تعيين كلمة المرور",
    enterEmailAddress: "أدخل عنوان البريد الإلكتروني",
    rememberMe: "تذكرنى",
    DoNotHaveAnAccount: "ليس لديك حساب؟",
    emailMustLessThan80Characters:
      "يجب أن يكون البريد الإلكتروني أقل من 80 حرفا",
    iAcceptAll: "أنا أقبل كل شيء",
    termsConditions: "الشروط والأحكام.",
    alreadyHaveAccount: "هل لديك حساب بالفعل؟",
    profileVerified: "تم التحقق من الملف الشخصي",
    invalidOtp: "كلمة مرور غير صالحة",
    otpResentSuccessfully: "تمت إعادة إرسال Otp بنجاح",
    verifyAccount: "التحقق من الحساب",
    sentSMSCodeEmail:
      "لقد أرسلنا لك رسالة نصية قصيرة تحتوي على رمز إلى البريد الإلكتروني",
  },
  testing: {
    test: "امتحان",
    testing: "اختبار",
    selectTypeOfTest: "حدد نوع الاختبار الذي تريد إجراؤه",
    abilities: {
      selectTypeOfAbility: "حدد نوع القدرات التي تريد الحصول عليها",
    },
    achievements: {
      selectTypeOfAchievement: "حدد نوع الإنجازات التي تريد تحقيقها",
    },
    questionsWithOptions: "أسئلة مع الخيارات",
    flashcards: "البطاقات التعليمية",
    deleteWarning: "هل أنت متأكد أنك تريد الحذف",
    testCompleted: "اكتمل الاختبار",
    selectSubject: "الرجاء تحديد الموضوع",
    repeatingQuestions: "تكرار الأسئلة",
    testResult: "نتيجة الاختبار",
    overallScore: "النتيجة الإجمالية",
    correct: "صحيح",
    incorrect: "غير صحيح",
    remaining: "متبقي",
    reAppear: "إعادة الظهور",
    reAppearMessage: "قم بهذا الاختبار مرة أخرى",
    continue: "يكمل",
    continueMessage: "مواصلة الاختبار",
    finish: "ينهي",
    finishMessage: "قم بإنهاء الاختبار",
    downloadWithoutAnswers: "تحميل الملف بدون إجابات",
    downloadWithAnswers: "تحميل الملف مع الإجابات",
    checkTestForYou: "تحقق من الاختبار بالنسبة لك",
    bySelf: "بالنفس",
    myTests: "اختباراتي",
    Abilities: "القدرات",
    Achievements: "الإنجازات",
    createATestForYourself: "قم بإنشاء اختبار لنفسك",
    TestQuestionsGeneratedSuccessfully: "تم إنشاء أسئلة الاختبار بنجاح",
    flashcardsGeneratedSuccessfully: "تم إنشاء البطاقات التعليمية بنجاح",
    pleaseWaitQuestionsBeingLoaded: "الرجاء الانتظار بينما يتم تحميل الأسئلة",
    Skip: "يتخطى",
    theAnswerIs: "الجواب هو",
    right: "يمين",
    wrong: "خطأ",
    ques: "أسئلة",
    ans: "الجواب",
    guessAnswerClickCard: "خمن الإجابة ثم اضغط على البطاقة",
    wasAnswerCorrect: "هل كانت إجابتك صحيحة؟",
    typeMessage: "اكتب رسالة",
  },
  fileSummarization: {
    fileSummarizationLowerCase: "تلخيص الملف",
    fileSummarization: "تلخيص الملف",
    fileDeletedSuccessfully: "تم حذف الملف بنجاح",
    createNewFile: "إنشاء ملف جديد",
    summarization: "تلخيص",
  },
  noteTaking: {
    notes: "ملحوظات",
    note: "",
    noteTaking: "تدوين الملاحظات",
    notetaking: "تدوين الملاحظات",
    selectNoteTakingType: "حدد نوع الملاحظة التي تريد تدوينها",
    createNewNote: "إنشاء ملاحظة جديدة",
    writeAboutMe: "اكتب عني",
    takeNotesForYou: "تدوين الملاحظات بالنسبة لك",
    editNotes: "تحرير الملاحظات",
    myPastNotes: "ملاحظاتي الماضية",
    allNotes: "جميع الملاحظات",
    recentNotes: "الملاحظات الأخيرة",
    favorite: "مفضل",
    noRecentNotes: "لا توجد ملاحظات حديثة",
    addSomethingToGetDetail: "الرجاء إضافة شيء للحصول على التفاصيل",
    selectSomethingToGetDetail: "الرجاء تحديد شيء للحصول على التفاصيل",
    speakSomethingBefore: "من فضلك تحدث بشيء قبل ذلك",
    pleaseWriteSomeComment: "يرجى كتابة بعض التعليق",
    noteUpdatedSuccessfully: "تم تحديث الملاحظة بنجاح",
    noteAddedSuccessfully: "تمت إضافة الملاحظة بنجاح",
    updateNote: "تحديث الملاحظة",
    addNote: "أضف ملاحظة",
    speakSomething: "تحدث بشيء",
    comment: "تعليق",
    comments: "تعليقات",
    noComments: "لا تعليقات",
    noteDownloadedSuccessfully: "تم تنزيل الملاحظة بنجاح",
    textCopiedSuccessfully: "تم نسخ النص بنجاح",
    textPastedSuccessfully: "تم لصق النص بنجاح",
    clipboardIsEmpty: "الحافظة فارغة",
    chooseTemplate: "اختر القالب",
  },
  research: {
    researchLowerCase: "بحث",
    research: "بحث",
    myResearches: "أبحاثي",
    startWithReferencesHeader: "ابدأ بحثك بالمراجع؟",
    startWithReferences: "ابدأ بالمراجع",
    startWithoutReferences: "ابدأ بدون مراجع",
    topicResearch: "موضوع البحث",
    createNewResearch: "إنشاء بحث جديد",
    reduceCitations: "تقليل الاقتباسات",
    specifyReferences: "تحديد المراجع",
    short: "قصير",
    medium: "واسطة",
    long: "طويل",
    veryLong: "طويل جدًا",
    approx: "تقريبا.",
    pages: "الصفحات",
    words: "كلمات",
    symbols: "الرموز",
    researchPaper: "ورقة بحثية",
    addPoint: "أضف نقطة",
    addSection: "إضافة قسم",
  },
  assignmentSolution: {
    assignmentsolutions: "حلول الواجبات",
    assignmentSolution: "حلول الواجبات",
    getNewSolution: "احصل على حل جديد",
    myPastSolutions: "حلولي السابقة",
    uploadQuestionsToGetSolutions:
      "قم بتحميل الأسئلة للحصول على حلول من خلال الذكاء الاصطناعي",
    selectLanguage: "اختر اللغة",
    GiveYouSolution: "أعطيك الحلول",
    requestRevision: "طلب المراجعة",
  },
  fileTypeConversion: {
    fileTypeConversion: "تحويل نوع الملف",
    uploadSelectTypeConversion: "قم بتحميل واختيار نوع التحويل",
    myPastFiles: "ملفاتي الماضية",
    dragAndDropFile: "قم بسحب وإسقاط الملف الخاص بك هنا",
    multipleImages: "صور متعددة",
    pleaseSelectTypeConversion: "الرجاء تحديد نوع التحويل",
    addFromCloud: "أضف ملفك من السحابة",
    uploadFromCloud: "تحميل من السحابة",
    selectTypeOfConversion: "اختر نوع التحويل",
    noFileSelected: "لم يتم تحديد أي ملف",
    convert: "يتحول",
    convertedFileReady: "ملفك المحول جاهز",
    totalFiles: "إجمالي الملفات",
    fileName: "اسم الملف",
  },
  presentation: {
    presentation: "عرض تقديمي",
    presentations: "العروض التقديمية",
    enterPresentationPrompt: "أدخل مطالبة الذكاء الاصطناعي لإنشاء عرض تقديمي",
    enterPresentationTopic: "أدخل موضوع العرض التقديمي",
    createNewPresentation: "إنشاء عرض تقديمي جديد",
    myPresentations: "عروضي التقديمية",
    selectTemplates: "حدد القوالب",
    pptDownloadedSuccessfully: "تم تنزيل ملف PPT بنجاح",
    pptDoNotHaveAnySlides: "لا تحتوي PPT على أية شرائح",
    selectSomethingToDelete: "حدد شيئًا لحذفه",
    selectImageToCrop: "حدد الصورة لاقتصاصها",
    themeChangedSuccessfully: "تم تغيير الموضوع بنجاح",
    presentationUpdatedSuccessfully: "تم تحديث العرض التقديمي بنجاح",
    itemCopiedSuccessfully: "تم نسخ العنصر بنجاح",
    pleaseSelectAnythingFirstToCopy: "الرجاء تحديد أي شيء أولاً لنسخه",
    itemPastedSuccessful: "تم لصق العنصر بنجاح",
  },
  article: {
    articles: "مقالات",
    Article: "شرط",
    myArticles: "مقالاتي",
    createNewArticle: "إنشاء مقالة جديدة",
    addPicturesWithAi: "إضافة الصور باستخدام الذكاء الاصطناعي",
    topicOfArticle: "موضوع المقال",
    enterArticleTopic: "أدخل موضوع المقال",
    selectToneVoice: "حدد نغمة الصوت",
    specifyLanguageRegionPages: "حدد اللغة والمنطقة وعدد الصفحات",
    selectLanguage: "اختر اللغة",
    selectRegion: "حدد المنطقة",
    pleaseSelectRegion: "الرجاء تحديد المنطقة",
    pointOfView: "وجهة نظر (الضمائر)",
    doYouWantToPutPictures: "هل تريد وضع الصور؟",
  },
  subscriptions: {
    subscriptions: "الاشتراكات",
    subscriptionPlan: "خطة الاشتراك",
    subscriptionPlans: "خطط الاشتراك",
    subscriptionPlansDescription:
      "إنها حقيقة مثبتة منذ زمن طويل أن المحتوى المقروء للصفحة سوف يشتت انتباه القارئ عند النظر إلى تخطيطها. ",
    subscriptionPageLine1:
      " إنها حقيقة مثبتة منذ زمن طويل أن المحتوى المقروء للصفحة سوف يشتت انتباه القارئ عند النظر إلى تخطيطها. ",
    subscriptionHomepageLine1:
      " إنها حقيقة مثبتة منذ زمن طويل أن المحتوى المقروء سيلهي القارئ",
    subscriptionHomepageLine2: " للصفحة عند النظر إلى تخطيطها. ",
    monthly: "شهريا",
    monthlyLine1: "إنها حقيقة ثابتة منذ زمن طويل ",
    monthlyLine2: "إنها حقيقة مثبتة منذ زمن طويل أن أ",
    monthlyLine3: "إنها حقيقة مثبتة منذ زمن طويل أن أ",
    monthlyLine4: "إنها حقيقة مثبتة منذ زمن طويل أن أ",
    quarterly: "ربع سنوية",
    quarterlyLine1: "إنها حقيقة ثابتة منذ زمن طويل ",
    quarterlyLine2: "إنها حقيقة مثبتة منذ زمن طويل أن أ",
    quarterlyLine3: "إنها حقيقة مثبتة منذ زمن طويل أن أ",
    quarterlyLine4: "إنها حقيقة مثبتة منذ زمن طويل أن أ",
    yearly: "سنوي",
    yearlyLine1: "إنها حقيقة ثابتة منذ زمن طويل ",
    yearlyLine2: "إنها حقيقة مثبتة منذ زمن طويل أن أ",
    yearlyLine3: "إنها حقيقة مثبتة منذ زمن طويل أن أ",
    yearlyLine4: "إنها حقيقة مثبتة منذ زمن طويل أن أ",
    subscribe: "يشترك",
    subscribeUpperCase: "يشترك",
    chooseAPlan: "اختر خطة",
    chooseAPlanDescription:
      "إنها حقيقة مثبتة منذ زمن طويل أن المحتوى المقروء للصفحة سوف يشتت انتباه القارئ عند النظر إلى تخطيطها. ",
    featureComparison: "مقارنة الميزات",
    basis: "أساس",
    learnerExperience: "تجربة المتعلم",
    usersOn: "المستخدمين على",
    paymentDetails: "تفاصيل الدفع",
    totalAmount: "المبلغ الإجمالي",
    tax: "ضريبة",
    grandTotal: "المجموع الإجمالي",
    payNow: "ادفع الآن",
    congratulations: "تهانينا",
    paymentCompleted: "تم إتمام عملية الدفع بنجاح",
    paymentOptions: "خيارات الدفع",
    addNewCard: "إضافة بطاقة جديدة",
    saveAndPayViaCard: "الحفظ والدفع عبر البطاقات.",
    whizzoSubscription: "اشتراك ويزو",
    freeTrial: "تجربة مجانية",
    monthlySubscription: "الاشتراك الشهري",
    quarterlySubscription: "اشتراك ربع سنوي",
    yearlySubscription: "الاشتراك السنوي",
    tryForFree: "جرب مجانًا",
  },
  settings: {
    settings: "إعدادات",
    verifyYourEmail: "يرجى التحقق من البريد الإلكتروني الخاص بك",
    profileUpdatedSuccessfully: "تم تحديث الملف الشخصي بنجاح",
    loginWithNewEmail: "الرجاء تسجيل الدخول بالبريد الإلكتروني الجديد",
    termsConditions2: "الشروط والأحكام",
    FAQs: "الأسئلة الشائعة",
    myProfile: "ملفي الشخصي",
    logOut: "تسجيل الخروج",
    noFaqFound: "لم يتم العثور على الأسئلة الشائعة",
    deleteAccount: "حذف الحساب",
    clickToWhatsapp: "اضغط هنا للانتقال إلى الواتس اب",
    clickToEmail: "انقر هنا للذهاب إلى البريد الإلكتروني",
    noTermsConditionsFound: "لم يتم العثور على الشروط والأحكام",
  },
  header: {},
  footer: {
    footerDescription:
      "المغزى من استخدام لوريم إيبسوم هو أنه يحتوي على توزيع طبيعي للأحرف إلى حد ما",
    quickLinks: "روابط سريعة",
    rightsReserved: "© 2024 WHIZZO، جميع الحقوق محفوظة.",
    facebook: "فيسبوك",
    instagram: "انستغرام",
    youtube: "يوتيوب",
    linkedIn: "ينكدين",
  },
  validations: {
    requiredEmail: "البريد الإلكتروني مطلوب",
    emailLessThan80: "يجب أن يكون البريد الإلكتروني 80 حرفًا أو أقل",
    enterValidEmail: "الرجاء إدخال بريد إلكتروني صالح",
    requiredName: "الاسم مطلوب",
    nameLessThan50: "يجب أن يكون الاسم 50 حرفًا أو أقل",
    requiredMessage: "الرسالة مطلوبة",
    messageLessThan200: "يجب أن تكون الرسالة 200 حرف أو أقل",
    requiredOldPassword: "كلمة المرور القديمة مطلوبة.",
    passwordAtleast5CharacterLong:
      "يجب أن تتكون كلمة المرور من 5 أحرف على الأقل",
    requireNewPassword: "كلمة المرور الجديدة مطلوبة.",
    requirePassword: "كلمة المرور مطلوبة.",
    newOldPasswordSameNotAllowed:
      "كلمة المرور الجديدة لا يمكن أن تكون نفس كلمة المرور القديمة.",
    passwordMustMatch: "يجب أن تتطابق كلمات المرور.",
    requiredConfirmPassword: "تأكيد كلمة المرور مطلوب.",
    minimumTwoCharacterRequired: "مطلوب حرفين على الأقل",
    maximum100CharactersRequired: "الحد الأقصى المطلوب هو 100 حرف",
    maximum50CharactersRequired: "الحد الأقصى المطلوب هو 50 حرفًا",
    nameContainOnlyAlphabets: "يجب أن يحتوي الاسم على حروف أبجدية فقط",
    requiredPhone: "رقم الهاتف مطلوب",
    phoneAtLeastMoreThan6Characters: "يجب أن لا يقل رقم الهاتف عن 6 أحرف",
    phoneMustLessThan16Characters: "يجب أن يكون رقم الهاتف أقل من 16 حرفًا",
  },
  errors: {
    onlyPDFFormat: "يرجى تحميل الملف بصيغة PDF فقط",
    somethingWentWrong: "حدث خطأ ما",
    selectValidFormat: "الرجاء تحديد تنسيق صالح.",
    onlyMultipleImages: "يمكنك فقط تحديد صور متعددة",
    userNotExist: "المستخدم غير موجود",
    onlyJpgPngAllowed: "تنسيق الملف غير صالح: يُسمح فقط بالصور png وjpg",
    imageUploadError: "حدث خطأ أثناء تحميل الصورة. ",
    selectPurpose: "الرجاء تحديد غرض",
    invalidCredentials: "بيانات اعتماد غير صالحة",
    acceptTermsConditions: "يرجى قبول الشروط والأحكام",
    selectPlan: "الرجاء تحديد خطة",
    topicNameNotExceed500Characters: "لا يمكن أن يتجاوز اسم الموضوع 500 حرف",
    enterArticleTopics: "الرجاء إدخال موضوع المقال",
    presentationTopicNameCantExceed500Characters:
      "لا يمكن أن يتجاوز اسم موضوع العرض التقديمي 500 حرف",
    enterTopicPresentation: "الرجاء إدخال موضوع العرض",
    enterTopicResearch: "الرجاء إدخال موضوع البحث",
    descriptionCanNotExceed500Characters: "لا يمكن أن يتجاوز الوصف 500 حرف",
    pleaseTryAgain: "يرجى المحاولة مرة أخرى",
    pleaseLogin: "الرجاء تسجيل الدخول",
    regenerationUnsuccessful: "عملية التجديد غير ناجحة، يرجى المحاولة مرة أخرى",
    GenerationUnsuccessful: "عملية الإنشاء غير ناجحة، يرجى المحاولة مرة أخرى",
    selectAtleastPage: "حدد صفحة واحدة على الأقل",
    corruptedPdfFile: "ملف PDF تالف",
    selectAtleastOneNote: "حدد ملاحظة واحدة على الأقل",
  },
  toastMessages: {
    generationSuccessfully: "الجيل بنجاح",
    ProfilePictureUploadedSuccessfully: "تم تحميل الصورة الشخصية بنجاح",
    logoutSuccessful: "تم تسجيل الخروج بنجاح",
    accountDeletedSuccessfully: "تم حذف الحساب بنجاح",
    articleDeletedSuccessfully: "تم حذف المقالة بنجاح",
    assignmentDeletedSuccessfully: "تم حذف المهمة بنجاح",
    pdfDownloadedSuccessfully: "تم تنزيل ملف PDF بنجاح",
    articleNameUpdatedSuccessfully: "تم تحديث اسم المقالة بنجاح",
    assignmentNameUpdatedSuccessfully: "تم تحديث اسم المهمة بنجاح",
    testNameUpdatedSuccessfully: "تم تحديث اسم الاختبار بنجاح",
    profileSetupSuccessfully: "تم إعداد الملف الشخصي بنجاح",
    passwordResetSuccessfully: "تم إعادة تعيين كلمة المرور بنجاح",
    LoginSuccessfully: "تم تسجيل الدخول بنجاح",
    conversionSuccessful: "تم التحويل بنجاح",
    testDeletedSuccessfully: "تم حذف الاختبار بنجاح",
    pdfSharedOverEmail: "قوات الدفاع الشعبي المشتركة عبر البريد الإلكتروني",
    regenerationSuccessful: "التجديد ناجح",
    researchDeletedSuccessfully: "تم حذف البحث بنجاح",
    researchNameUpdatedSuccessfully: "تم تحديث اسم البحث بنجاح",
    recordsDuplicatedSuccessfully: "تم تكرار السجلات بنجاح",
    recordsUpdatedSuccessfully: "تم تحديث السجلات بنجاح",
    recordsDeletedSuccessfully: "تم حذف السجلات بنجاح",
    fileNameUpdatedSuccessfully: "تم تحديث اسم الملف بنجاح",
    presentationNameUpdatedSuccessfully: "",
    downloadedSuccessfully: "تم التنزيل بنجاح",
    noteNameUpdatedSuccessfully: "",
    presentationDeletedSuccessfully: "",
  },
};
