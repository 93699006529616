import React, { useEffect, useState } from "react";
import Layout from "../../layout";
import "react-tabs/style/react-tabs.css";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { Button } from "@mui/material";
import { Accordion, AccordionItem } from "@szhsin/react-accordion";
import TextField from "@mui/material/TextField";
import Logout from "../../components/logout";
import Notification from "../../components/notification";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import { useLocation, useNavigate } from "react-router-dom";
import Header from "../../layout/header/Header";
import {
  useChangePasswordMutation,
  useLazyGetProfileQuery,
  useLazyGetPurposeQuery,
  usePostProfileSetupMutation,
  usePostSendOtpToNewMailMutation,
} from "../../services/auth";
import { showError, showToast, showWarning } from "../../constants/toast";
import { UploadMedia } from "../../utils/mediaUpload";
import { useFormik } from "formik";
import useAuth from "../../hooks/useAuth";
import * as Yup from "yup";
import { generateEncryptedKeyBody } from "../../utils/crypto";
import { CommonBody } from "../../types/General";
import { getFromStorage, removeFromStorage } from "../../constants/storage";
import { STORAGE_KEYS } from "../../constants/storageKeys";
import { useDispatch } from "react-redux";
import { getToken, resetAuth, setCredentials } from "../../reducers/authSlice";
import ChangePassword from "../../components/changePassword";
import {
  useLazyGetContactQuery,
  useLazyGetFaqQuery,
  useLazyGetTermsQuery,
} from "../../services/cms";
import VerifyEmail from "../../components/verifyEmail";
import Loader from "../../constants/Loader";
import { useAppDispatch, useAppSelector } from "../../hooks/store";
import "react-phone-input-2/lib/bootstrap.css";
import PhoneInput from "react-phone-input-2";
import useTranslation from "../../hooks/Translation";

function Setting() {
  const translate = useTranslation();
  const [open, setOpen] = React.useState<any>(false);
  const [open2, setOpen2] = React.useState<any>(false);
  const [tabIndex, setTabIndex] = useState(0);
  const [userData, setUserData] = useState<any>();
  const setToken = useAppSelector(getToken);
  const navigate = useNavigate();
  const [phoneCode, setPhoneCode] = useState("+1");
  const [imageId, setImageId] = useState<string>("");
  const [image, setImage] = useState<string>("");
  const [purposeData, setPurposeData] = useState<any>();
  const [selectedPurpose, setSelectedPurpose] = useState<any>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [getPurpose] = useLazyGetPurposeQuery();
  const [updateProfile] = usePostProfileSetupMutation();
  const [getTerms] = useLazyGetTermsQuery();
  const [termsData, setTermsData] = useState<any>();
  const [getfaq] = useLazyGetFaqQuery();
  const [faqData, setFaqData] = useState<any>();
  const [openPopup, setOpenPopup] = useState<boolean>(false);
  const [verified, setVerified] = useState<boolean>();
  const [sendOtp] = usePostSendOtpToNewMailMutation();
  const [contactUSApi] = useLazyGetContactQuery();
  const [contactNumber, setContactNumber] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const dispatch = useAppDispatch();
  const [getProfile] = useLazyGetProfileQuery();
  const token = getFromStorage(STORAGE_KEYS.token) as any;
  const [userDetail, setDetail] = useState({
    first_name: "",
    last_name: "",
  });
  console.log(userData, "userData?.email_verification");

  useEffect(() => {
    setVerified(userData?.email_verification);
    setDetail({
      first_name: userData?.first_name,
      last_name: userData?.last_name,
    });
  }, [userData]);

  const getOtp = async () => {
    setIsLoading(true);
    const body = {
      email: formik.values.email,
    };
    try {
      let encryptedBody = generateEncryptedKeyBody(body) as CommonBody;
      const res = await sendOtp(encryptedBody).unwrap();
      setIsLoading(false);
    } catch (error: any) {
      setIsLoading(false);
      showError(error?.data?.message || error?.message);
    }
  };

  const fetchData = async () => {
    try {
      const res = await getProfile({}).unwrap();
      if (res?.status === 200) {
        setUserData(res?.data);
        dispatch(
          setCredentials({
            user: res?.data || null,
            token: token ? JSON.parse(token) : null,
          })
        );
      }
    } catch (error: any) {
      showError(error?.data?.message || error?.message);
    }
  };

  const handleChangePhone = (phone: any, country: any) => {
    formik.setFieldValue("phone_no", phone?.replace(country.dialCode, ""));
    setPhoneCode(country?.dialCode);
  };

  const fetchContact = async () => {
    setIsLoading(true);
    try {
      const res = await contactUSApi({}).unwrap();
      if (res?.status === 200) {
        setContactNumber(
          res?.data?.contact_support?.country_code +
            "" +
            res?.data?.contact_support?.phone_no
        );
        setEmail(res?.data?.contact_support?.email);
      }
      setIsLoading(false);
    } catch (error: any) {
      setIsLoading(false);
      showError(
        error?.message ||
          translate.errors.somethingWentWrong ||
          "something went wrong"
      );
    }
  };

  const fetchPurpose = async () => {
    setIsLoading(true);
    try {
      const res = await getPurpose({}).unwrap();
      if (res?.status === 200) {
        setPurposeData(res?.data);
      }
      setIsLoading(false);
    } catch (error: any) {
      setIsLoading(false);
      showError(
        error?.message ||
          translate.errors.somethingWentWrong ||
          "something went wrong"
      );
    }
  };

  const fetchTerms = async () => {
    setIsLoading(true);
    try {
      const res = await getTerms({}).unwrap();
      if (res?.status === 200) {
        setTermsData(res?.data);
      }
      setIsLoading(false);
    } catch (error: any) {
      setIsLoading(false);
      showError(
        error?.message ||
          translate.errors.somethingWentWrong ||
          "something went wrong"
      );
    }
  };

  const fetchFaq = async () => {
    setIsLoading(true);
    try {
      const res = await getfaq({}).unwrap();
      if (res?.status === 200) {
        setFaqData(res?.data);
      }
      setIsLoading(false);
    } catch (error: any) {
      setIsLoading(false);
      showError(
        error?.message ||
          translate.errors.somethingWentWrong ||
          "something went wrong"
      );
    }
  };

  const handleImageUpload = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const files = event.target.files;
    const file = files?.length ? files[0] : null;

    try {
      if (file) {
        const allowedExtensions = ["png", "jpg", "jpeg"];
        const fileExtension = file?.name.split(".").pop()?.toLowerCase();
        if (!allowedExtensions.includes(fileExtension || "")) {
          setIsLoading(false);
          showError(
            translate.errors.onlyJpgPngAllowed ||
              "Invalid file format: only png, jpg images are allowed"
          );
          return;
        }
      }
      setIsLoading(true);
      const res = await UploadMedia(file);

      if (res?.status === 200) {
        console.log(res?.data[0]);
        setImage(res?.data[0]?.media_url);
        setImageId(res?.data[0]?.id);
      } else {
        showError(res?.message);
      }
    } catch (error) {
      console.error("Error uploading image:", error);
      showError(
        translate.errors.imageUploadError ||
          "Error uploading image. Please try again."
      );
    }
    setIsLoading(false);
  };

  console.log(userData, "userData");

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      first_name: userData?.first_name || "",
      last_name: userData?.last_name || "",
      email: userData?.email || "",
      purpose: userData?.purpose || "",
      phone_no: userData?.phone_no || "",
      country_code: userData?.country_code || "",
      profile_picture: userData?.profile_picture?.media_url || "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .required(translate.validations.requiredEmail || "Email is required")
        .matches(
          /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i,
          translate.validations.enterValidEmail || "Please enter a valid email"
        ),
      first_name: Yup.string()
        .required(translate.validations.requiredName || "Name is required")
        .min(
          2,
          translate.validations.minimumTwoCharacterRequired ||
            "Minimum 2 characters are required"
        )
        .max(
          100,
          translate.validations.maximum100CharactersRequired ||
            "Maximum 100 characters is required"
        )
        .matches(
          /^[A-Za-z\s'-]+$/,
          translate.validations.nameContainOnlyAlphabets ||
            "Name must contain only alphabets"
        ),
      last_name: Yup.string()
        .min(
          2,
          translate.validations.minimumTwoCharacterRequired ||
            "Minimum 2 characters are required"
        )
        .max(
          50,
          translate.validations.maximum50CharactersRequired ||
            "Maximum 50 characters is required"
        )
        .matches(
          /^[A-Za-z\s'-]+$/,
          translate.validations.nameContainOnlyAlphabets ||
            "Name must contain only alphabets"
        ),
      phone_no: Yup.string()
        .required(
          translate.validations.requiredPhone || "Phone number is required"
        )
        .min(
          6,
          translate.validations.phoneAtLeastMoreThan6Characters ||
            "Phone number must be at least 6 characters"
        )
        .max(
          16,
          translate.validations.phoneMustLessThan16Characters ||
            "Phone number must be less than 16 characters"
        ),
    }),

    onSubmit: async (values) => {
      formik.setSubmitting(true);
      let body = {
        profile_picture: imageId,
        email: values.email,
        first_name: values.first_name,
        last_name: values.last_name,
        phone_no: values.phone_no,
        country_code: (phoneCode.includes("+") ? "" : "+") + phoneCode,
        purpose: selectedPurpose,
      };
      console.log("body for setup", body);

      if (!verified) {
        showWarning(
          translate.settings.verifyYourEmail || "Please Verify your Email"
        );
        return;
      }

      try {
        let encryptedBody = generateEncryptedKeyBody(body) as CommonBody;
        const response = await updateProfile(encryptedBody).unwrap();
        console.log("TEST", image);
        setDetail({
          first_name: formik.values.first_name,
          last_name: formik.values.last_name,
        });
        dispatch(
          setCredentials({
            user: {
              ...userData,
              first_name: formik.values.first_name,
              last_name: formik.values.last_name,
              profile_picture: {
                ...userData.profile_picture,
                media_url: image,
              },
            },
            token: null,
          })
        );
        if (response?.status === 200) {
          if (response?.EMAIL_CHANGED === false) {
            showToast(
              translate.settings.profileUpdatedSuccessfully ||
                "Profile updated successfully"
            );
          } else if (response?.EMAIL_CHANGED === true) {
            showWarning(
              translate.settings.loginWithNewEmail ||
                "Please login with new Email"
            );
            navigate("/signin");
            removeFromStorage(STORAGE_KEYS.token);
            resetAuth();
          }
        }
      } catch (errors: any) {
        console.log(errors, "errrr");
        showError(
          errors?.data?.message ||
            errors?.data?.errors ||
            translate.errors.somethingWentWrong
        );
      }
    },
  });

  console.log(termsData, "termsData");

  const handleRedirect = () => {
    const number = contactNumber ? `${contactNumber}` : "966115207179";
    window.open(`https://wa.me/${number}`);
  };

  // const handleRedirectEmail = () => {
  //   const email = contactEmail ? `${contactEmail}` : "";

  // }

  useEffect(() => {
    fetchPurpose();
    fetchTerms();
    fetchFaq();
    fetchData();
    fetchContact();
  }, []);

  useEffect(() => {
    setImage(userData?.profile_picture?.media_url || "");
    setSelectedPurpose(userData?.purpose?.id || "");
  }, [userData]);

  console.log(faqData, "faqData");

  console.log(email, "email");

  const handleClose = () => {
    setOpen(false);
  };
  const handleClose2 = () => {
    setOpen2(false);
  };
  const [open1, setOpen1] = React.useState(false);
  const handleClose1 = () => setOpen1(false);
  const [sidebar, setSidebar] = useState(true);

  return (
    <>
      <Loader isLoad={isLoading} />
      <div className="sde_br_mn">
        <div className="sde_mn_cnt mt_70">
          <Tabs
            selectedIndex={tabIndex}
            onSelect={(index) => setTabIndex(index)}
          >
            <TabList>
              <Tab>{translate.settings.myProfile}</Tab>
              <Tab>{translate.auth.changePassword}</Tab>
              <Tab>{translate.settings.FAQs}</Tab>
              <Tab>{translate.contact.contactUs}</Tab>

              <Tab>{translate.settings.termsConditions2}</Tab>
            </TabList>
            <TabPanel>
              <div className="chn_pwrd mt_40">
                <div className="chn_pswd_lt">
                  <figure>
                    <img
                      src={image ? image : "/static/images/mbr_sldr1.png"}
                      alt=""
                    ></img>
                  </figure>
                  <figure className="cmra">
                    <input
                      type="file"
                      id="myFile"
                      name="filename"
                      accept=".jpg, .jpeg, .png"
                      onChange={handleImageUpload}
                    ></input>
                    {image ? (
                      <img src="/static/images/camera.png" alt="" />
                    ) : (
                      <img src="/static/images/camera.png" alt="" />
                    )}
                    {/* <img src="/static/images/camera.png" alt="" ></img> */}
                  </figure>
                  <h5>
                    {userDetail?.first_name
                      ? userDetail?.first_name + " " + userDetail?.last_name
                      : "-"}
                  </h5>
                </div>
                <div className="chn_pswd_rt sign_in">
                  <form
                    className="frmn frm_sn logn_rt "
                    onSubmit={formik.handleSubmit}
                  >
                    <div className="frmn_mn">
                      <TextField
                        className="control_group"
                        hiddenLabel
                        fullWidth
                        placeholder={translate.auth.firstName || "First name"}
                        variant="outlined"
                        inputProps={{ maxLength: 50 }}
                        name="first_name"
                        onChange={(val) => {
                          if (
                            val.target.value === " " ||
                            val.target.value === "."
                          ) {
                          } else {
                            formik.handleChange(val);
                          }
                        }}
                        onBlur={formik.handleBlur}
                        value={formik.values.first_name}
                        // helperText={
                        //   formik.touched.first_name && formik.errors.first_name
                        // }
                        error={
                          formik.touched.first_name &&
                          Boolean(formik.errors.first_name)
                        }
                      />
                    </div>

                    <div className="frmn_mn">
                      <TextField
                        className="control_group"
                        hiddenLabel
                        fullWidth
                        placeholder={translate.auth.lastName || "Last name"}
                        variant="outlined"
                        inputProps={{ maxLength: 50 }}
                        name="last_name"
                        onChange={(val) => {
                          if (
                            val.target.value === " " ||
                            val.target.value === "."
                          ) {
                          } else {
                            formik.handleChange(val);
                          }
                        }}
                        onBlur={formik.handleBlur}
                        value={formik.values.last_name}
                        // helperText={
                        //   formik.touched.last_name && formik.errors.last_name
                        // }
                        error={
                          formik.touched.last_name &&
                          Boolean(formik.errors.last_name)
                        }
                      />
                    </div>
                    <div className="frmn_mn">
                      <TextField
                        className="control_group"
                        hiddenLabel
                        fullWidth
                        placeholder={translate.auth.emailId || "Email Id"}
                        variant="outlined"
                        inputProps={{ maxLength: 50 }}
                        name="email"
                        onChange={(val) => {
                          if (val.target.value === " ") {
                          } else {
                            formik.handleChange(val);
                          }
                        }}
                        onBlur={formik.handleBlur}
                        value={formik.values.email}
                        // helperText={
                        //   formik.touched.email && formik.errors.email
                        // }
                        error={
                          formik.touched.email && Boolean(formik.errors.email)
                        }
                      />
                      <p
                        className="verify_btn"
                        style={{
                          margin: "0",
                          fontSize: "small",
                          padding: " 1px",
                          float: "right",
                          marginRight: "10px",
                          cursor: "pointer",
                          position: "absolute",
                          right: "0",
                          bottom: "20px",
                        }}
                        onClick={() => {
                          if (
                            !verified ||
                            formik.values.email !== userData?.email
                          ) {
                            setOpenPopup(true);
                            getOtp();
                          }
                        }}
                      >
                        {verified && formik.values.email === userData?.email
                          ? translate.global.verified || "Verified"
                          : translate.global.verify || "Verify"}
                      </p>
                    </div>

                    <div className="frmn_mn">
                      <PhoneInput
                        enableSearch={true}
                        value={phoneCode + formik.values.phone_no}
                        country={"us"}
                        inputClass="phoneInput"
                        placeholder={translate.auth.phoneNumber}
                        inputStyle={{ width: "100%" }}
                        onChange={(phone, country) =>
                          handleChangePhone(phone, country)
                        }
                        onBlur={formik.handleBlur}
                      />
                    </div>

                    <div className="frmn_mn">
                      {/* <label>Purpose</label> */}
                      <FormControl fullWidth className="control_group">
                        <Select
                          labelId="category-label"
                          id="category-select"
                          value={selectedPurpose}
                          displayEmpty
                          onChange={(e) => {
                            setSelectedPurpose(e.target.value);
                          }}
                        >
                          <MenuItem value="" disabled>
                            {translate.auth.purpose || "Purpose"}
                          </MenuItem>
                          {purposeData?.map((row: any, index: number) => {
                            return (
                              <MenuItem value={row?.id}>{row?.name}</MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </div>
                    <Button
                      className="btnn h_46"
                      type="submit"
                      onClick={() => {
                        if (formik?.errors?.first_name) {
                          showError(formik.errors.first_name as string);
                          return;
                        }
                        if (formik?.errors?.last_name) {
                          showError(formik.errors.last_name as string);
                          return;
                        }
                        if (formik?.errors?.email) {
                          showError(formik.errors.email as string);
                          return;
                        }
                        // if (formik?.errors?.profile_picture) {
                        //   showError(formik.errors.profile_picture as string);
                        // }
                        if (formik?.errors?.phone_no) {
                          showError(formik.errors.phone_no as string);
                          return;
                        }
                      }}
                    >
                      {translate.global.save || " Save"}
                    </Button>
                  </form>
                </div>
              </div>
              <div className="prfl_btn">
                <Button className="btnn h_46" onClick={() => setOpen(true)}>
                  {" "}
                  {translate.settings.logOut || "Log Out"}
                </Button>
                <Button className="btnn h_46" onClick={() => setOpen2(true)}>
                  {translate.settings.deleteAccount || "Delete Account"}
                </Button>
              </div>
            </TabPanel>
            <TabPanel className="mt_40">
              <ChangePassword />
            </TabPanel>
            <TabPanel className="faq_sec ">
              {faqData?.length ? (
                faqData.map((item: any) => {
                  return (
                    <Accordion>
                      <AccordionItem header={"Question: " + item?.question}>
                        {"Answer: " + item?.answer}
                      </AccordionItem>
                    </Accordion>
                  );
                })
              ) : (
                <h3>{translate.settings.noFaqFound || "No Faq Found"}</h3>
              )}
            </TabPanel>
            <TabPanel>
              <div className="stg_cnt_flx mt-bt">
                <div className="stg_cnt_lt">
                  <a href={"tel:" + `${contactNumber}`}>
                    <figure>
                      <img src="/static/images/cntact.png" alt=""></img>
                    </figure>
                  </a>
                </div>
                <div
                  className="stg_cnt_rt text_center hd_5"
                  style={{
                    display: "flex",
                    justifyContent: "space-evenly",
                    alignItems: "center",
                  }}
                >
                  {/* <h6>Feel free to drop us a message!</h6> */}
                  <div
                    className="whtsp "
                    style={{ cursor: "pointer", width: "33%" }}
                    onClick={handleRedirect}
                  >
                    <figure>
                      <img src="/static/images/whatsap.png" alt="img"></img>
                    </figure>
                    <p>
                      {" "}
                      {contactNumber ? contactNumber : ""}
                      <a>
                        <small>
                          {translate.settings.clickToWhatsapp ||
                            "Click Here to go to Whatsapp"}
                        </small>
                      </a>
                    </p>
                  </div>
                  <div
                    className="whtsp "
                    style={{ cursor: "pointer", width: "33%" }}
                  >
                    <figure>
                      <img
                        src="/static/images/communication.png"
                        alt="img"
                      ></img>
                    </figure>
                    <p>
                      <span style={{ fontSize: "13px" }}>
                        {email ? email : ""}
                      </span>
                      <a target="_blank" href={"mailto:" + `${email}`}>
                        <small>
                          {translate.settings.clickToEmail ||
                            "Click Here to go to E-mail"}
                        </small>
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </TabPanel>

            <TabPanel>
              <div className="descr">
                <div
                  style={{ textAlign: "center", fontSize: "22px" }}
                  dangerouslySetInnerHTML={{
                    __html: termsData?.[0]?.terms_condition
                      .trim()
                      .replace("\n", "")
                      .replace("<p></p>", "")
                      ? termsData?.[0]?.terms_condition
                      : translate.settings.noTermsConditionsFound ||
                        "No Terms and Conditions Found",
                  }}
                ></div>
              </div>
              {/* <div className="terms" >
                <h5>Do you accept Terms and conditions ?</h5>
                <div className="but_yn h_46" >
                  <button className="btnn btn_border h_46" >No</button>
                  <Button className="btnn h_46">Yes</Button>
                </div> 
              </div> */}
            </TabPanel>
          </Tabs>
        </div>
      </div>
      <Logout
        open={open}
        setOpen={setOpen}
        handleClose={handleClose}
        text={translate.settings.logOut || "Log out"}
      />
      <Logout
        open={open2}
        setOpen={setOpen2}
        handleClose={handleClose2}
        text={translate.settings.deleteAccount || "Delete account"}
      />
      <Notification
        open={open1}
        setOpen={setOpen1}
        handleClose={handleClose1}
      />
      <VerifyEmail
        open={openPopup}
        setOpen={setOpenPopup}
        email={formik.values.email}
        setVerified={setVerified}
      />
    </>
  );
}

export default Setting;
