/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { generateFileSummarization } from "../../services/fileSummarisation";
import { TestAI } from "../../constants/testAILoading";
import { generateEncryptedKeyBody } from "../../utils/crypto";
import { CommonBody } from "../../types/General";
import {
  researchByReference,
  useAddResearchMutation,
} from "../../services/research";
import { showError, showToast, showWarning } from "../../constants/toast";
import { END_POINTS } from "../../constants/url";
import { IconButton } from "@mui/material";
import { Close } from "@mui/icons-material";
import { useAddArticleMutation } from "../../services/article";
import { replace } from "formik";
import {
  getTestingSolution,
  usePostMcqAndFlashMutation,
} from "../../services/testingModule";
import useTranslation from "../../hooks/Translation";

let isCrossed = false;
function TestAi() {
  const navigate = useNavigate();
  const { state } = useLocation();
  // const path=state.newPage.split('"').join('');
  console.log(state, "state in test ai page");
  let controller = new AbortController();
  const [generateResearch] = useAddResearchMutation();
  const [generateTesting] = usePostMcqAndFlashMutation();
  const processFileSummarizationPdf = async () => {
    try {
      const response = await generateFileSummarization(state?.selectedFile);
      if (!isCrossed) {
        if (response?.status == 200) {
          console.log(response);
          navigate(state?.nextPage + "/" + response?.record_id, {
            replace: true,
            state: response,
          });
        } else {
          showError("File Summarization not completed");
          navigate(state?.prevPage, { replace: true });
        }
      } else {
        isCrossed = false;
      }
    } catch (error: any) {
      showError(
        error?.data?.message ||
          error?.data?.message ||
          "File Summarization not completed"
      );
      console.log(error);
    }
  };
  useEffect(() => {
    isCrossed = false;
    return () => {
      isCrossed = true;
    };
  }, []);
  const addNewResearch = async () => {
    const body = {
      reduce_citation: state?.isCitation,
      description: state?.description,
      topic: state?.researchTopic,
      page: state?.page,
      tone: state?.researchTone,
      reference:
        state?.reference?.toLowerCase() == "none" ? "" : state?.reference,
    };
    const encryptedBody = generateEncryptedKeyBody(body) as CommonBody;
    try {
      const response = await generateResearch(encryptedBody).unwrap();

      if (!isCrossed) {
        if (response?.status == 200) {
          // alert(isCrossed);
          showToast("Research generated successful");
          navigate("/reserch-edit2/" + response?.record_id, { replace: true });
          console.log(response);
        } else {
          navigate("/research", { replace: true });
          showError("Research not generated,Please try again");
        }
      } else {
        isCrossed = false;
      }
    } catch (error: any) {
      console.log(error);
      navigate("/research", { replace: true });
      showError(
        error?.data?.message || "Research not generated,Please try again"
      );
    }
  };
  const addNewPDFResearch = async () => {
    try {
      const response = await researchByReference(
        state?.selectedFile,
        "files",
        "",
        false,
        END_POINTS.uploadReferenceForResearch
      );
      if (!isCrossed) {
        if (response.status == 200) {
          showToast("Research generated successful");
          navigate("/reserch-edit2/" + response?.record_id, { replace: true });
        } else {
          showError("Research not generated, Please try again");
          console.log("INSIDE CATCH");

          navigate("/research", { replace: true });
        }
      } else {
        isCrossed = false;
      }
    } catch (error: any) {
      showError(
        error?.data?.message || "Research not generated, Please try again"
      );
      console.log("INSIDE CATCH");

      navigate("/research");
    }
  };

  const [generateArticle] = useAddArticleMutation();
  const addNewArticle = async () => {
    const body = {
      topic: state?.articleTopic,
      words: state?.wordLimit,
      language: state?.selectedLanguage,
      tone: state?.selectedTone,
      pronouns: state?.pronoun,
      region: state?.region,
      // picture: state?.isCitation,
    };
    const encryptedBody = generateEncryptedKeyBody(body) as CommonBody;
    try {
      const response = await generateArticle(encryptedBody).unwrap();
      if (!isCrossed) {
        if (response?.status == 200) {
          // alert(isCrossed);
          showToast("Article generated successful");
          navigate("/edit-article/" + response?.record_id, { replace: true });
          console.log(response);
        } else {
          navigate("/artical", { replace: true });
          showError("Article not generated");
          // showError("Article not generated , Please try again")
        }
      } else {
        isCrossed = false;
      }
    } catch (error: any) {
      console.log(error);
      navigate("/artical");
      showError(
        error?.data?.message || "Article not generated,Please try again"
      );
    }
  };

  const addTesting = async () => {
    const sub_cat = state?.nextPage === "/quiz/" ? 1 : 2;
    try {
      const res = await getTestingSolution(
        state.selectedFile,
        state.fileType,
        sub_cat
      );
      console.log(res, "res of testing module");

      if (!isCrossed) {
        if (res.status === 200) {
          navigate(state.nextPage, { state: res, replace: true });
        } else {
          showError(res?.data ? res?.data : "Something went wrong");
          navigate("/testing");
        }
      } else {
        isCrossed = false;
      }
    } catch (error: any) {
      showError(
        error?.data?.message ? error?.data?.message : "Something went wrong"
      );
      navigate("/testing");
    }
  };

  console.log(state?.selectedFile, "state.selectedFile");

  useEffect(() => {
    if (state?.type == TestAI.FILE_SUMMARIZATION) {
    }
    // processFileSummarizationPdf();
    else if (state?.type == TestAI.RESEARCH) {
      // addNewResearch();
    } else if (state?.type == TestAI.RESEARCH_REFERENCE) {
      // addNewPDFResearch();
    } else if (state?.type == TestAI.ARTICLE) {
    }
    // addNewArticle();
    else if (state?.type == TestAI.TESTING) {
      // addTesting();
    }
  }, []);
  const translate = useTranslation();
  return (
    <section className="bnr_sec cnvrt">
      <div className="conta_iner">
        <IconButton
          style={{ position: "absolute", right: "100px", color: "white" }}
          onClick={() => {
            isCrossed = true;
            if (
              state?.type == TestAI.RESEARCH_REFERENCE ||
              state?.type == TestAI.RESEARCH
            ) {
              navigate("/research");
              // isCrossed=false;
              return;
            }
            navigate(state?.prevPage || "/");
          }}
        >
          <Close />
        </IconButton>
        <div className="bnr_mn">
          <div className="bnr_lt text_center">
            <video
              autoPlay
              loop
              muted
              playsInline
              src="/static/images/note.mp4"
              className="tst_vdo"
            ></video>
            <h1>
              {translate.home.let}
              <span className="ai_bdr">
                {translate.home.ai}
                <span></span>
              </span>
              <span className="d_block">{translate.home.doItMagic}</span>
            </h1>
          </div>
        </div>
      </div>
    </section>
  );
}
export default TestAi;
