/* eslint-disable @typescript-eslint/no-unused-vars */
import Layout from "../../layout/index";
import "react-tabs/style/react-tabs.css";
import { useLocation, useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import React, { useState } from "react";
import Header from "../../layout/header/Header";
import useAuth from "../../hooks/useAuth";
import useTranslation from "../../hooks/Translation";

function ReserchTons() {
  const navigate = useNavigate();
  const translate = useTranslation();
  const { state } = useLocation();
  console.log(state);
  const [selectedTone, setSelectedTone] = useState(1);
  const researchToneEnum: any =
    translate?.getLanguage() == "en"
      ? {
          1: "Formal",
          2: "Informative",
          3: "Narrative",
          4: "Objective",
          5: "Analytical",
          6: "Persuasive",
          7: "Casual",
          8: "Descriptive",
        }
      : {
          1: "رسمي",
          2: "معلوماتي",
          3: "سردي",
          4: "موضوعي",
          5: "تحليلي",
          6: "إقناعي",
          7: "غير رسمي",
          8: "وصفي",
        };
  return (
    <>
      {/* <div className="sde_hdr">
        <div className="sde_hdr_lt">
        <figure>
            <img src="/static/images/research.png" alt=""></img>
          </figure>
          <div className="">
            <h3>{translate.research.research || "Research"}</h3>
            <p> Lorem Ipsum dolor et amet</p>
          </div>
        </div>
        <div className="sde_hdr_rt">
          <figure>
            <img src="/static/images/notification.png" alt=""></img>
          </figure>
          <h3>Hi, Ahmed</h3>
          <figure>
            <img src="/static/images/ahmed.png" alt=""></img>
          </figure>
        </div>
      </div> */}
      <div className="sde_br_mn slct">
        <div className="sde_mn_cnt tpc_rsrch">
          <h6>{translate.article.selectToneVoice || "Select Tone of voice"}</h6>

          <Tabs>
            <TabList>
              <Tab style={{ cursor: "context-menu" }}>
                {translate.research.createNewResearch ||
                  "Create a new Research"}
              </Tab>
              {/* <Tab>My Researches</Tab> */}
            </TabList>

            <TabPanel>
              <div className="sde_mn_cnt slct_mn articl tn_lst">
                <ul className="slct_tn text_center">
                  <li
                    className={selectedTone == 1 ? "active" : ""}
                    onClick={() => setSelectedTone(1)}
                    style={{
                      cursor: selectedTone == 1 ? "context-menu" : "pointer",
                    }}
                  >
                    {researchToneEnum[1]}
                  </li>
                  <li
                    className={selectedTone == 2 ? "active" : ""}
                    onClick={() => setSelectedTone(2)}
                    style={{
                      cursor: selectedTone == 2 ? "context-menu" : "pointer",
                    }}
                  >
                    {researchToneEnum[2]}
                  </li>
                  <li
                    className={selectedTone == 3 ? "active" : ""}
                    onClick={() => setSelectedTone(3)}
                    style={{
                      cursor: selectedTone == 3 ? "context-menu" : "pointer",
                    }}
                  >
                    {researchToneEnum[3]}
                  </li>
                  <li
                    className={selectedTone == 4 ? "active" : ""}
                    onClick={() => setSelectedTone(4)}
                    style={{
                      cursor: selectedTone == 4 ? "context-menu" : "pointer",
                    }}
                  >
                    {researchToneEnum[4]}
                  </li>
                  <li
                    className={selectedTone == 5 ? "active" : ""}
                    onClick={() => setSelectedTone(5)}
                    style={{
                      cursor: selectedTone == 5 ? "context-menu" : "pointer",
                    }}
                  >
                    {researchToneEnum[5]}
                  </li>
                  <li
                    className={selectedTone == 6 ? "active" : ""}
                    onClick={() => setSelectedTone(6)}
                    style={{
                      cursor: selectedTone == 6 ? "context-menu" : "pointer",
                    }}
                  >
                    {researchToneEnum[6]}
                  </li>
                  <li
                    className={selectedTone == 7 ? "active" : ""}
                    onClick={() => setSelectedTone(7)}
                    style={{
                      cursor: selectedTone == 7 ? "context-menu" : "pointer",
                    }}
                  >
                    {researchToneEnum[7]}
                  </li>
                  <li
                    className={selectedTone == 8 ? "active" : ""}
                    onClick={() => setSelectedTone(8)}
                    style={{
                      cursor: selectedTone == 8 ? "context-menu" : "pointer",
                    }}
                  >
                    {researchToneEnum[8]}
                  </li>
                </ul>
                <div className="rght_btn">
                  <Button
                    type="button"
                    className="btnn btn_primary"
                    onClick={() => {
                      navigate("/refernce", {
                        state: {
                          ...state,
                          researchTone: researchToneEnum[selectedTone],
                        },
                        replace: true,
                      });
                    }}
                  >
                    {translate.global.next || "Next"}
                  </Button>
                </div>
              </div>
            </TabPanel>
          </Tabs>
        </div>
      </div>
    </>
  );
}

export default ReserchTons;
