import { Box, Button, Tooltip } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import React, { useEffect, useLayoutEffect, useState } from "react";
import Notification from "../../components/notification";
import useAuth from "../../hooks/useAuth";
import { getFromStorage, setToStorage } from "../../constants/storage";
import { STORAGE_KEYS } from "../../constants/storageKeys";
import { useLanguageContext } from "../../context/Language";
import { Language } from "@mui/icons-material";
import useTranslation from "../../hooks/Translation";

function Header(props: any) {
  const location = useLocation();
  const translate = useTranslation();
  console.log(location.pathname);
  const navigate = useNavigate();
  const userData = useAuth();
  const [show, setShow] = useState(false);
  const [heightShow, setHeightShow] = useState(false);
  const [scrolling, setScrolling] = useState(false);
  const [open1, setOpen1] = React.useState(false);
  const handleOpen1 = () => setOpen1(true);
  const handleClose1 = () => setOpen1(false);
  const [arabic, setArabic] = useState(false);
  const { language, changeLanguage } = useLanguageContext();
  const getLang = getFromStorage(STORAGE_KEYS.language);
  const ImageData = [
    "/static/images/testing.png",
    "/static/images/summarization.png",
    "/static/images/note-taking.png",
    "/static/images/research.png",
    "/static/images/assignment.png",
    "/static/images/file_type.png",
    "/static/images/presentations.png",
    "/static/images/articles.png",
    "/static/images/subscriptions.png",
    "/static/images/settings.png",
    "/static/images/note-taking.png",
    "/static/images/assignment.png",
    "/static/images/assignment.png",
    "/static/images/summarization.png",
    "/static/images/research.png",
    "/static/images/research.png",
    "/static/images/research.png",
    "/static/images/research.png",
    "/static/images/articles.png",
    "/static/images/articles.png",
    "/static/images/articles.png",
    "/static/images/articles.png",
    "/static/images/articles.png",
    "/static/images/file_type.png",
    "/static/images/presentations.png",
    "/static/images/articles.png",
    "/static/images/research.png",
    "/static/images/note-taking.png",
  ];
  const TitleData =
    translate?.getLanguage() == "en"
      ? [
          "Testing",
          " File Summarization",
          " Note Taking",
          "Research",
          "Assignment Solutions",
          " File Type Conversion",
          "Presentations",
          "Articles",
          "Subscriptions",
          " Settings",
          " Note Taking",
          "Assignment Solutions",
          "Assignment Solutions",
          " File Summarization",
          "Research",
          "Research",
          "Research",
          "Research",
          "Articles",
          "Articles",
          "Articles",
          "Articles",
          "Articles",
          " File Type Conversion",
          "Presentations",
          "Articles",
          "Research",
          " Note Taking",
        ]
      : [
          "اختبار",
          "تلخيص الملفات",
          "تدوين الملاحظات",
          "بحث",
          "حلول الواجبات",
          "تحويل نوع الملفات",
          "عروض تقديمية",
          "مقالات",
          "اشتراكات",
          "إعدادات",
          "تدوين الملاحظات",
          "حلول الواجبات",
          "حلول الواجبات",
          "تلخيص الملفات",
          "بحث",
          "بحث",
          "بحث",
          "بحث",
          "مقالات",
          "مقالات",
          "مقالات",
          "مقالات",
          "مقالات",
          "تحويل نوع الملفات",
          "عروض تقديمية",
          "مقالات",
          "بحث",
          "تدوين الملاحظات",
        ];
  const secondTitleData =
    translate?.getLanguage() == "en"
      ? [
          "Let AI test your skills",
          "Summarize your day to day notes",
          "Let AI take notes for you",
          "Create a AI research paper",
          "Let AI solve your assignments",
          "Convert your files in one go",
          "Create a AI powered presentations ",
          "Create and publish your article using AI",
          "Manage your subscription",
          "Update your data",
          "Let AI take notes for you",
          "Let AI solve your assignments",
          "Let AI solve your assignments",
          "Summarize your day to day notes",
          "Create a AI research paper",
          "Create a AI research paper",
          "Create a AI research paper",
          "Create a AI research paper",
          "Create and publish your article using AI",
          "Create and publish your article using AI",
          "Create and publish your article using AI",
          "Create and publish your article using AI",
          "Create and publish your article using AI",
          "Convert your files in one go",
          "Create a AI powered presentations ",
          "Create and publish your article using AI",
          "Create a AI research paper",
          "Let AI take notes for you",
        ]
      : [
          "دع الذكاء الاصطناعي يختبر مهاراتك",
          "لخص ملاحظاتك اليومية",
          "دع الذكاء الاصطناعي يدون الملاحظات لك",
          "أنشئ بحثًا علميًا باستخدام الذكاء الاصطناعي",
          "دع الذكاء الاصطناعي يحل واجباتك",
          "حوّل ملفاتك في خطوة واحدة",
          "أنشئ عروضًا تقديمية مدعومة بالذكاء الاصطناعي",
          "أنشئ وانشر مقالتك باستخدام الذكاء الاصطناعي",
          "إدارة اشتراكك",
          "تحديث بياناتك",
          "دع الذكاء الاصطناعي يدون الملاحظات لك",
          "دع الذكاء الاصطناعي يحل واجباتك",
          "دع الذكاء الاصطناعي يحل واجباتك",
          "لخص ملاحظاتك اليومية",
          "أنشئ بحثًا علميًا باستخدام الذكاء الاصطناعي",
          "أنشئ بحثًا علميًا باستخدام الذكاء الاصطناعي",
          "أنشئ بحثًا علميًا باستخدام الذكاء الاصطناعي",
          "أنشئ بحثًا علميًا باستخدام الذكاء الاصطناعي",
          "أنشئ وانشر مقالتك باستخدام الذكاء الاصطناعي",
          "أنشئ وانشر مقالتك باستخدام الذكاء الاصطناعي",
          "أنشئ وانشر مقالتك باستخدام الذكاء الاصطناعي",
          "أنشئ وانشر مقالتك باستخدام الذكاء الاصطناعي",
          "أنشئ وانشر مقالتك باستخدام الذكاء الاصطناعي",
          "حوّل ملفاتك في خطوة واحدة",
          "أنشئ عروضًا تقديمية مدعومة بالذكاء الاصطناعي",
          "أنشئ وانشر مقالتك باستخدام الذكاء الاصطناعي",
          "أنشئ بحثًا علميًا باستخدام الذكاء الاصطناعي",
          "دع الذكاء الاصطناعي يدون الملاحظات لك",
        ];
  const [name, setName] = useState("Mohit");
  useEffect(() => {
    const user = getFromStorage(STORAGE_KEYS.user) as any;
    console.log("user :", user);
    // setName(user?.first_name + " " + user?.last_name)
  }, [userData]);
  const [selectedImage, setSelectedImage] = useState("/static/images/logo.svg");
  const [selectedTitle, setSelectedTitle] = useState("Article");
  const [selectedSecondTitle, setSelectedSecondTitle] = useState(
    "Create and publish your article using AI"
  );
  const [displayPrimaryHeader, setDisplayPrimaryHeader] = useState(true);
  const [displaySecondaryHeader, setDisplaySecondaryHeader] = useState(false);
  useLayoutEffect(() => {
    if (location?.pathname == "/testing") {
      setSelectedImage(ImageData[0]);
      setSelectedTitle(TitleData[0]);
      setSelectedSecondTitle(secondTitleData[0]);
      setDisplayPrimaryHeader(false);
      setDisplaySecondaryHeader(true);
    }
    if (location?.pathname == "/file-summarization") {
      setSelectedImage(ImageData[1]);
      setSelectedTitle(TitleData[1]);
      setSelectedSecondTitle(secondTitleData[1]);
      setDisplayPrimaryHeader(false);
      setDisplaySecondaryHeader(true);
    }
    if (location?.pathname.includes("/edit-article/")) {
      setSelectedImage(ImageData[7]);
      setSelectedSecondTitle(secondTitleData[7]);
      setSelectedTitle(TitleData[7]);
      setDisplayPrimaryHeader(false);
      setDisplaySecondaryHeader(true);
    }
    if (location?.pathname == "/note-taking") {
      setSelectedImage(ImageData[2]);
      setSelectedTitle(TitleData[2]);
      setSelectedSecondTitle(secondTitleData[2]);
      setDisplayPrimaryHeader(false);
      setDisplaySecondaryHeader(true);
    }
    if (location?.pathname == "/research") {
      setSelectedImage(ImageData[3]);
      setSelectedSecondTitle(secondTitleData[3]);
      setSelectedTitle(TitleData[3]);
      setDisplayPrimaryHeader(false);
      setDisplaySecondaryHeader(true);
    }
    if (location?.pathname == "/assignment-solution") {
      setSelectedImage(ImageData[4]);
      setSelectedTitle(TitleData[4]);
      setSelectedSecondTitle(secondTitleData[4]);
      setDisplayPrimaryHeader(false);
      setDisplaySecondaryHeader(true);
    }
    if (location?.pathname == "/file-type") {
      setSelectedImage(ImageData[5]);
      setSelectedTitle(TitleData[5]);
      setSelectedSecondTitle(secondTitleData[5]);
      setDisplayPrimaryHeader(false);
      setDisplaySecondaryHeader(true);
    }
    if (location?.pathname == "/presentations") {
      setSelectedImage(ImageData[6]);
      setSelectedTitle(TitleData[6]);
      setSelectedSecondTitle(secondTitleData[6]);
      setDisplayPrimaryHeader(false);
      setDisplaySecondaryHeader(true);
    }
    if (location?.pathname == "/artical") {
      setSelectedImage(ImageData[7]);
      setSelectedTitle(TitleData[7]);
      setSelectedSecondTitle(secondTitleData[7]);
      setDisplayPrimaryHeader(false);
      setDisplaySecondaryHeader(true);
    }
    if (location?.pathname == "/subscriptions") {
      setSelectedImage(ImageData[8]);
      setSelectedTitle(TitleData[8]);
      setSelectedSecondTitle(secondTitleData[8]);
      setDisplayPrimaryHeader(false);
      setDisplaySecondaryHeader(true);
    }
    if (location?.pathname == "/setting") {
      setSelectedImage(ImageData[9]);
      setSelectedTitle(TitleData[9]);
      setSelectedSecondTitle(secondTitleData[9]);
      setDisplayPrimaryHeader(false);
      setDisplaySecondaryHeader(true);
    }
    if (location?.pathname.includes("/edit-note")) {
      setSelectedImage(ImageData[10]);
      setSelectedSecondTitle(secondTitleData[10]);
      setSelectedTitle(TitleData[10]);
      setDisplayPrimaryHeader(false);
      setDisplaySecondaryHeader(true);
    }
    if (location?.pathname == "/assign-solution") {
      setSelectedImage(ImageData[11]);
      setSelectedTitle(TitleData[11]);
      setSelectedSecondTitle(secondTitleData[11]);
      setDisplayPrimaryHeader(false);
      setDisplaySecondaryHeader(true);
    }
    if (location?.pathname.includes("/edit-assign")) {
      setSelectedImage(ImageData[12]);
      setSelectedTitle(TitleData[12]);
      setSelectedSecondTitle(secondTitleData[12]);
      setDisplayPrimaryHeader(false);
      setDisplaySecondaryHeader(true);
    }
    if (location?.pathname.includes("/edit-file")) {
      setSelectedImage(ImageData[13]);
      setSelectedTitle(TitleData[13]);
      setSelectedSecondTitle(secondTitleData[13]);
      setDisplayPrimaryHeader(false);
      setDisplaySecondaryHeader(true);
    }
    if (location?.pathname == "/topic-research") {
      setSelectedImage(ImageData[14]);
      setSelectedTitle(TitleData[14]);
      setSelectedSecondTitle(secondTitleData[14]);
      setDisplayPrimaryHeader(false);
      setDisplaySecondaryHeader(true);
    }
    if (location?.pathname == "/research-page") {
      setSelectedImage(ImageData[15]);
      setSelectedTitle(TitleData[15]);
      setSelectedSecondTitle(secondTitleData[15]);
      setDisplayPrimaryHeader(false);
      setDisplaySecondaryHeader(true);
    }
    if (location?.pathname == "/reserch-tone") {
      setSelectedImage(ImageData[16]);
      setSelectedTitle(TitleData[16]);
      setSelectedSecondTitle(secondTitleData[16]);
      setDisplayPrimaryHeader(false);
      setDisplaySecondaryHeader(true);
    }
    if (location?.pathname == "/refernce") {
      setSelectedImage(ImageData[17]);
      setSelectedTitle(TitleData[17]);
      setSelectedSecondTitle(secondTitleData[17]);
      setDisplayPrimaryHeader(false);
      setDisplaySecondaryHeader(true);
    }
    if (location?.pathname == "/createartical") {
      setSelectedImage(ImageData[18]);
      setSelectedTitle(TitleData[18]);
      setSelectedSecondTitle(secondTitleData[18]);
      setDisplayPrimaryHeader(false);
      setDisplaySecondaryHeader(true);
    }
    if (location?.pathname == "/language") {
      setSelectedImage(ImageData[19]);
      setSelectedTitle(TitleData[19]);
      setSelectedSecondTitle(secondTitleData[19]);
      setDisplayPrimaryHeader(false);
      setDisplaySecondaryHeader(true);
    }
    if (location?.pathname == "/editartical") {
      setSelectedImage(ImageData[20]);
      setSelectedTitle(TitleData[20]);
      setSelectedSecondTitle(secondTitleData[20]);
      setDisplayPrimaryHeader(false);
      setDisplaySecondaryHeader(true);
    }
    if (location?.pathname == "/selecttons") {
      setSelectedImage(ImageData[21]);
      setSelectedTitle(TitleData[21]);
      setSelectedSecondTitle(secondTitleData[21]);
      setDisplayPrimaryHeader(false);
      setDisplaySecondaryHeader(true);
    }
    if (location?.pathname == "/pronouns") {
      setSelectedImage(ImageData[22]);
      setSelectedTitle(TitleData[22]);
      setDisplayPrimaryHeader(false);
      setSelectedSecondTitle(secondTitleData[22]);
      setDisplaySecondaryHeader(true);
    }
    if (location?.pathname == "/file-convert") {
      setSelectedImage(ImageData[23]);
      setSelectedTitle(TitleData[23]);
      setSelectedSecondTitle(secondTitleData[23]);
      setDisplayPrimaryHeader(false);
      setDisplaySecondaryHeader(true);
    }
    if (location?.pathname == "/creatpresentations") {
      setSelectedImage(ImageData[24]);
      setSelectedTitle(TitleData[24]);
      setSelectedSecondTitle(secondTitleData[24]);
      setDisplayPrimaryHeader(false);
      setDisplaySecondaryHeader(true);
    }
    if (location?.pathname == "/presentation-preview") {
      setSelectedImage(ImageData[24]);
      setSelectedTitle(TitleData[24]);
      setSelectedSecondTitle(secondTitleData[24]);
      setDisplayPrimaryHeader(false);
      setDisplaySecondaryHeader(true);
    }
    if (location?.pathname == "/artical-edit") {
      setSelectedImage(ImageData[25]);
      setSelectedTitle(TitleData[25]);
      setSelectedSecondTitle(secondTitleData[25]);
      setDisplayPrimaryHeader(false);
      setDisplaySecondaryHeader(true);
    }
    if (location?.pathname.includes("/reserch-edit")) {
      setSelectedImage(ImageData[26]);
      setSelectedTitle(TitleData[26]);
      setSelectedSecondTitle(secondTitleData[26]);
      setDisplayPrimaryHeader(false);
      setDisplaySecondaryHeader(true);
    }
    if (location?.pathname == "/note-edit") {
      setSelectedImage(ImageData[27]);
      setSelectedTitle(TitleData[27]);
      setSelectedSecondTitle(secondTitleData[27]);
      setDisplayPrimaryHeader(false);
      setDisplaySecondaryHeader(true);
    }
  }, [location.pathname]);
  useEffect(() => {
    change();
  }, [getLang]);

  useEffect(() => {
    if (!userData) {
    }
  }, []);
  const change = () => {
    const body = document.body; // Use document.body directly to get the body element

    if (body) {
      const language = getFromStorage(STORAGE_KEYS.language);
      const isArabic = language === "ar";

      // Remove the opposite class before adding the new one
      body.classList.remove(isArabic ? "english_lang" : "arabic_lang");
      body.classList.add(isArabic ? "arabic_lang" : "english_lang");
    }
  };
  const handleLangChange = (val: string) => {
    setTimeout(() => {
      // Perform other actions after the page is reloaded
      window.location.reload();
      setToStorage(STORAGE_KEYS.language, val);
      setArabic(!arabic);
      changeLanguage(val);
    }, 500);
  };

  return (
    <header className=" text_white">
      {displayPrimaryHeader ? (
        <div className="hdr">
          <div className="hdr_flx conta_iner">
            <div className="hdr_lt">
              <figure>
                <img
                  style={{ cursor: "pointer" }}
                  src="/static/images/logo.svg"
                  alt=""
                  onClick={() => navigate("/")}
                />
              </figure>
            </div>
            <ul className={`hdr_rt ${show ? "active" : ""}`}>
              <li className={location.pathname == "/" ? "active" : ""}>
                <Box component="a" onClick={() => navigate("/")}>
                  {translate.home.home}
                </Box>
              </li>
              <li className={location.pathname == "/about" ? "active" : ""}>
                <Box component="a" onClick={() => navigate("/about")}>
                  {translate.about.aboutUs}
                </Box>
              </li>
              <li
                className={
                  location.pathname == "/subscription" ||
                  location.pathname == "/subscription-plan"
                    ? "active"
                    : ""
                }
              >
                <Box component="a" onClick={() => navigate("/subscription")}>
                  {" "}
                  {translate.subscriptions.subscriptionPlans}
                </Box>{" "}
              </li>
              <li className={location.pathname == "/contact" ? "active" : ""}>
                <Box component="a" onClick={() => navigate("/contact")}>
                  {translate.contact.contactUs}
                </Box>
              </li>
              <li>
                {" "}
                <Button
                  type="button"
                  className="btn btn_primary btnn sm"
                  onClick={() => navigate("/login")}
                >
                  {translate.global.login}
                </Button>{" "}
              </li>
            </ul>

            <button className="humb_btn" onClick={() => setShow(!show)}>
              {" "}
              <MenuIcon />{" "}
            </button>
          </div>
        </div>
      ) : null}
      {displaySecondaryHeader ? (
        <div className="sde_hdr ">
          <div className="sde_hdr_lt">
            <figure>
              <img
                src={selectedImage || "/static/images/testing2.svg"}
                alt=""
              ></img>
            </figure>
            <div className="">
              <h3>{selectedTitle || "WHIZZO"}</h3>
              <p> {selectedSecondTitle || "Generate AI content"}</p>
            </div>
          </div>
          <div className="sde_hdr_rt">
            <h3
              onClick={() => handleLangChange(getLang == "en" ? "ar" : "en")}
              style={{
                cursor: "pointer",
                width: "40px",
                height: "40px",
                background: "#4b5554",
                padding: "8px",
                borderRadius: "50%",
              }}
            >
              <Tooltip title="Change language">
                <Language />
              </Tooltip>
            </h3>
            <button
              onClick={() => setHeightShow(!heightShow)}
              className={`${heightShow ? "active_size" : ""}`}
            >
              {" "}
              <MenuIcon />{" "}
            </button>
            <figure onClick={() => setOpen1(true)}>
              <img src="/static/images/notification.png" alt=""></img>
            </figure>
            <h3>
              {translate.global.hi || "Hi"},{" "}
              {`${userData?.first_name} ${userData?.last_name ? userData?.last_name : ""}`}
            </h3>

            <figure
              onClick={() => {
                location.pathname == "/setting"
                  ? window.location.reload()
                  : navigate("/setting", { state: true });
              }}
              className="prfl_img"
            >
              <img
                src={
                  userData?.profile_picture?.media_url
                    ? userData?.profile_picture?.media_url
                    : "/static/images/ahmed.png"
                }
                alt=""
              ></img>
            </figure>
          </div>
        </div>
      ) : null}

      <Notification
        open={open1}
        handleClose={handleClose1}
        setOpen={setOpen1}
      />
    </header>

    // sidebar header
  );
}

export default Header;
