/* eslint-disable @typescript-eslint/no-unused-vars */
import Layout from "../../layout/index";
import "react-tabs/style/react-tabs.css";
import { Button } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import Slider from "@mui/material/Slider";
import Box from "@mui/material/Box";
import React, { useState } from "react";
import Header from "../../layout/header/Header";
import useAuth from "../../hooks/useAuth";
import useTranslation from "../../hooks/Translation";

function ReserchPages() {
  const translate = useTranslation();
  const navigate = useNavigate();
  const { state } = useLocation();
  function valuetext(value: number) {
    return `${value}°C`;
  }
  const [sidebar, setSidebar] = useState(true);
  const [selectedValue, setSelectedValue] = useState(0);
  const [displayData, setDisplayData] = useState({
    label: translate.research.short || "Short",
    pageCountLabel: `(~ 1-2 ${translate.research.pages || "pages"})`,
    symbolCountLabel: `${translate.research.approx || "Approx."} 3600 - 7200`,
    wordCountLabel: `600 - 1200 ${translate.research.words || "words"}`,
    page: 1,
  });
  const pageData = [
    {
      label: translate.research.short || "Short",
      pageCountLabel: `(~ 1-2 ${translate.research.pages || "pages"})`,
      symbolCountLabel: `${translate.research.approx || "Approx."} 3600 - 7200`,
      wordCountLabel: `600 - 1200 ${translate.research.words || "words"}`,
      page: 1,
    },
    {
      label: translate.research.medium || "Medium",
      pageCountLabel: `(~ 3-5 ${translate.research.pages || "pages"})`,
      symbolCountLabel: `${translate.research.approx || "Approx."} 10800 - 18000`,
      wordCountLabel: `1800 - 3000 ${translate.research.words || "words"}`,
      page: 2,
    },
    {
      label: translate.research.long || "Long",
      pageCountLabel: `(~ 7-12 ${translate.research.pages || "pages"})`,
      symbolCountLabel: `${translate.research.approx || "Approx."} 25200 - 43200`,
      wordCountLabel: `4200 - 7200 ${translate.research.words || "words"}`,
      page: 3,
    },
    {
      label: translate.research.long || "Very Long",
      pageCountLabel: `(~ 15-25 ${translate.research.pages || "pages"})`,
      symbolCountLabel: `${translate.research.approx || "Approx."} 54000 - 90000`,
      wordCountLabel: `9000 - 15000 ${translate.research.words || "words"}`,
      page: 4,
    },
  ];
  const handleChange = (e: any) => {
    setSelectedValue(e.target.value);
    setDisplayData(pageData[e.target.value]);
  };
  return (
    <>
      <div className="sde_br_mn slct">
        <div className="sde_mn_cnt tpc_rsrch">
          <h5>{translate.research.topicResearch || "Topic of Research"}</h5>
          <Tabs>
            <TabList>
              <Tab style={{ cursor: "context-menu" }}>
                {translate.research.createNewResearch ||
                  "Create a new Research"}
              </Tab>
              {/* <Tab>My Researches</Tab> */}
            </TabList>

            <TabPanel>
              <div className="fle_type_mn text_center hd_6 rsrh_pge">
                <Box sx={{ width: 300 }}>
                  <Slider
                    aria-label="Temperature"
                    value={selectedValue}
                    onChange={handleChange}
                    step={1}
                    max={3}
                    getAriaValueText={valuetext}
                    color="secondary"
                  />
                </Box>
                <h3>
                  {displayData?.label}{" "}
                  <span className="d_block">
                    {" "}
                    {displayData?.pageCountLabel}
                  </span>
                </h3>
                <p>
                  {displayData?.symbolCountLabel}
                  <span className="d_block">
                    {translate.research.symbols || "symbols"} /{" "}
                    {displayData?.wordCountLabel}
                  </span>
                </p>

                <div className="rght_btn">
                  <Button
                    type="button"
                    className="btnn btn_primary"
                    onClick={() => {
                      navigate("/reserch-tone", {
                        state: {
                          researchTopic: state,
                          page: displayData?.page,
                        },
                        replace: true,
                      });
                    }}
                  >
                    {translate.global.next || "Next"}
                  </Button>
                </div>
              </div>
            </TabPanel>
          </Tabs>
        </div>
      </div>
    </>
  );
}

export default ReserchPages;
