export const handleDownload = async (fileUrl: string, fileName: string) => {
  try {
    console.log("TESTETET");

    const response = await fetch(fileUrl);
    const blob = await response.blob();
    const blobUrl = window.URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.href = blobUrl;
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    setTimeout(() => {
      document.body.removeChild(link);
      window.URL.revokeObjectURL(blobUrl);
    }, 100);

    // Clean up the Blob URL after download
  } catch (error) {
    console.error("Error downloading file:", error);
    // Handle error as needed
  }
};
