/* eslint-disable @typescript-eslint/no-unused-vars */
import Layout from "../../layout/index";
import "react-tabs/style/react-tabs.css";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { useNavigate } from "react-router-dom";
import Notification from "../../components/notification";
import useAuth from "../../hooks/useAuth";
import React, { useCallback, useEffect, useRef, useState } from "react";
import Header from "../../layout/header/Header";
import PptEditor from "../../components/Fabric";
import { showError, showToast } from "../../constants/toast";
import { CommonBody } from "../../types/General";

import { generateEncryptedKeyBody } from "../../utils/crypto";
import {
  useDeletePresentationMutation,
  useEditPresentationMutation,
  useGetPresentationMutation,
} from "../../services/presentation";
import Pagination from "../../components/pagination";
import Loader from "../../constants/Loader";
import { removeFromStorage } from "../../constants/storage";
import { STORAGE_KEYS } from "../../constants/storageKeys";
import useTranslation from "../../hooks/Translation";
import PresentationFolder from "../../components/presentationFolder";
import { DeleteModal } from "../../components/deleteModal";
import { RenameModal } from "../../components/renameModal";
import {
  useDeleteFileMutation,
  useEditFileMutation,
} from "../../services/fileSummarisation";

function Presentations() {
  type Presentation = {
    id: number;
    file_name: string;
    created_at: string;
    updated_at: string;
  };
  const translate = useTranslation();
  const navigate = useNavigate();
  const user = useAuth();
  const goProfile = () => {
    navigate("/setting");
  };
  const [open1, setOpen1] = React.useState(false);
  const handleOpen1 = () => setOpen1(true);
  const handleClose1 = () => setOpen1(false);
  const [sidebar, setSidebar] = useState(true);
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [page, setPage] = useState(1);
  const [presentation, setPresentation] = useState<Presentation[]>([]);
  const totalPages = Math.ceil(totalCount / 20);
  const hasMore = page <= totalPages;
  const pageRef = useRef(page);
  const onPageChange = (newPage: number) => {
    setPage(newPage);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const [getPresentation] = useGetPresentationMutation();
  const getPresentationList = useCallback(
    async (page: number) => {
      setIsLoading(true);
      try {
        const body = {
          start: page,
          length: 20,
          search: "",
        };
        const encryptedBody = generateEncryptedKeyBody(body) as CommonBody;
        const response = await getPresentation(encryptedBody).unwrap();
        if (response?.status === 200) {
          setPresentation(response?.data?.response_object);
          setTotalCount(response?.data?.total_records);
        } else {
          setPresentation([]);
        }
      } catch (error: any) {
        showError(
          error?.data?.message || translate.errors.somethingWentWrong || ""
        );
      }
      setIsLoading(false);
    },
    [getPresentation]
  );

  useEffect(() => {
    getPresentationList(page);
  }, [page]);
  useEffect(() => {
    removeFromStorage(STORAGE_KEYS.application);
  }, []);
  const [renameValue, setRenameValue] = useState("");
  const [openDelete, setOpenDelete] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [deleteData] = useDeletePresentationMutation();
  const [editData] = useEditPresentationMutation();
  const [selectedId, setSelectedId] = useState(0);
  const toggleDelete = (id: number) => {
    setOpenDelete(true);
    setSelectedId(id);
  };
  const handleDelete = async (e: any) => {
    e.stopPropagation();
    try {
      const response = await deleteData({ id: selectedId }).unwrap();
      if (response.status == 200) {
        showToast(
          translate.toastMessages.presentationDeletedSuccessfully ||
            "Presentation Deleted Successfully"
        );
        setOpenDelete(false);
      }
    } catch (error: any) {
      console.log(error);
      showError(
        error?.data?.message ||
          translate.errors.somethingWentWrong ||
          "Something went wrong"
      );
    } finally {
      await getPresentationList(page);
    }
  };
  const toggleEdit = (id: number, text: any) => {
    setOpenEdit(true);
    setRenameValue(text);
    setSelectedId(id);
  };
  const handleEdit = async (e: any) => {
    e.stopPropagation();
    try {
      const response = await editData({
        id: selectedId,
        body: { file_name: renameValue },
      }).unwrap();
      if (response.status == 200) {
        showToast(
          translate.toastMessages.presentationNameUpdatedSuccessfully ||
            "Presentation name updated Successfully"
        );
        setOpenEdit(false);
        setRenameValue("");
      }
    } catch (error: any) {
      console.log(error);
      showError(
        error?.data?.message ||
          translate.errors.somethingWentWrong ||
          "Something went wrong"
      );
    } finally {
      await getPresentationList(page);
    }
  };
  return (
    <>
      <Loader isLoad={isLoading} />
      <div className="sde_br_mn">
        {/* <div className="sde_hdr">
          <div className="sde_hdr_lt">
            <figure>
              <img src="/static/images/presentations.png" alt=""></img>
            </figure>
            <div className="">
              <h3>Presentations</h3>
              <p> Lorem Ipsum dolor et amet</p>
            </div>
          </div>
          <div className="sde_hdr_rt">
            <figure onClick={() => setOpen1(true)}>
              <img src="/static/images/notification.png" alt=""></img>
            </figure>
            <h3>Hi, Ahmed</h3>
            <figure onClick={goProfile}>
              <img src="/static/images/ahmed.png" alt=""></img>
            </figure>
          </div>
        </div> */}
        <div className="sde_mn_cnt">
          <button
            className="btnn btn_border pres_add"
            onClick={() => navigate("/creatpresentations")}
          >
            <i className="fa-regular fa-plus"></i>{" "}
            {translate.presentation.createNewPresentation ||
              "Create a new Presentation"}
          </button>
          <Tabs>
            <TabList>
              <Tab style={{ cursor: "context-menu" }}>
                {translate.presentation.myPresentations || "My Presentations"}
              </Tab>
            </TabList>

            <TabPanel>
              <ul className="tst_lst prsntsn_lst">
                {presentation?.length ? (
                  presentation?.map((data: Presentation, index: number) => {
                    return (
                      // <li
                      //   style={{ cursor: "pointer" }}
                      //   onClick={() =>
                      //     navigate(`/presentation-edit/${data?.id}`)
                      //   }
                      // >
                      //   <h3>
                      //     {translate.presentation.presentation ||
                      //       "Presentation"}{" "}
                      //     {(page - 1) * 10 + index + 1}
                      //   </h3>
                      //   <p className="mt_40">
                      //     {data?.created_at?.slice(0, 10)}
                      //   </p>
                      // </li>
                      <PresentationFolder
                        data={data}
                        index={index}
                        handleDelete={(e: any) => {
                          e.stopPropagation();
                          toggleDelete(data?.id);
                        }}
                        handleEdit={(e: any) => {
                          e.stopPropagation();
                          toggleEdit(data?.id, data?.file_name || "");
                        }}
                      />
                    );
                  })
                ) : (
                  <div
                    style={{
                      height: "85px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      width: "100%",
                      marginTop: "15%",
                    }}
                  >
                    {" "}
                    <h3 style={{ fontSize: "xxx-large", color: "darkgrey" }}>
                      {translate.global.noRecordsFound || "No Records Found"}
                    </h3>
                  </div>
                )}
              </ul>
            </TabPanel>
          </Tabs>
        </div>
      </div>
      <Pagination
        module={presentation}
        onPageChange={onPageChange}
        page={page}
        totalPages={totalPages}
      />
      <Notification
        open={open1}
        setOpen={setOpen1}
        handleClose={handleClose1}
      />
      <DeleteModal
        handleDelete={handleDelete}
        openDelete={openDelete}
        setOpenDelete={setOpenDelete}
        textData={translate.presentation.presentation || "presentation"}
      />
      <RenameModal
        handleEdit={handleEdit}
        openEdit={openEdit}
        renameValue={renameValue}
        setOpenEdit={setOpenEdit}
        setRenameValue={setRenameValue}
        textData={translate.presentation.presentation || "presentation"}
      />
    </>
  );
}

export default Presentations;
