import React, {
  ChangeEvent,
  MouseEventHandler,
  useEffect,
  useRef,
  useState,
} from "react";
import PptEditor from "../../components/Fabric";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { useWindowSize } from "@uidotdev/usehooks";
import { useDetectClickOutside } from "react-detect-click-outside";
import * as pdfjsLib from "pdfjs-dist";
import moment from "moment";
import {
  Button,
  Card,
  Dialog,
  DialogContent,
  Drawer,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  Menu,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { jsPDF } from "jspdf";
import PresentationSlider4 from "../presentationedit/presentationSlider4";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import * as fabric from "fabric";
import { showError, showToast } from "../../constants/toast";
import {
  useEditPresentationByIdMutation,
  useLazyGetPresentationByIdQuery,
} from "../../services/presentation";
import { presentationTemplate } from "../../constants/presentationTemplate";
import {
  Add,
  AddPhotoAlternateOutlined,
  AllInbox,
  ArrowDownward,
  ArrowUpward,
  Cancel,
  ChangeHistory,
  Check,
  CircleOutlined,
  Clear,
  ClearAllOutlined,
  Close,
  ContentCopyOutlined,
  ContentPasteOutlined,
  Create,
  Crop,
  Crop169,
  CropFree,
  Delete,
  DoNotTouch,
  DoNotTouchRounded,
  Draw,
  EditRoad,
  Favorite,
  FilterBAndW,
  FormatAlignCenterOutlined,
  FormatAlignLeftOutlined,
  FormatAlignRightOutlined,
  FormatBoldOutlined,
  FormatItalicOutlined,
  FormatUnderlinedOutlined,
  MoreVert,
  Pages,
  PanToolRounded,
  PanToolSharp,
  Polyline,
  PostAddRounded,
  Redo,
  RedoSharp,
  Scale,
  Search,
  Square,
  SquareFootOutlined,
  SquareOutlined,
  StickyNote2Sharp,
  Straighten,
  TextDecreaseOutlined,
  TextIncreaseOutlined,
  Timelapse,
  Undo,
  UndoSharp,
  VerticalSplit,
} from "@mui/icons-material";
import { CommonBody } from "../../types/General";
import { generateEncryptedKeyBody } from "../../utils/crypto";
import Hammer from "hammerjs";
import Loader from "../../constants/Loader";
import {
  useAddNoteTakingMutation,
  useEditNoteTakingByIdMutation,
  useGetNoteTakingMutation,
  useLazyGetNoteTakingByIdQuery,
} from "../../services/noteTaking";
import { setToStorage } from "../../constants/storage";
import { EraserBrush } from "@erase2d/fabric";
import { Canvas } from "fabric/fabric-impl";
import { UploadMedia, UploadTextData } from "../../utils/mediaUpload";
import useDetectKeyboardOpen from "use-detect-keyboard-open";
import {
  compressData,
  decompressData,
  GetTextData,
  insertAt,
  removeFromIndex,
} from "../../utils/decompressData";
import imageCompression from "browser-image-compression";
import { noteTemplate } from "../../constants/noteTakingTemplate";
import StickerPicker from "../../components/stickerPicker";
import useTranslation from "../../hooks/Translation";
import CanvasHistory from "../../components/history";
import { replace } from "formik";
import { RenameModal } from "../../components/renameModal";
import Ruler from "../../components/Ruler";
// import initCrop from "fabric-crop"

type Anchor = "right";
const CANVAS_WIDTH = 490;
const CANVAS_HEIGHT = 820;

// const CANVAS_WIDTH = 1366;
// const CANVAS_HEIGHT = 2323;
let lastSelectedButton = 1;
let isSomethingCopied = false;
let selectedTemplateID = 0;
// let selectedSlideIndex = 0;
let cropRect: fabric.Rect | null = null;
let enterCount = 0;
let undoCount = 0;
let redoCount = 0;
let allowScrolling = true;
let isRedoing = false;
let h: any = [];
// let isCropping=false;
let currentImage: any = null;
let currentImage2: any = null;
let isHighlight = false;
let debounce: any = null;
let canvasHeightArray: any = [];
let canvasSelectedTemplateId = [];
let disableScrollUp = false;
let disableOutisdeClickFunction = false;
// let binaryDataArray:any=[]
export let stopReading = false;
pdfjsLib.GlobalWorkerOptions.workerSrc = `https://unpkg.com/pdfjs-dist@${pdfjsLib.version}/build/pdf.worker.mjs`;

const NoteTakingEditFirst = () => {
  const navigate = useNavigate();
  const translate = useTranslation();
  const [age, setAge] = React.useState("");
  const handleChange = (event: SelectChangeEvent) => {
    setAge(event.target.value as string);
  };
  const { id } = useParams();
  const [drawingMode, setDrawingMode] = useState(false);
  const [selectedSlideIndex, setSelectedSlideIndex] = useState(0);
  const [highlightMode, setHighlightMode] = useState(false);
  const [stickerOpen, setStickerOpen] = useState(false);
  const [renameOpen, setRenameOpen] = useState(false);
  const [selectedButton, setSelectedButton] = useState(0);
  const [fakeSelectedButton, setFakeSelectedButton] = useState(1);
  const [highlightColor, setHighlightColor] = useState("#FFFF00");
  const changeHighlightColor = (e: any) => {
    canvas.freeDrawingBrush.color = e.target.value + "40";
    setHighlightColor(e.target.value);
  };
  const windowSize = useWindowSize();
  const isKeyboardOpen = useDetectKeyboardOpen();
  useEffect(() => {
    console.log("KEYBOARD " + isKeyboardOpen);

    if (!isKeyboardOpen) {
      if (canvas?.getActiveObject()?.type == "textbox") {
        canvas.discardActiveObject();
      }
    }
  }, [isKeyboardOpen]);

  const handleBeforeUnload = (e: any) => {
    e.preventDefault();
    const message =
      translate.main.editedDataWillLost ||
      "Are you sure you want to leave? Edited data will be lost.";
    e.returnValue = message;
    return message;
  };
  useEffect(() => {
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => window.removeEventListener("beforeunload", handleBeforeUnload);
  }, []);
  const [togglable, setTogglable] = useState(false);
  const [editModeHide, setEditModeHide] = useState(false);
  useEffect(() => {
    console.log("MM", windowSize);
    if (!windowSize?.width) return;
    if (windowSize.width < 768) {
      setSelectedButton(0);
      setEditModeHide(false);
      // setTogglable(true);
    } else {
      setEditModeHide(true);

      // setTogglable(false);
    }
  }, [windowSize]);
  const [drawerState, setDrawerState] = React.useState(false);
  const [themeDrawer, setThemeDrawer] = React.useState(false);
  const [pageDrawer, setPageDrawer] = React.useState(false);
  const [multiPageDrawer, setMultiPageDrawer] = React.useState(false);
  const [binaryDataArray, setBinaryDataArray] = useState<any>([]);
  const [binaryDataUpdated, setBinaryDataUpdated] = useState(false);
  console.log("binaryDataArray :", binaryDataArray);
  const [loadIndex, setLoadIndex] = useState(-1);
  const [canvas, setCanvas] = useState<any>(null);
  const [slides, setSlides] = useState<any>([]);
  const [noteTaking, setNoteTaking] = useState<any[]>([]);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [eraseMode, setEraseMode] = useState(false);
  const [dotted, setDotted] = useState(false);
  const [selectedColor, setSelectedColor] = useState("#000000");
  const [selectedFontSize, setSelectedFontSize] = useState(36);
  const [selectedFontFace, setSelectedFontFace] = useState("Arial");
  const [slideList, setSlideList] = useState<any>([]);
  const [slideListData, setSlideListData] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);
  // console.log('slideListData :', slideListData);
  const [backgroundImage, setBackgroundImage] = useState("");
  const [copiedObject, setCopiedObject] = useState<any>(null);
  const { state } = useLocation();
  // const xyz=()=>()=>{
  // const [currentImage, setCurrentImage] = useState<any>(null);
  const [selectionRect, setSelectionRect] = useState<any>(null);
  const [isCropping, setIsCropping] = useState(false);
  const [isSaved, setIsSaved] = useState(false);
  // }
  const [canvasHeight, setCanvasHeight] = useState(CANVAS_HEIGHT);
  console.log("canvasHeight :", canvasHeight);
  console.log("selectedSlideIndex :", selectedSlideIndex);

  const [getNoteTaking] = useGetNoteTakingMutation();
  const [page, setPage] = useState(1);
  const [debouncedTerm, setDebouncedTerm] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedRecentNote, setSelectedRecentNote] = useState(0);
  const [hasMore, setHasMore] = useState(false);
  const getNoteTakingList = async (selectedRecentNote?: number) => {
    setIsLoading(true);
    try {
      let body = {};
      if (selectedRecentNote) {
        body = {
          start: page,
          length: 20,
          search: searchQuery || "",
          filter: selectedRecentNote,
          type: 1,
        };
      } else {
        body = {
          start: page,
          length: 20,
          search: searchQuery || "",
          type: 1,
        };
      }
      console.log("body :", body);
      const encryptedBody = generateEncryptedKeyBody(body) as CommonBody;
      const response = await getNoteTaking(encryptedBody).unwrap();
      if (response?.status === 200) {
        if (page < response?.data?.total_records / 20) {
          setHasMore(true);
        } else {
          setHasMore(false);
        }
        setNoteTaking((prev) => [...prev, ...response?.data?.response_object]);
      } else {
        setNoteTaking([]);
      }
    } catch (error: any) {
      showError(
        error?.data?.message || translate.errors.somethingWentWrong || ""
      );
    }
    setIsLoading(false);
  };
  useEffect(() => {
    if (pageDrawer) getNoteTakingList(selectedRecentNote);
    else {
      setSearchQuery("");
      setPage(1);
      setSelectedRecentNote(0);
      setNoteTaking([]);
    }
  }, [pageDrawer, page, selectedRecentNote, searchQuery]);
  useEffect(() => {
    setNoteTaking([]);
  }, [selectedRecentNote, searchQuery]);
  const createMaskForCrop = () => {
    const active = canvas.getActiveObject();
    active.set({
      angle: 0,
    });
    canvas.remove(active);
    canvas.add(active);
    currentImage = active;
    // setCurrentImage(active);
    // isCropping=true;
    setIsCropping(true);
    const rect = new fabric.Rect({
      fill: "rgba(0,0,0,0.3)",
      originX: "left",
      originY: "top",
      stroke: "black",
      opacity: 1,
      width: active.getScaledWidth(),
      height: active.getScaledHeight(),
      left: active.left,
      top: active.top,
      flipX: false,
      flipY: false,
      lockRotation: true,
      lockScalingFlip: true,
      hasRotatingPoint: false,
      transparentCorners: false,
      cornerColor: "white",
      cornerStrokeColor: "black",
      borderColor: "black",
      cornerSize: 12,
      padding: 0,
      cornerStyle: "circle",
      // lockMovementX:true,
      // lockMovementY:true,
      borderDashArray: [5, 5],
      borderScaleFactor: 1.3,
      selectable: false,
      minX: active.left,
      minY: active.top,
      maxX: active.left + active.getScaledWidth(),
      maxY: active.top + active.getScaledHeight(),
    });

    // canvas.containSelection = true;
    // rect.scaleToWidth(300);
    // canvas.centerObject(rect);
    canvas.add(rect);
    setSelectionRect(rect);
    canvas.setActiveObject(rect);
    rect?.on("deselected", () => {
      console.log("REACT IS BEAST");
      canvas.remove(rect);
      // isCropping=false;
      disableScrollUp = false;
      stopReading = false;
      setIsCropping(false);
    });
    canvas.requestRenderAll();
  };
  const crop = () => {
    const rect = new fabric.Rect({
      left: selectionRect.left,
      top: selectionRect.top,
      width: selectionRect.getScaledWidth(),
      height: selectionRect.getScaledHeight(),
      absolutePositioned: true,
    });

    currentImage.clipPath = rect;

    canvas.remove(selectionRect);

    const cropped = new Image();
    cropped.src = canvas.toDataURL({
      left: rect.left,
      top: rect.top,
      width: rect.width,
      height: rect.height,
      multiplier: 1,
    });

    cropped.onload = function () {
      // canvas.clear();
      const image = new fabric.Image(cropped, {
        left: rect.left,
        top: rect.top,
      });
      canvas.remove(currentImage);
      stopReading = false;
      canvas.add(image);
      canvas.requestRenderAll();
      // setCurrentImage(null)
      currentImage = null;
      setIsCropping(false);
      // isCropping=false;
    };
  };
  const [anchorElTemplate, setAnchorElTemplate] =
    React.useState<null | HTMLElement>(null);
  const openTemplate = Boolean(anchorElTemplate);
  const handleClickTemplate = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElTemplate(event.currentTarget);
  };
  const handleCloseTemplate = () => {
    setAnchorElTemplate(null);
  };
  const cancelCrop = () => {
    if (currentImage) {
      currentImage = null;
      disableScrollUp = false;
      stopReading = false;
      setIsCropping(false);
      canvas.remove(canvas.getActiveObject());
    }
  };
  const handleStopDrawing = () => {
    setDrawingMode(false);
    setHighlightMode(false);
    canvas.isDrawingMode = false;
  };

  const handleCropMohit = () => {
    const active = canvas.getActiveObject();
    if (active && active.type === "image") {
      // initCrop(active,canvas);
      disableScrollUp = true;
      stopReading = true;
      createMaskForCrop();
    } else {
      showError(
        translate.presentation.selectImageToCrop || "Select image to crop"
      );
      return;
    }
  };
  const handleDeleteFromKey = (canvas: any) => {
    const active = canvas.getActiveObject();
    if (active?.type === "textbox") {
      // if (window.getSelection()?.toString()) {
      //   let temp = active.text;
      //   console.log("BEFORE TEMP", temp);

      //   temp = temp.replaceAll(window.getSelection()?.toString(), "");
      //   active.text = temp; // Update the internal buffer
      //   active.initTextContent(); // Reset the text content
      //   active.set({
      //     text: temp
      //   });

      //   console.log("AFTER TEMP", temp);
      //   canvas.renderAll();
      // } else {
      // showError("Select something to delete");
      // }
      return;
    } else {
      if (active) {
        canvas.remove(active);
      }
    }
  };
  const handleDelete = () => {
    const active = canvas?.getActiveObject();
    if (active?.type == "textbox") {
      if (window?.getSelection()?.toString()) {
        let temp = active?.text;
        console.log("BEFORE TEMP", temp);

        temp = temp.replaceAll(window.getSelection()?.toString(), "");
        // active.setText(temp)
        // active.text="jlsajd"
        active.text = temp;
        active.set({
          text: temp,
        });
        active.dirty = true;
        console.log("AFTER TEMP", temp);
        canvas.discardActiveObject();
        canvas.renderAll();
        canvas.requestRenderAll();
        canvas.setActiveObject(active);
      } else {
        showError(
          translate.presentation.selectSomethingToDelete ||
            "Select object to delete"
        );
      }
      return;
    }
    console.log(active);

    if (active) {
      canvas.remove(active);
    } else {
      showError(
        translate.presentation.selectSomethingToDelete ||
          "Select object to delete"
      );
    }
  };
  const [searchParam, setSearchParam] = useSearchParams();

  const handleFontChange = (event: SelectChangeEvent) => {
    const obj = canvas.getActiveObject();
    if (obj) {
      if (obj.type === "i-text") {
        const text = obj?.text;
        const selectionStart = obj.selectionStart;
        const selectionEnd = obj.selectionEnd;
        const selectedText = text.substring(selectionStart, selectionEnd);
        console.log("selectedText :", selectedText);
        if (selectedText?.length) {
          obj.setSelectionStyles({
            fontSize: Number(event.target.value),
          });
        } else {
          for (let i = 0; i < text.length; i++) {
            obj.setSelectionStyles(
              {
                fontSize: Number(event.target.value),
              },
              i,
              i + 1
            );
          }

          // obj.fontSize = Number(event.target.value); // set font
        }
        obj.dirty = true;
        canvas.renderAll();
      }
    }
    setSelectedFontSize(Number(event.target.value));
  };
  const handleFontFaceChange = (event: SelectChangeEvent) => {
    const obj = canvas.getActiveObject();
    console.log("obj21 :", obj);
    console.log(" obj23:", event.target.value);
    if (obj) {
      if (obj.type === "i-text" || obj.type === "textbox") {
        const text = obj.text;
        const selectionStart = obj.selectionStart;
        const selectionEnd = obj.selectionEnd;
        const selectedText = text.substring(selectionStart, selectionEnd);
        console.log("selectedText :", selectedText);
        if (selectedText.length) {
          obj.setSelectionStyles({
            fontFamily: event.target.value,
          });
        } else {
          for (let i = 0; i < text.length; i++) {
            obj.setSelectionStyles(
              {
                fontFamily: event.target.value,
              },
              i,
              i + 1
            );
          }
        }
        // obj.fontFace = event.target.value; // set font face
        obj.dirty = true;
        canvas.renderAll();
      }
    }
    setSelectedFontFace(event.target.value);
  };
  const handleAddRichText = () => {
    let stringData = "Sample Text Data";
    const text = new fabric.IText(stringData, {
      left: 50,
      top: 50,
      fontSize: selectedFontSize || 28,
      fontFamily: "Helvetica",
      fill: selectedColor || "#000000",
      lockScalingX: true,
      width: CANVAS_WIDTH,
      cornerColor: "#8A5FF7",
      cornerStrokeColor: "#8A5FF7",
      lockScalingY: true,
      hasControls: false,
    });
    canvas.add(text);
    canvas.setActiveObject(text);
  };
  const handleStartDrawing = () => {
    setDrawingMode(true);
    setHighlightMode(false);
    setDotted(false);
    setEraseMode(false);
    canvas.isDrawingMode = true;
    canvas.freeDrawingBrush = new fabric.PencilBrush(canvas);
    canvas.freeDrawingBrush.width = penSize || 8;
    canvas.freeDrawingBrush.strokeDashArray = null;
    canvas.freeDrawingBrush.color = selectedColor || "#000000";
  };
  const handleStartDrawingDotted = () => {
    setDrawingMode(true);
    setEraseMode(false);
    setDotted(true);
    canvas.freeDrawingBrush = new fabric.PencilBrush(canvas);
    canvas.freeDrawingBrush.width = penSize || 8;
    canvas.freeDrawingBrush.color = selectedColor || "#000000";
    canvas.freeDrawingBrush.strokeDashArray = [penSize, penSize * 3];
    canvas.isDrawingMode = true;
  };
  const handleAddFilter = (index?: number) => {
    if (canvas?.getActiveObject()?.type !== "image") {
      setAnchorEl1(null);
      showError("Filters can be applied to images only");
      return;
    }
    const imageToFilter = canvas?.getActiveObject();
    imageToFilter.filters.length = 0;
    switch (index) {
      case 1:
        const kodachrome = new fabric.filters.Kodachrome();
        imageToFilter.filters.push(kodachrome);
        break;
      case 2:
        const brownie = new fabric.filters.Brownie();
        imageToFilter.filters.push(brownie);
        break;
      case 3:
        const grayscale = new fabric.filters.Grayscale();
        imageToFilter.filters.push(grayscale);
        break;
      case 4:
        const invert = new fabric.filters.Invert();
        imageToFilter.filters.push(invert);
        break;
      case 5:
        const sepia = new fabric.filters.Sepia();
        imageToFilter.filters.push(sepia);
        break;
      case 6:
        const brightness = new fabric.filters.Brightness({ brightness: 0.1 });
        imageToFilter.filters.push(brightness);
        break;
      case 7:
        const contrast = new fabric.filters.Contrast({ contrast: 0.2 });
        imageToFilter.filters.push(contrast);
        break;
      case 8:
        const blur = new fabric.filters.Blur({ blur: 0.3 });
        imageToFilter.filters.push(blur);
        break;
      case 9:
        const pixelate = new fabric.filters.Pixelate({ blocksize: 8 });
        imageToFilter.filters.push(pixelate);
        break;
      case 10:
        const techniColor = new fabric.filters.Technicolor();
        imageToFilter.filters.push(techniColor);
        break;
      case 11:
        const polaroid = new fabric.filters.Polaroid();
        imageToFilter.filters.push(polaroid);
        break;
      case 12:
        const vintage = new fabric.filters.Vintage();
        imageToFilter.filters.push(vintage);
        break;
    }
    setAnchorEl1(null);
    imageToFilter.applyFilters();
    canvas.renderAll();
  };
  const handleStartDrawingTape = (tape: number) => {
    if (!drawingMode) return;
    canvas.freeDrawingBrush = new fabric.PatternBrush(canvas);
    // Enable free drawing mode
    switch (tape) {
      case 1:
        canvas.freeDrawingBrush.getPatternSrc = function () {
          var squareWidth = 10,
            squareDistance = 2;
          var patternCanvas = document.createElement("canvas"); // No need for fabric.document
          patternCanvas.width = patternCanvas.height =
            squareWidth + squareDistance;
          var ctx: any = patternCanvas.getContext("2d");
          var backgroundColor = "#f0f0f0"; // Replace with the desired background color
          ctx.fillStyle = backgroundColor;
          ctx.fillRect(0, 0, patternCanvas.width, patternCanvas.height); // Fill the entire canvas with the background color

          // Draw the pattern (squares) on top of the background
          ctx.fillStyle = this.color;
          ctx.fillRect(0, 0, squareWidth, squareWidth);

          return patternCanvas;
        };
        break;
      case 2:
        canvas.freeDrawingBrush.getPatternSrc = function () {
          var squareWidth = 10,
            squareDistance = 2;
          var patternCanvas = document.createElement("canvas"); // No need for fabric.document
          patternCanvas.width = patternCanvas.height =
            squareWidth + squareDistance;
          patternCanvas.width = patternCanvas.height = 10;

          var ctx: any = patternCanvas.getContext("2d");
          var backgroundColor = "#f0f0f0"; // Replace with the desired background color
          ctx.fillStyle = backgroundColor;
          ctx.fillRect(0, 0, patternCanvas.width, patternCanvas.height); // Fill the entire canvas with the background color

          // Draw the pattern (squares) on top of the background
          ctx.fillStyle = this.color;
          ctx.strokeStyle = this.color;
          ctx.lineWidth = 5;
          ctx.beginPath();
          ctx.moveTo(0, 5);
          ctx.lineTo(10, 5);
          ctx.closePath();
          ctx.stroke();
          return patternCanvas;
        };
        break;
      case 3:
        canvas.freeDrawingBrush.getPatternSrc = function () {
          var squareWidth = 10,
            squareDistance = 2;
          var patternCanvas = document.createElement("canvas"); // No need for fabric.document
          patternCanvas.width = patternCanvas.height = 10;
          var ctx: any = patternCanvas.getContext("2d");
          var backgroundColor = "#f0f0f0"; // Replace with the desired background color
          ctx.fillStyle = backgroundColor;
          ctx.fillRect(0, 0, patternCanvas.width, patternCanvas.height); // Fill the entire canvas with the background color

          // Draw the pattern (squares) on top of the background
          ctx.fillStyle = this.color;
          ctx.strokeStyle = this.color;
          ctx.lineWidth = 5;
          ctx.beginPath();
          ctx.moveTo(5, 0);
          ctx.lineTo(5, 10);
          ctx.closePath();
          ctx.stroke();
          return patternCanvas;
        };

        break;
      case 4:
        canvas.freeDrawingBrush.getPatternSrc = function () {
          var patternCanvas = document.createElement("canvas");
          patternCanvas.width = patternCanvas.height = 20;
          var ctx: any = patternCanvas.getContext("2d");

          var backgroundColor = "#f0f0f0"; // Background color
          ctx.fillStyle = backgroundColor;
          ctx.fillRect(0, 0, patternCanvas.width, patternCanvas.height); // Fill background

          // Draw zig-zag lines
          ctx.strokeStyle = this.color;
          ctx.lineWidth = 2;
          ctx.beginPath();
          ctx.moveTo(0, 10);
          ctx.lineTo(5, 5);
          ctx.lineTo(10, 15);
          ctx.lineTo(15, 5);
          ctx.lineTo(20, 10);
          ctx.stroke();

          return patternCanvas;
        };
        break;
      case 5:
        canvas.freeDrawingBrush.getPatternSrc = function () {
          var patternCanvas = document.createElement("canvas");
          patternCanvas.width = patternCanvas.height = 20;
          var ctx: any = patternCanvas.getContext("2d");

          var backgroundColor = "#f0f0f0"; // Background color
          ctx.fillStyle = backgroundColor;
          ctx.fillRect(0, 0, patternCanvas.width, patternCanvas.height); // Fill background

          // Draw horizontal lines
          ctx.strokeStyle = this.color;
          ctx.lineWidth = 2;
          ctx.beginPath();
          ctx.moveTo(0, 5);
          ctx.lineTo(20, 5);
          ctx.moveTo(0, 15);
          ctx.lineTo(20, 15);
          ctx.stroke();

          // Draw vertical lines
          ctx.beginPath();
          ctx.moveTo(5, 0);
          ctx.lineTo(5, 20);
          ctx.moveTo(15, 0);
          ctx.lineTo(15, 20);
          ctx.stroke();

          return patternCanvas;
        };
        break;
      case 6:
        canvas.freeDrawingBrush.getPatternSrc = function () {
          var patternCanvas = document.createElement("canvas");
          patternCanvas.width = patternCanvas.height = 20;
          var ctx: any = patternCanvas.getContext("2d");

          var backgroundColor = "#f0f0f0"; // Background color
          ctx.fillStyle = backgroundColor;
          ctx.fillRect(0, 0, patternCanvas.width, patternCanvas.height); // Fill background

          // Draw triangles
          ctx.fillStyle = this.color;
          ctx.beginPath();
          ctx.moveTo(10, 0);
          ctx.lineTo(0, 20);
          ctx.lineTo(20, 20);
          ctx.closePath();
          ctx.fill();

          return patternCanvas;
        };
        break;
      default:
        return;
    }

    canvas.isDrawingMode = true;
    handleClose0();
    canvas.freeDrawingBrush.width = 30;
    canvas.freeDrawingBrush.color = "#d09af4";
  };
  const handleStartEraser = () => {
    handleStopDrawing();
    setDrawingMode(true);
    setEraseMode(true);
    setDotted(false);
    canvas.isDrawingMode = true;
    const eraser = new EraserBrush(canvas);
    canvas.freeDrawingBrush = eraser;
    canvas.erasedObject = true;
    eraser.on("end", (e) => {
      // prevent from committing erasing to the tree
      e.preventDefault();
      // commit erasing manually
      eraser.commit(e.detail);
    });
    // canvas.freeDrawingBrush = new fabric.PencilBrush(canvas);
    canvas.freeDrawingBrush.width = penSize || 10;
    // canvas.freeDrawingBrush.color = '#fff'
  };
  const handleColorChange = (color: any) => {
    console.log("cooler :", color);
    if (canvas.freeDrawingBrush)
      canvas.freeDrawingBrush.color = eraseMode ? "#fff" : color;
    // currentColor = color; // Update the current color
    setSelectedColor(color);
    const activeObject = canvas.getActiveObject();
    if (activeObject) {
      if (activeObject.type === "i-text" || activeObject.type === "textbox") {
        const text = activeObject.text;
        const selectionStart = activeObject.selectionStart;
        const selectionEnd = activeObject.selectionEnd;
        const selectedText = text.substring(selectionStart, selectionEnd);
        console.log("selectedText :", selectedText);
        if (selectedText.length) {
          activeObject.setSelectionStyles({
            fill: color,
          });
        } else {
          for (let i = 0; i < text.length; i++) {
            activeObject.setSelectionStyles(
              {
                fill: color,
              },
              i,
              i + 1
            );
          }
        }
        // activeObject.fontFace = event.target.value; // set font face
      } else if (activeObject?.type == "path")
        activeObject.set("stroke", color);
      else activeObject.set("fill", color);
      activeObject.dirty = true;
      canvas.requestRenderAll();
    }
  };
  const handleAddShape = () => {
    const rect = new fabric.Rect({
      width: 100,
      height: 100,
      // top:window.scrollY,
      fill: selectedColor || "#d00661",
      cornerColor: "#8A5FF7",
      cornerStrokeColor: "#8A5FF7",
    });
    canvas.add(rect);
    canvas.setActiveObject(rect);
  };
  const [cropCanvas, setCropCanvas] = useState<any>(null);
  console.log("croppedImage :", cropCanvas);

  const handleCropCanvasSquare = () => {
    const rect = new fabric.Rect({
      width: 100,
      height: 100,
      top: 50,
      left: 50,
      lockScalingFlip: true,
      cornerColor: "#8A5FF7",
      fill: "rgba(0,0,0,0)",
      borderColor: "black",
      // borderDashArray: [30, 10],
    });
    rect.on("deselected", () => {
      setCropCanvas(null);
      currentImage2 = null;
      canvas.remove(rect);
    });
    currentImage2 = "mohit";
    canvas.add(rect);
    setCropCanvas(rect);
    canvas.setActiveObject(rect);
    canvas.requestRenderAll();
  };

  const handleCropCanvasPaste = () => {
    if (cropCanvas) {
      disableScrollUp = true;
      const cropped = new Image();
      cropped.src = canvas.toDataURL({
        left: cropCanvas.left,
        top: cropCanvas.top,
        width: cropCanvas.getScaledWidth(),
        height: cropCanvas.getScaledHeight(),
        multiplier: 1,
      });

      cropped.onload = function () {
        const image = new fabric.Image(cropped, {
          left: cropCanvas.left + 20,
          top: cropCanvas.top + 20,
          borderColor: "black",
          cornerColor: "black",
        });
        canvas.remove(currentImage);
        canvas.add(image);
        canvas.setActiveObject(image);
        canvas.requestRenderAll();
        disableScrollUp = false;
      };
    }
  };
  const handleAddCircle = () => {
    const circle = new fabric.Circle({
      radius: 50,
      fill: selectedColor || "#d00661",
      cornerColor: "#8A5FF7",
      cornerStrokeColor: "#8A5FF7",
      // erasable:true
    });
    canvas.add(circle);
    canvas.setActiveObject(circle);
  };

  const handleAddTriangle = () => {
    const triangle = new fabric.Triangle({
      width: 100,
      height: 100,
      cornerColor: "#8A5FF7",
      cornerStrokeColor: "#8A5FF7",
      fill: selectedColor || "#d00661",
    });
    canvas.add(triangle);
    canvas.setActiveObject(triangle);
  };

  const handleAlignLeft = () => {
    const activeObject = canvas.getActiveObject();
    if (
      activeObject &&
      (activeObject.type === "i-text" || activeObject.type === "textbox")
    ) {
      activeObject.set({ textAlign: "left" });
      canvas.renderAll();
    }
  };
  const fontFaces = [
    "Arial",
    "Calibri",
    "Cambria",
    "Courier New",
    "Garamond",
    "Georgia",
    "Helvetica",
    "Impact",
    "Lucida Console",
    "Lucida Sans Unicode",
    "Microsoft Sans Serif",
    "Monaco",
    "Open Sans",
    "Segoe UI",
    "Tahoma",
    "Times New Roman",
    "Verdana",
  ];
  const handleAlignCenter = () => {
    const activeObject = canvas.getActiveObject();
    if (
      activeObject &&
      (activeObject.type === "i-text" || activeObject.type === "textbox")
    ) {
      activeObject.set({ textAlign: "center" });
      canvas.renderAll();
    }
  };

  const handleAlignRight = () => {
    const activeObject = canvas.getActiveObject();
    if (
      activeObject &&
      (activeObject.type === "i-text" || activeObject.type === "textbox")
    ) {
      activeObject.set({ textAlign: "right" });
      canvas.renderAll();
    }
  };
  const handleUnderline = () => {
    const activeObject = canvas.getActiveObject();
    if (
      activeObject &&
      (activeObject.type === "i-text" || activeObject.type === "textbox")
    ) {
      console.log(activeObject.fontStyle);
      const text = activeObject.text;
      const selectionStart = activeObject.selectionStart;
      const selectionEnd = activeObject.selectionEnd;
      const selectedText = text.substring(selectionStart, selectionEnd);
      console.log("selectedText :", selectedText);
      // set the font weight of the selected activeObject to bold
      const underlineData = activeObject.getSelectionStyles(
        selectionStart,
        selectionEnd,
        true
      );
      console.log("underlineData :", underlineData);
      let isUnderline = false;
      if (selectedText?.length) {
        for (let i = 0; i < underlineData.length; i++) {
          if (underlineData[i].underline) {
            isUnderline = true;
            break;
          }
        }

        if (isUnderline) {
          activeObject.setSelectionStyles(
            {
              underline: false,
            },
            selectionStart,
            selectionEnd
          );
        } else {
          activeObject.setSelectionStyles(
            {
              underline: true,
            },
            selectionStart,
            selectionEnd
          );
        }
      }
      activeObject.dirty = true;
      // activeObject.set({ underline: !activeObject.underline });
      canvas.renderAll();
    }
  };

  const handleEraserSettings: any = () => {
    canvas.forEachObject(function (obj: any) {
      if (obj instanceof fabric.Path) {
        console.log("MG", obj);
        if (isHighlight) {
          if (obj.stroke?.toString().endsWith("40")) {
            obj.lockMovementX = true;
            obj.lockMovementY = true;
            obj.lockScalingX = true;
            obj.lockScalingY = true;
            obj.hasControls = false;
            obj.hoverCursor = "default";
            obj.selectable = false;
            obj.erasable = true;
            obj.evented = false;
          } else {
            obj.lockMovementX = true;
            obj.lockMovementY = true;
            obj.lockScalingX = true;
            obj.lockScalingY = true;
            obj.hasControls = false;
            obj.hoverCursor = "default";
            obj.selectable = false;
            obj.erasable = false;
            obj.evented = false;
          }
        } else {
          if (obj.stroke?.toString().endsWith("40")) {
            obj.lockMovementX = true;
            obj.lockMovementY = true;
            obj.lockScalingX = true;
            obj.lockScalingY = true;
            obj.hasControls = false;
            obj.hoverCursor = "default";
            obj.selectable = false;
            obj.erasable = false;
            obj.evented = false;
          } else {
            obj.lockMovementX = true;
            obj.lockMovementY = true;
            obj.lockScalingX = true;
            obj.lockScalingY = true;
            obj.hasControls = false;
            obj.hoverCursor = "default";
            obj.selectable = false;
            obj.erasable = true;
            obj.evented = false;
          }
        }
      }
    });
  };

  // const handleBold = () => {
  //   const activeObject = canvas.getActiveObject();
  //   if (activeObject && activeObject.type === 'i-text') {
  //     console.log(activeObject.fontStyle);
  //     let newStyle="";
  //     if(activeObject.fontStyle=="bold")
  //     {
  //       newStyle="normal"
  //     }
  //     else if(activeObject.fontStyle=="bold italic")
  //     {
  //       newStyle="italic"
  //     }
  //     else if(activeObject.fontStyle=="italic")
  //     {
  //          newStyle="bold italic"
  //     }
  //     else
  //     {
  //       newStyle="bold"
  //     }
  //     activeObject.set({
  //       fontStyle: newStyle,
  //     });
  //     canvas.renderAll();
  //   }
  // };
  // function downloadCanvasAsImage() {
  //   // Get the canvas data URL
  //   var dataURL = canvas.toDataURL({
  //     format:"png",
  //     quality:1,
  //   });

  //   // Create a link element
  //   var link = document.createElement('a');
  //   link.href = dataURL;
  //   link.download = 'canvas_image.png'; // Set the file name and extension

  //   // Simulate a click on the link to trigger the download
  //   link.click();
  // }
  console.log("1610", canvasHeight);
  const downloadPDF = async () => {
    setIsLoading(true);
    const doc = new jsPDF();

    // const ImageData = canvas.toDataURL({
    //   format: "png",
    //   quality: 1,
    // });
    for (let key = 0; key < slideList?.length; key++) {
      if (key !== 0) {
        doc.addPage("", "p");
      }
      const canvasWidth = canvas.getWidth(); // 480
      const canvasHeight = canvas.getHeight(); // 1280
      const pdfWidth = doc.internal.pageSize.getWidth();
      const pdfHeight = doc.internal.pageSize.getHeight();

      // Calculate the scaling factor to maintain aspect ratio
      const scaleFactor = Math.min(
        pdfWidth / canvasWidth,
        pdfHeight / canvasHeight
      );

      // Set the image dimensions with the scaling factor, rounded to nearest integer
      const imageWidth = Math.floor(canvasWidth * scaleFactor);
      const imageHeight = Math.floor(canvasHeight * scaleFactor);

      // Calculate the x and y coordinates to center the image
      const x = (pdfWidth - imageWidth) / 2;
      const y = (pdfHeight - imageHeight) / 2;
      canvas.requestRenderAll();
      doc.addImage(
        key == selectedSlideIndex
          ? await canvas.toDataURL({
              format: "png",
              quality: 1,
            })
          : slideList[key],
        "png",
        x,
        y,
        imageWidth,
        imageHeight
      );
    }
    // const image = new Image();
    // image.src = ImageData;
    // image.onload = async () => {
    // for (let key = 0; key < binaryDataArray.length; key++) {
    //   if (selectedSlideIndex == key) {
    //     finalDAta.push(await compressData(JSON.stringify(canvas.toJSON())));
    //   } else {
    //     finalDAta.push(await compressData(binaryDataArray[key]));
    //   }

    // doc.addImage(ImageData, "png", x, y, imageWidth, imageHeight);

    const data = doc.output("blob");
    const uploadPPT = await UploadMedia(data, "Note-taking.pdf");
    console.log("TEST", uploadPPT);
    // alert(uploadPPT?.data?.[0]?.media_url);
    // navigate(window.location.href);
    //@ts-ignore
    window["ReactNativeWebView"] &&
      //@ts-ignore
      window["ReactNativeWebView"].postMessage(uploadPPT?.data?.[0]?.media_url);
    setIsLoading(false);
    window.open(uploadPPT?.data?.[0]?.media_url);
    showToast(
      translate.noteTaking.noteDownloadedSuccessfully ||
        "Note Downloaded Successfully"
    );
    // console.log('test :', test);
    // doc.save("whizzo-note.pdf");
    // };
  };
  const [anchorEl0, setAnchorEl0] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl0);
  const handleClick0 = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl0(event.currentTarget);
  };
  const handleClose0 = () => {
    setAnchorEl0(null);
  };
  const [anchorEl1, setAnchorEl1] = React.useState<null | HTMLElement>(null);
  const open1 = Boolean(anchorEl1);
  const handleClick1 = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl1(event.currentTarget);
  };
  const handleClose1 = () => {
    setAnchorEl1(null);
  };
  const [penSize, setPenSize] = useState(3);
  const handleBold = async () => {
    const activeObject = canvas.getActiveObject();
    if (
      activeObject &&
      (activeObject.type === "i-text" || activeObject.type === "textbox")
    ) {
      console.log(activeObject.fontStyle);
      const text = activeObject.text;
      const selectionStart = activeObject.selectionStart;
      const selectionEnd = activeObject.selectionEnd;
      const selectedText = text.substring(selectionStart, selectionEnd);
      console.log("selectedText :", selectedText);
      // set the font weight of the selected activeObject to bold
      const boldData = activeObject.getSelectionStyles(
        selectionStart,
        selectionEnd,
        true
      );
      console.log("boldData :", boldData);
      let isBold = false;
      if (selectedText?.length) {
        for (let i = 0; i < boldData.length; i++) {
          if (boldData[i].fontWeight === "bold") {
            isBold = true;
            break;
          }
        }
        console.log("activeObject :", activeObject);

        if (isBold) {
          activeObject.setSelectionStyles(
            {
              fontWeight: "normal",
            },
            selectionStart,
            selectionEnd
          );
        } else {
          activeObject.setSelectionStyles(
            {
              fontWeight: "bold",
            },
            selectionStart,
            selectionEnd
          );
        }
      }
      console.log(
        "activeObject.getSelectionStyles(selectionStart, selectionEnd, true); :",
        activeObject.getSelectionStyles(selectionStart, selectionEnd, true)
      );
      // else
      // {
      //   activeObject.setSelectionStyles({
      //     fontWeight: activeObject.fontWeight?.includes('bold')?'normal':'bold'
      //   }, 0, text.length);
      //   // let newStyle="";
      //   //     if(activeObject.fontStyle=="bold")
      //   //     {
      //   //       newStyle="normal"
      //   //     }
      //   //     else if(activeObject.fontStyle=="bold italic")
      //   //     {
      //   //       newStyle="italic"
      //   //     }
      //   //     else if(activeObject.fontStyle=="italic")
      //   //     {
      //   //          newStyle="bold italic"
      //   //     }
      //   //     else
      //   //     {
      //   //       newStyle="bold"
      //   //     }
      //   //     activeObject.set({
      //   //       fontStyle: newStyle,
      //   //     });

      // }
      // render the canvas to apply the changes

      // canvas.remove(activeObject)
      // canvas.add(activeObject);
      // canvas.setActiveObject(activeObject)
      // setDrawerState(!drawerState)
      // activeObject.text += '\n'; // add a newline character
      //   activeObject.text = activeObject.text.replace(/\n$/, ''); // remove the newline character
      // activeObject.set('text', activeObject.text);
      activeObject.dirty = true;

      await canvas.requestRenderAll();
      // await canvas.renderAll();
      // activeObject.set('text', activeObject.text);
      // activeObject.dirty=true;
    }
  };
  async function undo() {
    console.log(`UNDO :
    undoCount=${undoCount}
    redoCount=${redoCount}`);

    if (undoCount > 0) {
      --undoCount;
      ++redoCount;
      h.push(await canvas._objects.pop());
      await canvas.renderAll();
    }
    // if(canvas._objects.length>0){
    //  h.push(canvas._objects.pop());
    //  await canvas.renderAll();
    // }
  }
  async function redo() {
    console.log(`REDO START :
    undoCount=${undoCount}
    redoCount=${redoCount}`);
    console.log("REDO ARRAY :", h);

    if (redoCount > 0) {
      --redoCount;
      // ++undoCount;
      isRedoing = true;
      await canvas.add(h.pop());
      await canvas.renderAll();
    }
    console.log(`REDO EXIT :
  undoCount=${undoCount}
  redoCount=${redoCount}`);
    // if(h.length>0){
    //   setIsRedoing(true)
    //  await canvas.add(h.pop());
    //  await canvas.renderAll();

    // }
  }
  const handleItalic = async () => {
    const activeObject = canvas.getActiveObject();
    if (
      activeObject &&
      (activeObject.type === "i-text" || activeObject.type === "textbox")
    ) {
      console.log(activeObject.fontStyle);
      const text = activeObject.text;
      const selectionStart = activeObject.selectionStart;
      const selectionEnd = activeObject.selectionEnd;
      const selectedText = text.substring(selectionStart, selectionEnd);
      console.log("selectedText :", selectedText);
      // set the font weight of the selected activeObject to bold
      const italicData = activeObject.getSelectionStyles(
        selectionStart,
        selectionEnd,
        true
      );
      console.log("italicData :", italicData);
      let isItalic = false;
      if (selectedText?.length) {
        for (let i = 0; i < italicData.length; i++) {
          if (italicData[i].fontStyle === "italic") {
            isItalic = true;
            break;
          }
        }

        if (isItalic) {
          activeObject.setSelectionStyles(
            {
              fontStyle: "normal",
            },
            selectionStart,
            selectionEnd
          );
        } else {
          activeObject.setSelectionStyles(
            {
              fontStyle: "italic",
            },
            selectionStart,
            selectionEnd
          );
        }
      }
      // else
      // {
      //   activeObject.setSelectionStyles({
      //     fontStyle: 'normal'
      //   }, 0, text.length);
      //   let newStyle="";
      //   if(activeObject.fontStyle=="italic")
      //   {
      //     newStyle="normal"
      //   }
      //   else if(activeObject.fontStyle=="bold italic")
      //   {
      //     newStyle="bold"
      //   }
      //   else if(activeObject.fontStyle=="bold")
      //   {
      //        newStyle="bold italic"
      //   }
      //   else
      //   {
      //     newStyle="italic"
      //   }
      //   activeObject.set({
      //     fontStyle: newStyle,
      //   });
      // }
      activeObject.dirty = true;
      await canvas.requestRenderAll();
    }
  };
  const loadImageAndAdd = async (link: string) => {
    const res = await fetch(link?.slice(0, link.lastIndexOf("?")), {
      method: "GET",
      headers: { Accept: "*" },
    });
    console.log("res :", res);

    if (!res.ok) {
      throw new Error(`Failed to retrieve text data: ${res.statusText}`);
    }
    const ImageFile = await res.blob();
    console.log("ImageFile :", ImageFile);
    const reader = new FileReader();
    reader.onload = () => {
      const imageData = reader.result;
      const img: any = new Image();
      img.src = imageData;
      img.onload = () => {
        const image = new fabric.Image(img, {
          scaleX: 200 / img.width,
          cornerColor: "#8A5FF7",
          cornerStrokeColor: "#8A5FF7",
          scaleY: 200 / img.width,
          backgroundColor: "rgb(0,0,0,0)",
        });
        canvas.add(image);
        canvas.setActiveObject(image);
      };
    };
    reader.readAsDataURL(ImageFile);
  };
  // const handleItalic = () => {
  //   const activeObject = canvas.getActiveObject();
  //   if (activeObject && activeObject.type === 'i-text') {
  //     let newStyle="";
  //     if(activeObject.fontStyle=="italic")
  //     {
  //       newStyle="normal"
  //     }
  //     else if(activeObject.fontStyle=="bold italic")
  //     {
  //       newStyle="bold"
  //     }
  //     else if(activeObject.fontStyle=="bold")
  //     {
  //          newStyle="bold italic"
  //     }
  //     else
  //     {
  //       newStyle="italic"
  //     }
  //     activeObject.set({
  //       fontStyle: newStyle,
  //     });
  //     canvas.renderAll();
  //   }
  // };

  // const handleBold = () => {
  //   const activeObject = canvas.getActiveObject();
  //   if (activeObject && activeObject.type === 'i-text') {
  //     const selectedText = activeObject.getSelectedText();
  //     const startIndex = activeObject.getSelectionStart();
  //     const endIndex = activeObject.getSelectionEnd();

  //     if (selectedText) {
  //       const currentStyle = activeObject.getSelectionStyles(
  //         startIndex,
  //         endIndex
  //       )[0];
  //       const newStyle = {
  //         ...currentStyle,
  //         fontWeight: currentStyle.fontWeight === "bold" ? "normal" : "bold",
  //       };

  //       activeObject.setText(selectedText, newStyle, startIndex, endIndex);
  //       canvas.renderAll();
  //     }
  //   }
  // };
  const handleTemplateChange = (index: number) => {
    {
      const img = new Image();
      img.src = noteTemplate?.[index].path;
      img.onload = () => {
        const image = new fabric.Image(img, {
          width: CANVAS_WIDTH,
          height: CANVAS_HEIGHT,
          selectable: false, // make sure the background image is not selectable
        });
        canvas.backgroundColor = new fabric.Pattern({ source: img });
        // canvas.backgroundImage = image; // set the background image
        canvas.renderAll(); // render the canvas
      };
    }
  };
  const handleIncreaseFontSize = () => {
    const activeObject = canvas.getActiveObject();
    if (activeObject && activeObject.type === "i-text") {
      activeObject.set({ fontSize: activeObject.fontSize + 1 });
      canvas.renderAll();
    }
  };

  const handleDecreaseFontSize = () => {
    const activeObject = canvas.getActiveObject();
    if (activeObject && activeObject.type === "i-text") {
      activeObject.set({ fontSize: activeObject.fontSize - 1 });
      canvas.renderAll();
    }
  };
  const outsideRef: any = useRef(null);
  console.log("anchorEl01710 :", anchorEl0);
  console.log("anchorEl11710 :", anchorEl1);
  // useEffect(() => {
  //   function handleClickOutside(event: MouseEvent) {
  //     if (
  //       outsideRef.current &&
  //       !outsideRef.current.contains(event.target )
  //     ) {
  //       if(!currentImage)
  //       setSelectedButton(0);
  //     }
  //   }

  //   if (selectedButton) {
  //     document.addEventListener("mousedown", handleClickOutside);
  //   } else {
  //     document.removeEventListener("mousedown", handleClickOutside);
  //   }

  //   return () => {
  //     document.removeEventListener("mousedown", handleClickOutside);
  //   };
  // }, [selectedButton]);
  const [addNote] = useAddNoteTakingMutation();
  const [editById] = useEditNoteTakingByIdMutation();

  const handleAddUpdateNote = async () => {
    setIsLoading(true);
    try {
      canvas.requestRenderAll();
      const finalDAta = [];
      // for (let key = 0; key < binaryDataArray.length; key++) {
      //   if (selectedSlideIndex == key) {
      //     finalDAta.push(JSON.stringify(canvas.toJSON()))
      //   } else {
      //     finalDAta.push(binaryDataArray[key])
      //   }
      // }
      for (let key = 0; key < binaryDataArray.length; key++) {
        if (selectedSlideIndex == key) {
          finalDAta.push(await compressData(JSON.stringify(canvas.toJSON())));
        } else {
          finalDAta.push(await compressData(binaryDataArray[key]));
        }
        console.log("muskan1 :", await compressData(binaryDataArray[key]));
        // console.log("muskan1", await compress(binaryDataArray[key], 'gzip'))
      }
      for (let key = 0; key < binaryDataArray.length; key++) {
        // finalDAta.push(await decompress(binaryDataArray[key], 'gzip'))
        console.log("muskan2", await decompressData(finalDAta[key]));
      }
      const binaryAWS = await UploadTextData(JSON.stringify(finalDAta));
      // const binaryAWS = await UploadTextData(JSON.stringify(canvas?.toJSON()));
      const body: Record<string, any> = {
        type: 1,
        // canvas.toJSON(['selectable', 'name', 'ownType', 'ddlValue', 'lockScalingX'])
        // binary_data:JSON.stringify(canvas.toJSON(['selectable', 'name','lockMovementX','hasControls','transparentCorners','lockMovementY'])),
        binary_data: binaryAWS,
        note_screenshot: JSON.stringify(canvas?.toDataURL()),
        canvas_height: canvas?.getHeight(),
        text_timestamp: "mohit",
        canvas_height_array: JSON.stringify(
          canvasHeightArray?.map((data: number, index: number) =>
            index == selectedSlideIndex ? canvasHeight : data
          )
        ),
        // text_timestamp: selectedTemplateID,
        file_name: noteName || "Note",
      };
      console.log("body :", body);
      const encryptedBody = generateEncryptedKeyBody(body) as CommonBody;
      const response = id
        ? await editById({ id: id as string, body: encryptedBody }).unwrap()
        : await addNote(encryptedBody).unwrap();
      if (response?.status == 200) {
        // generateImagesFromPptJson(data)
        cancelCrop();
        if (!searchParam.get("app")) {
          showToast(
            !id
              ? translate.noteTaking.noteAddedSuccessfully ||
                  "Note added successfully"
              : translate.noteTaking.noteUpdatedSuccessfully ||
                  "Note updated successfully"
          );
          navigate("/note-taking", { replace: true });
        } else {
          //@ts-ignore
          window["ReactNativeWebView"] &&
            //@ts-ignore
            window["ReactNativeWebView"].postMessage("back");
        }
        console.log(response);
      }
    } catch (error: any) {
      showError(
        error?.data?.message || translate.errors.somethingWentWrong || ""
      );
      console.log(error);
    }
    setIsLoading(false);
  };

  // let currentColor = '#000000';
  const [isDisabled, setIsDisabled] = useState(true);
  async function Copy() {
    // clone what are you copying since you
    // may want copy and paste on different moment.
    // and you do not want the changes happened
    // later to reflect on the copy.

    const active = canvas.getActiveObject();
    if (active) {
      if (active?.type == "textbox" || active?.type == "i-text") {
        if (active?.isEditing) {
          try {
            const text = active.text;
            const selectionStart = active.selectionStart;
            const selectionEnd = active.selectionEnd;
            const selectedText = text.substring(selectionStart, selectionEnd);
            if (!selectedText) {
              return;
            }
            showToast(
              translate.noteTaking.textCopiedSuccessfully ||
                "Text Copied successfully"
            );
            await navigator.clipboard.writeText(
              document.getSelection()?.toString() as string
            );
          } catch (error: any) {
            console.log(error);
          }
        }
      }
    }

    if (canvas.getActiveObject()?.type == "textbox") return;

    const temp = await canvas.getActiveObject()?.clone();
    console.log(temp);
    if (temp) {
      setCopiedObject(temp);
      showToast(
        translate.presentation.itemCopiedSuccessfully ||
          "Item Copied successfully"
      );
      isSomethingCopied = true;
      // _clipboard = cloned;
    }
  }

  async function Paste() {
    // clone again, so you can do multiple copies.
    // const clonedObj = await copiedObject?.clone();
    const active = canvas.getActiveObject();

    let coordinates = { top: null, left: null };
    if (active) {
      if (active.type == "textbox" || active.type == "i-text") {
        if (active?.isEditing) {
          try {
            const textToPaste = await navigator.clipboard.readText();
            if (textToPaste) {
              const text = active.text;
              const selectionStart = active.selectionStart;
              const selectionEnd = active.selectionEnd;
              active.enterEditing();
              active.insertChars(
                textToPaste,
                null,
                selectionStart,
                selectionEnd
              );
              canvas.requestRenderAll();
              canvas.discardActiveObject();
              canvas.setActiveObject(active);
              canvas.requestRenderAll();
              showToast(
                translate.noteTaking.textPastedSuccessfully ||
                  "Text pasted successfully"
              );
              console.log(textToPaste);
              return;
            }
          } catch (error: any) {
            console.log(error);
          }
        }
      }
    }

    if (!isSomethingCopied) {
      showError(translate.noteTaking.clipboardIsEmpty || "Clipboard is empty");
      return;
    }
    // canvas.discardActiveObject();
    copiedObject.set({
      left: copiedObject.left + 10,
      top: copiedObject.top + 10,
      evented: true,
    });
    if (active?.type == "textbox") {
      coordinates = { top: copiedObject.top, left: copiedObject.left };
    } else {
      coordinates = { top: active.top, left: active.left };
    }
    disableScrollUp = true;

    if (copiedObject.type === "activeselection") {
      // active selection needs a reference to the canvas.
      copiedObject.canvas = canvas;
      copiedObject.forEachObject(function (obj: any) {
        canvas.add(obj);
      });
      copiedObject.setCoords();
      // this should solve the unselectability
    } else {
      canvas.add(copiedObject);
    }

    copiedObject.top = (coordinates.top || copiedObject.top) + 10;
    copiedObject.left = (coordinates.left || copiedObject.left) + 10;
    canvas.setActiveObject(copiedObject);
    canvas.requestRenderAll();
    isSomethingCopied = false;
    showToast(
      translate.presentation.itemPastedSuccessful || "Item Pasted Successfully"
    );
    disableScrollUp = false;
    Copy();
  }
  const handleHighlightMode = () => {
    if (highlightMode) return;
    setDrawingMode(false);
    setHighlightMode(true);
    canvas.isDrawingMode = true;
    canvas.freeDrawingBrush = new fabric.PencilBrush(canvas);
    canvas.freeDrawingBrush.width = penSize || 8;
    canvas.freeDrawingBrush.color = "#FFFF0040";
    setHighlightColor("#FFFF00");
    // canvas.on("path:created", (options: any) => {
    // if (highlightMode) {
    //   const path = options.path;
    //   path.set({
    //     stroke: color,
    //     strokeWidth: 5,
    //     selectable: true,
    //   });
    // }
    // });
  };
  // const handleCloseHighlightMode=()=>{
  //   if (highlightMode) {
  //     setHighlightMode(false);
  //     canvas.isDrawingMode = false;
  //     return;
  //   }
  // }
  const handleUploadImage = async (event: any) => {
    const file = event?.target?.files?.[0];
    if (!file) {
      return;
    }
    try {
      setIsLoading(true);
      const allowedExtensions = ["png", "jpg", "jpeg"];
      const fileExtension = file.name.split(".").pop()?.toLowerCase();
      console.log("sssssssssssssssssssss", fileExtension);
      if (!allowedExtensions.includes(fileExtension || "")) {
        showError(
          translate.errors.onlyJpgPngAllowed ||
            "Invalid file format: only png, jpg images are allowed"
        );
        return;
      }
      const reader = new FileReader();
      reader.onload = () => {
        const imageData = reader.result;
        const img: any = new Image();
        img.src = imageData;
        img.onload = () => {
          const image = new fabric.Image(img, {
            scaleX: 200 / img.width,
            cornerColor: "#8A5FF7",
            cornerStrokeColor: "#8A5FF7",
            scaleY: 200 / img.width,
          });
          canvas.add(image);
          canvas.setActiveObject(image);
          setIsLoading(false);
        };
      };
      const options = {
        maxSizeMB: 2,
        maxWidthOrHeight: 1920,
        useWebWorker: true,
      };
      const compressedFile = await imageCompression(file, options);
      reader.readAsDataURL(compressedFile);
    } catch (error: any) {
      console.log(error);
      setIsLoading(false);
    }
  };
  const generateImagesFromPptJson = async (pptData: any[]) => {
    const canvasElement: any = document.createElement("canvas");

    try {
      const canvasInstance = new fabric.Canvas(canvasElement, {
        width: CANVAS_WIDTH,
        height: CANVAS_HEIGHT,
        backgroundColor: "#FFFFFF",
      });
      const slideList: string[] = [];
      // console.log('pptData :', pptData);
      console.log("pptData :", pptData);
      for (const data of pptData) {
        await canvasInstance
          .loadFromJSON(JSON.parse(await decompressData(data)))
          .then(() => {
            canvasInstance.renderAll();
            // console.log('canvasInstance.toDataURL() :', canvasInstance.toDataURL());
            slideList.push(canvasInstance.toDataURL());
            // setSlideList((prevData:any)=>[...prevData,canvasInstance?.toDataURL()])
            // console.log('slideList00000 :', slideList);

            canvasInstance.clear();
          });
      }

      setSlideList(slideList);
      // setSelectedSlide(slideList[0]);
      // console.log('slideList232323 :', slideList);
    } catch (error: any) {
      console.log(error);
      setIsLoading(false);
    }
    setIsLoading(false);
  };
  function adjustCanvasHeight2(newHeight: any, canvasInstance: any) {
    setCanvasHeight(newHeight);
    // canvasInstance.container.scrollTop += delta;
    window.scrollTo(
      0,
      window.scrollY + (newHeight - canvasInstance.getHeight())
    );
    canvasInstance.setHeight(newHeight);
    canvas.requestRenderAll();
  }
  useEffect(() => {
    if (binaryDataArray?.length) {
      if (!binaryDataUpdated) {
        canvas.loadFromJSON(JSON.parse(binaryDataArray[0])).then(() => {
          canvas.setHeight(canvasHeightArray[0] || CANVAS_HEIGHT);
          setCanvasHeight(canvasHeightArray[0] || CANVAS_HEIGHT);
          canvas.requestRenderAll();
          updateCanvasAttributes(canvas);
          const history = new CanvasHistory(canvas, stopReading);
          setHistoryData(history);
          canvas.renderAll();
        });
        setBinaryDataUpdated(true);
      }
    }
  }, [binaryDataArray]);

  const [getDataById] = useLazyGetNoteTakingByIdQuery();
  const getDataByIdList = async (canvasInstance: any) => {
    setIsLoading(true);
    try {
      const response = await getDataById({ id }).unwrap();
      if (response.status == 200) {
        // const finaldata: any = JSON.parse(
        //   response?.data?.binary_data.replaceAll("'", '"')
        // );
        // console.log("finaldata :", finaldata);
        // console.log(
        //   "123 textData :",
        //   JSON.parse(response?.data?.binary_data.replaceAll("'", '"'))
        // );
        // selectedSlideIndex=0
        setSelectedSlideIndex(0);
        // selectedTemplateID = response?.data?.text_timestamp;
        // const localHeightArray=
        canvasHeightArray = JSON.parse(response?.data?.canvas_height_array);
        const data = JSON.parse(
          await GetTextData(response?.data?.binary_data?.data?.[0]?.media_url)
          // await GetTextData(JSON.parse(response?.data?.binary_data?.replaceAll("'", '"'))?.data?.[0]?.media_url)
        );
        console.log("data1610 :", data);
        setNoteName(response?.data?.file_name);
        let finalData = [];
        for (let key = 0; key < data?.length; key++) {
          finalData.push(await decompressData(data[key]));
          // await decompress(finalDAta[key], 'gzip')
        }
        generateImagesFromPptJson(data);
        setBinaryDataArray(finalData);

        // const textData = await GetTextData(finaldata?.data?.[0]?.media_url);
        // console.log("textData :", textData);
        // await canvasInstance.loadFromJSON(textData).then(() => {
        //   setIsLoading(false);
        //   canvasInstance.forEachObject(function (obj: any) {
        //     obj.cornerColor = "#8A5FF7";
        //     obj.cornerStrokeColor = "#8A5FF7";
        //     if (obj instanceof fabric.Textbox) {
        //       obj.lockMovementX = true;
        //       obj.lockMovementY = true;
        //       obj.lockScalingX = true;
        //       obj.lockScalingY = true;
        //       obj.hasControls = false;
        //       obj.on("mouse:over", () => {
        //         obj.isEditing = true;
        //         console.log(obj, obj.isEditing);
        //       });

        //       obj.on("changed", () => {
        //         const activeObject = canvasInstance?.getActiveObject();
        //         if (!activeObject) return;

        //         let lines = activeObject.text.split("\n").length;
        //         if (!enterCount) {
        //           enterCount = lines;
        //         }

        //         if (lines > enterCount) {
        //           if (lines > 17) {
        //             adjustCanvasHeight2(
        //               activeObject.getScaledHeight(),
        //               canvasInstance
        //             );
        //           }
        //         } else if (lines < enterCount) {
        //           if (lines > 17) {
        //             adjustCanvasHeight2(
        //               activeObject.getScaledHeight(),
        //               canvasInstance
        //             );
        //           } else {
        //             activeObject.set({ text: `${activeObject.text}\n\n` });
        //             canvasInstance.requestRenderAll();
        //           }
        //         }

        //         // Add \n at the end until the count reaches 17
        //         while (lines < 17) {
        //           activeObject.dirty = true;

        //           activeObject.text = activeObject.text + "\n";
        //           activeObject.set("text", activeObject.text);
        //           // canvasInstance.remove(activeObject)
        //           // canvasInstance.add(activeObject)
        //           canvasInstance.discardActiveObject();
        //           canvasInstance.setActiveObject(activeObject);

        //           // activeObject.text += '\n';
        //           canvasInstance.requestRenderAll();
        //           lines++;
        //         }

        //         enterCount = lines;
        //       });

        //       // function adjustCanvasHeight(delta:any) {
        //       //   canvasInstance.setHeight(canvasInstance.getHeight() + delta);
        //       //   setCanvasHeight((prev) => prev - delta);
        //       //   // canvasInstance.container.scrollTop += delta;
        //       //   window.scrollTo(0, window.scrollY + delta)

        //       // }
        //     }
        //     if (obj instanceof fabric.Path) {
        //       obj.lockMovementX = true;
        //       obj.lockMovementY = true;
        //       obj.lockScalingX = true;
        //       obj.lockScalingY = true;
        //       obj.hasControls = false;
        //       obj.selectable = false;
        //       obj.erasable = true;
        //     }
        //     if (obj instanceof fabric.IText) {
        //       // obj.lockMovementX = true;
        //       // obj.lockMovementY = true;
        //       obj.lockScalingX = true;
        //       obj.lockScalingY = true;
        //       obj.hasControls = false;
        //     }
        //     if (obj instanceof fabric.Text) {
        //       // obj.lockMovementX = true;
        //       // obj.lockMovementY = true;
        //       obj.lockScalingX = true;
        //       obj.lockScalingY = true;
        //       obj.hasControls = false;
        //       obj.hoverCursor = "text";
        //     }
        //   });
        //   canvasInstance.renderAll();
        // });
        // if (response?.data?.canvas_height) {
        //   canvasInstance.setHeight(response?.data?.canvas_height);
        //   setCanvasHeight(response?.data?.canvas_height);
        // }
        // setCurrentPresentationData(response?.data);
        // generateImagesFromPptJson(response?.data?.binary_data)
        // topicName=response?.data?.text;
        // selectedTemplateID=response?.data?.template_id;
        //  setBinaryDataArray(response?.data?.binary_data);

        // template_id=response?.data?.template_id
        // setCurrentSlideInfo({currentPage:1,totalPages:response?.data?.binary_data?.length})
        console.log(response);
      }
    } catch (error: any) {
      showError(
        error?.data?.message ||
          error?.data?.message ||
          translate.errors.somethingWentWrong
      );
      console.log(error);
      setIsLoading(false);
    } finally {
      setIsSaved(true);
    }
  };
  // useEffect(() => {
  //   const canvasElement:any = document.getElementById('canvas');
  //   const canvasInstance = new fabric.Canvas(canvasElement, {
  //     width: CANVAS_WIDTH,
  //     height: CANVAS_HEIGHT,
  //     backgroundColor: '#FFFFFF',
  //   });
  //   canvasInstance.renderAll();
  //   setCanvas(canvasInstance);
  //   if(id)
  //   getDataByIdList();
  //   return () => {
  //     canvasInstance.dispose();
  //   };
  // }, []);
  useEffect(() => {
    if (isDisabled) {
      //@ts-ignore
      canvasCoverRef.current.style.pointerEvents = "all";
    } else {
      //@ts-ignore
      canvasCoverRef.current.style.pointerEvents = "none";
    }
  }, [isDisabled]);
  const handleInitialText = (canvasInstance: any) => {
    const text = new fabric.Textbox(
      // ``
      "\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n",
      // \n\n\n",
      // \n\n\n\n\n\n\n\n\n\n\n
      // \n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n",
      {
        fontSize: 36,
        fontFamily: "Arial",
        fill: "#000000",
        editable: true,
        height: CANVAS_HEIGHT - 1,
        width: CANVAS_WIDTH - 1,
        name: "MainText",
        lockMovementX: true,
        hasControls: false,
        transparentCorners: true,
        lockMovementY: true,
        hoverCursor: "text",
        splitByGrapheme: true,
      }
    );

    text.on("changed", () => {
      // console.log("TEST123",text.text);
      const activeObject = canvasInstance?.getActiveObject();
      if (!activeObject) return;

      let lines = activeObject.text.split("\n").length;
      if (!enterCount) {
        enterCount = lines;
      }

      if (lines > enterCount) {
        if (lines > 17) {
          adjustCanvasHeight2(activeObject.getScaledHeight(), canvasInstance);
        }
      } else if (lines < enterCount) {
        if (lines > 17) {
          adjustCanvasHeight2(activeObject.getScaledHeight(), canvasInstance);
        } else {
          activeObject.set({ text: `${activeObject.text}\n\n` });
          canvasInstance.requestRenderAll();
        }
      }

      // Add \n at the end until the count reaches 17
      while (lines < 18) {
        activeObject.dirty = true;
        const temp = activeObject.text;
        activeObject.text = temp + "\n";
        activeObject.set("text", temp);
        // canvasInstance.remove(activeObject)
        // canvasInstance.add(activeObject)
        canvasInstance.discardActiveObject();
        canvasInstance.setActiveObject(activeObject);

        // activeObject.text += '\n';
        canvasInstance.requestRenderAll();
        lines++;
      }

      enterCount = lines;
    });
    text.on("selection:changed", () => {
      setSelectedButton(1);
      // setDisplayRuler(0);
      textButtonRef?.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
      setFakeSelectedButton(1);
    });
    // function adjustCanvasHeight(delta:any) {
    //   canvasInstance.setHeight(canvasInstance.getHeight() + delta);
    //   setCanvasHeight((prev) => prev - delta);
    //   // canvasInstance.container.scrollTop += delta;
    //   window.scrollTo(0, window.scrollY + delta)

    // }
    // function adjustCanvasHeight2(newHeight:any) {
    //   setCanvasHeight((prev) => newHeight);
    //   // canvasInstance.container.scrollTop += delta;
    //   window.scrollTo(0, window.scrollY + (newHeight-canvasInstance.getHeight()))
    //   canvasInstance.setHeight(newHeight);

    // }

    canvasInstance.add(text);
    canvasInstance.setActiveObject(text);
  };
  // const panCanvas = (canvas:Canvas|any, delta:any) => {
  //   // Copy the current viewport transform
  //   const vpt = canvas.viewportTransform.slice(0);

  //   // Apply the delta values to the viewport transform
  //   vpt[4] += delta.x;
  //   vpt[5] += delta.y;

  //   // Define container dimensions
  //   const containerWidth = CANVAS_WIDTH;
  //   const containerHeight = CANVAS_HEIGHT;
  //   // const containerWidth = 1280;
  //   // const containerHeight = 720;

  //   // Calculate canvas dimensions considering zoom
  //   const canvasWidth = canvas.getWidth() * canvas.getZoom();
  //   const canvasHeight = canvas.getHeight() * canvas.getZoom();

  //   // Adjust viewport to stay within canvas bounds
  //   if (canvasWidth < containerWidth) {
  //       vpt[4] = Math.min(0, Math.max(vpt[4], containerWidth - canvasWidth));
  //   } else {
  //       vpt[4] = Math.max(containerWidth - canvasWidth, Math.min(0, vpt[4]));
  //   }

  //   if (canvasHeight < containerHeight) {
  //       vpt[5] = Math.min(0, Math.max(vpt[5], containerHeight - canvasHeight));
  //   } else {
  //       vpt[5] = Math.max(containerHeight - canvasHeight, Math.min(0, vpt[5]));
  //   }

  //   // Set the adjusted viewport transform
  //   if (canvas.getZoom() > 1 && !canvas.isDrawingMode) {
  //       canvas.viewportTransform = vpt;
  //   }

  //   // Update the canvas viewport
  //   canvas.zoomToPoint(delta, canvas.getZoom());

  //   // Render the canvas
  //   canvas.renderAll();
  //   canvas.requestRenderAll();
  // };
  // const zoomCanvas = (canvas:Canvas|any, scale:any, center:any) => {
  //   const zoom = canvas.getZoom() * scale;
  //   const zoomPoint = new fabric.Point(center.x, center.y);
  //   canvas.zoomToPoint(zoomPoint, zoom);
  //   canvas.renderAll();
  //   canvas.requestRenderAll();
  // };
  const updateCanvasAttributes = (canvas: any) => {
    canvas.forEachObject(function (obj: any) {
      obj.cornerColor = "#8A5FF7";
      obj.cornerStrokeColor = "#8A5FF7";
      if (obj instanceof fabric.Textbox) {
        obj.lockMovementX = true;
        obj.lockMovementY = true;
        obj.lockScalingX = true;
        obj.lockScalingY = true;
        obj.hasControls = false;
        obj.on("mouse:over", () => {
          obj.isEditing = true;
          console.log(obj, obj.isEditing);
        });
        if (obj.getScaledHeight() > CANVAS_HEIGHT) {
          adjustCanvasHeight2(obj.getScaledHeight(), canvas);
        }
        obj.on("changed", () => {
          // const activeObject = canvas?.getActiveObject();
          // if (!activeObject) return;
          console.log("TEST123", obj.text);

          if (obj.getScaledHeight() > CANVAS_HEIGHT) {
            adjustCanvasHeight2(obj.getScaledHeight(), canvas);
          }
          let lines = obj.text.split("\n").length;
          if (!enterCount) {
            enterCount = lines;
          }

          if (lines > enterCount) {
            if (lines > 17) {
              // adjustCanvasHeight2(
              //   obj.getScaledHeight(),
              //   canvas
              // );
            }
          } else if (lines < enterCount) {
            if (lines > 17) {
              // adjustCanvasHeight2(
              //   obj.getScaledHeight(),
              //   canvas
              // );
            } else {
              obj.set({ text: `${obj.text}\n\n` });
              canvas.requestRenderAll();
            }
          }

          if (lines < 17) adjustCanvasHeight2(CANVAS_HEIGHT, canvas);
          // Add \n at the end until the count reaches 17
          while (lines < 17) {
            obj.dirty = true;

            const temp = obj.text;
            obj.text = temp + "\n";
            obj.set("text", temp + "\n");
            // canvas.remove(obj)
            // canvas.add(obj)
            canvas.discardActiveObject();
            canvas.setActiveObject(obj);

            // obj.text += '\n';
            canvas.requestRenderAll();
            lines++;
          }

          enterCount = lines;
        });

        // function adjustCanvasHeight(delta:any) {
        //   canvasInstance.setHeight(canvasInstance.getHeight() + delta);
        //   setCanvasHeight((prev) => prev - delta);
        //   // canvasInstance.container.scrollTop += delta;
        //   window.scrollTo(0, window.scrollY + delta)

        // }
      }
      if (obj instanceof fabric.Path) {
        obj.lockMovementX = true;
        obj.lockMovementY = true;
        obj.lockScalingX = true;
        obj.lockScalingY = true;
        obj.hasControls = false;
        obj.selectable = false;
        obj.erasable = true;
        obj.hoverCursor = "default";
        obj.evented = false;
      }
      if (obj instanceof fabric.IText) {
        // obj.lockMovementX = true;
        // obj.lockMovementY = true;
        obj.lockScalingX = true;
        obj.lockScalingY = true;
        obj.hasControls = false;
      }
      if (obj instanceof fabric.Text) {
        // obj.lockMovementX = true;
        // obj.lockMovementY = true;
        obj.lockScalingX = true;
        obj.lockScalingY = true;
        obj.hasControls = false;
        obj.hoverCursor = "text";
        obj.on("selection:changed", () => {
          setSelectedButton(1);
          textButtonRef?.current.scrollIntoView({
            behavior: "smooth",
            block: "center",
          });
          // setDisplayRuler(0);
          setFakeSelectedButton(1);
        });
      }
    });
  };
  const objectAddedAttributes = (canvasInstance: any) => {
    canvasInstance.on("object:added", (options: any) => {
      setIsSaved(false);
      setIsDisabled(true);
      console.log("options :", options);
      if (!options?.target?.path && !currentImage && !disableScrollUp)
        window.scrollTo(0, 0);
      if (disableScrollUp) disableScrollUp = false;
      canvasInstance.forEachObject(function (obj: any) {
        if (obj instanceof fabric.Path) {
          console.log("MG", obj);
          if (isHighlight) {
            if (obj.stroke?.toString().endsWith("40")) {
              obj.lockMovementX = true;
              obj.lockMovementY = true;
              obj.lockScalingX = true;
              obj.lockScalingY = true;
              obj.hasControls = false;
              obj.hoverCursor = "default";
              obj.selectable = false;
              obj.erasable = true;
              obj.evented = false;
            } else {
              obj.lockMovementX = true;
              obj.lockMovementY = true;
              obj.lockScalingX = true;
              obj.lockScalingY = true;
              obj.hasControls = false;
              obj.hoverCursor = "default";
              obj.selectable = false;
              obj.erasable = false;
              obj.evented = false;
            }
          } else {
            if (obj.stroke?.toString().endsWith("40")) {
              obj.lockMovementX = true;
              obj.lockMovementY = true;
              obj.lockScalingX = true;
              obj.lockScalingY = true;
              obj.hasControls = false;
              obj.hoverCursor = "default";
              obj.selectable = false;
              obj.erasable = false;
              obj.evented = false;
            } else {
              obj.lockMovementX = true;
              obj.lockMovementY = true;
              obj.lockScalingX = true;
              obj.lockScalingY = true;
              obj.hasControls = false;
              obj.hoverCursor = "default";
              obj.selectable = false;
              obj.erasable = true;
              obj.evented = false;
            }
          }
        }
      });
    });
  };

  const objectMovingAttributes = (canvasInstance: any) => {
    canvasInstance.on("object:moving", () => {
      setIsSaved(false);

      if (currentImage) {
        const selectedObject = canvasInstance.getActiveObject();
        if (!selectedObject) return;
        if (selectedObject?.left <= currentImage.left) {
          selectedObject?.set({
            left: currentImage.left,
          });
        }
        if (selectedObject?.top <= currentImage.top) {
          selectedObject?.set({
            top: currentImage.top,
          });
        }
        console.log(
          "(selectedObject.left+selectedObject.getBoundingRect().width)<=(currentImage.left+currentImage.getBoundingRect().width) :",
          selectedObject.left + selectedObject.getBoundingRect().width <=
            currentImage.left + currentImage.getBoundingRect().width
        );
        console.log(
          "(selectedObject.left+selectedObject.getBoundingRect().width) :",
          selectedObject.left + selectedObject.getBoundingRect().width
        );
        console.log(
          "(currentImage.left+currentImage.getBoundingRect().width) :",
          currentImage.left + currentImage.getBoundingRect().width
        );
        if (
          selectedObject.left + selectedObject.getBoundingRect().width >=
          currentImage.left + currentImage.getBoundingRect().width
        ) {
          selectedObject?.set({
            left:
              currentImage.left +
              currentImage.getBoundingRect().width -
              selectedObject.getBoundingRect().width,
          });
        }
        if (
          selectedObject.top + selectedObject.getBoundingRect().height >=
          currentImage.top + currentImage.getBoundingRect().height
        ) {
          selectedObject?.set({
            top:
              currentImage.top +
              currentImage.getBoundingRect().height -
              selectedObject.getBoundingRect().height,
          });
        }
        canvasInstance.requestRenderAll();
      }
    });
  };
  const mouseDownAttributes = (canvasInstance: any) => {
    canvasInstance.on("mouse:down", () => {
      console.log("DRAG ENTER");
      console.log("cropCanvas :", cropCanvas);
      if (!currentImage && !currentImage2) setSelectedButton(0);
    });
  };

  const objectScalingAttributes = (canvasInstance: any) => {
    canvasInstance.on("object:scaling", (e: any) => {
      setIsSaved(false);

      if (currentImage) {
        const selectedObject = e.target;
        const scaleX = selectedObject.scaleX;
        const scaleY = selectedObject.scaleY;
        const width = selectedObject.width * scaleX;
        const height = selectedObject.height * scaleY;
        const left = selectedObject.left;
        const top = selectedObject.top;

        // Calculate the maximum scale values to keep the object within the image boundaries
        const maxScaleX =
          (currentImage.left + currentImage.getBoundingRect().width - left) /
          selectedObject.width;
        const maxScaleY =
          (currentImage.top + currentImage.getBoundingRect().height - top) /
          selectedObject.height;

        // Limit the scale values to the maximum allowed
        selectedObject.set({
          scaleX: Math.min(scaleX, maxScaleX),
          scaleY: Math.min(scaleY, maxScaleY),
        });

        // Adjust the object's position to maintain edge alignment
        if (selectedObject?.left <= currentImage.left) {
          selectedObject?.set({
            left: currentImage.left,
            scaleX: Math.min(
              scaleX,
              (currentImage.left +
                currentImage.getBoundingRect().width -
                currentImage.left) /
                selectedObject.width
            ),
          });
        }
        if (selectedObject?.top <= currentImage.top) {
          selectedObject?.set({
            top: currentImage.top,
            scaleY: Math.min(
              scaleY,
              (currentImage.top +
                currentImage.getBoundingRect().height -
                currentImage.top) /
                selectedObject.height
            ),
          });
        }

        canvasInstance.requestRenderAll();
      }
    });
  };

  const [pointerEvents, setPointerEvents] = useState<any>("all");
  const canvasCoverRef = useRef(null);
  const undoFabric = async () => {
    // console.log("undo :", undo);
    // await canvas.undo();
    // await canvas.loadFromJSON(undo.pop());
    // await canvas.renderAll();
    await historyData.undo();
    updateCanvasAttributes(canvas);
    objectAddedAttributes(canvas);
    objectScalingAttributes(canvas);
    mouseDownAttributes(canvas);
    objectMovingAttributes(canvas);
    canvas.requestRenderAll();
  };
  const redoFabric = async () => {
    // await canvas.redo();
    await historyData.redo();
    updateCanvasAttributes(canvas);
    objectAddedAttributes(canvas);
    objectScalingAttributes(canvas);
    mouseDownAttributes(canvas);
    objectMovingAttributes(canvas);
    canvas.requestRenderAll();
  };
  const [historyData, setHistoryData] = useState<any>();
  useEffect(() => {
    selectedTemplateID = 0;
    const canvasElement: any = document.getElementById("canvas");
    const canvasInstance = new fabric.Canvas(canvasElement, {
      width: CANVAS_WIDTH,
      height: CANVAS_HEIGHT,
      backgroundColor: "#FFFFFF",
      preserveObjectStacking: true,
      selection: false,
      imageSmoothingEnabled: true,
      enableRetinaScaling: true,
      // enablePointerEvents: true,
      // controlsAboveOverlay:true,
      // centeredScaling:true,
      allowTouchScrolling: true,
      objectCaching: false,
      renderOnAddRemove: true,
    });
    canvasInstance.on("mouse:down", function (event) {
      event.e.preventDefault();
    });
    //  undo redo working but issue is eraser ,crop,canvas crop not properly with undo redo

    // canvasInstance.getActiveObject().
    //     canvasInstance.on('object:moving', (options:any) => {
    // const obj:any=canvasInstance.getActiveObject();
    // if(currentImage){
    //   // alert("MOHIT")
    //   if(obj.getBoundingRect().top < currentImage.top){ //Top boundary
    //     obj.top = currentImage.top;
    // }
    // currentImage.bottom = currentImage.top+currentImage.height;
    // if(obj.getBoundingRect().top+obj.getBoundingRect().height > currentImage.top+currentImage.height){  //Bottom boundary
    //     obj.top = currentImage.bottom-obj.getScaledHeight();
    // }
    // if(obj.getBoundingRect().left < currentImage.left){  //Left boundary
    //     obj.left = currentImage.left;
    // }
    // currentImage.right = currentImage.left+currentImage.width;
    // if(obj.getBoundingRect().left+obj.getBoundingRect().width > currentImage.left+currentImage.width){  //Right boundary
    //     obj.left = currentImage.right-obj.getScaledWidth();
    // }
    // }

    //     });
    // const tet=canvasInstance.getActiveObject();
    // tet?.set({
    //   angle:0
    // })

    canvasInstance.on("object:modified", () => {
      setIsSaved(false);
    });

    window.addEventListener("keydown", (e) => {
      if (e.key == "Delete") {
        console.log(e);
        handleDeleteFromKey(canvasInstance);
      }
    });
    canvasInstance.allowTouchScrolling = true;

    //KING FABRIC
    // canvasInstance.on('mouse:down', (options) => {
    // // options.e.detail
    // alert(options.e.detail);
    //   //   if (!canvasInstance.isDrawingMode) {
    // //     // options.e.preventDefault();
    // //     // options.e.stopImmediatePropagation();
    // //     canvasInstance.selection=false;
    // // canvasInstance.getElement().style.zIndex="3";
    // // // alert("MY NAME IS MOHIT")
    // //     canvasInstance.renderAll();
    // //     canvasInstance.requestRenderAll();
    // //   }
    // });
    // canvasInstance.on('after:render',(options)=>{
    //   if (isDisabled) {
    //     // options.e.preventDefault();
    //     // options.e.stopImmediatePropagation();
    // canvasInstance.getElement().style.zIndex="3";
    //     // @ts-ignore
    //     // canvasInstance.interactive = false; // disable interactive mode

    //     // // to enable scrolling
    //     // //@ts-ignore
    //     // // canvasInstance.canvas.container.style.overflow = 'auto';
    //     // canvasInstance.renderAll();
    //     // canvasInstance.requestRenderAll();
    //   }
    // })

    //KING
    // window.addEventListener('touchstart', (e) => {

    //   if (e.touches.length === 2) {
    //     // Delay to ensure the browser processes the touch event
    //     requestAnimationFrame(() => {
    //       // Ensure canvasCoverRef is properly set
    //       if (canvasCoverRef.current) {
    //         canvasInstance.discardActiveObject();
    //       }
    //     });
    //   }
    // });

    // window.addEventListener('dragstart', (e) => {
    //   // if (e.touches.length === 2) {
    //      //@ts-ignore
    //      canvasCoverRef.current.style.pointerEvents = 'none';
    //     // const canvasCoveringDiv=document.getElementById("mohit");
    //     // if(canvasCoveringDiv){
    //     // // canvasCoveringDiv.style.zIndex = '300';
    //     // canvasCoveringDiv.style.pointerEvents='none'}
    //     // setPointerEvents('none')

    //     // canvasInstance.renderAll();
    //   // }
    // });

    // window.addEventListener('touchend', (e) => {

    //   //@ts-ignore
    //   // canvasCoverRef.current.style.pointerEvents = 'all';

    //   // if (e.touches.length === 2) {
    //   //   const canvasCoveringDiv=document.getElementById("mohit");
    //   //   if(canvasCoveringDiv){
    //   //   // canvasCoveringDiv.style.zIndex = '-1';
    //   //   canvasCoveringDiv.style.pointerEvents='all'
    //   // }

    //     // canvasInstance.renderAll();
    //   // }
    // });
    // window.addEventListener('')

    // canvasInstance.allowTouchScrolling=true;
    // var disableScroll = function(){
    //   canvasInstance.allowTouchScrolling = false;
    // };

    // var enableScroll = function(){
    //   canvasInstance.allowTouchScrolling = true;
    // };

    // canvasInstance.on('object:moving', disableScroll);
    // canvasInstance.on('object:scaling', disableScroll);
    // canvasInstance.on('object:rotating', disableScroll);
    // canvasInstance.on('mouse:move', enableScroll);

    objectAddedAttributes(canvasInstance);
    objectScalingAttributes(canvasInstance);
    mouseDownAttributes(canvasInstance);
    objectMovingAttributes(canvasInstance);
    // canvasInstance.on('selection:updated',()=>{
    //   const hammerCanvas=new Hammer(canvasInstance.getSelectionElement())
    //   hammerCanvas.get('pinch').set({enable:true})
    //   hammerCanvas.on('pinch',(event:any)=>{
    //     const scale = event.scale;
    //     const centerX = event.center.x;
    //     const centerY = event.center.y;

    //     const activeObject = canvasInstance.getActiveObject();

    //     if (activeObject) {
    //       const objectCenterX = activeObject.left + activeObject.width / 2;
    //       const objectCenterY = activeObject.top + activeObject.height / 2;

    //       const deltaX = centerX - objectCenterX;
    //       const deltaY = centerY - objectCenterY;

    //       let newScaleX = activeObject.scaleX * scale;
    //       let newScaleY = activeObject.scaleY * scale;

    //       // Set upper and lower limits for scaling
    //       const minScale = 1;
    //       const maxScale = 5;
    //       newScaleX = Math.min(Math.max(newScaleX, minScale), maxScale);
    //       newScaleY = Math.min(Math.max(newScaleY, minScale), maxScale);

    //       activeObject.scaleX = newScaleX ;
    //       activeObject.scaleY = newScaleY ;

    //       // activeObject.left += deltaX * (scale - 1);
    //       // activeObject.top += deltaY * (scale - 1);

    //       canvasInstance.requestRenderAll();
    //     }
    //   })
    //   hammerCanvas.get('pan').set({direction:Hammer.DIRECTION_ALL,threshold:0,pointers:0})
    //   hammerCanvas.on('pan',(event:any)=>{
    //     event.preventDefault();
    //     const e=event;
    //     panCanvas(canvasInstance,{
    //       x:e.deltaX,
    //       y:e.deltaY
    //     })
    //     // alert("PINCHED")
    //   })
    //   // alert("GELLO")

    // })

    // canvasInstance.on('after:render',()=>{
    //   if(canvasInstance.getActiveObject()?.type=="textbox")
    //   {

    //     //@ts-ignore
    //     console.log('canvasInstance?.getActiveObject()?.text :', canvasInstance?.getActiveObject()?.text);
    //     //@ts-ignore
    //      if(canvasInstance?.getActiveObject()?.text?.split('\n')?.length>90)
    //      {
    //       // canvasInstance.setHeight(canvasHeight+100)
    //       setCanvasHeight(prev=>prev+100)
    //      }

    //   }
    // })
    // canvasInstance.on('text:changed',()=>{
    //   console.log("TESTET");

    //   //@ts-ignore
    //   const temp=canvasInstance?.getActiveObject()?.text?.replaceAll('\n',"--++==%%")
    //   if(temp?.split("--++==%%")?.length>50)
    //     {
    //      canvasInstance.setHeight(canvasHeight+50)
    //      setCanvasHeight(prev=>prev+100)
    //     }
    // })
    canvasInstance.renderAll();
    if (id) getDataByIdList(canvasInstance);
    else {
      setSelectedSlideIndex(0);
      canvasHeightArray = [CANVAS_HEIGHT];
      setBinaryDataArray((prev: any) => [
        JSON.stringify(canvasInstance.toJSON()),
        ...prev,
      ]);
      setSlideList((prev: any) => [canvasInstance.toDataURL(), ...prev]);
    }
    setCanvas(canvasInstance);
    handleInitialText(canvasInstance);
    currentImage = null;
    setIsCropping(false);
    isHighlight = false;
    // isCropping=false;
    return () => {
      window.removeEventListener("keydown", () => {});
      canvasInstance.dispose();
    };
  }, []);

  const pageChangeCanvas = (index: number) => {
    // setCurrentSlideInfo(prev=>({...prev,currentPage:index+1}))
    // setSelectedSlide(data)
    // if(canvas)
    // canvas?.requestRenderAll();
    if (index > slideList?.length - 1 || index < 0) return;
    if (selectedSlideIndex == index) {
      setMultiPageDrawer(false);

      console.log("canvasHeightArray 4029:", canvasHeightArray);
      canvasHeightArray.with(index, canvasHeight);
      console.log("canvasHeightArray 4031:", canvasHeightArray);
      setSlideList((prev: any) =>
        prev.map((data: any, index: number) =>
          index == selectedSlideIndex ? canvas?.toDataURL() : data
        )
      );

      return;
    }
    setIsLoading(true);

    setSelectedButton(0);
    console.log("canvasHeightArray before 4043:", canvasHeightArray);
    console.log("canvasHeight 4044:", canvasHeight);
    setSlideList((prev: any) =>
      prev.map((data: any, index: number) =>
        index == selectedSlideIndex ? canvas?.toDataURL() : data
      )
    );
    canvasHeightArray = canvasHeightArray.with(
      selectedSlideIndex,
      canvasHeight
    );
    console.log("canvasHeightArray After 4045:", canvasHeightArray);
    let idx = -1;
    idx = binaryDataArray?.findIndex(
      (ele: any, index: number) => index === selectedSlideIndex
    );
    if (idx > -1) {
      let copySlides = [...binaryDataArray];
      copySlides[idx] = JSON.stringify(canvas.toJSON());
      console.log("copySlides[idx] :", copySlides[idx]);
      setBinaryDataArray([...copySlides]);
    }
    adjustCanvasHeight2(canvasHeightArray[index], canvas);

    canvas.loadFromJSON(binaryDataArray[index]).then(() => {
      // canvas.forEachObject(function (obj: any) {
      //   obj.cornerColor = "#8A5FF7";
      //   obj.cornerStrokeColor = "#8A5FF7";
      //   if (obj instanceof fabric.Text) {
      //     // obj.lockMovementX = true;
      //     // obj.lockMovementY = true;
      //     obj.lockScalingX = true;
      //     obj.lockScalingY = true;
      //     obj.hasControls = false;
      //   }
      // });
      updateCanvasAttributes(canvas);
      canvas.renderAll();
      setMultiPageDrawer(false);
      setIsLoading(false);
    });

    console.log("selectedSlideIndex :", selectedSlideIndex);

    // setBinaryDataArray((prev:any)=>prev.map((data:any,index:number)=>(index==selectedSlideIndex) ? JSON.stringify(canvas.toJSON()):data))

    // setSelectedButton(1);
    handleStopDrawing();
    // selectedSlideIndex = index;
    setSelectedSlideIndex(index);
  };
  const handlePrevPage = () => {
    pageChangeCanvas(selectedSlideIndex - 1);
  };
  const handleNextPage = () => {
    pageChangeCanvas(selectedSlideIndex + 1);
  };

  const [noteName, setNoteName] = useState(
    moment(Date.now()).format("DD MMM YYYY")
  );
  const [noteName2, setNoteName2] = useState("Note");

  const editNoteName = (e: any) => {
    setNoteName(noteName2);
    setRenameOpen(false);
    window.scrollTo(0, 0);
  };

  //import PDF STUFF
  const insertAt = (index: number, value: any, arrayName: any[]) => {
    return [
      ...arrayName?.slice(0, index),
      value,
      ...arrayName?.slice(index, arrayName?.length),
    ];
  };
  const [images, setImages] = useState<any>([]);
  console.log("images1710------------ :", images);
  const handleImportPDF = (e: ChangeEvent<HTMLInputElement>) => {
    setAnchorElTemplate(null);

    console.log("bro", e?.target?.files?.[0]);
    const file = e?.target?.files?.[0];
    if (!file) {
      showError("Invalid file type");
      return;
    }
    e.target.value = "";
    if (
      file?.name
        ?.split(".")
        [file.name?.split(".")?.length - 1].toLocaleLowerCase() !== "pdf"
    ) {
      showError("Invalid file type , only pdf supported");
      return;
    }
    convertPdfToImages(file);
  };

  const convertPdfToImages = async (file: File) => {
    setIsLoading(true);
    const fileReader = new FileReader();
    fileReader.onload = async () => {
      try {
        const pdfBytes = fileReader.result;
        const loadingTask = pdfjsLib.getDocument({
          data: pdfBytes as ArrayBuffer,
          disableStream: true,
          disableAutoFetch: true,
        });
        const pdf = await loadingTask.promise;
        const imageArray = [];

        for (let pageNumber = 1; pageNumber <= pdf.numPages; pageNumber++) {
          const page = await pdf.getPage(pageNumber);
          const viewport = page.getViewport({ scale: 2 }); // Lower scale for less resolution

          const canvas = document.createElement("canvas");
          const context: any = canvas.getContext("2d");
          canvas.height = viewport.height;
          canvas.width = viewport.width;

          const renderContext: any = {
            canvasContext: context,
            viewport: viewport,
          };

          await page.render(renderContext).promise;
          const imgSrc = canvas.toDataURL();

          // Reduce image size before pushing
          const resizedImage = await resizeSingleImage(imgSrc);
          imageArray.push({ image: resizedImage, index: imageArray.length });

          // Clean up resources to free memory
          page.cleanup();
          context.clearRect(0, 0, canvas.width, canvas.height);
        }

        setImages(imageArray);
      } catch (error: any) {
        console.log(error);
        showError(
          "Something is wrong with PDF, it might be encrypted or corrupted"
        );
        setIsLoading(false);
      }
    };
    fileReader.readAsArrayBuffer(file);
  };

  const resizeSingleImage = (image: string): Promise<string> => {
    return new Promise((resolve, reject) => {
      const img = document.createElement("img");
      img.onload = () => {
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");

        if (!ctx) {
          reject("Failed to get canvas context");
          return;
        }

        // Set width and height
        canvas.width = CANVAS_WIDTH;
        canvas.height = CANVAS_HEIGHT;

        // Draw image and export to a data-uri
        ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
        const dataURI = canvas.toDataURL();
        resolve(dataURI);
      };

      img.onerror = () => reject("Failed to load image");

      img.src = image;
    });
  };

  const importPDFOnCanvas = async (images: any[]) => {
    let tempBinaryData: any[] = [];
    const tempCanvasHeightArray: any[] = [];

    const loadImageOnCanvas = async (key: any) => {
      return new Promise<string>((resolve, reject) => {
        const img = new Image();
        img.src = key?.image;
        img.onload = () => {
          const canvas = document.createElement("canvas");
          const canvasInstance = new fabric.Canvas(canvas, {
            width: CANVAS_WIDTH,
            height: CANVAS_HEIGHT,
          });

          canvasInstance.backgroundColor = new fabric.Pattern({ source: img });
          handleInitialText(canvasInstance);
          canvasInstance.renderAll();

          console.log("tempBinaryData catching");
          const temp = JSON.stringify(canvasInstance?.toJSON());
          tempCanvasHeightArray.push(CANVAS_HEIGHT);
          canvasInstance.dispose(); // Free up memory
          resolve(temp); // Resolve the promise when done
        };

        img.onerror = () => reject("Failed to load image");
      });
    };

    // Sequentially process each image to reduce memory usage
    for (const image of images) {
      const result = await loadImageOnCanvas(image);
      tempBinaryData.push(result);
    }

    canvasHeightArray = tempCanvasHeightArray;
    canvas.setHeight(CANVAS_HEIGHT);
    console.log("tempBinaryData :", tempBinaryData);
    setSlideList(images.map((data) => data.image));
    setImages([]);
    setBinaryDataArray(tempBinaryData);
    setCanvasHeight(tempCanvasHeightArray[0]);
    console.log("tempCanvasHeightArray[0] :", tempCanvasHeightArray[0]);
    adjustCanvasHeight2(tempCanvasHeightArray[0], canvas);
    await canvas?.requestRenderAll();
    await canvas?.renderAll();
    setSelectedSlideIndex(0);
    setIsLoading(false);
  };

  useEffect(() => {
    if (images?.length) {
      setBinaryDataUpdated(false);
      importPDFOnCanvas(images);
    }
  }, [images]);
  const [ruler, setRuler] = useState(false);
  const [displayRuler, setDisplayRuler] = useState(0);
  const textButtonRef: any = useRef(null);
  return (
    <>
      {displayRuler ? <Ruler rulerType={displayRuler} /> : ""}

      <main>
        <Loader isLoad={isLoading} />
        <section className="prev_ed pres_bk note_taking_edit">
          <div className="prev_ed_lt sde_br_botm">
            <div className=" edit_note_button">
              {noteName
                ? noteName?.length > 30
                  ? noteName?.slice(0, 20) + "..."
                  : noteName
                : "Create Note"}{" "}
              <button
                className="edit_icon"
                onClick={() => {
                  setNoteName2(noteName);
                  setRenameOpen(true);
                }}
              >
                <figure>
                  <img
                    loading="lazy"
                    src="/static/images/edit_icon.svg"
                    alt=""
                  />
                </figure>
              </button>{" "}
            </div>
            <div className="sde_br_flx">
              <div className="but_bottom but_resp">
                <div>
                  {!editModeHide && (
                    <Button
                      className="btnn sm"
                      onClick={() => {
                        // showToast(!isDisabled?"Edit mode activated":"Edit Mode deactivated")
                        setIsDisabled(!isDisabled);
                        // setThemeDrawer(true);
                        // handleCloseTemplate();
                      }}
                    >
                      {isDisabled ? <DoNotTouchRounded /> : <PanToolRounded />}
                    </Button>
                  )}
                  <button
                    ref={textButtonRef}
                    className={fakeSelectedButton == 1 ? "active" : ""}
                    onClick={() => {
                      if (canvas) {
                        if (currentImage) canvas.discardActiveObject();
                        canvas.renderAll();
                      }
                      // setDisplayRuler(0);
                      setHighlightMode(false);
                      isHighlight = false;
                      if (true) {
                        if (selectedButton == 1) {
                          setSelectedButton(0);
                        } else {
                          setSelectedButton(1);
                          setFakeSelectedButton(1);
                        }
                      } else {
                        setSelectedButton(1);
                      }
                      if (drawingMode || highlightMode) {
                        handleStopDrawing();
                      }
                      cancelCrop();
                    }}
                  >
                    <figure style={fakeSelectedButton == 1 ? {} : {}}>
                      <img src="/static/images/text_icon.svg" alt="" />
                      <figcaption>Text</figcaption>
                    </figure>
                  </button>
                  <button
                    className={fakeSelectedButton == 2 ? "active" : ""}
                    onClick={() => {
                      isHighlight = false;
                      if (canvas) {
                        if (currentImage) canvas.discardActiveObject();
                        canvas.renderAll();
                      }
                      setHighlightMode(false);
                      // setDisplayRuler(0);

                      if (true) {
                        if (selectedButton == 2) {
                          setSelectedButton(0);
                        } else {
                          setSelectedButton(2);
                          setFakeSelectedButton(2);
                        }
                      } else {
                        setSelectedButton(2);
                      }
                      if (drawingMode || highlightMode) {
                        handleStopDrawing();
                      }
                      cancelCrop();

                      // cancelCrop();
                    }}
                  >
                    <figure style={fakeSelectedButton == 2 ? {} : {}}>
                      <img src="/static/images/media_icon.svg" alt="" />
                      <figcaption>Media</figcaption>
                    </figure>
                  </button>
                  <button
                    className={fakeSelectedButton == 3 ? "active" : ""}
                    onClick={() => {
                      if (canvas) {
                        canvas.discardActiveObject();
                        canvas.renderAll();
                      }
                      setHighlightMode(false);
                      // setDisplayRuler(0);

                      isHighlight = false;
                      if (true) {
                        if (selectedButton == 3) {
                          setSelectedButton(0);
                        } else {
                          setSelectedButton(3);
                          setFakeSelectedButton(3);
                        }
                      } else {
                        setSelectedButton(3);
                      }
                      canvas.discardActiveObject();
                      handleEraserSettings();
                      handleStartDrawing();
                      cancelCrop();
                    }}
                  >
                    <figure style={fakeSelectedButton == 3 ? {} : {}}>
                      <img src="/static/images/pencil_icon.svg" alt="" />
                      <figcaption>Pencil</figcaption>
                    </figure>
                  </button>
                  <button
                    className={fakeSelectedButton == 4 ? "active" : ""}
                    onClick={() => {
                      if (canvas) {
                        canvas.discardActiveObject();
                        canvas.renderAll();
                      }

                      if (true) {
                        if (selectedButton == 4) {
                          setSelectedButton(0);
                        } else {
                          setSelectedButton(4);
                          setFakeSelectedButton(4);
                        }
                      } else {
                        setSelectedButton(4);
                      }
                      // setDisplayRuler(0);
                      isHighlight = true;
                      handleEraserSettings();
                      handleHighlightMode();
                      cancelCrop();
                    }}
                  >
                    <figure style={fakeSelectedButton == 4 ? {} : {}}>
                      <img src="/static/images/highlighter_icon.svg" alt="" />
                      <figcaption>Highlighter</figcaption>
                    </figure>
                  </button>
                  <button
                    className={fakeSelectedButton == 5 ? "active" : ""}
                    onClick={() => {
                      if (canvas.getActiveObject()?.type !== "image")
                        canvas.discardActiveObject();
                      setHighlightMode(false);

                      if (true) {
                        if (selectedButton == 5) {
                          setSelectedButton(0);
                        } else {
                          setSelectedButton(5);
                          setFakeSelectedButton(5);
                        }
                      } else {
                        setSelectedButton(5);
                      }
                      // setDisplayRuler(0);
                      isHighlight = false;
                      if (drawingMode || highlightMode) {
                        handleStopDrawing();
                      }
                    }}
                  >
                    <figure style={fakeSelectedButton == 5 ? {} : {}}>
                      <img src="/static/images/crop_icon.svg" alt="" />
                      <figcaption>Crop</figcaption>
                    </figure>
                  </button>

                  <button
                    className={fakeSelectedButton == 6 ? "active" : ""}
                    onClick={() => {
                      if (displayRuler) {
                        setDisplayRuler(0);
                        // return;
                      }
                      if (canvas.getActiveObject()?.type !== "image")
                        canvas.discardActiveObject();
                      setHighlightMode(false);
                      isHighlight = false;
                      if (drawingMode || highlightMode) {
                        handleStopDrawing();
                      }
                      if (selectedButton == 6) {
                        setSelectedButton(0);
                      } else {
                        setSelectedButton(6);
                        setFakeSelectedButton(6);
                      }
                    }}
                  >
                    <figure>
                      <Straighten htmlColor="white" />
                      <figcaption>Ruler</figcaption>
                    </figure>
                  </button>
                  <button
                    onClick={() => {
                      stopReading = true;
                      const mohit = canvas?.getObjects()?.length;
                      console.log("mohit1012 :", mohit);

                      canvas.forEachObject((Element: any, index: any) => {
                        if (!(Element instanceof fabric.Textbox)) {
                          canvas.remove(Element);
                          if (index == mohit - 2) {
                            stopReading = false;
                          }
                        } else {
                          Element.set(
                            "text",
                            "\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n"
                          );
                          canvas.setHeight(CANVAS_HEIGHT);
                          canvas.discardActiveObject();
                          window.scrollTo(0, 0);
                          canvas.renderAll();
                        }
                      });
                      stopReading = false;
                      canvas.requestRenderAll();
                      // setDisplayRuler(0);
                    }}
                  >
                    <figure>
                      {/* <img loading="lazy" src="/static/images/pen3.svg" alt="" /> */}
                      <img src="/static/images/clear_icon.svg" alt="" />
                      <figcaption>Clear</figcaption>
                    </figure>
                  </button>
                  {/* <button
                    className={selectedButton == 1 ? "active" : ""}
                    onClick={() => {
                      if (canvas) {
                        canvas.discardActiveObject();
                        canvas.renderAll();
                      }

                      setHighlightMode(false);
                      isHighlight = false;
                      if (togglable) {
                        if (selectedButton == 1) {
                          setSelectedButton(0);
                        } else {
                          setSelectedButton(1);
                        }
                      } else {
                        setSelectedButton(1);
                      }
                      if (drawingMode || highlightMode) {
                        handleStopDrawing();
                      }
                      cancelCrop();
                    }}
                  >
                    <figure>
                      <img loading="lazy" src="/static/images/t1.svg" alt="" />
                    </figure>
                  </button>
                  <button
                    className={selectedButton == 2 ? "active" : ""}
                    onClick={() => {
                      isHighlight = false;
                      if (canvas) {
                        canvas.discardActiveObject();
                        canvas.renderAll();
                      }
                      setHighlightMode(false);

                      if (togglable) {
                        if (selectedButton == 2) {
                          setSelectedButton(0);
                        } else {
                          setSelectedButton(2);
                        }
                      } else {
                        setSelectedButton(2);
                      }
                      if (drawingMode || highlightMode) {
                        handleStopDrawing();
                      }
                      cancelCrop();

                      // cancelCrop();
                    }}
                  >
                    <figure>
                      <img loading="lazy" src="/static/images/media.svg" alt="" />
                    </figure>
                  </button>
                  <button
                    className={selectedButton == 3 ? "active" : ""}
                    onClick={() => {
                      if (canvas) {
                        canvas.discardActiveObject();
                        canvas.renderAll();
                      }
                      setHighlightMode(false);

                      isHighlight = false;
                      if (togglable) {
                        if (selectedButton == 3) {
                          setSelectedButton(0);
                        } else {
                          setSelectedButton(3);
                        }
                      } else {
                        setSelectedButton(3);
                      }
                      canvas.discardActiveObject();
                      handleEraserSettings();
                      handleStartDrawing();
                      cancelCrop();
                    }}
                  >
                    <figure>
                      <img loading="lazy" src="/static/images/t7.svg" alt="" />
                    </figure>
                  </button>
                  <button
                    className={selectedButton == 4 ? "active" : ""}
                    onClick={() => {
                      if (canvas) {
                        canvas.discardActiveObject();
                        canvas.renderAll();
                      }

                      if (togglable) {
                        if (selectedButton == 4) {
                          setSelectedButton(0);
                        } else {
                          setSelectedButton(4);
                        }
                      } else {
                        setSelectedButton(4);
                      }
                      isHighlight = true;
                      handleEraserSettings();
                      handleHighlightMode();
                      cancelCrop();
                    }}
                  >
                    <figure>
                      <img loading="lazy" src="/static/images/pen2.svg" alt="" />
                    </figure>
                  </button>
                  <button
                    className={selectedButton == 5 ? "active" : ""}
                    onClick={() => {
                      if (canvas.getActiveObject()?.type !== "image")
                        canvas.discardActiveObject();
                      setHighlightMode(false);

                      if (togglable) {
                        if (selectedButton == 5) {
                          setSelectedButton(0);
                        } else {
                          setSelectedButton(5);
                        }
                      } else {
                        setSelectedButton(5);
                      }
                      isHighlight = false;
                      if (drawingMode || highlightMode) {
                        handleStopDrawing();
                      }
                    }}
                  >
                    <figure>
                      <img loading="lazy" src="/static/images/pen3.svg" alt="" />
                    </figure>
                  </button> */}
                  {/* <button onClick={() => {
                    const img = new Image();
                    img.src = "/static/images/ppt/sample.png";
                    img.onload = () => {
                      const image = new fabric.Image(img, {
                        width: CANVAS_WIDTH,
                        height: CANVAS_HEIGHT,
                        selectable: false, // make sure the background image is not selectable
                      });
                      canvas.backgroundColor = new fabric.Pattern({ source: img })
                      // canvas.backgroundImage = image; // set the background image
                      canvas.renderAll(); // render the canvas
                    };
                  }}>
                    PATTERN
                  </button> */}
                </div>
                {/* <Button  onClick={()=>setDrawerState(true)} size="small">All pages</Button> */}
              </div>
              <div className="but_gp mt_0">
                <div>
                  {" "}
                  <IconButton
                    aria-label="more"
                    id="long-button"
                    aria-controls={openTemplate ? "long-menu" : undefined}
                    aria-expanded={openTemplate ? "true" : undefined}
                    aria-haspopup="true"
                    onClick={handleClickTemplate}
                  >
                    <MoreVert htmlColor="white" />
                  </IconButton>
                  <Menu
                    id="long-menu"
                    className="more_drop"
                    MenuListProps={{
                      "aria-labelledby": "long-button",
                    }}
                    anchorEl={anchorElTemplate}
                    open={openTemplate}
                    onClose={handleCloseTemplate}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "right",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                  >
                    <MenuItem>
                      <Button
                        className="btnn sm"
                        onClick={() => {
                          setThemeDrawer(true);
                          handleCloseTemplate();
                          setAnchorElTemplate(null);
                        }}
                      >
                        {translate.noteTaking.chooseTemplate ||
                          "Choose template"}
                      </Button>
                    </MenuItem>
                    <MenuItem>
                      <label htmlFor="icon-button-files">
                        <div
                          className="uploader"
                          style={{
                            padding: "5px 17px 0px",
                            color: "black",
                            fontSize: "12.5px",
                            cursor: "pointer",
                          }}
                        >
                          <input
                            style={{ display: "none" }}
                            id="icon-button-files"
                            type="file"
                            onChange={handleImportPDF}
                            accept=".pdf"
                            // multiple
                          />
                          Import PDF
                        </div>
                      </label>
                    </MenuItem>
                    <MenuItem>
                      <Button
                        className="btnn sm"
                        onClick={() => {
                          setAnchorElTemplate(null);
                          downloadPDF();
                          // setThemeDrawer(true);
                          // handleCloseTemplate();
                        }}
                      >
                        {translate.global.download || "Download"}
                      </Button>
                    </MenuItem>
                    {/* <MenuItem>
                      {togglable && (
                        <Button
                          className="btnn sm"
                          onClick={() => {
                            // showToast(!isDisabled?"Edit mode activated":"Edit Mode deactivated")
                            setIsDisabled(!isDisabled);
                            // setThemeDrawer(true);
                            // handleCloseTemplate();
                          }}
                        >
                          {isDisabled ? (
                            <DoNotTouchRounded />
                          ) : (
                            <PanToolRounded />
                          )}
                        </Button>
                      )}
                    </MenuItem> */}
                    <MenuItem>
                      <Button
                        className="btnn sm"
                        onClick={() => {
                          handleAddUpdateNote();
                          setAnchorElTemplate(null);
                          // setThemeDrawer(true);
                          // handleCloseTemplate();
                        }}
                      >
                        {id
                          ? translate.global.update || "Update"
                          : translate.global.save || "Save"}
                      </Button>
                    </MenuItem>
                  </Menu>
                </div>
              </div>
            </div>
            <div className="sde_hdr sde_back_but">
              {
                <div className="sde_ed_bt">
                  <div
                    onClick={() => {
                      const message =
                        translate.main.editedDataWillLost ||
                        "Are you sure you want to leave? Edited data will be lost.";
                      // alert(message);
                      if (window.confirm(message)) {
                        if (searchParam.get("app")) {
                          //@ts-ignore
                          window["ReactNativeWebView"] &&
                            //@ts-ignore
                            window["ReactNativeWebView"].postMessage("back");
                        } else navigate("/note-taking", { replace: true });
                      }
                      console.log("kkkkkkkkkkkkk");
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    <ArrowBackIosNewIcon />
                  </div>
                  <div className="edit_div">
                    {" "}
                    <h2>
                      {noteName ? (
                        noteName?.length > 6 ? (
                          <Tooltip title={noteName}>
                            <Typography>
                              {noteName.slice(0, 6) + "..."}
                            </Typography>
                          </Tooltip>
                        ) : (
                          noteName
                        )
                      ) : (
                        "Create Note"
                      )}
                    </h2>
                    <button
                      className="edit_icon"
                      onClick={() => {
                        setNoteName2(noteName);
                        setRenameOpen(true);
                      }}
                    >
                      <figure>
                        <img
                          loading="lazy"
                          src="/static/images/edit_icon.svg"
                          alt=""
                        />
                      </figure>
                    </button>
                  </div>
                </div>
              }
            </div>
            {selectedButton ? (
              <div className="sde_br_mn sde_br_new">
                {/* <h2>Edit Note</h2> */}
                <div className="sde_hdr_bt">
                  {selectedButton == 1 && (
                    <>
                      <div className="frmn_mn">
                        <Select
                          className="control_group"
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={selectedFontFace}
                          onChange={handleFontFaceChange}
                        >
                          <MenuItem value={""} disabled>
                            {translate.global.select || "Select"}
                          </MenuItem>
                          {fontFaces?.map((data: string) => {
                            return <MenuItem value={data}>{data}</MenuItem>;
                          })}
                        </Select>
                      </div>
                      <div className="frmn_mn">
                        <Select
                          className="control_group"
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={selectedFontSize.toString()}
                          onChange={handleFontChange}
                        >
                          {/* <MenuItem value={8}>1</MenuItem> */}
                          <MenuItem value={26}>8</MenuItem>
                          <MenuItem value={30}>12</MenuItem>
                          <MenuItem value={36}>18</MenuItem>
                          <MenuItem value={38}>22</MenuItem>
                          <MenuItem value={42}>26</MenuItem>
                          <MenuItem value={46}>30</MenuItem>
                          <MenuItem value={50}>34</MenuItem>
                          <MenuItem value={52}>38</MenuItem>
                        </Select>
                      </div>

                      {/* <button>
                      <figure>
                        <img loading="lazy" src="/static/images/t2.svg" alt="image" />
                      </figure>
                    </button>
    
                    <button>
                      <figure>
                        <img loading="lazy" src="/static/images/t5.svg" alt="image" />
                      </figure>
                    </button>
                    <button>
                      <figure>
                        <img loading="lazy" src="/static/images/t4.svg" alt="image" />
                      </figure>
                    </button>
                    <button>
                      <figure>
                        <img loading="lazy" src="/static/images/t6.svg" alt="image" />
                      </figure>
                    </button>
                    <button>
                      <figure>
                        <img loading="lazy" src="/static/images/B.svg" alt="image" />
                      </figure>
                    </button> */}
                      <button
                        style={{ cursor: "pointer" }}
                        onClick={() => handleAddRichText()}
                      >
                        <figure style={{ cursor: "pointer" }}>
                          <PostAddRounded htmlColor="white" />
                        </figure>
                      </button>
                      <input
                        type="color"
                        className="colorPickMohit"
                        value={selectedColor}
                        onChange={(e) => {
                          handleColorChange(e.target.value);
                        }}
                      />
                      {/* <button style={{cursor:"pointer"}} onClick={handleIncreaseFontSize}>
                      <figure style={{cursor:"pointer"}}>
                        <TextIncreaseOutlined htmlColor="white"/>
                      </figure>
                    </button>
                    <button style={{cursor:"pointer"}} onClick={handleDecreaseFontSize}>
                      <figure style={{cursor:"pointer"}}>
                        <TextDecreaseOutlined htmlColor="white"/>
                      </figure>
                    </button> */}
                      <button
                        style={{ cursor: "pointer" }}
                        onClick={handleAddShape}
                      >
                        <figure style={{ cursor: "pointer" }}>
                          <SquareOutlined htmlColor="white" />
                        </figure>
                      </button>
                      <button
                        style={{ cursor: "pointer" }}
                        onClick={handleAddCircle}
                      >
                        <figure style={{ cursor: "pointer" }}>
                          <CircleOutlined htmlColor="white" />
                        </figure>
                      </button>
                      <button
                        style={{ cursor: "pointer" }}
                        onClick={handleAddTriangle}
                      >
                        <figure style={{ cursor: "pointer" }}>
                          <ChangeHistory htmlColor="white" />
                        </figure>
                      </button>
                      <button
                        style={{ cursor: "pointer" }}
                        onClick={handleAlignLeft}
                      >
                        <figure style={{ cursor: "pointer" }}>
                          <FormatAlignLeftOutlined htmlColor="white" />
                        </figure>
                      </button>
                      <button
                        style={{ cursor: "pointer" }}
                        onClick={handleAlignCenter}
                      >
                        <figure style={{ cursor: "pointer" }}>
                          <FormatAlignCenterOutlined htmlColor="white" />
                        </figure>
                      </button>
                      <button
                        style={{ cursor: "pointer" }}
                        onClick={handleAlignRight}
                      >
                        <figure style={{ cursor: "pointer" }}>
                          <FormatAlignRightOutlined htmlColor="white" />
                        </figure>
                      </button>
                      <button
                        style={{ cursor: "pointer" }}
                        onClick={handleBold}
                      >
                        <figure style={{ cursor: "pointer" }}>
                          <FormatBoldOutlined htmlColor="white" />
                        </figure>
                      </button>
                      <button
                        style={{ cursor: "pointer" }}
                        onClick={handleItalic}
                      >
                        <figure style={{ cursor: "pointer" }}>
                          <FormatItalicOutlined htmlColor="white" />
                        </figure>
                      </button>
                      <button
                        style={{ cursor: "pointer" }}
                        onClick={handleUnderline}
                      >
                        <figure style={{ cursor: "pointer" }}>
                          <FormatUnderlinedOutlined htmlColor="white" />
                        </figure>
                      </button>

                      <button style={{ cursor: "pointer" }} onClick={Copy}>
                        <figure style={{ cursor: "pointer" }}>
                          <ContentCopyOutlined htmlColor="white" />
                        </figure>
                      </button>
                      <button style={{ cursor: "pointer" }} onClick={Paste}>
                        <figure style={{ cursor: "pointer" }}>
                          <ContentPasteOutlined htmlColor="white" />
                        </figure>
                      </button>
                      <button
                        style={{ cursor: "pointer" }}
                        onClick={undoFabric}
                      >
                        <figure style={{ cursor: "pointer" }}>
                          <UndoSharp htmlColor="white" />
                        </figure>
                      </button>
                      <button
                        style={{ cursor: "pointer" }}
                        onClick={redoFabric}
                      >
                        <figure style={{ cursor: "pointer" }}>
                          <RedoSharp htmlColor="white" />
                        </figure>
                      </button>
                      <button
                        style={{ cursor: "pointer" }}
                        onClick={handleDelete}
                      >
                        <figure style={{ cursor: "pointer" }}>
                          <Delete htmlColor="white" />
                        </figure>
                      </button>
                    </>
                  )}
                  {selectedButton == 2 && (
                    <div className="top_dv">
                      {/* <input accept='image/*' id="ppt" type="file" onChange={handleUploadImage} style={{display:"none"}}/>
                  <label htmlFor="ppt" style={{cursor:"pointer"}}> <button style={{cursor:"pointer"}}>
                    <figure style={{cursor:"pointer"}}>
                    <AddPhotoAlternateOutlined htmlColor="white"/>
                    </figure>
                  </button></label> */}

                      {!Boolean(cropCanvas) && (
                        <React.Fragment>
                          <button
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              setStickerOpen(true);
                            }}
                          >
                            <StickyNote2Sharp htmlColor="white" />
                          </button>
                          <button
                            style={{ cursor: "pointer" }}
                            onClick={handleCropCanvasSquare}
                          >
                            <figure style={{ cursor: "pointer" }}>
                              <CropFree htmlColor="white" />
                            </figure>
                          </button>
                          <label htmlFor="icon-button-files">
                            <div className="uploader">
                              <input
                                style={{ display: "none" }}
                                id="icon-button-files"
                                type="file"
                                onChange={handleUploadImage}
                                accept=".png, .jpg, .jpeg"
                                // multiple
                              />
                              <figure
                                style={{
                                  height: "20px",
                                  width: "20px",
                                  cursor: "pointer",
                                }}
                              >
                                <AddPhotoAlternateOutlined
                                  sx={{ color: "white" }}
                                />
                              </figure>
                            </div>
                          </label>
                          <button
                            style={{ cursor: "pointer" }}
                            onClick={() => handleDelete()}
                          >
                            <figure style={{ cursor: "pointer" }}>
                              <Delete htmlColor="white" />
                            </figure>
                          </button>
                          <button
                            style={{ cursor: "pointer" }}
                            id="basic-button"
                            aria-controls={open1 ? "basic-menu" : undefined}
                            aria-haspopup="true"
                            aria-expanded={open1 ? "true" : undefined}
                            onClick={handleClick1}
                          >
                            <figure style={{ cursor: "pointer" }}>
                              {/* <FilterBAndW htmlColor="white" /> */}
                              <img
                                loading="lazy"
                                src="/static/images/filter.svg"
                                alt=""
                              />
                            </figure>
                          </button>
                          <Menu
                            id="basic-menu"
                            anchorEl={anchorEl1}
                            open={open1}
                            onClose={handleClose1}
                            MenuListProps={{
                              "aria-labelledby": "basic-button",
                            }}
                            onClick={(e) => {
                              console.log("filter", e);
                            }}
                          >
                            <MenuItem onClick={() => handleAddFilter(1)}>
                              Kodachrome
                            </MenuItem>
                            <MenuItem onClick={() => handleAddFilter(2)}>
                              Brownie
                            </MenuItem>
                            <MenuItem onClick={() => handleAddFilter(3)}>
                              Grayscale
                            </MenuItem>
                            <MenuItem onClick={() => handleAddFilter(4)}>
                              Invert
                            </MenuItem>
                            <MenuItem onClick={() => handleAddFilter(5)}>
                              Sepia
                            </MenuItem>
                            <MenuItem onClick={() => handleAddFilter(6)}>
                              Brighten
                            </MenuItem>
                            <MenuItem onClick={() => handleAddFilter(7)}>
                              Contrasted
                            </MenuItem>
                            <MenuItem onClick={() => handleAddFilter(8)}>
                              Blur
                            </MenuItem>
                            <MenuItem onClick={() => handleAddFilter(9)}>
                              Pixelate
                            </MenuItem>
                            <MenuItem onClick={() => handleAddFilter(10)}>
                              Techno-Color
                            </MenuItem>
                            <MenuItem onClick={() => handleAddFilter(11)}>
                              Polaroid
                            </MenuItem>
                            <MenuItem onClick={() => handleAddFilter(12)}>
                              Vintage
                            </MenuItem>
                            <MenuItem onClick={() => handleAddFilter(13)}>
                              Clear all filters
                            </MenuItem>
                          </Menu>
                        </React.Fragment>
                      )}
                      {Boolean(cropCanvas) && (
                        <button
                          style={{ cursor: "pointer" }}
                          onClick={handleCropCanvasPaste}
                        >
                          <figure style={{ cursor: "pointer" }}>
                            <ContentPasteOutlined htmlColor="white" />
                          </figure>
                        </button>
                      )}
                    </div>
                  )}
                  {selectedButton == 3 && (
                    <>
                      <input
                        type="color"
                        className="colorPickMohit"
                        value={selectedColor}
                        onChange={(e) => {
                          handleColorChange(e.target.value);
                        }}
                      />
                      <Typography>Size</Typography>

                      <input
                        type="range"
                        min="1"
                        max="10"
                        style={{ padding: 0 }}
                        value={`${penSize}`}
                        onChange={(value) => {
                          console.log(value.target.value);
                          if (value.target.value) {
                            setPenSize(Number(value.target.value));
                            if (canvas && canvas.freeDrawingBrush) {
                              canvas.freeDrawingBrush.width =
                                value.target.value;
                              if (dotted)
                                canvas.freeDrawingBrush.strokeDashArray = [
                                  value.target.value,
                                  Number(value.target.value) * 3,
                                ];
                            }
                          }
                        }}
                      />
                      <button
                        style={{ cursor: "pointer" }}
                        onClick={handleStartEraser}
                      >
                        <figure style={{ cursor: "pointer" }}>
                          <img
                            loading="lazy"
                            src="/static/images/eraser.svg"
                            color="white"
                            alt=""
                          />
                        </figure>
                      </button>
                      <button
                        style={{ cursor: "pointer" }}
                        onClick={handleStartDrawing}
                      >
                        <figure style={{ cursor: "pointer" }}>
                          <Draw htmlColor="white" />
                          {/* <img loading="lazy" src="/static/images/t7.svg" alt="" /> */}
                        </figure>
                      </button>
                      <button
                        style={{ cursor: "pointer" }}
                        onClick={handleStartDrawingDotted}
                      >
                        <figure style={{ cursor: "pointer" }}>
                          <EditRoad htmlColor="white" />
                          {/* <img loading="lazy" src="/static/images/t7.svg" alt="" /> */}
                        </figure>
                      </button>
                      <button
                        style={{ cursor: "pointer" }}
                        id="basic-button"
                        aria-controls={open ? "basic-menu" : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? "true" : undefined}
                        onClick={handleClick0}
                      >
                        <figure style={{ cursor: "pointer" }}>
                          {/* <Polyline htmlColor="white" /> */}
                          <img
                            loading="lazy"
                            src="/static/images/tape.svg"
                            alt=""
                          />
                        </figure>
                      </button>
                      <Menu
                        id="basic-menu"
                        anchorEl={anchorEl0}
                        open={open}
                        onClose={handleClose0}
                        MenuListProps={{
                          "aria-labelledby": "basic-button",
                        }}
                      >
                        <MenuItem onClick={() => handleStartDrawingTape(1)}>
                          Tape 1
                        </MenuItem>
                        <MenuItem onClick={() => handleStartDrawingTape(2)}>
                          Tape 2
                        </MenuItem>
                        <MenuItem onClick={() => handleStartDrawingTape(3)}>
                          Tape 3
                        </MenuItem>
                        <MenuItem onClick={() => handleStartDrawingTape(4)}>
                          Tape 4
                        </MenuItem>
                        <MenuItem onClick={() => handleStartDrawingTape(5)}>
                          Tape 5
                        </MenuItem>
                        <MenuItem onClick={() => handleStartDrawingTape(6)}>
                          Tape 6
                        </MenuItem>
                      </Menu>

                      {/* <button>
                    <figure>
                    <img loading="lazy" src="/static/images/t6.svg" alt="image" />
                    </figure>
                  </button> */}
                    </>
                  )}
                  {selectedButton == 4 && (
                    <>
                      <input
                        type="color"
                        className="colorPickMohit"
                        value={highlightColor}
                        onChange={changeHighlightColor}
                      />
                      <Typography>Size</Typography>
                      <input
                        type="range"
                        style={{ padding: 0 }}
                        min="1"
                        max="10"
                        value={`${penSize}`}
                        onChange={(value) => {
                          console.log(value.target.value);
                          if (value.target.value) {
                            setPenSize(Number(value.target.value));
                            if (canvas && canvas.freeDrawingBrush) {
                              canvas.freeDrawingBrush = new fabric.PencilBrush(
                                canvas
                              );
                              canvas.freeDrawingBrush.color =
                                highlightColor + "40";
                              canvas.freeDrawingBrush.width = Number(
                                value.target.value
                              );
                            }
                          }
                        }}
                      />
                      <button
                        style={{ cursor: "pointer" }}
                        onClick={handleStartEraser}
                      >
                        <figure style={{ cursor: "pointer" }}>
                          <img
                            loading="lazy"
                            src="/static/images/eraser.svg"
                            color="white"
                            alt=""
                          />
                        </figure>
                      </button>
                      <button
                        style={{ cursor: "pointer" }}
                        onClick={handleHighlightMode}
                      >
                        <figure style={{ cursor: "pointer" }}>
                          <Draw htmlColor="white" />
                          {/* <img loading="lazy" src="/static/images/t7.svg" alt="" /> */}
                        </figure>
                      </button>
                      {/* <button>
                    <figure>
                    <img loading="lazy" src="/static/images/t6.svg" alt="image" />
                    </figure>
                  </button> */}
                    </>
                  )}
                  {selectedButton == 5 && (
                    <div className="top_dv">
                      {/* <input accept='image/*' id="ppt" type="file" onChange={handleUploadImage} style={{display:"none"}}/>
                 <label htmlFor="ppt" style={{cursor:"pointer"}}> <button style={{cursor:"pointer"}}>
                   <figure style={{cursor:"pointer"}}>
                   <AddPhotoAlternateOutlined htmlColor="white"/>
                   </figure>
                 </button></label> */}

                      {/*
                 <button style={{cursor:"pointer"}} onClick={()=>handleCrop()}>
                   <figure style={{cursor:"pointer"}}>
                     <Crop htmlColor="white"/>
                   </figure>
                 </button>
                 <button style={{cursor:"pointer"}} onClick={()=>cropImage()}>
                   <figure style={{cursor:"pointer"}}>
                     <Crop169 htmlColor="white"/>
                   </figure>
                 </button>*/}
                      {isCropping ? (
                        <>
                          <button style={{ cursor: "pointer" }} onClick={crop}>
                            <figure style={{ cursor: "pointer" }}>
                              <Check htmlColor="white" />
                            </figure>
                          </button>
                          <button
                            style={{ cursor: "pointer" }}
                            onClick={cancelCrop}
                          >
                            <figure style={{ cursor: "pointer" }}>
                              <Cancel htmlColor="white" />
                            </figure>
                          </button>
                        </>
                      ) : (
                        <>
                          <button
                            style={{ cursor: "pointer" }}
                            onClick={handleCropMohit}
                          >
                            <figure style={{ cursor: "pointer" }}>
                              <Crop htmlColor="white" />
                            </figure>
                          </button>
                          <button
                            style={{ cursor: "pointer" }}
                            onClick={() => handleDelete()}
                          >
                            <figure style={{ cursor: "pointer" }}>
                              <Delete htmlColor="white" />
                            </figure>
                          </button>
                        </>
                      )}
                    </div>
                  )}
                  {selectedButton == 6 && (
                    <div className="top_dv">
                      <button
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          if (displayRuler == 1) setDisplayRuler(0);
                          else setDisplayRuler(1);
                          setSelectedButton(0);
                        }}
                      >
                        <figure
                          style={{
                            cursor: "pointer",
                            transform: "rotate(90deg)",
                          }}
                        >
                          <Straighten htmlColor="white" />
                        </figure>
                      </button>
                      <button
                        onClick={() => {
                          if (displayRuler == 2) setDisplayRuler(0);
                          else setDisplayRuler(2);
                          setSelectedButton(0);
                        }}
                        style={{ cursor: "pointer" }}
                      >
                        <figure style={{ cursor: "pointer" }}>
                          <Straighten htmlColor="white" />
                        </figure>
                      </button>
                    </div>
                  )}
                </div>
              </div>
            ) : (
              ""
            )}
            <div className="intro_preview">
              {/* /* Here canvas will come */}
              <div
                id="mohit"
                ref={canvasCoverRef}
                style={{
                  pointerEvents: pointerEvents,
                  display: "flex",
                  justifyContent: "center",

                  alignItems: "center",
                }}
              >
                <canvas
                  id="canvas"
                  style={{ border: "1px solid #ccc", position: "absolute" }}
                />
                {/* <input type="color" className="colorPickMohit" onChange={(e)=>{
                    handleColorChange(e.target.value);
              }}/> */}
              </div>
              {/* <div style={{    
              display: "flex",
              justifyContent: "space-between",
              marginInline: "250px"
              }}>
              <Button 
              // onClick={() => navigate("/presentation-down")}
              >
                Delete Slide
              </Button>
              <Button 
              // onClick={() => navigate("/presentation-down")}
              >
                Save Slide
              </Button>
            </div> */}
            </div>
          </div>
          <Button
            className="side_btn"
            onClick={() => setPageDrawer(true)}
            size="small"
            title="Notes Listing"
          >
            <ArrowBackIosNewIcon />
          </Button>
          <Button
            className="side_btn"
            style={{ marginTop: "50px" }}
            onClick={() => setMultiPageDrawer(true)}
            size="small"
            title="Pages"
          >
            <Pages />
          </Button>
        </section>
      </main>
      <Drawer
        anchor="right"
        open={themeDrawer}
        onClose={() => {
          setThemeDrawer(false);
          setSelectedButton(0);
        }}
      >
        <div className="prev_ed_rt">
          <Typography>
            {translate.noteTaking.chooseTemplate || "Choose Template"}
          </Typography>
          <ul className="drawerUl dd">
            {noteTemplate?.map((data: any, index: number) => {
              return (
                <li
                  key={index}
                  // id={selectedTemplateID == data.id ? "selectedTheme" : ""}
                  style={{ cursor: "pointer", color: "black" }}
                >
                  {`${index + 1}) ${data?.name}`}
                  <figure
                    onClick={() => {
                      handleTemplateChange(index);
                      selectedTemplateID = data.id;
                      setThemeDrawer(false);
                    }}
                  >
                    <img
                      loading="lazy"
                      style={{
                        border:
                          selectedTemplateID == data.id
                            ? "5px solid #7548f1"
                            : "",
                      }}
                      src={data?.path ? data?.path : "/static/images/AI1.jpg"}
                      alt="image"
                    />
                  </figure>
                </li>
              );
            })}
          </ul>
        </div>
      </Drawer>
      <Drawer
        anchor="right"
        open={pageDrawer}
        onClose={() => {
          setPageDrawer(false);
        }}
      >
        <div className="prev_ed_rt">
          {/* <Typography>{"Note taking"}</Typography> */}
          <div style={{ position: "absolute", top: 10 }}></div>
          <ul className="prev_ed_lst">
            <li
              onClick={() => {
                setPage(1);

                setSelectedRecentNote(0);
              }}
            >
              <figure className={selectedRecentNote == 0 ? "active" : ""}>
                {/* <img loading="lazy" src="/static/images/media.svg" alt=""></img> */}
                <AllInbox />
              </figure>
              <h3>All</h3>
            </li>
            <li
              onClick={() => {
                setPage(1);
                setSelectedRecentNote(1);
              }}
            >
              <figure className={selectedRecentNote == 1 ? "active" : ""}>
                {/* <img loading="lazy" src="/static/images/media.svg" alt=""></img>
                 */}
                <Favorite />
              </figure>
              <h3>Favorite</h3>
            </li>
            <li
              onClick={() => {
                setPage(1);
                setSelectedRecentNote(2);
              }}
            >
              <figure className={selectedRecentNote == 2 ? "active" : ""}>
                {/* <img loading="lazy" src="/static/images/media.svg" alt=""></img> */}
                <Timelapse />
              </figure>
              <h3>Recent</h3>
            </li>
          </ul>
          <div className="search_field">
            <TextField
              placeholder="Search"
              value={debouncedTerm}
              onChange={(e: any) => {
                setDebouncedTerm(e.target.value);
                if (debounce) clearTimeout(debounce);
                debounce = setTimeout(() => {
                  setSearchQuery(e.target.value);
                  setPage(1);
                }, 1000);
              }}
              fullWidth
              hiddenLabel
            ></TextField>
            <div
              className="cross_but"
              onClick={() => {
                setSearchQuery("");
                setDebouncedTerm("");
              }}
            >
              <InputAdornment position="end">
                <Close />
              </InputAdornment>
            </div>
          </div>
          <ul className="prev_ed_inr_lst">
            {noteTaking?.length ? (
              noteTaking
                // ?.filter((data2: any) => data2?.type == 1)
                ?.map((data: any) => {
                  return (
                    <li
                      onClick={() => {
                        navigate(
                          (data?.type == 1
                            ? "/note-taking-edit/"
                            : "/edit-note/") + data?.id,
                          { replace: true }
                        );
                        window.location.reload();
                      }}
                    >
                      <figure>
                        <img
                          loading="lazy"
                          style={{
                            maxHeight: "160px",
                            minHeight: "160px",
                            width: "100%",
                          }}
                          src={
                            data?.note_screenshot
                              ? JSON.parse(data?.note_screenshot)
                              : "/static/images/agrement.png"
                          }
                          alt=""
                        ></img>
                      </figure>
                      <h3>
                        {data?.file_name
                          ? data?.file_name?.length < 10
                            ? data?.file_name
                            : data?.file_name?.slice(0, 10) + "..."
                          : "Note"}
                      </h3>
                    </li>
                  );
                })
            ) : selectedRecentNote == 0 ? (
              <p
                style={{
                  marginTop: "100px",
                  width: "100%",
                  textAlign: "center",
                }}
              >
                No notes found
              </p>
            ) : selectedRecentNote == 1 ? (
              <p
                style={{
                  marginTop: "100px",
                  width: "100%",
                  textAlign: "center",
                }}
              >
                No favorite notes found
              </p>
            ) : (
              <p
                style={{
                  marginTop: "100px",
                  width: "100%",
                  textAlign: "center",
                }}
              >
                No recent notes found
              </p>
            )}
          </ul>
          {hasMore ? (
            <p
              style={{ width: "100%", textAlign: "center", marginTop: "20px" }}
            >
              <Button
                style={{ width: "135px", minHeight: "25px" }}
                onClick={() => setPage((prev) => prev + 1)}
              >
                Load more
              </Button>
            </p>
          ) : null}
        </div>
      </Drawer>
      <Drawer
        anchor="right"
        open={multiPageDrawer}
        onClose={() => {
          setMultiPageDrawer(false);
          // setSelectedButton(1);
          handleStopDrawing();
          setDrawerState(false);
        }}
      >
        <div className="prev_ed_rt">
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Typography>Current Page: {selectedSlideIndex + 1}</Typography>
            <Typography>{`Total Pages : ${slideList?.length}`}</Typography>
          </div>
          <ul className="drawerUl dc">
            <figure
              style={{
                width: "100%",
                textAlign: "center",
                marginBottom: "10px",
                cursor: "pointer",
              }}
            >
              {" "}
              <Add
                htmlColor="white"
                onClick={() => {
                  // selectedSlideIndex += 1;
                  setSelectedSlideIndex(selectedSlideIndex + 1);
                  console.log("canvasHeightArray 3975:", canvasHeightArray);
                  canvasHeightArray?.unshift(CANVAS_HEIGHT);
                  console.log("canvasHeightArray 3977:", canvasHeightArray);
                  const img = new Image();
                  img.src =
                    (noteTemplate?.find((data) => 0 == data?.id)
                      ?.path as string) || noteTemplate?.[0]?.path;
                  img.onload = () => {
                    const image = new fabric.Image(img, {
                      width: CANVAS_WIDTH,
                      height: CANVAS_HEIGHT,
                      selectable: false, // make sure the background image is not selectable
                    });
                    const canvas = document.createElement("canvas");
                    const canvasInstance = new fabric.Canvas(canvas, {
                      width: CANVAS_WIDTH,
                      height: CANVAS_HEIGHT,
                      // backgroundColor: "#fff",
                    });
                    canvasInstance.backgroundColor = new fabric.Pattern({
                      source: img,
                    });
                    handleInitialText(canvasInstance);
                    // canvasInstance.backgroundImage = image; // set the background image
                    canvasInstance.renderAll(); // render the canvas
                    // binaryData.push(
                    //   JSON.stringify(canvasInstance.toJSON())
                    // );
                    // };
                    setBinaryDataArray((prev: any) => [
                      JSON.stringify(canvasInstance.toJSON()),
                      ...prev,
                    ]);
                    setSlideList((prev: any) => [
                      canvasInstance.toDataURL(),
                      ...prev,
                    ]);
                  };
                }}
                style={{ background: "grey", borderRadius: "50%" }}
              />
            </figure>
            {slideList?.map((data: any, index: number) => {
              return (
                <li
                  key={index}
                  id={selectedSlideIndex == index ? "selectedPage" : ""}
                  style={{ cursor: "pointer" }}
                >
                  <figure
                    style={{
                      position: "relative",
                      maxHeight: "300px",
                      maxWidth: "200px",
                      overflow: "hidden",
                    }}
                    onClick={() => {
                      pageChangeCanvas(index);
                    }}
                  >
                    {/* <div style={{ position: "relative" }}> */}{" "}
                    {selectedSlideIndex == index ? null : (
                      <Delete
                        onClick={(e) => {
                          if (index <= selectedSlideIndex) {
                            // selectedSlideIndex = selectedSlideIndex - 1;
                            setSelectedSlideIndex(selectedSlideIndex - 1);
                          }
                          console.log(
                            "canvasHeightArray 4195 :",
                            canvasHeightArray
                          );
                          canvasHeightArray = removeFromIndex(
                            index,
                            canvasHeightArray
                          );
                          console.log(
                            "canvasHeightArray 4197:",
                            canvasHeightArray
                          );
                          setSlideList((prev: any) => [
                            ...prev.slice(0, index),
                            ...prev.slice(index + 1, slideList.length),
                          ]);
                          setBinaryDataArray((prev: any) => [
                            ...prev.slice(0, index),
                            ...prev.slice(index + 1, slideList.length),
                          ]);
                          e.stopPropagation();
                        }}
                        color="error"
                        style={{
                          background: "#b0a7a7",
                          position: "absolute",
                          borderRadius: "50%",
                          top: 5,
                          right: 5,
                          padding: "3px",
                        }}
                      />
                    )}
                    {/* </div> */}
                    <img
                      loading="lazy"
                      src={data ? data : "/static/images/AI1.jpg"}
                      style={{
                        width: "100%",
                        height: "100%",
                        border:
                          selectedSlideIndex == index
                            ? "5px solid #7548f1"
                            : "",
                      }}
                      alt="image"
                    />
                  </figure>
                  <figure
                    style={{
                      width: "100%",
                      textAlign: "center",
                      marginTop: "10px",
                    }}
                  >
                    {" "}
                    <Add
                      onClick={() => {
                        if (index < selectedSlideIndex) {
                          // selectedSlideIndex = selectedSlideIndex + 1;
                          setSelectedSlideIndex(selectedSlideIndex + 1);
                        }
                        console.log(
                          "canvasHeightArray 4244:",
                          canvasHeightArray
                        );
                        canvasHeightArray = insertAt(
                          index,
                          CANVAS_HEIGHT,
                          canvasHeightArray
                        );
                        console.log(
                          "canvasHeightArray 4246:",
                          canvasHeightArray
                        );
                        const img = new Image();
                        img.src =
                          (noteTemplate?.find((data) => 0 == data?.id)
                            ?.path as string) || noteTemplate?.[0]?.path;
                        img.onload = () => {
                          const image = new fabric.Image(img, {
                            width: CANVAS_WIDTH,
                            height: CANVAS_HEIGHT,
                            selectable: false, // make sure the background image is not selectable
                          });
                          const canvas = document.createElement("canvas");
                          const canvasInstance = new fabric.Canvas(canvas, {
                            width: CANVAS_WIDTH,
                            height: CANVAS_HEIGHT,
                            // backgroundColor: "#fff",
                            // backgroundImage: image,
                          });
                          canvasInstance.backgroundColor = new fabric.Pattern({
                            source: img,
                          });
                          handleInitialText(canvasInstance);

                          // canvasInstance.backgroundImage = image; // set the background image
                          canvasInstance.renderAll(); // render the canvas
                          // binaryData.push(
                          //   JSON.stringify(canvasInstance.toJSON())
                          // );
                          // };
                          setBinaryDataArray((prev: any) => [
                            ...prev.slice(0, index + 1),
                            JSON.stringify(canvasInstance.toJSON()),
                            ...prev.slice(index + 1, binaryDataArray.length),
                          ]);
                          setSlideList((prev: any) => [
                            ...prev.slice(0, index + 1),
                            canvasInstance.toDataURL(),
                            ...prev.slice(index + 1, slideList.length),
                          ]);
                        };
                      }}
                      style={{ background: "grey", borderRadius: "50%" }}
                    />
                  </figure>
                </li>
              );
            })}
          </ul>
        </div>
      </Drawer>
      {slideList?.length > 1 ? (
        <div className="arrows_u_d">
          <Button className="arrows_u_d_but" onClick={handleNextPage}>
            <ArrowDownward />
          </Button>
          <Button className="arrows_u_d_but" onClick={handlePrevPage}>
            <ArrowUpward />
          </Button>
        </div>
      ) : null}
      <StickerPicker
        open={stickerOpen}
        setOpen={setStickerOpen}
        loadImageAndAdd={loadImageAndAdd}
      />
      <RenameModal
        openEdit={renameOpen}
        setOpenEdit={setRenameOpen}
        renameValue={noteName2}
        setRenameValue={setNoteName2}
        textData={"note"}
        handleEdit={editNoteName}
      />
    </>
    // <PptEditor/>
  );
};
export default NoteTakingEditFirst;
