import React from "react";
import Header from "../header/Header";
import Footer from "../footer/Footer";
import { Outlet, useLocation } from "react-router-dom";

const MainContainer2 = () => {
  const { pathname } = useLocation();
  return (
    <div className={pathname == "/" ? "hdr_bg" : ""}>
      <Header />
      <Outlet />
      <Footer />
    </div>
  );
};

export default MainContainer2;
