import Notification from "../../components/notification";
import SubjectsModal from "../../components/subjectsModal";
import React, { useEffect, useState } from "react";
import {
  useDeleteTestingMutation,
  useEditTestingMutation,
  useGetAllPastRecordsMutation,
} from "../../services/testingModule";
import { showError, showToast } from "../../constants/toast";
import { generateEncryptedKeyBody } from "../../utils/crypto";
import { CommonBody } from "../../types/General";
import Loader from "../../constants/Loader";
import Pagination from "../../components/pagination";
import moment from "moment";
import TestingResultModal from "../../components/testingResultModal";
import { DeleteModal } from "../../components/deleteModal";
import { FolderListComponent } from "../../components/folderList";
import { RenameModal } from "../../components/renameModal";
import useTranslation from "../../hooks/Translation";

function AchievementsTesting() {
  const [getHistory] = useGetAllPastRecordsMutation();
  const translate = useTranslation();
  const [openAch, setOpenAch] = useState<boolean>(false);
  const [achievements, setAchievements] = useState<any>([]);
  const [totalCount, setTotalCount] = useState(0);
  const [page, setPage] = useState(1);
  const totalPages = Math.ceil(totalCount / 20);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [open2, setOpen2] = useState<boolean>(false);
  const [rescordId, setRecordId] = useState<any>();
  const [open1, setOpen1] = React.useState(false);
  const handleClose1 = () => setOpen1(false);
  const onPageChange = (newPage: number) => {
    setPage(newPage);
  };

  const handleCloseAch = () => {
    setOpenAch(false);
  };

  const handleClose2 = () => {
    setOpen2(false);
  };

  const fetchHistory3 = async () => {
    setIsLoading(true);
    const body = {
      length: 20,
      start: page,
      search: "",
      sub_category: [4],
    };
    try {
      let encryptedBody = generateEncryptedKeyBody(body) as CommonBody;
      const res = await getHistory(encryptedBody).unwrap();
      setIsLoading(false);
      if (res?.status === 200) {
        setAchievements(res?.data?.response_object);
        setTotalCount(res?.data?.total_records);
      }
    } catch (error: any) {
      setIsLoading(false);
      showError(
        error?.data?.message ||
          translate.errors.somethingWentWrong ||
          "Something went wrong"
      );
    }
  };

  useEffect(() => {
    fetchHistory3();
  }, [page]);
  const [renameValue, setRenameValue] = useState("");
  const [openDelete, setOpenDelete] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [deleteData] = useDeleteTestingMutation();
  const [editData] = useEditTestingMutation();
  const [selectedId, setSelectedId] = useState(0);
  const toggleDelete = (id: number) => {
    setOpenDelete(true);
    setSelectedId(id);
  };
  const handleDelete = async (e: any) => {
    e.stopPropagation();
    try {
      const response = await deleteData({ id: selectedId }).unwrap();
      if (response.status == 200) {
        showToast(
          translate.toastMessages.testDeletedSuccessfully ||
            "Test Deleted Successfully"
        );
        setOpenDelete(false);
      }
    } catch (error: any) {
      console.log(error);
      showError(
        error?.data?.message ||
          translate.errors.somethingWentWrong ||
          "Something went wrong"
      );
    } finally {
      await fetchHistory3();
    }
  };
  const toggleEdit = (id: number, text: any) => {
    setOpenEdit(true);
    setRenameValue(text);
    setSelectedId(id);
  };
  const handleEdit = async (e: any) => {
    e.stopPropagation();
    try {
      const response = await editData({
        id: selectedId,
        body: { file_name: renameValue },
      }).unwrap();
      if (response.status == 200) {
        showToast(
          translate.toastMessages.testNameUpdatedSuccessfully ||
            "Test name updated Successfully"
        );
        setOpenEdit(false);
        setRenameValue("");
      }
    } catch (error: any) {
      console.log(error);
      showError(
        error?.data?.message ||
          translate.errors.somethingWentWrong ||
          "Something went wrong"
      );
    } finally {
      await fetchHistory3();
    }
  };
  return (
    <>
      <Loader isLoad={isLoading} />

      <button className="btnn btn_border" onClick={() => setOpenAch(true)}>
        <i className="fa-regular fa-plus"></i>{" "}
        {translate.testing.bySelf || "By Self"}
      </button>
      <ul className="tst_lst txt_rght">
        {achievements?.length ? (
          achievements.map((item: any) => (
            // <li
            //     key={item?.id}
            //     onClick={() => {
            //         setRecordId(item?.id);
            //         setOpen2(true);
            //     }}
            // >
            //     <h3>{item?.sub_category}</h3>
            //     <p className="mt_20">{moment(item?.created_at).format('DD/MM/YYYY')}</p>
            // </li>
            <FolderListComponent
              index={item?.index}
              dataCreatedAt={item?.created_at}
              dataName={item?.file_name}
              newClick={() => {
                setRecordId(item?.id);
                setOpen2(true);
              }}
              isEdit={true}
              key={item?.id}
              handleDelete={(e: any) => {
                e.stopPropagation();
                toggleDelete(item?.id);
              }}
              handleEdit={(e: any) => {
                e.stopPropagation();
                toggleEdit(item?.id, item?.file_name || "");
              }}
            />
          ))
        ) : (
          <div
            style={{
              height: "85px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
              marginTop: "15%",
            }}
          >
            {" "}
            <h3 style={{ fontSize: "xxx-large", color: "darkgrey" }}>
              {translate.global.noRecordsFound || "No Records Found"}
            </h3>
          </div>
        )}
      </ul>

      <TestingResultModal
        open={open2}
        setOpen={setOpen2}
        handleClose={handleClose2}
        id={rescordId}
      />
      <Notification
        open={open1}
        setOpen={setOpen1}
        handleClose={handleClose1}
      />
      <SubjectsModal
        open={openAch}
        setOpen={setOpenAch}
        handleClose={handleCloseAch}
      />
      <Pagination
        module={achievements}
        page={page}
        onPageChange={onPageChange}
        totalPages={totalPages}
      />
      <DeleteModal
        handleDelete={handleDelete}
        openDelete={openDelete}
        setOpenDelete={setOpenDelete}
        textData={translate.testing.test || "test"}
      />
      <RenameModal
        handleEdit={handleEdit}
        openEdit={openEdit}
        renameValue={renameValue}
        setOpenEdit={setOpenEdit}
        setRenameValue={setRenameValue}
        textData={translate.testing.test || "test"}
      />
    </>
  );
}

export default AchievementsTesting;
