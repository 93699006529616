import React, { ReactNode, useEffect, useState } from "react";
import Header from "../header/Header";
import SidePanel from "../sidepanel";
import { Outlet, useLocation, useSearchParams } from "react-router-dom";
import {
  getFromStorage,
  removeFromStorage,
  setToStorage,
} from "../../constants/storage";
import { STORAGE_KEYS } from "../../constants/storageKeys";

const MainContainer = () => {
  const [sidebar, setSidebar] = useState(true);
  const [searchParams, setSearchParams] = useSearchParams();
  const [displayHeaderSidebar, setDisplayHeaderSidebar] = useState(true);
  const { pathname } = useLocation();
  useEffect(() => {
    if (pathname === "/testing") {
      removeFromStorage(STORAGE_KEYS?.application);
    }
    if (searchParams?.get("app") || getFromStorage(STORAGE_KEYS.application)) {
      setToStorage(STORAGE_KEYS?.application, "1");
      setDisplayHeaderSidebar(false);
    }
    // else
    // {
    //   removeFromStorage(STORAGE_KEYS?.application);
    // }
  }, [pathname]);
  return (
    <div className="sdbr_rltv">
      {sidebar ? displayHeaderSidebar ? <SidePanel /> : "" : null}
      {displayHeaderSidebar ? <Header setSidebar={setSidebar} /> : ""}
      <Outlet />
    </div>
  );
};

export default MainContainer;
