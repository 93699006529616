export const en = {
  global: {
    searchStickers: "Search stickers",
    hi: "Hi",
    name: "Name",
    email: "Email",
    message: "Message",
    submit: "Submit",
    faqs: "FAQ's",
    privacyPolicy: "Privacy Policy",
    termsConditions: "Terms & conditions",
    whizzoUpperCase: "WHIZZO",
    login: "Login",
    signup: "Signup",
    update: "Update",
    yes: "Yes",
    no: "No",
    of: "of",
    users: "USERS",
    backToHome: "Back to Home",
    downloadPDF: "Download Pdf",
    downloadDoc: "Download Doc",
    shareViaEmail: "Share Via Email",
    back: "Back",
    download: "Download",
    changeLanguageDownload: "Change Language & Download",
    changeLanguage: "Change Language",
    translate: "Translate",
    downloadFileWithSpecifyingImportantVocabulary:
      "Download File with specifying important vocabulary",
    dragAndDropPdf: "Drag and drop an pdf here",
    dragAndDropImage: "Drag and drop an image here",
    or: "or",
    browseFile: "Browse a file",
    browsePDF: "Browse a pdf",
    edit: "Edit",
    delete: "Delete",
    file: "File",
    seeAll: "See All",
    notifications: "Notifications",
    notificationDescription: "All your notifications are here ",
    showing: "Showing",
    items: "items",
    rename: "Rename",
    cancel: "Cancel",
    nameIsRequired: "name is required",
    noStickersFound: "No Stickers found",
    noRecordsFound: "No Records Found",
    regenerate: "Regenerate",
    identifyingImportantVocabulary: "Identifying Important Vocabulary",
    aIExplanation: "AI Explanation",
    english: "English",
    arabic: "Arabic",
    next: "Next",
    getStarted: "Get Started",
    enterDescription: "Enter description",
    verified: "Verified",
    verify: "Verify",
    save: "Save",
    select: "Select",
    generate: "Generate",
    whizzoAiChatbot: "Whizzo AI Chatbot",
    noMessagesYet: " No messages yet.",
    done: "Done",
    highlightVocab: "Highlight Vocabulary",
  },
  main: {
    editedDataWillLost:
      "Are you sure you want to leave? Edited data will be lost.",
  },
  home: {
    home: "Home",
    let: "LET’S",
    ai: "AI",
    doYourWork: "DO YOUR WORK",
    doItMagic: "Do it's Magic",
    homeMessageLine1:
      "It is a long established fact that a reader will be distracted",
    homeMessageLine2: "by the readable content of a page when looking at its",
    homeMessageLine3: "layout. The point of using Lorem Ipsum is.",
    getStarted: "Get Started",
    getPersonalizedWithWhizzo: "Get Personal With WHIZZO Today",
    getPersonalizedSubtext:
      " Connect personally with Whizzo! Click below to initiate your chat and enjoy a personalized and engaging conversation experience.",
  },
  about: {
    aboutUs: "About Us",
    noInformationProvided: "No information Provided",
    seeMore: "see more",
    howItStarted: "How it started",
    howItStartedLine1:
      "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like).",
    howItStartedLine2:
      "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here",
    services: "Services",
    weProvide: "We provide",
    servicesWeProvideLine:
      "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is.",
    ourMembers: "Our Members",
    join: "Join",
    millions: "Millions",
    ofUsers: "Of Users",
    joinMillionsUserLine:
      "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is.",
    singleReviewLine1:
      "Awesome, the best artificial intelligence application. The first time my grades were low in the tests, I was not finding time, but this application saved us a lot of time.Awesome, the best artificial intelligence application. The first time my grades were low in the tests, I was not finding time, but this application saved us a lot of time.",
    singleReviewLine2:
      "Awesome, the best artificial intelligence application. My first grade was down,I had no time for the tests, but this application saved a lot of time time is on us",

    singleReviewName: "Adam Smith",
    singleReviewDetailLine1: "Lorem ipsum",
    singleReviewDetailLine2: "Lorem",
  },
  contact: {
    contactUs: "Contact Us",
    contactUsHomepageLine1: "It is a long established fact that a",
    contactUsHomepageLine2: "reader will be distracted by the",
    contactUsHomepageLine3: "readable content",
    contactUsPageLine1:
      "It is a long established fact that a reader will be distracted by the readable content",
    contactUsPageLine2:
      " of a page when looking at its layout. The point of using Lorem Ipsum is.",
    feelFreeToContactUs:
      "Feel free to contact us any time. We will get back to you as soon as we can.",
    whatWillBeNextStep: "What will be next step?",
    whatWillBeNextStepHeading:
      "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.",
    step1: "Lorem Ipsum",
    step1Description:
      "It is a long established fact that a reader will be distracted",
    step2: "Lorem Ipsum",
    step2Description:
      "It is a long established fact that a reader will be distracted",
    step3: "Lorem Ipsum",
    step3Description:
      "It is a long established fact that a reader will be distracted",
  },
  auth: {
    password: "Password",
    emailId: "Email Id",
    enterYourEmail: "Enter your email",
    forgotPassword: "Forgot Password",
    changePassword: "Change Password",
    oldPassword: "Old Password",
    newPassword: "New Password",
    passwordChanged: "Password changed successfully",
    currentPassword: "Current Password",
    enterCurrentPassword: "Enter current password",
    enterNewPassword: "Enter new password",
    enterPassword: "Enter password",
    reEnterPassword: "Re Enter Password",
    confirmNewPassword: "Confirm new password",
    confirmPassword: "Confirm Password",
    logoutConfirmation: "Are you sure you want to log out ?",
    deleteAccountConfirmation: "Are you sure you want to delete your account ?",
    enterValidOTP: "Please enter a valid otp",
    emailVerified: "Email Verified",
    otpVerification: "Otp Verification",
    codeSentToEmail: "We have sent you an SMS with a code to email",
    codeNotReceived: "Didn’t get the code?",
    resend: "Resend",
    forgetPasswordDetailList1:
      "It is a long established fact that a reader will be distracted",
    forgetPasswordDetailList2:
      "by the readable content of a page when looking at its",
    forgetPasswordDetailList3: "layout. The point of using Lorem Ipsum is.",
    joinMillions: "JOIN MILLIONS",
    loginDetailLine1:
      "It is a long established fact that a reader will be distracted",
    loginDetailLine2: "by the readable content of a page when looking at its",
    loginDetailLine3: "    layout. The point of using Lorem Ipsum is.",
    signIn: "Sign in",
    signUp: "Sign up",
    profileSetupDetailLine1:
      "It is a long established fact that a reader will be distracted",
    profileSetupDetailLine2:
      "by the readable content of a page when looking at its",
    profileSetupDetailLine3: "layout. The point of using Lorem Ipsum is.",
    profileSetup: "Profile Setup",
    firstName: "First Name",
    lastName: "Last Name",
    enterFirstName: "Enter first name",
    enterLastName: "Enter last name",
    phoneNumber: "Phone number",
    purpose: "Purpose",
    resetPassword: "Reset Password",
    enterEmailAddress: "Enter Email Address",
    rememberMe: "Remember me",
    DoNotHaveAnAccount: "Don’t have an account?",
    emailMustLessThan80Characters: "Email should be less than 80 characters",
    iAcceptAll: "I accept all",
    termsConditions: "terms & conditions .",
    alreadyHaveAccount: "Already have an account?",
    profileVerified: "Profile verified",
    invalidOtp: "Invalid Otp",
    otpResentSuccessfully: "Otp Resent successfully",
    verifyAccount: "Verify Account",
    sentSMSCodeEmail: "We have sent you an SMS with a code to email",
  },
  testing: {
    test: "test",
    testing: "Testing",
    selectTypeOfTest: "Select the type of test you want to take",
    abilities: {
      selectTypeOfAbility: "Select the type of Abilities you want to take",
    },
    achievements: {
      selectTypeOfAchievement:
        "Select the type of Achievements you want to take",
    },
    questionsWithOptions: "Questions with Options",
    flashcards: "Flashcards",
    deleteWarning: "Are you sure you want to delete",
    testCompleted: "Test Complete",
    selectSubject: "Please select a subject",
    repeatingQuestions: "Repeating questions",
    testResult: "Test Result",
    overallScore: "Overall Score",
    correct: "Correct",
    incorrect: "Incorrect",
    remaining: "Remaining",
    reAppear: "Re-appear",
    reAppearMessage: "Do this test again",
    continue: "Continue",
    continueMessage: "Continue the test",
    finish: "Finish",
    finishMessage: "Finish the test",
    downloadWithoutAnswers: "Download File Without Answers",
    downloadWithAnswers: "Download File With Answers",
    checkTestForYou: "CHECK TEST FOR YOU",
    bySelf: "By Self",
    myTests: "My Tests",
    Abilities: "Abilities",
    Achievements: "Achievements",
    createATestForYourself: "Create a Test for Yourself",
    TestQuestionsGeneratedSuccessfully: "Test questions Generated successfully",
    flashcardsGeneratedSuccessfully: "Flashcards Generated successfully",
    pleaseWaitQuestionsBeingLoaded:
      "Please wait while the questions are being loaded",
    Skip: "Skip",
    theAnswerIs: "The answer is",
    right: "Right",
    wrong: "Wrong",
    ques: "Ques",
    ans: "Ans",
    guessAnswerClickCard: "Guess the answer and click on card",
    wasAnswerCorrect: "Was your answer Correct?",
    typeMessage: "Type a message",
  },
  fileSummarization: {
    fileSummarizationLowerCase: "file summarization",
    fileSummarization: "File Summarization",
    fileDeletedSuccessfully: "File Deleted Successfully",
    createNewFile: "Create a new File",
    summarization: "Summarization",
  },
  noteTaking: {
    notes: "Notes",
    note: "note",
    noteTaking: "Note Taking",
    notetaking: "Note taking",
    selectNoteTakingType: "Select the type of Note you want to take",
    createNewNote: "Create a new Note",
    writeAboutMe: "Write About Me",
    takeNotesForYou: "TAKE NOTES FOR YOU",
    editNotes: "Edit Notes",
    myPastNotes: "My Past Notes",
    allNotes: "All Notes",
    recentNotes: "Recent notes",
    favorite: "Favourites",
    noRecentNotes: "No Recent Notes",
    addSomethingToGetDetail: "Please add something to get detail",
    selectSomethingToGetDetail: "Please select something to get detail",
    speakSomethingBefore: "Please speak something before",
    pleaseWriteSomeComment: "Please write some comment",
    noteUpdatedSuccessfully: "Note updated successfully",
    noteAddedSuccessfully: "Note added successfully",
    updateNote: "Update Note",
    addNote: "Add Note",
    speakSomething: "Speak Something",
    comment: "Comment",
    comments: "Comments",
    noComments: "No Comments",
    noteDownloadedSuccessfully: "Note Downloaded Successfully",
    textCopiedSuccessfully: "Text Copied successfully",
    textPastedSuccessfully: "Text pasted successfully",
    clipboardIsEmpty: "Clipboard is empty",
    chooseTemplate: "Choose Template",
  },
  research: {
    researchLowerCase: "research",
    research: "Research",
    myResearches: "My Researches",
    startWithReferencesHeader: "Start Your Research with References?",
    startWithReferences: "Start with References",
    startWithoutReferences: "Start without References",
    topicResearch: "Topic of Research",
    createNewResearch: "Create a new Research",
    reduceCitations: "Reduce Citations",
    specifyReferences: "Specify References",
    short: "Short",
    medium: "Medium",
    long: "Long",
    veryLong: "Very Long",
    approx: "Approx.",
    pages: "pages",
    words: "words",
    symbols: "symbols",
    researchPaper: "Research Paper",
    addPoint: "Add Point",
    addSection: "Add Section",
  },
  assignmentSolution: {
    assignmentsolutions: "Assignment solutions",
    assignmentSolution: "Assignment Solutions",
    getNewSolution: "Get a new Solution",
    myPastSolutions: "My Past Solutions",
    uploadQuestionsToGetSolutions:
      "Upload Questions to get solutions through AI",
    selectLanguage: "Select Language",
    GiveYouSolution: "GIVE YOU SOLUTIONS",
    requestRevision: "Request Revision",
  },
  fileTypeConversion: {
    fileTypeConversion: "File Type Conversion",
    uploadSelectTypeConversion: "Upload and select the type of conversion",
    myPastFiles: "My Past Files",
    dragAndDropFile: "Drag and drop your file here",
    multipleImages: "Multiple images",
    pleaseSelectTypeConversion: "Please select a Type of conversion",
    addFromCloud: "Add your file from cloud",
    uploadFromCloud: "Upload from cloud",
    selectTypeOfConversion: "Select type of conversion",
    noFileSelected: "No file selected",
    convert: "Convert",
    convertedFileReady: "Your Converted file is ready",
    totalFiles: "Total files",
    fileName: "File Name",
  },
  presentation: {
    presentation: "Presentation",
    presentations: "Presentations",
    enterPresentationPrompt: "Enter Prompt for AI to generate presentation",
    enterPresentationTopic: "Enter presentation topic",
    createNewPresentation: "Create a new Presentation",
    myPresentations: "My Presentations",
    selectTemplates: "Select Templates",
    pptDownloadedSuccessfully: "PPT Downloaded Successfully",
    pptDoNotHaveAnySlides: "PPT don't have any slides",
    selectSomethingToDelete: "Select something to delete",
    selectImageToCrop: "Select image to crop",
    themeChangedSuccessfully: "Theme changed successfully",
    presentationUpdatedSuccessfully: "Presentation updated successfully",
    itemCopiedSuccessfully: "Item Copied successfully",
    pleaseSelectAnythingFirstToCopy: "Please select anything first to copy",
    itemPastedSuccessful: "Item Pasted Successful",
  },
  article: {
    articles: "Articles",
    Article: "Article",
    myArticles: "My Articles",
    createNewArticle: "Create a new Article",
    addPicturesWithAi: "Add Pictures with AI",
    topicOfArticle: "Topic of Article",
    enterArticleTopic: "Enter article topic",
    selectToneVoice: "Select Tone of voice",
    specifyLanguageRegionPages:
      "Specify Language, Region and the number of pages",
    selectLanguage: "Select Language",
    selectRegion: "Select Region",
    pleaseSelectRegion: "Please select region",
    pointOfView: "Point of View (Pronouns)",
    doYouWantToPutPictures: "Do you want to put pictures?",
  },
  subscriptions: {
    subscriptions: "Subscriptions",
    subscriptionPlan: "Subscription Plan",
    subscriptionPlans: "Subscription Plans",
    subscriptionPlansDescription:
      "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is.",
    subscriptionPageLine1:
      " It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is.",
    subscriptionHomepageLine1:
      " It is a long established fact that a reader will be distracted by the readable content",
    subscriptionHomepageLine2:
      " of a page when looking at its layout. The point of using Lorem Ipsum is.",
    monthly: "Monthly",
    monthlyLine1: "It is a long established fact ",
    monthlyLine2: "It is a long established fact that a",
    monthlyLine3: "It is a long established fact that a",
    monthlyLine4: "It is a long established fact that a",
    quarterly: "Quarterly",
    quarterlyLine1: "It is a long established fact ",
    quarterlyLine2: "It is a long established fact that a",
    quarterlyLine3: "It is a long established fact that a",
    quarterlyLine4: "It is a long established fact that a",
    yearly: "Yearly",
    yearlyLine1: "It is a long established fact ",
    yearlyLine2: "It is a long established fact that a",
    yearlyLine3: "It is a long established fact that a",
    yearlyLine4: "It is a long established fact that a",
    subscribe: "Subscribe",
    subscribeUpperCase: "SUBSCRIBE",
    chooseAPlan: "Choose a plan",
    chooseAPlanDescription:
      "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is.",
    featureComparison: "Feature Comparison",
    basis: "Basis",
    learnerExperience: "Learner Experience",
    usersOn: "users on",
    paymentDetails: "Payment Details",
    totalAmount: "Total Amount",
    tax: "Tax",
    grandTotal: "Grand Total",
    payNow: "Pay Now",
    congratulations: "Congratulations",
    paymentCompleted: "Your payment is successfully completed",
    paymentOptions: "Payment Options",
    addNewCard: "ADD NEW CARD",
    saveAndPayViaCard: "Save and Pay via Cards.",
    whizzoSubscription: "Whizzo Subscription",
    freeTrial: "Free Trial",
    monthlySubscription: "Monthly Subscription",
    quarterlySubscription: "Quarterly Subscription",
    yearlySubscription: "Yearly Subscription",
    tryForFree: "Try for Free",
  },
  settings: {
    settings: "Settings",
    verifyYourEmail: "Please Verify your Email",
    profileUpdatedSuccessfully: "Profile updated successfully",
    loginWithNewEmail: "Please login with new Email",
    termsConditions2: "Terms & Conditions",
    FAQs: "FAQs",
    myProfile: "My Profile",
    logOut: "Log Out",
    noFaqFound: "No Faq Found",
    deleteAccount: "Delete Account",
    clickToWhatsapp: "Click Here to go to Whatsapp",
    clickToEmail: "Click Here to go to E-mail",
    noTermsConditionsFound: "No Terms and Conditions Found",
  },
  header: {},
  footer: {
    footerDescription:
      "The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters",
    quickLinks: "Quick links",
    rightsReserved: "© 2024 WHIZZO, All Rights Reserved.",
    facebook: "Facebook",
    instagram: "Instagram",
    youtube: "Youtube",
    linkedIn: "LinkedIn",
  },
  validations: {
    requiredEmail: "Email is required",
    emailLessThan80: "Email must be 80 characters or fewer",
    enterValidEmail: "Please enter a valid email",
    requiredName: "Name is required",
    nameLessThan50: "Name must be 50 characters or fewer",
    requiredMessage: "Message is required",
    messageLessThan200: "Message must be 200 characters or fewer",
    requiredOldPassword: "Old password is required.",
    passwordAtleast5CharacterLong:
      "Password must be at least 5 characters long",
    requireNewPassword: "New password is required.",
    requirePassword: "Password is required.",
    newOldPasswordSameNotAllowed:
      "New password cannot be same as old password.",
    passwordMustMatch: "Passwords must match.",
    requiredConfirmPassword: "Confirm password is required.",
    minimumTwoCharacterRequired: "Minimum 2 characters are required",

    maximum100CharactersRequired: "Maximum 100 characters is required",
    maximum50CharactersRequired: "Maximum 50 characters is required",
    nameContainOnlyAlphabets: "Name must contain only alphabets",
    requiredPhone: "Phone number is required",
    phoneAtLeastMoreThan6Characters:
      "Phone number must be at least 6 characters",
    phoneMustLessThan16Characters:
      "Phone number must be less than 16 characters",
  },
  errors: {
    onlyPDFFormat: "Please upload the file in PDF format only",
    somethingWentWrong: "Something went wrong",
    selectValidFormat: "Please select a valid format.",
    onlyMultipleImages: "You can only select multiple images",
    userNotExist: "User does not exist",
    onlyJpgPngAllowed: "Invalid file format: only png, jpg images are allowed",
    imageUploadError: "Error uploading image. Please try again.",
    selectPurpose: "Please select a purpose",
    invalidCredentials: "Invalid credentials",
    acceptTermsConditions: "Please accept terms & conditions",
    selectPlan: "Please select a plan",
    topicNameNotExceed500Characters: "Topic name can't exceed 500 characters",
    enterArticleTopics: "Please enter topic of article",
    presentationTopicNameCantExceed500Characters:
      "Presentation topic name can't exceed 500 characters",
    enterTopicPresentation: "Please enter topic of presentation",
    enterTopicResearch: "Please enter topic of research",
    descriptionCanNotExceed500Characters:
      "Description can't exceed 500 characters",
    pleaseTryAgain: "Please try again",
    pleaseLogin: "Please login",
    regenerationUnsuccessful: "Regeneration unsuccessful ,Please try again",
    GenerationUnsuccessful: "Generation unsuccessful ,Please try again",
    selectAtleastPage: "Select atleast one page",
    corruptedPdfFile: "Corrupted PDF file",
    selectAtleastOneNote: "Select atleast one note",
  },
  toastMessages: {
    generationSuccessfully: "Generation successfully",
    ProfilePictureUploadedSuccessfully: "Profile Picture uploaded successfully",
    logoutSuccessful: "Logged out successfully",
    accountDeletedSuccessfully: "Account deleted successfully",
    articleDeletedSuccessfully: "Article Deleted Successfully",
    assignmentDeletedSuccessfully: "Assignment Deleted Successfully",
    pdfDownloadedSuccessfully: "Pdf downloaded successfully",
    articleNameUpdatedSuccessfully: "Article name updated successfully",
    assignmentNameUpdatedSuccessfully: "Assignment name updated Successfully",
    testNameUpdatedSuccessfully: "Test name updated Successfully",
    profileSetupSuccessfully: "Profile setup successfully",
    passwordResetSuccessfully: "Password reset successfully",
    LoginSuccessfully: "Logged in successfully",
    conversionSuccessful: "Conversion successful",
    testDeletedSuccessfully: "Test Deleted Successfully",
    pdfSharedOverEmail: "Pdf shared over email",
    regenerationSuccessful: "Regeneration successful",
    researchDeletedSuccessfully: "Research Deleted Successfully",
    researchNameUpdatedSuccessfully: "Research name updated Successfully",
    recordsDuplicatedSuccessfully: "Records duplicated successfully",
    recordsUpdatedSuccessfully: "Records updated successfully",
    recordsDeletedSuccessfully: "Records deleted successfully",
    fileNameUpdatedSuccessfully: "File name updated Successfully",
    downloadedSuccessfully: "Downloaded Successfully",
    presentationNameUpdatedSuccessfully: "Presentation name updated Successfully",
    noteNameUpdatedSuccessfully: "Note name updated Successfully",
    presentationDeletedSuccessfully: "Presentation Deleted Successfully",
  },
};
