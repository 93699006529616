import React from "react";

function PageNotFound() {
  const containerStyle: any = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    color: "white",
    padding: "10%",
    minHeight: "100vh",
  };

  const headingStyle = {
    fontSize: "2.5rem",
    marginBottom: "1rem",
    fontWeight: "bold",
  };

  const textStyle = {
    fontSize: "1.2rem",
    maxWidth: "500px",
    lineHeight: "1.5",
    marginBottom: "2rem",
  };

  const linkStyle = {
    color: "#7054cb",
    fontWeight: "bold",
    textDecoration: "underline",
    cursor: "pointer",
  };

  return (
    <div style={containerStyle}>
      <h2 style={headingStyle}>404 - Page Not Found 😮</h2>
      <p style={textStyle}>
        Oops! The page you're looking for doesn’t seem to exist on Whizzo Web.
        It might have been moved or deleted, or the URL might be incorrect.
        Don’t worry, we’ll help you get back on track!
      </p>
      <p style={textStyle}>
        <span style={linkStyle} onClick={() => (window.location.href = "/")}>
          Go back to the Whizzo Web homepage
        </span>
      </p>
    </div>
  );
}

export default PageNotFound;
