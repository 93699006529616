/* eslint-disable @typescript-eslint/no-unused-vars */
import Layout from "../../layout/index";
import "react-tabs/style/react-tabs.css";
import { useNavigate } from "react-router-dom";
import { Button, SelectChangeEvent } from "@mui/material";
import React, { useState } from "react";
import Header from "../../layout/header/Header";
import useAuth from "../../hooks/useAuth";
import { useDropzone } from "react-dropzone";
import { showError, showWarning } from "../../constants/toast";
import useTranslation from "../../hooks/Translation";

function Assignsolution() {
  function valuetext(value: number) {
    return `${value}°C`;
  }
  const translate = useTranslation();
  const user = useAuth();
  const navigate = useNavigate();
  const [language, setLanguage] = useState<string>("english");
  const [selectedFile, setSelectedFile] = useState<any>(null);
  const [category, setCategory] = React.useState("0");
  const handleChange = (event: SelectChangeEvent) => {
    setCategory(event.target.value as string);
  };
  const [sidebar, setSidebar] = useState(true);

  const imagesType = ["png", "jpeg", "jpg"];
  const handleFileChange = (event: any) => {
    let type = 2;
    if (!event.length) {
      showWarning(
        translate.errors.selectValidFormat || `Please select a valid format.`
      );
      return;
    }
    const file = event.length === 1 ? event[0] : event;
    if (event.length == 1) {
      const extension =
        file?.name?.split(".")?.[file?.name?.split(".")?.length - 1];
      if (extension === "pdf") {
        type = 1;
        navigate("/pdfPreview", {
          state: {
            type: type,
            prevPage: "/assign-solution",
            nextPage: "/edit-assign",
            selectedFile: file,
            language: language,
          },
        });
      } else {
        type = 2;
        navigate("/edit-assign", {
          state: { selectedFile: file, type, language },
        });
      }
    } else {
      let flag = 1;
      for (let key = 0; key < event.length; key++) {
        const extension =
          event[key]?.name?.split(".")?.[
            event[key]?.name?.split(".")?.length - 1
          ];
        if (!imagesType.includes(extension)) {
          flag = 0;
        }
      }
      if (flag == 0) {
        showError(
          translate.errors.onlyMultipleImages ||
            "You can only select multiple images"
        );
        return;
      }
      type = 2;
      navigate("/edit-assign", {
        state: { selectedFile: file, type, language },
      });
    }
    // const validTypes = ['application/pdf', 'image/png', 'image/jpeg'];
    // if (!validTypes.includes(file.type)) {
    //   showError('Please select a valid format (PDF, PNG, or JPG)');
    //   return;
    // }
    setSelectedFile(file);

    // navigate("/edit-assign", { state: { file, type, language } });
    // navigate("/assign-video", { state: { file, type, language } })
  };

  // console.log("FILE", selectedFile);

  const dropFile = (e: any) => {
    console.log(e);
    handleFileChange(e);
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      "application/pdf": [".pdf"],
      "image/*": [".png", ".jpeg", ".jpg"],
    },
    onDrop: dropFile,
    multiple: true,
  });

  return (
    <>
      <div className="sde_br_mn">
        <div className="slutn_txt">
          <p>{translate.assignmentSolution.uploadQuestionsToGetSolutions}</p>
          <Button className="btnn btn_primary h_46" disabled>
            {translate.assignmentSolution.getNewSolution}
          </Button>
        </div>
        <div className="sde_mn_cnt fle_type_mn asgn">
          <ul className="slct_lst">
            <li>
              <p>{translate.assignmentSolution.selectLanguage}</p>
              <div className="lguag_btn">
                <Button
                  className={
                    language === "english"
                      ? "btnn btn_primary"
                      : "btnn btn_primary inActive"
                  }
                  style={{ marginRight: "20px" }}
                  onClick={() => setLanguage("english")}
                >
                  {translate.global.english}
                </Button>
                <Button
                  className={
                    language === "arabic"
                      ? "btnn btn_primary"
                      : "btnn btn_primary inActive"
                  }
                  onClick={() => setLanguage("arabic")}
                >
                  {" "}
                  {translate.global.arabic}
                </Button>
              </div>
            </li>
          </ul>
          <div {...getRootProps()} className="modl_drg modl text_center">
            <figure>
              <img
                style={{ maxWidth: "45%" }}
                src={
                  selectedFile
                    ? "/static/images/format/pdf.png"
                    : "/static/images/image-upload.png"
                }
                alt=""
              ></img>
            </figure>
            <p className="drg">
              {translate.global.dragAndDropImage}
              <span className="d_block">{translate.global.or}</span>
            </p>
            <Button className="btnn btn_primary upld_btn">
              {translate.global.browseFile}
              <input type="file" {...getInputProps()} />
            </Button>
          </div>
        </div>
      </div>
    </>
  );
}

export default Assignsolution;
