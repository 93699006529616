import React, { useState, useEffect } from 'react';
import Draggable from 'react-draggable';
import './ruler4.css';
import { IconButton } from '@mui/material';
import { Close } from '@mui/icons-material';

const HorizontalRuler = () => {
    const [rotation, setRotation] = useState(0);
    const [tickCount, setTickCount] = useState(0);
    const [isDragging, setIsDragging] = useState(false); // State to track dragging

    const calculateTickCount = () => {
        const containerWidth = window.innerWidth;
        setTickCount(Math.floor(containerWidth / 10));
    };

    useEffect(() => {
        calculateTickCount();
        window.addEventListener('resize', calculateTickCount);
        return () => {
            window.removeEventListener('resize', calculateTickCount);
        };
    }, []);

    // Mouse down and up handlers to control dragging
    const handleMouseDown = () => {
        setIsDragging(true);
    };

    const handleMouseUp = () => {
        setIsDragging(false);
    };

    return (
        <div
            style={{
                transform: `rotate(${rotation}deg)`,
                transformOrigin: 'center center',
                position: 'fixed',  // Fixed to stay in place
                top: '100px', // Center it vertically on the screen (adjust based on ruler height)
                zIndex: 9999,       // Ensure it's on top of other elements
                width: '100%',      // Take full width for ticks
            }}
        >
            <Draggable
                // axis="y" // Allow vertical movement
                onStart={handleMouseDown} // Start dragging
                onStop={handleMouseUp} // Stop dragging
                // bounds={{ left: 0, top: 0, right: 0, bottom: window.innerHeight-170 }} // Limit vertical bounds
            >
                <div className="ruler">
                    {/* <div style={{position:'absolute',right:0,top:-10}}>
                        <IconButton>
                            <Close/>
                        </IconButton>
                    </div> */}
                    <div className="ruler-ticks">
                        {[...Array(tickCount)].map((_, i) => (
                            <div
                                key={i}
                                className={`tick ${i % 10 === 0 ? 'major' : 'minor'}`}
                                style={{ left: `${i * 10}px` }}
                            >
                                {/* {i % 10 === 0 && <span className="label">{i / 10}</span>} */}
                            </div>
                        ))}
                    </div>
                </div>
            </Draggable>
        </div>
    );
};

const VerticalRuler = () => {
    const [tickCount, setTickCount] = useState(0);
    const [isDragging, setIsDragging] = useState(false);

    const calculateTickCount = () => {
        const containerHeight = window.innerHeight;
        setTickCount(Math.floor(containerHeight / 10));
    };

    useEffect(() => {
        calculateTickCount();
        window.addEventListener('resize', calculateTickCount);
        return () => {
            window.removeEventListener('resize', calculateTickCount);
        };
    }, []);

    // Mouse down and up handlers to control dragging
    const handleMouseDown = () => {
        setIsDragging(true);
    };

    const handleMouseUp = () => {
        setIsDragging(false);
    };

    return (
        <div
            style={{
                position: 'fixed',
                left: 0, // Position it horizontally (adjust based on ruler width)
                top: 112,
                zIndex: 9999, // Ensure it's on top of other elements
                height: '100%', // Take full height for ticks
            }}
        >
            <Draggable
                // axis="x" // Allow horizontal movement
                onStart={handleMouseDown}
                onStop={handleMouseUp}
                // bounds={{ left: 0, top: 0, right: window.innerWidth -70, bottom: 0 }} // Limit horizontal bounds
            >
                <div className="mohit-ruler">
                    <div className="mohit-ruler-ticks-vertical">
                        {[...Array(tickCount)].map((_, i) => (
                            <div
                                key={i}
                                className={`mohit-tick ${i % 10 === 0 ? 'mohit-major' : 'mohit-minor'}`}
                                style={{ top: `${i * 10}px` }}
                            >
                                {/* {i % 10 === 0 && <span className="mohit-label">{i / 10}</span>} */}
                            </div>
                        ))}
                    </div>
                </div>
            </Draggable>
        </div>
    );
};
const Ruler = ({rulerType}:any) => {
 if(rulerType==1)
 {
    return <VerticalRuler/>
 }
 else if(rulerType==2)
 {
    return <HorizontalRuler/>
 }
 else 
 {
    return null;
 }

}

export default Ruler
